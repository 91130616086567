<template>
  <v-container class="pa-0 no-width base-table--filters" grid-list-md>
    <v-layout wrap>
      <v-flex xs12 sm6 md3>
        <InputDateTime
          @change:datetimepicker="handleDatepickerChange"
          :startedDate="startedDate"
        >{{ lang.period }}</InputDateTime>
      </v-flex>
      <v-flex xs12 sm6 md2 align-self-center>
        <ButtonPrimary
          small
          class="base-table--filters__button"
          @click:base-button="handleSearch"
        >Filter</ButtonPrimary>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
  import ButtonPrimary from "@/components/ButtonPrimary";
  import InputDateTime from "@/components/InputDateTime";

  export default {
    components: {
      ButtonPrimary,
      InputDateTime
    },

    data() {
      return {
        startedDate: {
          start: null,
          end: null
        },
        filters: {
          dateFrom: null,
          dateTo: null
        }
      };
    },

    computed: {
      lang() {
        return {
          period: "Period"
        };
      }
    },

    methods: {
      handleSearch() {
        this.$router.replace({ query: { ...this.filters } });
        this.$emit("click:search-btn", this.filters);
      },
      handleDatepickerChange({ start, end }) {
        if (start && end) {
          if (
            start !== this.$route.query.dateFrom ||
            end !== this.$route.query.dateTo
          ) {
            this.filters.dateFrom = start;
            this.filters.dateTo = end;
            this.handleSearch();
          }
        }
      },
      setStartedDate() {
        const dateFrom = this.$route.query.dateFrom;
        const dateTo = this.$route.query.dateTo;

        if (typeof dateFrom !== "undefined" && typeof dateTo !== "undefined") {
          this.startedDate = {
            start: dateFrom,
            end: dateTo
          };
        } else {
          this.startedDate = {
            start: window
              .moment()
              .startOf("month")
              .format("YYYY-MM-DD"),
            end: window
              .moment()
              .endOf("month")
              .format("YYYY-MM-DD")
          };
        }

        this.filters.dateFrom = this.startedDate.start;
        this.filters.dateTo = this.startedDate.end;
      }
    },
    created() {
      this.setStartedDate();
    },
    mounted() {
      this.handleSearch();
    },
    beforeDestroy() {
      this.$off("click:base-button");
      this.$off("change:datetimepicker");
    }
  };
</script>

<style>
</style>
