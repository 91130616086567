<template>
  <div class="catalog-banners">
    <v-container fluid grid-list-lg class="pa-0">
      <v-layout row>
        <v-flex d-flex class="pl-0 pt-0 pb-0">
          <div class="catalog-banner catalog-banner--big" v-html="banners[0].bodyHtml"></div>
        </v-flex>
        <v-flex>
          <v-layout row>
            <v-flex d-flex-inline class="pt-0">
              <div class="catalog-banner catalog-banner--small" v-html="banners[1].bodyHtml"></div>
            </v-flex>
          </v-layout>
          <v-layout row>
            <v-flex d-flex-inline>
              <div class="catalog-banner catalog-banner--small" v-html="banners[2].bodyHtml"></div>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
export default {
  props: {
    banners: {
      type: [Array],
      required: true
    }
  },
  data() {
    return {
      TYPE_BIG: "big",
      TYPE_SMALL: "small"
    };
  }
};
</script>

<style lang="scss">
$padding: 0px;

.catalog-banners {
  overflow: hidden;
  max-height: 558px + $padding;
  display: inline-block;

  .catalog-banner {
    position: relative;
    overflow: scroll;
    padding: $padding;
    width: 100%;

    &--big {
      text-align: right;
      width: 463px;
      height: 558px;

      img {
        width: inherit !important;
      }
    }

    &--small {
      text-align: right;
      width: 271px;
      height: 271px;
    }
  }
}
</style>
