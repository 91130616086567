import api from '@/api/translations';

import {
  STATUS_OK
} from '@/constants/httpCodes';

export default {
  state: {
    translations: false,
  },
  getters: {
    getTranslations: state => state.translations,
  },
  mutations: {
    SET_TRANSLATIONS_DATA(state, payload) {
      state.translations = payload;
    },
  },
  actions: {
    fetchTranslations({
      commit,
    }, payload) {
      api.get(payload)
        .then(({
          data: {
            items
          },
          status
        }) => {
          if (status === STATUS_OK) {
            commit('SET_TRANSLATIONS_DATA', items);
          }
        })
        .finally(() => {})
    },
  },
};
