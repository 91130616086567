import api from '@/api/categories';

import {
  STATUS_OK
} from '@/constants/httpCodes';

export default {
  state: {
    categories: null,
    category: null
  },
  getters: {
    getCategories: state => state.categories,
    getSubCategoriesById: state => id => state.categories && state.categories.items.filter(item =>
      item.id == id)[0].children,
    getCategory: state => state.category,
  },
  mutations: {
    SET_CATEGORIES_DATA(state, payload) {
      state.categories = payload;
    },
    SET_CATEGORY_DATA(state, payload) {
      state.category = payload;
    },
    REMOVE_CATEGORY_DATA(state) {
      state.category = null;
    },
  },
  actions: {
    fetchCategories({
      commit,
      dispatch
    }) {
      dispatch('setIsProgressData', true);
      api.get()
        .then(({
          data,
          status
        }) => {
          if (status === STATUS_OK) {
            commit('SET_CATEGORIES_DATA', data);
          }
        })
        .finally(() => {
          dispatch('setIsProgressData', false);
        })
    },
    fetchCategoryById({
      commit,
      dispatch
    }, payload) {
      dispatch('setIsProgressData', true);
      api.getById(payload)
        .then(({
          data,
          status
        }) => {
          if (status === STATUS_OK) {
            commit('SET_CATEGORY_DATA', data);
          }
        })
        .finally(() => {
          dispatch('setIsProgressData', false);
        })
    },
  },
};
