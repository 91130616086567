<template>
    <div>
        <BaseTable
            class="hide-first-id"
            :headers="headers"
            :items="items"
            :hide-columns="['status']"
        >
            <th v-if="items && items.length" slot="th"></th>
            <template slot-scope="{item: {id, status}}" slot="conditional-button">
                <router-link
                    v-if="status === constants.STATUS_APPROVED || status === constants.STATUS_MODERATE_CANCEL"
                    :to="{name: 'dashboard.payment-solutions.details', params: {id: id}}"
                >
                    <ButtonPrimary outline small>Finish connection</ButtonPrimary>
                </router-link>
                <ButtonPrimary
                    v-else-if="status === constants.STATUS_CANCELED"
                    @click:base-button="tryAgain(id)"
                    outline
                    small
                    :loading="itemsIsLoading[id]"
                >Try again</ButtonPrimary>
            </template>
        </BaseTable>
    </div>
</template>

<script>
import { mapActions } from "vuex";
import { getStatusText, getStatusStyle, getStorePlatformText } from "@/helpers/paymentSolution";
import * as constants from "@/constants/paymentSolutions";
import ButtonPrimary from "@/components/ButtonPrimary";
import { getTypeIdText } from "@/helpers/salesChannel";
import api from "@/api/paymentSolutions";
import { STATUS_OK } from "@/constants/httpCodes";

export default {
    components: {
        ButtonPrimary
    },
    data() {
        return {
            items: [],
            headers: [
                { text: "ID", value: "id" },
                { text: "Site URL", value: "siteUrl" },
                { text: "Store platform", value: "storePlatform" },
                { text: "Status", value: "statusText" },
                { text: "Cancel reason", value: "cancelReason" }
            ],
            constants: constants,
            itemsIsLoading: {}
        };
    },
    props: {
        paymentSolutions: {
            type: Array,
            required: true
        }
    },
    methods: {
        ...mapActions(["setNotifyMsg"]),
        async tryAgain(id) {
            this.$set(this.itemsIsLoading, id, true);

            try {
                const { status } = await api.changeStatus(id, constants.STATUS_PENDING);

                if (status === STATUS_OK) {
                    this.paymentSolutions.forEach(function (paymentSolution) {
                        if (paymentSolution.id === id) {
                            paymentSolution.status = constants.STATUS_PENDING;
                        }
                    }.bind(this));
                }
            } catch (error) {
                this.setNotifyMsg({ text: error.response.data.errors, color: "error" });
            } finally {
                this.$set(this.itemsIsLoading, id, false);
            }
        }
    },
    watch: {
        paymentSolutions: {
            immediate: true,
            deep: true,
            handler(paymentSolutions) {
                this.items = paymentSolutions.map(item => {
                    return {
                        id: item.id,
                        status: item.status,
                        siteUrl: item.salesChannel ? item.salesChannel.name : item.siteUrl,
                        storePlatform: item.salesChannel ? getTypeIdText(item.salesChannel.typeId) : getStorePlatformText(item.storePlatform),
                        statusText: `<div class="tag-status tag-status--${getStatusStyle(item.status)}">${getStatusText(item.status)}</div>`,
                        cancelReason: [constants.STATUS_CANCELED, constants.STATUS_MODERATE_CANCEL].indexOf(item.status) >= 0 ? item.cancelReason : null,
                        conditionalButton: true
                    };
                });
            }
        }
    }
}
</script>

<style scoped lang="scss">
</style>
