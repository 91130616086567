<template>
  <BaseIcon :width="width" :height="height" stroke="none" fill="none" name="tickets">
    <path
      :fill="fill"
      d="M16 1c.6 0 1 .4 1 1v9c0 .6-.4 1-1 1h-3v2.6l-2.2-2.3-.3-.3H2c-.6 0-1-.4-1-1V2c0-.6.4-1 1-1h14zm0-1H2C.9 0 0 .9 0 2v9c0 1.1.9 2 2 2h8.1l3.9 4v-4h2c1.1 0 2-.9 2-2V2c0-1.1-.9-2-2-2z"
    />
    <path
      :fill="fill"
      fill-rule="evenodd"
      d="M4 4.5c0-.276.187-.5.417-.5h9.166c.23 0 .417.224.417.5s-.187.5-.417.5H4.417C4.187 5 4 4.776 4 4.5zM4 8.5c0-.276.13-.5.292-.5h6.416c.161 0 .292.224.292.5s-.13.5-.292.5H4.292C4.13 9 4 8.776 4 8.5z"
      clip-rule="evenodd"
    />
  </BaseIcon>
</template>

<script>
export default {
  props: {
    width: {
      type: [Number, String],
      default: 18
    },
    height: {
      type: [Number, String],
      default: 17
    },
    fill: {
      type: String,
      default: "#7A7C7E"
    },
    stroke: {
      type: String,
      default: "none"
    }
  }
};
</script>

<style scoped>
</style>
