export const STATUS_PENDING = 1;
export const STATUS_PAID = 2;
export const STATUS_PROCESSING = 3;
export const STATUS_FULFILLED = 4;
export const STATUS_PARTIAL_FULFILLED = 5;
export const STATUS_REFUNDED = 6;
export const STATUS_PARTIAL_REFUNDED = 8;
export const STATUS_PARTIALLY_PAID = 9;
export const STATUS_ABANDONED_CHECKOUT = 11;
export const STATUS_TEST = 12;
export const STATUS_CHECK_FRAUD = 13;
export const STATUS_PAYMENT_NOT_FOUND = 14;
export const STATUS_CANCELED = 15;
export const STATUS_CALL_CENTER = 16;
export const STATUS_TRASH = 17;
export const STATUS_APPROVE = 18;
