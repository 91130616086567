<template>
  <v-container class="no-width" grid-list-md>
    <div class="tickets-settings">
      <h2>{{ lang.settings }}</h2>
      <v-layout wrap class="tickets-settings__email">
        <v-flex xs12 sm4 md3 class="base-table--filters__item email-input-holder">
          <span
            v-if="!formData.hasEmail"
            class="email-ending hidden-sm-and-up"
          >{{ defaultEmailEnding }}</span>
          <InputTextCopy
            :value="formData.email"
            :show-icon=formData.hasEmail
            :readonly=formData.hasEmail
            @input:base-text="handleSupportEmail"
          >{{ lang.supportEmail }}</InputTextCopy>
        </v-flex>
        <v-flex xs12 sm8 md4 align-self-center class="tickets-settings__btn-holder">
          <span
            v-if="!formData.hasEmail"
            class="email-ending hidden-xs-only"
          >{{ defaultEmailEnding }}</span>
          <ButtonPrimary
            class="base-table--filters__button"
            v-if="!formData.hasEmail"
            small
            :disabled="!this.formData.email"
            :loading="loading"
            @click:base-button="handleCreate"
          >{{ lang.create }}</ButtonPrimary>
          <span class="has-email-msg" v-if="formData.hasEmail">{{ lang.hasEmailText }}</span>
        </v-flex>
        <v-flex xs12 sm12 md12 v-if="!formData.hasEmail">
          <span class="tickets-settings__info-msg">{{ lang.emailInfoMsg }}</span>
        </v-flex>
      </v-layout>
    </div>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import ButtonPrimary from "@/components/ButtonPrimary";
import InputTextCopy from "@/components/InputTextCopy";

export default {
  components: {
    InputTextCopy,
    ButtonPrimary
  },
  data() {
    return {
      languages: [
        {
          text: "English",
          value: "en"
        },
        {
          text: "Spanish",
          value: "es"
        },
        {
          text: "Portuguese",
          value: "pt"
        }
      ],
      defaultEmailEnding: "@shop-desk.com",
      formData: {
        hasEmail: false,
        email: "",
        language: null,
        code: null
      }
    };
  },
  computed: {
    ...mapGetters({
      salesChannel: "getSalesChannel",
      chatCodes: "getChatScripts",
      loading: "getIsLoading"
    }),
    lang() {
      return {
        supportEmail: "Your support email",
        create: "Create",
        settings: "Settings",
        chatLanguage: "Сhat language",
        chatLanguagePlaceholder: "Choose language for your chat",
        chatCode: "Chat code",
        chatCodeText:
          "Choose language to get a code for your chat. Place the code on your site.",
        hasEmailText: "It is your support email. Place it on your site.",
        emailInfoMsg: "Create your email address. It can’t be chanded after."
      };
    }
  },
  methods: {
    ...mapActions([
      "fetchSalesChannelById",
      "setChannelLogin",
      "getChatScripts",
    ]),
    handleLanguage(val) {
      this.formData.language = val;
      if (this.formData.language) {
        this.formData.code = this.chatCodes[val];
      }
    },
    handleSupportEmail(val) {
      this.formData.email = val.toLowerCase();
    },
    async handleCreate() {
      if (this.formData.email && !this.formData.hasEmail) {
        const data = {
          id: this.$salesChannel.get(),
          data: {
            login: this.formData.email
          }
        };
        const { status } = await this.setChannelLogin(data);

        if (status) {
          this.formData = {
            ...this.formData,
            email: this.formData.email + this.defaultEmailEnding,
            hasEmail: true
          };
        }
      }
    }
  },
  watch: {
    "$route.params": {
      immediate: true,
      handler() {
        const data = {
          id: this.$salesChannel.get(),
          filters: null
        };
        this.fetchSalesChannelById(data);
        this.getChatScripts();
      }
    },
    salesChannel: {
      handler(salesChannel) {
        if (salesChannel.login && !this.formData.hasEmail) {
          this.formData.email = salesChannel.login;
          this.formData.email = this.formData.email + this.defaultEmailEnding;
          this.formData.hasEmail = true;
        }
      }
    }
  },
  beforeDestroy() {
    this.$off("click:base-button");
    this.$off("change:datetimepicker");
  }
};
</script>

<style lang="scss">
.tickets-settings {
  margin: 0 0 60px;
  @include breakpoint($m) {
    margin-bottom: 10px;
  }
  h2 {
    color: #7e7e7e;
    font-size: 18px;
    line-height: 1.2;
    margin: 0 0 10px;
  }
  &__email {
    margin-bottom: 20px !important;
  }
  &__btn-holder {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    @include rem($m) {
      padding-top: 19px !important;
    }
    .base-table--filters__button {
      margin-top: 0 !important;
      @include rem($m) {
        margin-left: 15px !important;
      }
    }
    .has-email-msg {
      color: #a6a8a9;
      font-size: 12px;
      line-height: 1.2;
      vertical-align: top;
      display: inline-block;
    }
  }
  &__info-msg {
    color: #a6a8a9;
    display: block;
    font-size: 14px;
    line-height: 1.4;
    margin: 0 0 20px;
  }
  .email-ending {
    color: #222528;
    font-size: 14px;
    line-height: 1.2;
    vertical-align: top;
    white-space: nowrap;
    display: inline-block;
    @include breakpoint($m) {
      float: right;
      margin: 27px 0 0 10px;
    }
  }
  .email-input-holder {
    @include breakpoint($m) {
      &:after {
        content: "";
        clear: both;
        display: block;
      }
      .base-input {
        overflow: hidden;
      }
    }
  }
}
</style>
