<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="width"
    :height="height"
    fill="none"
    :viewBox="`0 0 ${width} ${height}`"
  >
    <path
      :fill="fill"
      d="M26.46 4.54A15.399 15.399 0 0 0 15.5 0C11.36 0 7.467 1.612 4.54 4.54A15.399 15.399 0 0 0 0 15.5c0 4.14 1.612 8.033 4.54 10.96A15.398 15.398 0 0 0 15.5 31c4.14 0 8.033-1.612 10.96-4.54A15.399 15.399 0 0 0 31 15.5c0-4.14-1.612-8.033-4.54-10.96zM15.5 29.184c-7.545 0-13.684-6.139-13.684-13.684 0-7.545 6.139-13.684 13.684-13.684 7.545 0 13.684 6.139 13.684 13.684 0 7.545-6.139 13.684-13.684 13.684z"
    ></path>
    <path
      :fill="fill"
      d="M16.473 14.592h-1.945a2.012 2.012 0 0 1-2.01-2.01c0-1.109.901-2.01 2.01-2.01h3.89a.908.908 0 1 0 0-1.817h-2.01v-2.01a.908.908 0 0 0-1.816 0v2.01h-.064a3.83 3.83 0 0 0-3.827 3.827 3.83 3.83 0 0 0 3.827 3.826h1.945c1.109 0 2.01.902 2.01 2.01 0 1.109-.901 2.01-2.01 2.01h-3.89a.908.908 0 1 0 0 1.817h2.01v2.01a.908.908 0 1 0 1.816 0v-2.01h.064a3.83 3.83 0 0 0 3.827-3.827 3.83 3.83 0 0 0-3.827-3.826z"
    ></path>
  </svg>
</template>

<script>
export default {
  props: {
    width: {
      type: [Number, String],
      default: 31
    },
    height: {
      type: [Number, String],
      default: 31
    },
    fill: {
      type: String,
      default: "#000"
    },
    stroke: {
      type: String,
      default: "none"
    }
  }
};
</script>

<style scoped>
</style>
