<template>
  <div class="search-products">
    <h3 class="search-products__title">{{ lang.title }}</h3>
    <template v-if="categories">
      <Catalog :categories="categories.items"/>
    </template>
    <template v-if="leadProducts.length > 0">
      <div v-for="(val, k) in leadProductsData" :key="k" class="lead-products-block">
        <template>
          <div class="lead-products-block__header">
            <h2 class="lead-products-block__title">{{ val.name }}</h2>
            <p
              class="lead-products-block-view-all hover--red"
              @click="onViewAll(val.id)"
            >{{ lang.viewAll }}</p>
          </div>
          <v-container fluid grid-list-md class="pr-0 pl-0 pt-0">
            <v-layout row wrap>
              <v-flex xs12 sm6 md3 xl3 v-for="product in leadProducts[k]" :key="product.id">
                <CardProduct
                  :product="product"
                  @click:product="handleCardProductClick(product.id, val.id)"
                />
              </v-flex>
            </v-layout>
          </v-container>
        </template>
      </div>
    </template>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";

import Catalog from "@/components/Catalog";
import CardProduct from "@/components/CardProduct";

export default {
  components: {
    Catalog,
    CardProduct
  },
  data() {
    return {
      leadProductsData: []
    };
  },
  computed: {
    ...mapGetters({
      categories: "getCategories",
      leadProducts: "getLeadProducts"
    }),
    lang() {
      return {
        title: "Catalog",
        viewAll: "View all"
      };
    }
  },
  methods: {
    ...mapActions(["fetchLeadProducts", "fetchBanners"]),
    handleCardProductClick(id) {
      this.$router.push({
        name: "dashboard.products.product-details",
        params: {
          productId: id
        }
      });
    },
    handleFilterNameChange(name) {
      this.$router.push({
        name: "dashboard.products.search-results",
        query: {
          name,
          page: 1,
          page_size: 20
        }
      });
    },
    onViewAll(categoryId) {
      this.$router.push({
        name: "dashboard.products.list",
        params: { id: categoryId },
        query: { page_size: 20, page: 1 }
      });
    }
  },
  watch: {
    categories: {
      immediate: true,
      handler(val) {
        if (val) {
          this.leadProductsData = [
            { name: val.items[0].name, id: val.items[0].id },
            { name: val.items[1].name, id: val.items[1].id },
            { name: val.items[2].name, id: val.items[2].id }
          ];
          this.fetchLeadProducts({ id: val.items[0].id, index: 0 });
          this.fetchLeadProducts({ id: val.items[1].id, index: 1 });
          this.fetchLeadProducts({ id: val.items[2].id, index: 2 });
          this.fetchBanners();
        }
      }
    }
  },
  mounted() {
    this.$root.$on("input:search", name => this.handleFilterNameChange(name));
  },
  beforeDestroy() {
    this.$root.$off("input:search");
  }
};
</script>

<style scoped lang="scss">
.search-products__title {
  margin-top: 10px;
  margin-bottom: 20px;
  @include breakpoint($t) {
    margin-top: 0;
  }
}

.lead-products-block {
  margin: 40px 0;
  .lead-products-block__header {
    display: flex;
    margin: 0 0 10px;
    align-items: flex-end;
    justify-content: space-between;
    @include breakpoint($m) {
    }
  }
  .lead-products-block__title {
    margin: 0;
    font-size: 30px;
    @include breakpoint($m) {
      font-size: 24px;
      line-height: 30px;
    }
  }
  .lead-products-block-view-all {
    font-size: 12px;
    cursor: pointer;
    color: $title-color;
    white-space: nowrap;
    margin: 0 0 10px 20px;
    @include breakpoint($m) {
      margin-bottom: 3px;
    }
  }
}
</style>
