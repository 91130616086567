import { render, staticRenderFns } from "./IconDocumentation.vue?vue&type=template&id=11e7958c&scoped=true&"
import script from "./IconDocumentation.vue?vue&type=script&lang=js&"
export * from "./IconDocumentation.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "11e7958c",
  null
  
)

component.options.__file = "IconDocumentation.vue"
export default component.exports