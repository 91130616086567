<template>
  <div class="subcategory-filter">
    <h4 class="subcategory-filter__title">{{ lang.title }}</h4>
    <ul class="subcategory-filter__list-level-1">
      <li
        class="subcategory-filter__list-level-1__item"
        v-for="subcategory in subcategories"
        :key="subcategory.id"
      >
        <span class="hover--red">
          <router-link
            :to="{ params: { id: subcategory.id }, query: {page_size: 20, page: 1} }"
          >{{ subcategory.name }}</router-link>
        </span>
        <ul class="subcategory-filter__list-level-2">
          <li
            class="subcategory-filter__list-level-2__item"
            :class="{'subcategory-active': subchidren.id == currentSubcategoryId}"
            v-for="subchidren in subcategory.children"
            :key="subchidren.id"
          >
            <router-link
              class="hover--red"
              :to="{ params: { id: subchidren.id }, query: {page_size: 20, page: 1} }"
            >{{ subchidren.name }}</router-link>
          </li>
        </ul>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    subcategories: {
      type: Array,
      required: true
    },
    currentSubcategoryId: {
      type: [String, Number],
      required: true
    }
  },
  computed: {
    lang() {
      return {
        title: "Subcategories"
      };
    }
  },
  methods: {}
};
</script>

<style scoped lang="scss">
.subcategory-filter {
  .subcategory-filter__title {
    font-size: em(18);
    color: $text-sidebar-color;
  }

  .subcategory-filter__list-level-1 {
    padding: 0;
    list-style-type: none;
    margin: 10px 0;
    padding: 15px 0;

    span {
      cursor: pointer;
      color: $title-color;
    }

    .subcategory-filter__list-level-1__item {
      font-size: em(14);
      margin: 10px 0;
    }

    .subcategory-filter__list-level-2 {
      padding: 0;
      list-style-type: none;

      .subcategory-filter__list-level-2__item {
        a {
          color: $text-sidebar-color;
        }
        font-size: em(12);
        padding: 3px 0;
        cursor: pointer;
      }
    }

    .subcategory-active {
      color: $title-color !important;
    }
  }
}
</style>
