<template>
  <div class="product-statistic">
    <h3>{{ lang.title }}</h3>
    <TableProductsStatistics/>
  </div>
</template>

<script>
import TableProductsStatistics from "@/components/Tables/TableProductsStatistics";

export default {
  components: {
    TableProductsStatistics
  },
  computed: {
    lang() {
      return {
        title: "Products Statistic"
      };
    }
  }
};
</script>

<style scoped lang="scss">
.product-statistic {
  max-width: $max-width !important;
}
</style>
