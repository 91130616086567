<template>
  <div class="home">
    <v-layout row fill-height wrap>
      <v-flex md8 xs12>
        <div class="home-left">
          <div class="home-left__logo">
            <Logo/>
          </div>
          <h1 class="home-left__title" v-html="lang.title"></h1>
        </div>
      </v-flex>
      <v-flex md4 xs12>
        <div class="home-right">
          <div class="home-right-form">
            <h3 class="home-right-form__title" v-html="lang.recoveryTitle"></h3>
            <div class="v-text-field--flat v-text-field--flat--gray">
              <FormChangePassword @submit:form="handleSubmitForm"></FormChangePassword>
            </div>
          </div>
        </div>
      </v-flex>
    </v-layout>
    <vue-programmatic-invisible-google-recaptcha
      ref="invisibleRecaptcha1"
      :sitekey="recaptchaToken"
      :elementId="'invisibleRecaptcha1'"
      :showBadgeMobile="false"
      :showBadgeDesktop="false"
      @recaptcha-callback="recaptchaCallback"
    ></vue-programmatic-invisible-google-recaptcha>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";

import Logo from "@/components/Logo";
import FormChangePassword from "@/components/FormChangePassword";
import VueProgrammaticInvisibleGoogleRecaptcha from "vue-programmatic-invisible-google-recaptcha";

const GOOGLE_RECAPTCHA_TOKEN = process.env.VUE_APP_GOOGLE_RECAPTCHA_TOKEN;

export default {
  components: {
    FormChangePassword,
    Logo,
    VueProgrammaticInvisibleGoogleRecaptcha
  },
  data() {
    return {
      requestData: null,
      recaptchaToken: GOOGLE_RECAPTCHA_TOKEN,
      form: {
        plainPassword: {
          first: null,
          second: null
        }
      }
    };
  },
  computed: {
    ...mapState({
      successMsg: state => state.errors.notifyMessages.success,
      errorMsg: state => state.errors.notifyMessages.somethingWrong,
      notValidUserMsg: state => state.errors.notifyMessages.notValidUser
    }),
    ...mapGetters({
      progress: "getIsProgress"
    }),
    lang() {
      return {
        recoveryTitle: "Change password",
        title: "Start Dropshipping <br> With Ecombo Today!"
      };
    },
    recoveryId() {
      return this.$route.params.id;
    }
  },
  methods: {
    ...mapActions(["setNotifyMsg"]),
    async handleSubmitForm(data) {
      const requestData = {
        token: this.recoveryId,
        data: {
          plainPassword: data
        }
      };

      const { status } = await this.changePassword(requestData);

      var notifyData = {
        text: status ? this.successResetPassMsg : this.errorMsg,
        color: status ? "success" : "error"
      };

      this.sendNotify(notifyData);

      if (status) {
        this.$router.push({ name: "home" });
      }
    },
    sendNotify({ text, color }) {
      this.setNotifyMsg({
        text,
        color
      });
    },
    async recaptchaCallback(recaptchaToken) {
      const captchaRepsonse = await this.checkCaptcha({
        token: recaptchaToken
      }).then(data => {
        if (data) {
          return data;
        }
      });

      if (!captchaRepsonse) {
        return;
      }

      const { status, text } = await this.registerUser(this.requestData);

      var notifyData = {
        text: text || (status ? this.successMsg : this.notValidUserMsg),
        color: status ? "success" : "error"
      };

      this.sendNotify(notifyData);
    },
    ...mapActions(["changePassword", "checkCaptcha"])
  },
  beforeDestroy() {
    this.$off("submit:form");
  }
};
</script>

<style lang="scss">
$bottom-margin: 81px;

.home {
  height: 100vh;
  @include breakpoint($t) {
    height: auto;
    background: #fff;
    min-height: 100vh;
  }

  h1 {
    @include breakpoint($t) {
      font-size: 40px;
      line-height: 1.41;
    }
    @include breakpoint($m) {
      font-size: 6.875vw;
    }
  }

  .home-right,
  .home-left {
    min-height: 100%;
    display: flex;
  }

  .home-left {
    padding: 25px 36px $bottom-margin;
    background-color: $primary-color;
    @include breakpoint($t) {
      min-height: 0;
      display: block;
      margin: 0 0 25px;
      padding: 20px 20px 50px;
    }
    @include breakpoint($m) {
      margin: 0 0 4.375vw;
      padding: 3.125vw 3.125vw 9.375vw;
    }
    .home-left__logo {
      position: absolute;
      @include breakpoint($t) {
        position: static;
        margin: 0 0 150px;
        img {
          display: block;
          min-width: 268px;
          max-width: 268px;
        }
      }
      @include breakpoint($m) {
        margin: 0 0 24.6875vw;
        img {
          min-width: 44.6875vw;
          max-width: 44.6875vw;
        }
      }
    }

    .home-left__title {
      align-self: flex-end;
      color: #fff;
    }
  }

  .home-right {
    align-items: center;
    background-color: #fff;
    justify-content: center;

    .home-right-form {
      height: 100%;
      min-width: em(300);
      @include breakpoint($t) {
        width: 100%;
        height: auto;
        min-width: 0;
        max-width: 480px;
        padding: 0 10px 10px;
      }
      @include breakpoint($m) {
        padding-left: 3.125vw;
        padding-right: 3.125vw;
        padding-bottom: 3.125vw;
      }

      .home-right-form__title {
        margin-bottom: 20px;
        @include breakpoint($t) {
          font-size: 22px;
          line-height: 1.41;
          br {
            display: none;
          }
        }
      }

      .home-right-form__no-account {
        position: absolute;
        font-size: em(12);
        bottom: $bottom-margin;
        @include breakpoint($t) {
          line-height: 2;
          font-size: 12px;
          position: static;
          margin-top: 30px;
        }
      }
    }
  }

  .landing-form {
    .landing-form-content {
      margin-bottom: 69px;
      @include breakpoint($t) {
        margin-bottom: 10px;
      }

      .landing-form-content__item__forgot-password {
        position: absolute;
        right: 0;
        margin-top: 5px;
        color: #d8d8d8;
        font-size: em(10);
        text-decoration: none;
      }
    }

    .landing-form-content__item {
      position: relative;
    }

    .landing-form-content__item__label,
    .landing-form-content__item__desc {
      font-size: em(12);
      color: $text-color;
    }

    .landing-form-content__item__desc {
      margin-top: 10px;
      margin-bottom: 50px;
    }
    &__button {
      text-align: center;
      .landing-form-content__item__link {
        font-size: 14px;
        line-height: 1.2;
        margin: 10px 0 0;
        vertical-align: top;
        display: inline-block;
      }
    }
  }
}
</style>
