<template>
  <div class="table-sm" v-if="items">
    <BaseTable show-radio-buttons class="table-sm__content" :headers="headers" :items="items"></BaseTable>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  components: {},
  data() {
    return {
      items: [],
      headers: [
        { text: "Company", value: "company" },
        { text: "Shipping days", value: "shipday" },
        { text: "Cost", value: "cost" }
      ]
    };
  },
  computed: {
    ...mapGetters({
      shipmentMethods: "getShipmentMethods"
    })
  },
  watch: {
    shipmentMethods: {
      immediate: true,
      handler(shipmentMethods) {
        if (!shipmentMethods) {
          this.items = [];
          return;
        }
        const tempItems = shipmentMethods.map(item => {
          return {
            company: item.shippingCompany.name,
            shipday: item.shipday,
            cost: item.price
          };
        });
        this.items = tempItems;
      }
    }
  },
  mounted() {}
};
</script>

<style lang="scss">
.table-sm {
  .v-table thead tr {
    height: 40px;
  }
}
</style>
