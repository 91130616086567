<template>
  <v-avatar :tile="tile" :size="size">
    <img :src="src" :lazy-src="src" :alt="alt">
  </v-avatar>
</template>

<script>
export default {
  props: {
    tile: {
      type: Boolean,
      default: false
    },
    size: {
      type: Number,
      default: 50
    },
    src: {
      type: String,
      required: true
    },
    alt: {
      type: String,
      default: "avatar"
    }
  }
};
</script>

<style scoped>
</style>
