import api from '@/api/notifications';

import {
  STATUS_OK,
  STATUS_SERVER_ERROR
} from '@/constants/httpCodes';

export default {
  state: {
    notifications: false,
    notification: null,
  },
  getters: {
    getNotifications: state => state.notifications && state.notifications.items,
    getNotificationsPagination: state => state.notifications && state.notifications.links,
  },
  mutations: {
    SET_NOTIFICATIONS_DATA(state, payload) {
      state.notifications = payload;
    },
    SET_NOTIFICATION_DATA(state, payload) {
      state.notification = payload;
    },
    CLEAR_NOTIFICATION_DATA(state) {
      state.notification = null;
    },
    CHANGE_NOTIFICATION_BY_ID(state, payload) {
      if (state.notifications && state.notifications.items) {
        const newItems = state.notifications.items.map(item => {
          if (item.id === payload.id) {
            return payload;
          } else {
            return item;
          }
        })

        state.notifications = {
          ...state.notifications,
          items: newItems
        }
      }
    }
  },
  actions: {
    fetchNotifications({
      commit,
      dispatch
    }, payload) {
      dispatch('setIsProgressData', true);
      api.get(payload)
        .then(({
          data,
          status
        }) => {
          if (status === STATUS_OK) {
            commit('SET_NOTIFICATIONS_DATA', data);
          }
        })
        .finally(() => {
          dispatch('setIsProgressData', false);
        })
    },
    fetchNotificationById({
      commit,
      dispatch
    }, payload) {
      dispatch('setIsProgressData', true);
      api.getById(payload)
        .then(({
          data,
          status
        }) => {
          if (status === STATUS_OK) {
            commit('SET_NOTIFICATION_DATA', data);
          }
        })
        .finally(() => {
          dispatch('setIsProgressData', false);
        })
    },
    async readAllNotifications({
      dispatch
    }) {
      dispatch('setIsProgressData', true);
      const response = api.readAll()
        .then(({
          status
        }) => {
          if (status === STATUS_OK) {
            return {
              status: true,
              text: null
            }
          }
        })
        .catch(error => {
          if (error.response.status === STATUS_SERVER_ERROR) {
            return {
              text: null,
              status: false
            }
          }
        })
        .finally(() => {
          dispatch('setIsProgressData', false);
        });
      return response;
    }
  },
};
