export default {
  state: {
    rules: {
      required: value => !!value || "The field is required.",
      requiredNumber: value => !!value || "Not valid number value",
      isNumber: value => isNaN(value) ? 'The field must contain only numbers or floating numbers' :
        false,
      validEmail: value => {
        const re =
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        if (!value) {
          return " ";
        }

        if (value && !re.test(value)) {
          return "Not valid email field";
        }

        return false;
      },
      validUrl: value => {
        var re = new RegExp('(https?://)\\w' + // protocol
          '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
          '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
          '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
          '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
          '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator

        if (value && !re.test(value)) {
          return "Not valid url";
        }

        return false;
      },
      validWebmoney: value => {
        const re = new RegExp('^Z[0-9]{12}$');

        if (!value) {
          return '';
        }

        if (!re.test(value)) {
          return "Not valid webmoney wallet";
        }

        return false;
      },
      validCapitalist: value => {
        const re = new RegExp('^U[0-9]{8}$');

        if (!value) {
          return '';
        }

        if (!re.test(value)) {
          return "Not valid capitalist wallet, should be UXXXXXXXX";
        }

        return false;
      },
      isAbsNumber: value => {
        return new String(value).charAt(0) === '-' ?
          'The field must have absolute value' : false
      },
      validEPayments: value => {
        const re = new RegExp('^[0-9]{3}-[0-9]{6}$');

        if (!value) {
          return '';
        }

        if (!re.test(value)) {
          return "Not valid ePayments wallet";
        }

        return false;
      }
    },
  },
  getters: {},
  mutations: {},
  actions: {},
};
