import api from '@/api/transactions';

import {
  STATUS_OK,
} from '@/constants/httpCodes';

export default {
  state: {
    transactions: null,
    transaction: null,
  },
  getters: {
    getTransactions: state => state.transactions && state.transactions.items,
    getTransactionsPagination: state => state.transactions && state.transactions.links,
    getTransaction: state => state.transaction,
  },
  mutations: {
    SET_TRANSACTIONS_DATA(state, payload) {
      state.transactions = payload;
    },
    SET_TRANSACTION_DATA(state, payload) {
      state.transaction = payload;
    },
  },
  actions: {
    fetchTransactions({
      commit,
      dispatch,
    }, payload) {
      dispatch('setIsProgressData', true);
      api.get(payload)
        .then(({
          data,
          status
        }) => {
          if (status === STATUS_OK) {
            commit('SET_TRANSACTIONS_DATA', data);
          }
        })
        .finally(() => {
          dispatch('setIsProgressData', false);
        })
    },
    fetchTransactionsById({
      commit,
      dispatch,
    }, payload) {
      dispatch('setIsProgressData', true);
      api.getById(payload)
        .then(({
          data,
          status
        }) => {
          if (status === STATUS_OK) {
            commit('SET_TRANSACTION_DATA', data);
          }
        })
        .finally(() => {
          dispatch('setIsProgressData', false);
        })
    },
  },
};
