import axios from 'axios';
import BaseApi from './base';
import {
  NOTIFICATIONS,
  NOTIFICATIONS_READ_ALL
} from '@/constants/api';

export default class extends BaseApi {
  static get(filters = null) {
    return new Promise((resolve, reject) => {
      return axios.get(this.baseURI + NOTIFICATIONS, {
          params: filters
        })
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  }
  static getById(id) {
    return new Promise((resolve, reject) => {
      return axios.get(this.baseURI + NOTIFICATIONS + '/' + id, {
          params: {
            groups: 'Notification.Detail'
          }
        })
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  static readAll() {
    return new Promise((resolve, reject) => {
      return axios.put(this.baseURI + NOTIFICATIONS + '/' + NOTIFICATIONS_READ_ALL, )
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  }
}
