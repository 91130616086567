import { render, staticRenderFns } from "./IconDashboard.vue?vue&type=template&id=19d7c152&scoped=true&"
import script from "./IconDashboard.vue?vue&type=script&lang=js&"
export * from "./IconDashboard.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "19d7c152",
  null
  
)

component.options.__file = "IconDashboard.vue"
export default component.exports