<template>
  <div class="input-secondary-text">
    <div class="input-secondary-text__label">
      <slot></slot>
    </div>
    <v-text-field solo single-line flat v-bind="$attrs" v-on="$listeners"></v-text-field>
  </div>
</template>

<script>
export default {
  inheritAttrs: false,
  props: {
    height: {
      type: Number,
      default: 40
    },
    borderColor: {
      type: String,
      default: "#a6a8a9"
    }
  }
};
</script>

<style lang="scss">
.input-secondary-text {
  &__label {
    font-size: 12px;
    line-height: 22px;
    color: $text-color;

    @include breakpoint($m) {
      line-height: 18px;
    }
  }

  .v-input {
    font-size: 14px;
    &__slot {
      border-color: #a6a8a9 !important;
      border-radius: 0 !important;
      border-width: 1px !important;
      min-height: 40px !important;

      input {
        margin: 0 !important;
      }
    }
    .v-text-field__details {
      padding: 0 !important;
    }
  }
}
</style>
