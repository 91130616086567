<template>
  <div class="inventory-secondary-block">
    <v-layout wrap>
      <v-flex md4 xs12>
        <div class="inventory-secondary-main-block">
          <h3 class="inventory-secondary-main-block__title" v-text="data.title"></h3>
          <h4 class="inventory-secondary-main-block__subtitle" v-text="data.subtitle"></h4>
        </div>
      </v-flex>
      <v-flex md8 xs12>
        <v-img
          :src="data.image"
          :lazy-src="data.image"
          :max-width="659"
          class="inventory-secondary-block__image"
        ></v-img>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      required: true
    }
  }
};
</script>

<style scoped lang="scss">
.inventory-secondary-block {
  background-color: $body-color;

  .inventory-secondary-main-block {
    padding-left: 23px;
    &__title {
      font-size: 30px;
      line-height: 32px;
      margin-bottom: 25px;
    }

    &__subtitle {
      font-size: 18px;
      line-height: 22px;
      color: $text-sidebar-color;
    }

    @include breakpoint($t) {
      padding-left: 0;
    }
  }

  &__image {
    margin-left: auto;

    @include breakpoint($t) {
      margin-top: 40px;
    }
  }
}
</style>
