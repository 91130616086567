<template>
  <div class="inventory-stepper">
    <v-layout row wrap fill-height>
      <v-flex md8>
        <div class="inventory-stepper-header">
          <div
            class="inventory-stepper-header__item"
            v-for="(header, i) in headers"
            :key="i"
            :class="{'inventory-stepper-header__item--active': header.step === step, 'inventory-stepper-header__item--complete': header.step < step}"
          >{{ header.step + '.' + header.title}}</div>
        </div>
      </v-flex>
    </v-layout>
    <v-container grid-list-md class="pa-0">
      <v-layout row wrap fill-height>
        <v-flex md8 class="pr-0 pl-0">
          <div class="inventory-stepper-content">
            <slot name="main-content"></slot>
            <slot name="actions"></slot>
          </div>
        </v-flex>
        <slot name="secondary-block"></slot>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
export default {
  props: {
    headers: {
      type: Array,
      required: true
    },
    step: {
      type: Number,
      required: true
    }
  }
};
</script>

<style lang="scss">
.inventory-stepper {
  display: block;

  &-header {
    background-color: $body-color;
    display: flex;
    justify-content: space-between;

    &__item {
      display: inline-block;
      min-width: 56px;
      padding: 10px 0;
      font-size: 12px;
      line-height: 14px;
      color: $text-color;
      text-align: center;

      &--active {
        color: $warning-color;
      }

      &--complete {
        color: $title-color;
      }

      @include breakpoint($m) {
        font-size: 10px;
      }

      @include breakpoint($t) {
        padding-left: 5px;
        padding-right: 5px;
      }
    }
  }

  &-content,
  &-content-static {
    display: flex;
    flex-direction: column;
    height: 100%;
    min-height: 310px;
    background-color: #fff;
    border-radius: 4px;
    padding: 18px;
  }
  &-content {
    &__title {
      font-size: 20px;
      line-height: 32px;
      margin-bottom: 20px;
    }
  }

  &-actions {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    flex-grow: 1;
    align-items: flex-end;
  }

  &-content-static {
    &__title {
      font-size: 12px;
      line-height: 14px;
    }

    &__actions {
      display: flex;
      justify-content: space-between;
      flex-grow: 1;
      align-items: flex-end;

      .action-title {
        font-size: 14px;
        line-height: 20px;
        font-family: "DIN Pro Bold" !important;
      }
      .action-subtitle {
        font-size: 12px;
        line-height: 18px;
        color: $text-sidebar-color;
      }
    }
  }

  .inventory-stepper-radio-group {
    .v-input__control {
      flex-grow: 1;

      .radio-box {
        margin-bottom: 20px;
      }
    }
  }

  .step3-checkboxes-group {
    .checkbox-box {
      margin-bottom: 20px;
    }
  }

  .step2-checkbox {
    margin-left: 13px;
    margin-top: -15px;

    label {
      color: $title-color;
      font-size: 12px;
    }

    .v-icon {
      color: $text-color;
      font-size: 20px;
      line-height: 1.2;
    }
  }

  .add-country-title {
    font-size: 12px;
    color: $warning-color;
    cursor: pointer;
  }

  .v-btn {
    margin: 0 !important;
  }

  .inventory-stepper-content-static-mobile {
    border-radius: 0;
    background-color: #fff;
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
    margin-top: -10px;
    border-top: 1px solid $text-color;
    padding: 10px;

    .action-title,
    .action-subtitle {
      font-size: 14px;
    }

    .action-title {
      font-family: "DIN Pro Bold" !important;
      margin-right: 5px;
    }

    .action-subtitle {
      color: $text-sidebar-color !important;
    }

    .inventory-stepper-content-static__title {
      margin-top: 5px;
      font-size: 14px;
      font-size: 500;
    }
  }
}
</style>
