<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="width"
    :height="height"
    fill="none"
    :viewBox="`0 0 ${width} ${height}`"
  >
    <path
      :fill="fill"
      d="M15.364 2.636A8.941 8.941 0 0 0 9 0a8.941 8.941 0 0 0-6.364 2.636A8.941 8.941 0 0 0 0 9c0 2.404.936 4.664 2.636 6.364A8.941 8.941 0 0 0 9 18a8.941 8.941 0 0 0 6.364-2.636A8.941 8.941 0 0 0 18 9a8.941 8.941 0 0 0-2.636-6.364zM9 16.946c-4.381 0-7.945-3.565-7.945-7.946S4.619 1.055 9 1.055 16.945 4.619 16.945 9 13.381 16.945 9 16.945z"
    ></path>
    <path
      :fill="fill"
      d="M9.564 8.473h-1.13a1.168 1.168 0 0 1-1.166-1.168c0-.643.523-1.167 1.167-1.167h2.26a.527.527 0 1 0 0-1.054H9.526V3.916a.527.527 0 0 0-1.055 0v1.168h-.037a2.224 2.224 0 0 0-2.222 2.221c0 1.226.997 2.222 2.222 2.222h1.13a1.168 1.168 0 0 1 0 2.335h-2.26a.527.527 0 0 0 0 1.054h1.167v1.168a.527.527 0 0 0 1.055 0v-1.168h.037a2.224 2.224 0 0 0 2.222-2.222 2.224 2.224 0 0 0-2.222-2.221z"
    ></path>
  </svg>
</template>

<script>
export default {
  props: {
    width: {
      type: [Number, String],
      default: "18"
    },
    height: {
      type: [Number, String],
      default: "18"
    },
    fill: {
      type: String,
      default: "#7A7C7E"
    },
    stroke: {
      type: String,
      default: "none"
    }
  }
};
</script>

<style scoped>
svg {
  margin: 0 auto;
  margin-top: 2px;
}
</style>
