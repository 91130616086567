<template>
  <div>
    <v-progress-linear
      :indeterminate="true"
      class="progress"
      color="secondary"
      :height="3"
      :active="isProgress"
    ></v-progress-linear>
    <TheHeader :mobile-menu-open="mobileMenuOpen" @togglemenu="openMobileMenu"></TheHeader>
    <TheSidebar :mobile-menu-open="mobileMenuOpen" @menutoggled="menutoggled"></TheSidebar>
    <v-container fluid fill-height>
      <v-content>
        <v-layout>
          <v-flex md12>
            <slot />
          </v-flex>
        </v-layout>
      </v-content>
    </v-container>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import TheSidebar from "@/components/TheSidebar";
import TheHeader from "@/components/TheHeader";

export default {
  components: {
    TheSidebar,
    TheHeader
  },
  data() {
    return {
      mobileMenuOpen: false
    };
  },
  computed: {
    ...mapGetters({
      isProgress: "getIsProgress"
    })
  },
  methods: {
    ...mapActions(["fetchCountries"]),
    openMobileMenu() {
      this.mobileMenuOpen = true;
    },
    menutoggled() {
      this.mobileMenuOpen = false;
    }
  },
  mounted() {
    this.fetchCountries();
  }
};
</script>
