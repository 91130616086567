<template>
  <div class="account-settings">
    <h3 class="account-settings__title">{{ lang.title }}</h3>
    <FormAccountSettings/>
  </div>
</template>

<script>
import FormAccountSettings from "@/components/FormAccountSettings";

export default {
  components: {
    FormAccountSettings
  },
  computed: {
    lang() {
      return {
        title: "Account Settings"
      };
    }
  }
};
</script>

<style lang="scss">
.account-settings {
  max-width: $max-width;

  &__title {
    margin-bottom: 20px;
  }
}
</style>
