<template>
  <div class="order-statistic">
    <h3>{{ lang.title }}</h3>
    <TableOrdersStatistics/>
  </div>
</template>

<script>
import TableOrdersStatistics from "@/components/Tables/TableOrdersStatistics";

export default {
  components: {
    TableOrdersStatistics
  },
  computed: {
    lang() {
      return {
        title: "Orders Statistic"
      };
    }
  }
};
</script>

<style lang="scss">
.order-statistic {
  max-width: $max-width !important;
}
</style>
