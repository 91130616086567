<template>
  <div class="dashboard-main">
    <h3 class="dashboard-main__title">{{ lang.title }}</h3>
    <FilterDashboard @click:search-btn="handleSearch"/>
    <div class="dashboard-main__content" v-if="dashboard">
      <v-container class="no-width" grid-list-md>
        <v-layout row wrap>
          <v-flex xs12 sm12 md6>
            <v-layout row wrap>
              <v-flex xs6 sm6 md6>
                <div class="dashboard-main__content__box">
                  <h6 class="dashboard-main__content__title">{{ lang.orders }}</h6>
                  <h5 class="dashboard-main__content__box__title">{{ dashboard.orders }}</h5>
                  <p class="dashboard-main__content__box__desc">{{ lang.perPeriod }}</p>
                </div>
              </v-flex>
              <v-flex xs6 sm6 md6>
                <div class="dashboard-main__content__box">
                  <h6 class="dashboard-main__content__title">{{ lang.profit }}</h6>
                  <h5 class="dashboard-main__content__box__title">{{ dashboard.profit | toFixed }}</h5>
                  <p class="dashboard-main__content__box__desc">{{ lang.perPeriod }}</p>
                </div>
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex xs12 sm12 md6>
            <div class="dashboard-main__content__box">
              <h6 class="dashboard-main__content__title">{{ lang.total }}</h6>
              <h5 class="dashboard-main__content__box__title">{{ dashboard.income | toFixed }}</h5>
              <p class="dashboard-main__content__box__desc"></p>
            </div>
          </v-flex>
        </v-layout>
        <v-layout row wrap>
          <v-flex xs12 sm12 md6>
            <h6 class="dashboard-main__content__title">{{ lang.ordersDiagram }}</h6>
            <div class="dashboard-main-diagram">
              <ApexCharts
                width="100%"
                type="line"
                :options="orders.chartOptions"
                :series="orders.series"
              ></ApexCharts>
            </div>
          </v-flex>
          <v-flex xs12 sm12 md6>
            <h6 class="dashboard-main__content__title">{{ lang.paymentsAndProfitsDiagram }}</h6>
            <div class="dashboard-main-diagram">
              <ApexCharts
                width="100%"
                type="bar"
                :options="profit.chartOptions"
                :series="profit.series"
              ></ApexCharts>
            </div>
          </v-flex>
        </v-layout>
      </v-container>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import ApexCharts from "vue-apexcharts";
import FilterDashboard from "@/components/Filters/FilterDashboard";

export default {
  data() {
    return {
      orders: {
        chartOptions: {
          chart: {
            width: "100%",
            id: "chart-orders",
            fontFamily: "DIN Pro Medium, sans-serif",
            toolbar: {
              show: false
            },
            background: "#fff",
            zoom: {
              enabled: false
            }
          },
          legend: {
            show: true,
            position: "top",
            showForSingleSeries: true,
            showForZeroSeries: true,
            horizontalAlign: "right",
            floating: true,
            labels: {
              colors: ["#7e7e7e"]
            },
            markers: {
              width: 5,
              height: 5
            }
          },
          stroke: {
            curve: "smooth",
            width: 2
          },
          colors: ["#2068ff"],
          markers: {
            size: 4
          },
          xaxis: {
            labels: {
              show: true,
              style: {
                colors: "#7e7e7e",
                fontSize: "14px"
              }
            },
            type: "datetime",
            categories: []
          },
          yaxis: {
            labels: {
              style: {
                color: "#7e7e7e",
                fontSize: "14px",
                cssClass: "apexcharts-xaxis-label"
              }
            }
          },
          grid: {
            xaxis: {
              lines: {
                show: true
              }
            },
            yaxis: {
              lines: {
                show: false
              }
            }
          }
        },
        series: [
          {
            name: "Orders",
            data: []
          }
        ]
      },
      profit: {
        chartOptions: {
          plotOptions: {
            bar: {
              horizontal: false,
              columnWidth: "100%"
            }
          },
          chart: {
            width: "100%",
            id: "chart-profit",
            fontFamily: "DIN Pro Medium, sans-serif",
            toolbar: {
              show: false
            },
            background: "#fff",
            zoom: {
              enabled: false
            }
          },
          legend: {
            show: true,
            position: "top",
            horizontalAlign: "right",
            floating: true,
            labels: {
              colors: ["#7e7e7e"]
            },
            markers: {
              width: 5,
              height: 5
            }
          },
          stroke: {
            show: false
          },
          colors: ["#2068ff", "#00eb74"],
          markers: {
            size: 4
          },
          xaxis: {
            labels: {
              show: true,
              style: {
                colors: "#7e7e7e",
                fontSize: "14px"
              }
            },
            type: "datetime",
            categories: []
          },
          yaxis: {
            labels: {
              style: {
                color: "#7e7e7e",
                fontSize: "14px"
              }
            }
          },
          dataLabels: {
            enabled: false
          },
          grid: {
            // borderColor: "#dadada",
            xaxis: {
              lines: {
                show: true
              }
            },
            yaxis: {
              lines: {
                show: false
              }
            }
          }
        },
        series: [
          {
            name: "Profit",
            data: []
          },
          {
            name: "Payments",
            data: []
          }
        ]
      }
    };
  },
  components: {
    FilterDashboard,
    ApexCharts
  },
  computed: {
    ...mapGetters({
      dashboard: "getDashboard"
    }),
    lang() {
      return {
        title: "Dashboard",
        orders: "Orders",
        profit: "Profit",
        total: "Total income",
        perPeriod: "per period",
        ordersDiagram: "Orders diagram",
        paymentsAndProfitsDiagram: "Payments and profits diagram"
      };
    }
  },
  methods: {
    ...mapActions(["fetchDashboard"]),
    handleSearch(filters) {
      this.fetchDashboardWithFilters(filters);
    },
    fetchDashboardWithFilters(filters = {}) {
      const salesChannel = this.$salesChannel.get();

      const newFilters = {
        salesChannel,
        ...filters
      };

      this.fetchDashboard(newFilters);
    },
    setOrdersGraphData(data) {
      this.orders.series[0].data = data.orders.map(item => +item);

      this.orders.chartOptions = {
        ...this.orders.chartOptions,
        ...{
          xaxis: {
            ...this.orders.chartOptions.xaxis,
            categories: [...data.day]
          }
        }
      };
    },
    setProfitGraphData(data) {
      this.profit.series[0].data = data.profits.map(item => +item);
      this.profit.series[1].data = data.payments.map(item => +item);

      this.profit.chartOptions = {
        ...this.profit.chartOptions,
        ...{
          xaxis: {
            ...this.profit.chartOptions.xaxis,
            categories: [...data.day]
          }
        }
      };
    }
  },
  filters: {
    toFixed(val) {
      return Math.round(val * 100) / 100;
    }
  },
  watch: {
    dashboard: {
      handler(data) {
        if (data) {
          this.setOrdersGraphData(data.orderCountChart);
          this.setProfitGraphData(data.paymentsAndProfitsChart);
        }
      }
    }
  },
  mounted() {
    this.$root.$on("updated-sales-channel", () => {
      const filters = {
        dateFrom: window
          .moment()
          .startOf("month")
          .format("YYYY-MM-DD"),
        dateTo: window
          .moment()
          .endOf("month")
          .format("YYYY-MM-DD")
      };
      this.fetchDashboardWithFilters(filters);
    });
  },
  beforeDestroy() {
    this.$off("click:search-btn");
    this.$root.$off("updated-sales-channel");
  }
};
</script>

<style scoped lang="scss">
.dashboard-main {
  .dashboard-main__title {
    margin-bottom: 10px;
  }

  .dashboard-main__content {
    margin-top: 42px;

    &__box {
      margin-bottom: 20px;
      .dashboard-main__content__title {
        margin-bottom: 10px;
      }
      &__title {
        font-size: 22px;
        line-height: 1.2;
        font-family: "DIN Pro Bold" !important;
      }
      &__desc {
        font-size: 14px;
        line-height: 1.2;
        padding-top: 5px;
        color: $text-sidebar-color;
      }
    }
  }
  .dashboard-main__content__title {
    font-size: 18px;
    line-height: 1.2;
    margin: 0 0 14px;
    color: $text-sidebar-color;
  }
}
</style>
