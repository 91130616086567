import {
  INTERCOM_EVENT,
  DOCUMENTATION_EVENT,
  VIRTUAL_INVENTORY_EVENT,
  STORE_CONNECT_EVENT,
  SAVE_PAYOUT_ACCOUNT_EVENT,
  REGISTER_EVENT
} from "@/constants/ga";

export function gaIntercomClick() {
  window.Intercom("onShow", () => {
    window.ga("send", "event", INTERCOM_EVENT, "click");
  });
}

export function gaDocumentationLinkClick() {
  window.ga("send", "event", DOCUMENTATION_EVENT, "click");
}

export function gaPayoutAccountSubmit() {
  window.ga("send", "event", SAVE_PAYOUT_ACCOUNT_EVENT, "submit");
}

export function gaVirtualInventorySubmit() {
  window.ga("send", "event", VIRTUAL_INVENTORY_EVENT, "submit");
}

export function gaConnectStoreSubmit() {
  window.ga("send", "event", STORE_CONNECT_EVENT, "submit");
}

export function gaRegisterSubmit() {
  window.ga("send", "event", REGISTER_EVENT, "submit");
}
