<template>
  <div class="base-table" v-if="items">
    <v-data-table
      :headers="headers"
      :items="items"
      hide-actions
    >

      <template slot="headers" slot-scope="props">
        <tr>
          <th
            class="text-xs-left"
            v-for="header in props.headers"
            :key="header.value"
          >
            {{ header.text }}
          </th>
        </tr>
      </template>

      <template slot="items" slot-scope="props">
        <tr>
          <td>{{ props.item.email }}</td>
          <td>{{ props.item.phone }}</td>
          <td>{{ props.item.created_at }}</td>
          <td>
            <ButtonPrimary
              small
              outline
              @click:base-button="downloadReport(props.item)"
            >Download</ButtonPrimary>
          </td>
        </tr>
      </template>

    </v-data-table>
  </div>
</template>

<script>
  import { mapState, mapActions } from "vuex";
  import gdprRequestsApi from "@/api/gdprRequests";
  import fileSaver from "file-saver";
  import ButtonPrimary from "@/components/ButtonPrimary";

  export default {
    components: {ButtonPrimary},
    data() {
      return {
        items: [],
        headers: [
          {text: "Customer email", value:"email"},
          {text: "Customer phone", value:"phone"},
          {text: "Report requested", value:"created_at"},
          {text: "", value:"download"}
        ]
      };
    },

    computed: {
      ...mapState({
        gdprRequests: state => state.gdprRequests.requests
      })
    },
    methods: {
      ...mapActions([
        "fetchRequests",
        "setNotifyMsg"
      ]),

      refreshRequests() {
        return this.fetchRequests({id : this.$route.params.id});
      },

      async downloadReport(item) {
        try {
          const response = await gdprRequestsApi.download(item.id);
          await fileSaver.saveAs(response.data, this.getFileName(item));
        } catch(error) {
          this.setNotifyMsg({ text: error.message, color: "error" });
        }
      },

      getFileName(item) {
        return item.foreign_id + '_' + item.created_at_raw.format('YYYY_MM_DD_HH_mm_ss') + '.csv';
      }
    },
    watch: {
      gdprRequests: {
        handler(gdprRequests) {
          this.items = gdprRequests.map(item => {
            return {
              id: item.id,
              created_at: window.moment(item.createdAt).format("DD MMM YYYY HH:mm"),
              email: item.customer.email,
              phone: item.customer.phone,
              download: item.filePath,
              foreign_id: item.customer.foreignId,
              created_at_raw: window.moment(item.createdAt)
            };
          });
        }
      },
      "$route.query": {
        immediate: true,
        handler() {
          this.refreshRequests();
        }
      }
    },
    mounted() {}
  };
</script>

<style lang="scss">
</style>
