<template>
  <Modal v-bind="$attrs" v-on="$listeners">
    <div slot="content">
      <div class="modal-welcome">
        <v-layout>
          <v-flex xs11>
            <h2 class="modal-welcome__title">{{ lang.mainTitle }}</h2>
            <h5 class="modal-welcome__subtitle">{{ lang.subTitle }}</h5>
          </v-flex>
          <v-flex xs1 align-content-end class="text-xs-right">
            <v-icon class="close-icon item--hover" @click="handleButtonClick('-')">close</v-icon>
          </v-flex>
        </v-layout>
        <div class="modal-welcome-buttons">
          <div
            v-for="(button, k) in buttons"
            :key="k"
            @click="handleButtonClick(button.text)"
            class="modal-welcome-buttons__button item--hover"
          >
            <img class="button-icon" :src="require('@/assets/emodji/' + button.icon + '.svg')">
            {{ button.text }}
          </div>
        </div>
      </div>
    </div>
  </Modal>
</template>

<script>
import { mapActions, mapState } from "vuex";

import Modal from "@/components/Modal";

export default {
  inheritAttrs: false,
  components: {
    Modal,
  },
  data() {
    return {};
  },
  computed: {
    ...mapState({
      errorMsg: state => state.errors.notifyMessages.somethingWrong,
      user: state => state.users.currentUser
    }),
    lang() {
      return {
        mainTitle: "Welcome to Ecombo!",
        subTitle:
          "We are constantly improving our service and developing tools for drop shippers. So no matter what experience you have, you can always earn more and grow with us.To better understand what functions can help you to develop and improve your store, tell us - What is your experience with drop shipping?"
      };
    },
    buttons() {
      return [
        {
          text: "I’m a beginner, just learning dropshipping",
          icon: "baby"
        },
        {
          text:
            "I’m already informed about dropshipping. I’ve created a store, but unfortunately, there are no orders",
          icon: "thinking_face"
        },
        {
          text:
            "I have already started working with drop shipping (50-100 orders per month)",
          icon: "cool_face"
        },
        {
          text:
            "I’m an expert in drop shipping (more than 100 orders per month)",
          icon: "money_face"
        }
      ];
    }
  },
  methods: {
    ...mapActions(["setNotifyMsg", "setUserSettings"]),
    async handleButtonClick(val) {
      const { status } = await this.setUserSettings({
        data: { practice: val },
        id: this.user.id
      });

      if (status) {
        this.$emit("esc:modal");
      } else {
        this.setNotifyMsg({ text: this.errorMsg, color: "error" });
      }
    }
  }
};
</script>

<style lang="scss">
.modal-welcome {
  &__title {
    font-size: 38px;
    margin-bottom: 20px;
    margin-top: 15px;
    line-height: 1.2;
  }

  &__subtitle {
    font-size: 16px;
    color: $text-sidebar-color;
  }

  &-buttons {
    margin: 20px 0;

    &__button {
      margin: 7px 0;
      display: flex;
      align-content: center;

      @include breakpoint($m) {
        margin: 20px 0;
      }

      .button-icon {
        margin-right: 7px;
        margin-top: -2px;
      }
    }
  }
}
</style>
