<template>
  <div class="table-sm" v-if="referrals && paginationsLinks">
    <div class="base-table--filters">
      <FilterReferrals @click:search-btn="handleSearch"/>
    </div>
    <BaseTable
      class="table-sm__content"
      :headers="headers"
      :items="items"
      show-pagination
      show-page-size
      :custom-pagination="paginationsLinks"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions, mapState } from "vuex";
import FilterReferrals from "@/components/Filters/FilterReferrals";
import moneyHelper from "@/helpers/money";
import BaseTable from "@/components/BaseTable";

export default {
  components: {
    FilterReferrals,
    BaseTable
  },

  data() {
    return {
      items: [],
      headers: [
        { text: "ID", value: "id" },
        { text: "Name", value: "username" },
        { text: "Income", value: "income" },
      ]
    }
  },
  computed: {
    ...mapState({
      referrals: state => state.users.referrals,
      paginationsLinks: state => state.users.referralsLinks
    }),
    ...mapGetters({
      currentUser: "getCurrentUser",
      loading: "getIsProgress",
    }),
  },
  methods: {
    ...mapActions(["fetchReferrals"]),

    fetchReferralsByFilter(filters = {}) {
      const newFilters = {
        page: 1,
        page_size: 20,
        ...this.$route.query,
        ...filters
      }

      this.fetchReferrals(newFilters)
    },

    handleSearch(filters = {}) {
      this.fetchReferralsByFilter(filters);
    }
  },
  watch: {
    referrals: {
      handler(referrals) {
        this.items = referrals.map(item => {
          return {
            id: item.id,
            username: item.username,
            income: moneyHelper.toMoney(item.income)
          }
        });
      }
    },
    "$route.query": {
      immediate: true,

      handler() {
        this.fetchReferralsByFilter();
      }
    }
  }
}
</script>
