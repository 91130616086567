import { render, staticRenderFns } from "./IconBalance.vue?vue&type=template&id=018de561&scoped=true&"
import script from "./IconBalance.vue?vue&type=script&lang=js&"
export * from "./IconBalance.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "018de561",
  null
  
)

component.options.__file = "IconBalance.vue"
export default component.exports