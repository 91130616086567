<template>
  <div>
    <h3>{{ lang.title + " #" + ticketId }}</h3>
    <br class="hidden-xs-only">
    <Breadcrumbs :items="breadcrumbs"></Breadcrumbs>
    <div class="ticket" v-if="ticket">
      <div class="ticket__heading">
        <h3>{{ lang.orderInformation }}</h3>
        <div class="ticket__information">
          <div>
            <span
              class="ticket__information__rounded ticket__information__status tag-status"
              v-bind:class="ticket.tagClass"
            >{{ ticket.statusText }}</span>
            <span class="ticket__information__date">{{ ticket.createdAt }}</span>
          </div>
          <div>
            <span
              class="ticket__information__rounded ticket__information__email"
            >{{ ticket.clientEmail }}</span>
            <span
              class="ticket__information__rounded ticket__information__source"
            >{{ ticket.sourceText }}</span>
          </div>
        </div>
      </div>
      <ul class="ticket__chat-list">
        <li
          v-for="chatItem in ticket.ticketComments"
          class="ticket__chat-list__item"
          :key="chatItem.id"
        >
          <div class="ticket__chat-list__sender-holder">
            <span
              class="ticket__chat-list__sender"
              v-bind:class="chatItem.tagClass"
            >{{ chatItem.sender }}</span>
            <span class="ticket__chat-list__date hidden-sm-and-up">{{ chatItem.createdAt }}</span>
          </div>
          <div class="ticket__chat-list__content">
            <span class="ticket__chat-list__date hidden-xs-only">{{ chatItem.createdAt }}</span>
            <span class="ticket__chat-list__text">{{ chatItem.message }}</span>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Breadcrumbs from "@/components/Breadcrumbs";
import {
  STATUS_OPEN,
  STATUS_RESOLVED,
  STATUS_CLOSED,
  STATUS_DELETED,
  STATUS_HOLD,
  STATUS_WAITING,
  STATUS_SPAM,
  STATUS_NEW,
  STATUS_SENDING,
  SOURCE_CHAT,
  SOURCE_MAIL
} from "@/constants/tickets";

export default {
  components: {
    Breadcrumbs
  },
  computed: {
    ...mapGetters({
      ticket: "getTicket"
    }),
    lang() {
      return {
        title: "Tickets",
        orderInformation: "Order information",
        source: {
          chat: "Chat",
          mail: "Mail"
        },
        status: {
          open: "Open",
          resolved: "Resolved",
          closed: "Closed",
          deleted: "Deleted",
          hold: "Hold",
          waiting: "Waiting",
          spam: "Spam",
          new: "New",
          sending: "Sending"
        }
      };
    },
    ticketId() {
      return this.$route.params.id;
    },
    breadcrumbs() {
      return [
        {
          text: "Tickets",
          disabled: false,
          href: {
            name: "dashboard.tickets"
          }
        },
        {
          text: "Tickets #" + this.ticketId,
          disabled: true,
          href: { name: "" }
        }
      ];
    }
  },
  methods: {
    ...mapActions(["fetchTicketById"]),
    getSourceText(val) {
      if (val == SOURCE_CHAT) {
        return this.lang.source.chat;
      }

      if (val == SOURCE_MAIL) {
        return this.lang.source.mail;
      }
    },
    getStatusText(val) {
      if (val == STATUS_OPEN) {
        return this.lang.status.open;
      }

      if (val == STATUS_RESOLVED) {
        return this.lang.status.resolved;
      }

      if (val == STATUS_CLOSED) {
        return this.lang.status.closed;
      }

      if (val == STATUS_DELETED) {
        return this.lang.status.deleted;
      }

      if (val == STATUS_HOLD) {
        return this.lang.status.hold;
      }

      if (val == STATUS_WAITING) {
        return this.lang.status.waiting;
      }

      if (val == STATUS_SPAM) {
        return this.lang.status.spam;
      }

      if (val == STATUS_NEW) {
        return this.lang.status.new;
      }

      if (val == STATUS_SENDING) {
        return this.lang.status.sending;
      }
    },
    getStatusTag(status) {
      if (
        status === STATUS_OPEN ||
        status === STATUS_RESOLVED ||
        status === STATUS_CLOSED
      ) {
        return "success";
      }
      if (status === STATUS_HOLD || status === STATUS_WAITING) {
        return "waiting";
      }
      if (status === STATUS_DELETED || status === STATUS_SPAM) {
        return "danger";
      }
      if (status === STATUS_NEW || status === STATUS_SENDING) {
        return "neutral";
      }
      return "neutral";
    },
    getSenderTag(sender) {
      if (sender === "client") {
        return "client";
      }
      if (sender === "user") {
        return "user";
      }
    },
    getSenderText(sender) {
      if (sender === "client") {
        return "Client";
      }
      if (sender === "user") {
        return "Manager";
      }
    }
  },
  watch: {
    "$route.params": {
      immediate: true,
      handler() {
        const data = { id: this.ticketId };
        this.fetchTicketById(data);
      }
    },
    ticket: {
      handler(ticket) {
        ticket.sourceText = this.getSourceText(ticket.source);
        ticket.statusText = this.getStatusText(ticket.status);
        ticket.createdAt = window.moment(ticket.createdAt).format("LLL");
        ticket.tagClass = "tag-status--" + this.getStatusTag(ticket.status);
        ticket.ticketComments.map(chatItem => {
          chatItem.sender = this.getSenderText(chatItem.from);
          chatItem.createdAt = window.moment(chatItem.createdAt).format("LLL");
          chatItem.tagClass =
            "ticket__chat-list__sender--" + this.getSenderTag(chatItem.from);
        });
      }
    }
  }
};
</script>

<style scoped lang="scss">
.ticket {
  padding: 20px;
  background: #fff;
  max-width: 775px;
  @include breakpoint($m) {
    padding-left: 8px;
    padding-right: 8px;
  }
  &__heading {
    margin: 0 0 80px;
    @include breakpoint($m) {
      margin-bottom: 40px;
    }
    h3 {
      color: #7e7e7e;
      margin: 0 0 7px;
      font-size: 18px;
      line-height: 1.2;
    }
  }
  &__information {
    display: flex;
    margin: 0 -5px;
    font-size: 12px;
    line-height: 15px;
    align-items: flex-end;
    justify-content: space-between;
    @include breakpoint($m) {
      display: block;
      > div:first-child {
        margin-bottom: 20px;
      }
    }
    &__date {
      margin-left: 25px;
      color: #a6a8a9 !important;
    }
    &__rounded {
      margin: 0 5px;
      padding: 0 10px;
      border-radius: 7px;
    }
    &__email {
      background: #d8d8d8;
      color: #636363 !important;
    }
    &__source {
      background: #f0f0f1;
      color: #636363 !important;
    }
  }
  &__chat-list {
    margin: 0;
    padding: 0;
    font-size: 14px;
    list-style: none;
    line-height: 1.57;
    &__item {
      display: flex;
      margin: 0 0 25px;
      align-items: flex-start;
      justify-content: flex-start;
      @include breakpoint($m) {
        display: block;
      }
    }
    &__sender-holder {
      width: 68px;
      flex-shrink: 0;
      margin: 0 10px 0 0;
      @include breakpoint($m) {
        width: auto;
        display: flex;
        flex-wrap: wrap;
        margin: 0 0 8px;
        align-items: center;
        justify-content: flex-start;
      }
    }
    &__sender {
      padding: 0 8px;
      font-size: 12px;
      line-height: 15px;
      border-radius: 7px;
      vertical-align: top;
      display: inline-block;
      color: #fff !important;
      @include breakpoint($m) {
        margin: 0 10px 0 0;
      }
      &--client {
        background: #ffa724;
      }
      &--user {
        background: #00c2c2;
      }
    }
    &__date {
      display: block;
      font-size: 12px;
      line-height: 1.2;
      margin: 0 0 12px;
      color: #a6a8a9 !important;
      @include breakpoint($m) {
        margin: 0;
      }
    }
    &__text {
      display: block;
    }
  }
}
</style>
