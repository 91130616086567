<template>
  <div class="payouts" v-if="user">
    <component
      :is="modalName"
      :show="showModal"
      @change:modal="handleChangeModal"
      @esc:modal="showModal = false"
      max-width="500"
    ></component>
    <h3 class="payouts__title">{{ lang.title }}</h3>
    <v-container class="pa-0">
      <v-layout wrap>
        <v-flex md></v-flex>
        <v-flex md3>
          <ButtonPrimary small block @click:base-button="handleOpenModal">{{ lang.newRequest }}</ButtonPrimary>
        </v-flex>
      </v-layout>
    </v-container>
    <TablePayouts/>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import TablePayouts from "@/components/Tables/TablePayouts";
import ButtonPrimary from "@/components/ButtonPrimary";
import ModalAddPayoutAccounts from "@/components/ModalAddPayoutAccounts";
import ModalAddNewRequest from "@/components/ModalAddNewRequest";

export default {
  components: {
    TablePayouts,
    ButtonPrimary,
    ModalAddPayoutAccounts,
    ModalAddNewRequest
  },
  data() {
    return {
      showModal: false,
      modalName: "modal-add-payout-accounts"
    };
  },
  computed: {
    ...mapState({
      payoutAccounts: state => state.payoutAccounts.payoutAccounts,
      user: state => state.users.currentUser
    }),
    lang() {
      return {
        title: "Payouts",
        newRequest: "New request"
      };
    }
  },
  methods: {
    ...mapActions(["fetchPayoutAccounts"]),
    handleOpenModal() {
      this.showModal = true;
    },
    handleChangeModal(val) {
      this.showModal = val;
    }
  },
  mounted() {
    this.fetchPayoutAccounts();
  },
  watch: {
    payoutAccounts: {
      immediate: true,
      handler(val) {
        if (val && val.length) {
          this.showModal = false;
          this.modalName = "modal-add-new-request";
        }
      }
    },
    user: {
      immediate: true,
      handler(data) {
        if (data && !data.payoutEnabled) {
          this.$router.back();
        }
      }
    }
  }
};
</script>

<style lang="scss">
.payouts {
  max-width: $max-width;
  &__title {
    margin-bottom: 20px;
  }
}
</style>
