<template>
  <div class="inventory-wizzard">
    <h3 v-if="showWizzard">{{ lang.title }}</h3>
    <v-layout wrap v-if="showWizzard">
      <v-flex md8 class="inventory-wizzard-form">
        <PresentionalStepperForm :headers="headers" :step="step">
          <div slot="main-content">
            <div v-show="step === 1">
              <h4 class="inventory-stepper-content__title">{{ contents.step1.title }}</h4>
              <v-layout wrap>
                <v-flex md9>
                  <InputSecondaryText
                    outline
                    v-model="form.productUrl"
                    :value="form.productUrl"
                    :placeholder="lang.form.productUrlPlaceholder"
                    :rules="[rules.required]"
                    @update:error="onInpuError"
                  >{{ lang.form.productUrlLabel }}</InputSecondaryText>
                </v-flex>
                <v-flex md3>
                  <InputSecondaryText
                    outline
                    v-model.number="form.productQuantity"
                    :value="form.productQuantity"
                    :rules="[rules.required]"
                    @update:error="onInpuError"
                  >{{ lang.form.productQuantityLabel }}</InputSecondaryText>
                </v-flex>
              </v-layout>
            </div>
            <div v-show="step === 2">
              <h4 class="inventory-stepper-content__title">{{ contents.step2.title }}</h4>
              <v-radio-group
                v-model="form.packType"
                :value="form.packType"
                class="inventory-stepper-radio-group"
                @change="$root.$emit('change:radio-group', form.packType)"
                hide-details
              >
                <RadioBorderBox
                  v-for="(radio, k) in radios.step2"
                  :value="radio.value"
                  :label="radio.label"
                  :key="k"
                >
                  <div slot="icon">
                    <v-img
                      :src="require(`@/assets/icons/${radio.icon}.svg`)"
                      :width="20"
                      :height="20"
                    ></v-img>
                  </div>
                </RadioBorderBox>
              </v-radio-group>
              <v-checkbox
                v-if="form.packType === 'design'"
                class="step2-checkbox"
                color="warning"
                :label="lang.form.packType.custom"
                v-model="form.ownDesign"
              ></v-checkbox>
            </div>

            <div v-show="step === 3">
              <h4 class="inventory-stepper-content__title">{{ contents.step3.title }}</h4>
              <div class="step3-checkboxes-group">
                <CheckboxBorderBox
                  v-for="(checkbox, k) in checkboxes.step3"
                  :value="checkbox.value"
                  :label="checkbox.label"
                  @change="handleSelectedAttachmentType($event, checkbox.value)"
                  :key="k"
                >
                  <div slot="icon">
                    <v-img
                      :src="require(`@/assets/icons/${checkbox.icon}.svg`)"
                      :width="20"
                      :height="20"
                    ></v-img>
                  </div>
                </CheckboxBorderBox>
              </div>
              <InputSecondaryText
                outline
                v-if="form.attachmentTypes.includes('other')"
                v-model.number="form.attachment"
                :placeholder="lang.form.attachmentTypeOtherPlaceholder"
                :value="form.attachment"
                :rules="[rules.required]"
                @update:error="onInpuError"
              ></InputSecondaryText>
            </div>

            <div v-show="step === 4">
              <h4 class="inventory-stepper-content__title">{{ contents.step4.title }}</h4>
              <InputSecondarySelect
                v-for="num in countriesCount"
                :key="num"
                outline
                item-text="niceName"
                item-value="iso2"
                :items="countries"
                height="40"
                append-icon="expand_more"
                @change="onChangeCountry($event, num)"
              />
              <div
                class="add-country-title"
                v-text="contents.step4.addCountry"
                @click="countriesCount++"
              ></div>
            </div>

            <div v-show="step === 5">
              <h4 class="inventory-stepper-content__title">{{ contents.step5.title }}</h4>
              <InputSecondaryText
                outline
                v-model="form.email"
                :value="form.email"
                :rules="[rules.validEmail]"
                @update:error="onInpuError"
              >{{ lang.form.emailLabel }}</InputSecondaryText>
              <InputSecondaryText
                outline
                v-model="form.phone"
                :value="form.phone"
              >{{ lang.form.phoneLabel }}</InputSecondaryText>
              <InputSecondaryText
                outline
                v-model="form.skype"
                :value="form.skype"
              >{{ lang.form.skypeLabel }}</InputSecondaryText>
            </div>
          </div>

          <div slot="actions" class="inventory-stepper-actions">
            <ButtonSecondary
              small
              color="#d32d11"
              outline
              v-if="step > 1"
              @click:base-button="changeStep(--step, 'prev')"
            >Go back</ButtonSecondary>
            <div v-else></div>
            <ButtonPrimary
              small
              v-if="step !== 5"
              @click:base-button="changeStep(++step, 'next')"
              :disabled="isDisabled"
            >Next</ButtonPrimary>
            <ButtonPrimary
              small
              v-if="step === 5"
              @click:base-button="handleSubmitForm"
              :loading="isLoading"
              :disabled="isDisabled"
            >Submit</ButtonPrimary>
          </div>
          <v-flex md4 slot="secondary-block" class="hidden-sm-and-down">
            <div class="inventory-stepper-content-static">
              <h5
                class="inventory-stepper-content-static__title"
              >{{ lang.staticBlock.title['step' + step] }}</h5>
              <div class="inventory-stepper-content-static__actions">
                <v-layout wrap align-end>
                  <v-flex md8>
                    <div class="action-title" v-text="lang.staticBlock.actions.title"></div>
                    <div class="action-subtitle" v-text="lang.staticBlock.actions.subtitle"></div>
                  </v-flex>
                  <v-flex md4>
                    <Avatar :src="require(`@/assets/images/tim-avatar.jpeg`)" contain/>
                  </v-flex>
                </v-layout>
              </div>
            </div>
          </v-flex>
          <v-flex md4 slot="secondary-block" class="hidden-md-and-up">
            <div class="inventory-stepper-content-static-mobile">
              <v-flex xs12>
                <span class="action-title" v-text="lang.staticBlock.actions.title"></span>
                <span class="action-subtitle" v-text="lang.staticBlock.actions.subtitle"></span>
                <h5
                  class="inventory-stepper-content-static__title"
                >{{ lang.staticBlock.title['step' + step] }}</h5>
              </v-flex>
            </div>
          </v-flex>
        </PresentionalStepperForm>
      </v-flex>
    </v-layout>
    <div v-else>
      <h2 class="inventory-wizzard-success-title" v-text="successMsg"></h2>
    </div>
  </div>
</template>

<script>
import api from "@/api/brandedProducts";
import { mapState, mapActions, mapGetters } from "vuex";
import { STATUS_CREATED } from "@/constants/httpCodes";
import { gaVirtualInventorySubmit } from "@/helpers/gaEvents";

import PresentionalStepperForm from "@/components/PresentionalStepperForm";
import InputSecondaryText from "@/components/InputSecondaryText";
import InputSecondarySelect from "@/components/InputSecondarySelect";
import CheckboxBorderBox from "@/components/CheckboxBorderBox";
import RadioBorderBox from "@/components/RadioBorderBox";
import ButtonSecondary from "@/components/ButtonSecondary";
import ButtonPrimary from "@/components/ButtonPrimary";
import Avatar from "@/components/Avatar";

export default {
  components: {
    PresentionalStepperForm,
    InputSecondaryText,
    CheckboxBorderBox,
    RadioBorderBox,
    InputSecondarySelect,
    ButtonSecondary,
    ButtonPrimary,
    Avatar
  },
  data() {
    return {
      showWizzard: true,
      step: 1,
      radios: {
        step2: [
          {
            label: "Original pack",
            value: "usual",
            icon: "box-simple"
          },
          {
            label: "Designed pack",
            value: "design",
            icon: "box-design"
          }
        ]
      },
      checkboxes: {
        step3: [
          {
            label: "Store flyer",
            value: "business_cards",
            icon: "business-card"
          },
          {
            label: "Product Instruction",
            value: "instructions",
            icon: "instructions"
          },
          {
            label: "Leaflet",
            value: "fliers",
            icon: "fliers"
          },
          {
            label: "Other",
            value: "other",
            icon: "other"
          }
        ]
      },
      countriesCount: 1,
      selectedCountries: {},
      isLoading: false,
      isDisabled: true,
      headers: [
        {
          step: 1,
          title: "Product"
        },
        {
          step: 2,
          title: "Packing"
        },
        {
          step: 3,
          title: "Attachments"
        },
        {
          step: 4,
          title: "Stock"
        },
        {
          step: 5,
          title: "Contacts"
        }
      ],
      contents: {
        step1: {
          title: "Choose your product"
        },
        step2: {
          title: "Packing type"
        },
        step3: {
          title: "Print Item Attachment"
        },
        step4: {
          title: "Country item delivery",
          addCountry: "Add country"
        },
        step5: {
          title: "Contact details"
        }
      },
      form: {
        salesChannel: this.$salesChannel.get(),
        productUrl: "",
        productQuantity: 1,
        packType: "",
        ownDesign: false,
        attachmentTypes: [],
        attachment: "",
        countries: [],
        email: "",
        phone: "",
        skype: ""
      }
    };
  },
  computed: {
    ...mapState({
      rules: state => state.validations.rules
    }),
    ...mapGetters({
      countries: "getCountries"
    }),
    ...mapState({
      successMsg: state => state.errors.notifyMessages.wizzardSucces
    }),
    lang() {
      return {
        title: "Batch order",
        staticBlock: {
          title: {
            step1:
              "Paste product link here. It can be product link from our catalog or from other sources. One product = One order. If you want to order three different products, you need to create three orders.",
            step2:
              "In this section you may choose product pack design. Original pack provided by supplier. Designed pack can be developed by our Brand Develop Team. My pack design allows you to add your own variant.",
            step3:
              "Here you may choose attachment and it will be added to parcel with order. You can add Shop flyer with your store information or Product Instruction or Leaflet with your Store Product List or click Other and add your own variant of attachment. You can also choose all variants from our Attachment list.",
            step4:
              "In this section you can choose a country with warehousing service. All your products will be keeping at the stock, so you will always be able to control product quantity and provide quick delivery to your client.",
            step5:
              "Leave your contact details and we will reply you in 48 hours"
          },
          actions: {
            title: "Tim",
            subtitle: "Manager"
          }
        },
        form: {
          productUrlLabel: "Product",
          productUrlPlaceholder: "Product URL",
          productQuantityLabel: "Quantity",
          attachmentTypeOtherPlaceholder: "Describe your attachment",
          emailLabel: "Email",
          phoneLabel: "Phone",
          skypeLabel: "Skype",
          packType: {
            custom: "My pack design"
          }
        }
      };
    }
  },
  methods: {
    ...mapActions(["setNotifyMsg"]),
    changeStep(val, direction) {
      this.step = val;

      if (direction === "next") {
        this.isDisabled = true;
        this.checkIsDisabled(this.form);
      }

      if (direction === "prev") {
        this.isDisabled = false;
      }

      if (val === 2) {
        this.$root.$emit("set-active-radio", this.form.packType);
      }

      if (val === 3) {
        this.$root.$emit("set-active-radio", this.form.attachmentTypes);
      }

      if (val === 5) {
        this.setCountries();
      }
    },
    onChangeCountry(e, num) {
      if (this.isDisabled) {
        this.isDisabled = !this.isDisabled;
      }
      this.selectedCountries[num] = e;
    },
    setCountries() {
      this.form.countries = [];
      const countriesValues = Object.values(this.selectedCountries);
      this.form.countries = [...new Set(countriesValues)];
    },
    async handleSubmitForm() {
      this.isLoading = true;

      try {
        const { status } = await api.post(this.form);

        if (status === STATUS_CREATED) {
          gaVirtualInventorySubmit();
          this.$root.$emit("hide-wizzard");
        }
      } catch (error) {
        this.setNotifyMsg({ text: error.response.data.errors, color: "error" });
      } finally {
        this.isLoading = false;
      }
    },
    onInpuError(val) {
      this.isDisabled = val;
    },
    checkIsDisabled(form) {
      if (this.step === 2) {
        if (form.packType) {
          this.isDisabled = false;
        }
      }

      if (this.step === 3) {
        if (form.attachmentTypes.length) {
          this.isDisabled = false;

          if (form.attachmentTypes.includes("other") && !form.attachment) {
            this.isDisabled = true;
          } else {
            this.isDisabled = false;
          }
        } else {
          this.isDisabled = true;
        }
      }

      if (this.step === 4) {
        if (Object.keys(this.selectedCountries).length) {
          this.isDisabled = false;
        } else {
          this.isDisabled = true;
        }
      }

      if (this.step === 5) {
        const email = typeof this.rules.validEmail(form.email) !== "string";
        if (form.phone || form.skype || email) {
          this.isDisabled = false;
        } else {
          this.isDisabled = true;
        }
      }
    },
    handleSelectedAttachmentType(e, val) {
      if (e) {
        this.form.attachmentTypes.push(val);
      } else {
        const index = this.form.attachmentTypes.indexOf(val);
        if (index !== -1) {
          const attachmentTypes = this.form.attachmentTypes;
          attachmentTypes.splice(index, 1);
          this.form.attachmentTypes = attachmentTypes;
        }
      }
    }
  },
  watch: {
    "form.packType": {
      handler(val) {
        if (val !== "design") {
          this.form.ownDesign = false;
        }
      }
    },
    "form.attachmentTypes": {
      handler(val) {
        if (!val.includes("other")) {
          this.form.attachment = "";
        }
      }
    },
    form: {
      deep: true,
      immediate: true,
      handler(form) {
        this.checkIsDisabled(form);
      }
    }
  },
  mounted() {
    this.$root.$on("hide-wizzard", () => {
      this.showWizzard = false;
    });
  }
};
</script>

<style scoped lang="scss">
.inventory-wizzard {
  max-width: $max-width;

  &__title {
    margin-left: 16px;
    margin-bottom: 32px;
  }

  .inventory-wizzard-success-title {
    color: #979797;
    font-size: 30px;
    line-height: 32px;
    text-align: center;
    margin-top: 100px;
  }

  h3 {
    margin-bottom: 20px;
  }
}
</style>
