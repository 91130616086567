import * as constants from "@/constants/salesChannel";

export function getTypeIdText(typeId) {
    switch (typeId) {
        case constants.TYPE_SHOPIFY:
            return "Shopify";
        case constants.TYPE_WOO_COMMERCE:
            return "WooCommerce";
        default:
            return typeId;
    }
}
