<template>
  <Modal
    v-bind="$attrs"
    v-on="$listeners"
    @change:modal="onChangeModal"
    scrollable
    :show="showModal"
    @esc:modal="onChangeModal(false)"
  >
    <div slot="content">
      <div class="modal-dp">
        <h2 class="modal-dp__title">{{ lang.mainTitle }}</h2>
        <p class="modal-dp__text">{{ lang.mainText() }}</p>
        <div class="modal-dp__btn">
          <ButtonPrimary
            v-if="salesChannel"
            @click:base-button="getStoreUrl(salesChannel.authUrl)"
            :loading="progress"
          >{{ lang.btnText }}</ButtonPrimary>
        </div>
      </div>
    </div>
  </Modal>
</template>

<script>
import { mapActions, mapState, mapGetters, mapMutations } from "vuex";
import { TYPE_SHOPIFY } from "@/constants/salesChannel";
import Modal from "@/components/Modal";
import ButtonPrimary from "@/components/ButtonPrimary";

export default {
  inheritAttrs: false,
  components: {
    Modal,
    ButtonPrimary
  },
  data() {
    return {
      showModal: null
    };
  },
  computed: {
    ...mapState({
      errorSalesChannelAuthUrlMsg: state =>
        state.errors.notifyMessages.salesChannelStoreUrlNotFound
    }),
    ...mapGetters({
      progress: "getIsProgress",
      salesChannel: "getSalesChannel"
    }),
    lang() {
      return {
        mainTitle: "Reconnect shop",
        mainText: () => {
          if (!this.salesChannel) return "";
          return `Your store ${this.salesChannel.name} need to reconnect`;
        },
        btnText: "Reconnect"
      };
    },
    salesChannelId() {
      return this.$salesChannel.get();
    }
  },
  methods: {
    ...mapActions(["fetchSalesChannelById", "setNotifyMsg"]),
    ...mapMutations(["SET_IS_RECONNECT_SALES_CHANNEL_DATA"]),
    getStoreUrl(authUrl) {
      if (!authUrl) {
        this.setNotifyMsg({
          color: "error",
          text: this.errorSalesChannelAuthUrlMsg
        });
        return;
      }

      return (window.location = authUrl);
    },
    onChangeModal(val) {
      this.showModal = val;
      if (!val) {
        this.SET_IS_RECONNECT_SALES_CHANNEL_DATA(true);
      }
    }
  },
  created() {
    if (this.salesChannelId) {
      this.fetchSalesChannelById({
        id: this.salesChannelId,
        filters: null
      });
    }
  },
  mounted() {
    this.$root.$on("show-modal-checker", val => {
      this.showModal = val;
    });
  },
  watch: {
    salesChannel: {
      handler(data) {
        if (!data) return;
        const { status } = data;
        if (status != TYPE_SHOPIFY) {
          this.showModal = true;
        }
      }
    }
  }
};
</script>

<style lang="scss">
.modal-dp {
  .modal-dp__title {
    font-size: 30px;
    margin-bottom: 20px;
  }
  .modal-dp__btn {
    display: flex;
    flex-direction: row-reverse;
    margin-top: 30px;
    text-align: right;
    @include breakpoint($m) {
      text-align: left;
    }
  }
  .modal-dp__text {
    font-size: 16px;
  }
}
</style>
