<template>
  <div class="tickets">
    <h3>{{ lang.title }}</h3>
    <v-layout wrap name="settings" class="base-table--filters">
      <FormTicketsSettings/>
    </v-layout>
    <TableTickets/>
  </div>
</template>

<script>
import TableTickets from "@/components/Tables/TableTickets";
import FormTicketsSettings from "@/components/FormTicketsSettings";

export default {
  components: {
    TableTickets,
    FormTicketsSettings
  },
  data() {
    return {};
  },
  computed: {
    lang() {
      return {
        title: "Tickets"
      };
    }
  },
  methods: {}
};
</script>

<style scoped lang="scss">
h3 {
  margin-bottom: 20px;
}

.tickets {
  max-width: $max-width;
}
</style>
