<template>
  <div class="input-secondary-datepicker">
    <div class="input-secondary-datepicker__label">
      <slot></slot>
    </div>
    <v-menu
      ref="menu"
      v-model="menu"
      :close-on-content-click="false"
      :nudge-right="40"
      lazy
      transition="fade"
      offset-y
      full-width
      min-width="290px"
    >
      <v-text-field solo single-line flat outline slot="activator" v-model="date" readonly></v-text-field>
      <v-date-picker
        ref="picker"
        v-model="date"
        :max="new Date().toISOString().substr(0, 10)"
        min="1950-01-01"
        @change="save"
      ></v-date-picker>
    </v-menu>
  </div>
</template>

<script>
export default {
  inheritAttrs: false,
  props: {
    value: {
      type: String
    },
    height: {
      type: Number,
      default: 40
    }
  },
  data() {
    return {
      date: null,
      menu: false
    };
  },
  watch: {
    menu(val) {
      val && setTimeout(() => (this.$refs.picker.activePicker = "YEAR"));
    },
    value(val) {
      this.date = val;
    }
  },
  methods: {
    save(date) {
      this.$refs.menu.save(date);
      this.$emit("change:datetime-of-birth", date);
    }
  }
};
</script>

<style lang="scss">
.input-secondary-datepicker {
  &__label {
    font-size: 12px;
    line-height: 22px;
    color: $text-color;

    @include breakpoint($m) {
      line-height: 18px;
    }
  }

  .v-input {
    font-size: 14px;

    &__slot {
      border-color: #a6a8a9 !important;
      border-radius: 0 !important;
      border-width: 1px !important;
      min-height: 40px !important;

      input {
        margin: 0 !important;
      }
    }

    .v-text-field__details {
      padding: 0 !important;
    }
  }
}
</style>
