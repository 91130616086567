import axios from 'axios';

import {
  BASE_URI
} from '@/constants/api';


axios.defaults.headers.post['Content-Type'] = 'application/json';
export default class BaseApi {
  static get baseURI() {
    return BASE_URI;
  }
}
