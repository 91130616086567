<template>
  <div class="product-options">
    <div
      v-on="$listeners"
      class="product-option"
      :class="{'product-option--active': selectedItem === item.id}"
      v-for="(item, k) in items"
      :key="k"
      @click="handleClickOption(item)"
    >{{item.name}}</div>
  </div>
</template>

<script>
import mixin from "@/mixins/productOptionMixin";

export default {
  props: {
    items: {
      type: Array,
      required: true
    },
    optionNumber: {
      type: [Number, String],
      required: true
    }
  },
  mixins: [mixin]
};
</script>

<style scoped lang="scss">
</style>
