<template>
  <div class="inventory-store">
    <PresentationalStartBlock :data="blockData"/>
    <div v-for="(block, k) in lang.secondaryBlocks" :key="k" class="inventory-store-blocks-wrapper">
      <PresentationalStartSecondaryBlock :data="block"/>
    </div>
  </div>
</template>

<script>
import PresentationalStartBlock from "@/components/PresentationalStartBlock";
import PresentationalStartSecondaryBlock from "@/components/PresentationalStartSecondaryBlock";

export default {
  components: {
    PresentationalStartBlock,
    PresentationalStartSecondaryBlock
  },
  data() {
    return {
      blockData: {
        title: "Increase your profit and develop your own brand!",
        subtitle: "Make a wholesale order with individual pack design and flyers. Keep and send products from different parts of the world for 5-10 days.",
        btnText: "Start",
        img: "inventory-start.svg",
        routerName: "dashboard.inventory.wizzard"
      }
    };
  },
  computed: {
    lang() {
      return {
        secondaryBlocks: [
          {
            title: "Stock management",
            subtitle: "Control product quantity at the stock and be informed about purchases in time.",
            image: require("@/assets/images/inventory-pic-1.png")
          },
          {
            title: "Wholesale order option",
            subtitle: "Make wholesale order according to your requirements - from your individual packaging design till exact country warehousing.",
            image: require("@/assets/images/inventory-pic-2.png")
          }
        ]
      };
    }
  }
};
</script>

<style scoped lang="scss">
.inventory-store {
  max-width: $max-width;

  .inventory-store__title {
    margin-bottom: 47px;
  }

  .inventory-store-blocks-wrapper {
    margin: 150px 0;
  }
}
</style>
