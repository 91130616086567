<template>
  <div class="base-combobox">
    <label class="base-combobox__label">
      <slot></slot>
    </label>
    <v-combobox
      class="base-combobox-background--gray"
      v-model="model"
      v-bind="$attrs"
      :items="items"
      :search-input.sync="search"
      hide-selected
      hide-details
      single-line
      multiple
      flat
      persistent-hint
      small-chips
      @change="handleModelChange"
    >
      <template slot="no-data">
        <v-list-tile>
          <v-list-tile-content>
            <v-list-tile-title>
              Press
              <kbd>enter</kbd> to create a new one
            </v-list-tile-title>
          </v-list-tile-content>
        </v-list-tile>
      </template>
    </v-combobox>
  </div>
</template>

<script>
export default {
  inheritAttrs: false,
  props: {
    items: {
      type: Array,
      default() {
        return [];
      }
    },
    value: {
      type: String
    },
    backgroundColor: {
      type: String,
      default: "white"
    },
    height: {
      type: String,
      default: "30"
    }
  },
  data() {
    return {
      model: [],
      search: null
    };
  },
  methods: {
    setPropsHeight() {
      let els = document.getElementsByClassName("v-select");
      for (let item of els) {
        item.style.height = this.height + "px";
        item.style["min-height"] = this.height + "px";
        item.style["max-height"] = this.height + "px";
      }
    },
    handleModelChange(model) {
      this.$emit("change:base-combobox", model);
    }
  },
  mounted() {
    this.setPropsHeight();
  },
  watch: {
    value: {
      immediate: true,
      handler(tags) {
        if (tags) {
          this.model = tags.split(", ");
        }
      }
    }
  }
};
</script>

<style lang="scss">
.base-combobox {
  .base-combobox__label {
    font-size: em(12);
    color: $text-color;
  }

  .v-select--chips {
    padding: 0;

    * {
      font-size: 12px !important;
    }

    div,
    input {
      height: inherit !important;
      max-height: inherit !important;
      min-height: inherit !important;
    }

    .v-input__control {
      .v-input__slot {
        padding-left: 10px;
        &::before {
          border: none !important;
        }

        &::after {
          display: none !important;
        }

        box-shadow: none !important;
        border-radius: 4px !important;

        label {
          color: $title-color !important;
        }

        .v-input__icon--append {
          i {
            font-size: 16px !important;
          }
        }

        .v-input__icon {
          display: none;
        }
      }
    }

    .v-select__selections {
      overflow: hidden !important;
      input {
        text-indent: 10px;
      }
      .v-chip {
        margin: 0;
        margin-right: 5px;
        .v-chip__content {
          color: $title-color;
        }
      }
    }

    .v-select__selection {
      margin: 0 !important;
    }

    .v-select-list {
      .v-list__tile__title {
        font-size: 12px !important;
      }
    }
  }
}

.base-combobox-background--gray {
  .v-input__control {
    .v-input__slot {
      background-color: $body-color !important;
    }
  }
}
</style>
