<template>
  <v-rating
    v-model="rating"
    :readonly="readonly"
    :color="color"
    :background-color="backgroundColor"
    :size="size"
    v-bind="$attrs"
    half-increments
  ></v-rating>
</template>

<script>
export default {
  inheritAttrs: false,
  props: {
    rating: {
      type: [String, Number],
      default: 0
    },
    color: {
      type: String,
      default: "#ffa724"
    },
    backgroundColor: {
      type: String,
      default: "#a6a8a9"
    },
    readonly: {
      type: Boolean,
      default: true
    },
    size: {
      type: Number,
      default: 18
    }
  }
};
</script>

<style lang="scss">
.application{
  .v-rating{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    .v-icon{
      @include breakpoint($m) {
        padding: 1px;
      }
    }
  }
}
</style>
