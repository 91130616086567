<template>
  <div class="split-options-table">
    <BaseTable
      :headers="headers"
      :items="items"
      show-radio-buttons
      @fetch-selected-item-number="handleSelectedOption"
      radio-buttons-number
    ></BaseTable>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import ButtonPrimary from "@/components/ButtonPrimary";
import BaseTable from "@/components/BaseTable";

export default {
  inheritAttrs: false,
  components: {
    ButtonPrimary,
    BaseTable
  },
  data() {
    return {
      selected: [],
      items: [],
      headers: [],
      options: ["option1", "option2", "option3"]
    };
  },
  computed: {
    ...mapGetters({
      product: "getMyProduct",
      progress: "getIsProgress"
    })
  },
  methods: {
    setHeaders() {
      this.headers = [{ text: "Options", value: "name" }];
    },
    handleSelectedOption(num) {
      this.$emit("fetch-selected-option", `option${num}`);
    },
    handleSplitProducts() {
      this.$emit("click:split");
    },
    getOptionByName(name) {
      let result;
      this.options.forEach(option => {
        if (this.product[option] === name) {
          result = option;
        }
      });
      return result;
    }
  },
  watch: {
    product: {
      immediate: true,
      handler(product) {
        this.setHeaders();
        const tempItems = this.options
          .map(item => {
            if (this.product[item]) return { name: this.product[item] };
          })
          .filter(item => typeof item !== "undefined");

        const initOptionName = tempItems[0].name;
        const initOption = this.getOptionByName(initOptionName);

        this.$emit("fetch-selected-option", initOption);
        this.items = tempItems;
      }
    }
  },
  beforeDestroy() {
    this.$off();
  }
};
</script>

<style lang="scss">
.split-options-table {
  .v-table__overflow {
    height: 300px;
    overflow: auto;
  }

  &__header {
    margin-bottom: 10px;
  }
}
</style>
