<template>
  <div class="table-orders" v-if="items">
    <BaseTable class="table-orders__content" :headers="headers" :items="items"></BaseTable>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  components: {},
  data() {
    return {
      items: [],
      headers: [
        { text: "Name", value: "name" },
        { text: "Price", value: "price" },
        { text: "Quantity", value: "quantity" },
        { text: "SKU", value: "sku" },
        { text: "Track numbers", value: "trackNumbers" }
      ]
    };
  },
  computed: {
    ...mapGetters({
      order: "getOrder"
    })
  },
  methods: {},
  watch: {
    order: {
      handler(order) {
        const tempItems = order.items.map(item => {
          return {
            name: item.name,
            price: item.price,
            quantity: item.quantity,
            sku: item.sku,
            trackNumbers: item.trackNumbers.join('<br />')
          };
        });
        this.items = tempItems;
      }
    }
  }
};
</script>

<style scoped lang="scss">
</style>
