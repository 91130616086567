<template>
  <v-autocomplete
    append-icon="expand_more"
    v-on="$listeners"
    v-bind="$attrs"
    :items="items"
    class="autocoplete-select"
    v-model="model"
  ></v-autocomplete>
</template>

<script>
export default {
  inheritAttrs: false,
  props: {
    items: {
      type: Array,
      required: true
    },
    selected: {
      type: [Array, String, Number],
      default: ""
    }
  },
  data() {
    return {
      model: this.selected
    };
  }
};
</script>

<style lang="scss">
.autocoplete-select {
  font-size: 14px;
  margin: 0 !important;
  padding: 0 !important;
  height: auto !important;
  min-height: 0 !important;
  max-height: none !important;

  .v-select__slot {
    height: 30px;
    padding: 0 12px;
    background: #fff;
    margin: 22px 0 0;

    input {
      font-size: 12px;
    }

    .v-label {
      height: auto;
      font-size: 0.75em;
      margin-bottom: 5px;
    }

    .v-select__selections {
      font-size: 12px;
    }
  }

  .v-label {
    left: 0 !important;
    right: 0 !important;
    top: auto !important;
    bottom: 100% !important;
    color: #a6a8a9 !important;
    transform: none !important;
  }

  .v-input__slot {
    margin: 0;
    &:after,
    &:before {
      display: none;
    }
  }

  .v-text-field__details {
    display: none;
  }

  .material-icons {
    font-size: 16px;
  }

  .v-input__icon--clear {
    .material-icons {
      font-size: 12px;
    }
  }

  .v-select__selections {
    height: 30px;
    overflow: hidden;
  }

  &.v-text-field {
    .v-input__append-inner {
      padding-left: 0;
    }
  }

  .v-chip {
    .v-chip__content {
      height: auto;
      text-transform: capitalize;
    }
    .v-chip--select-multi {
      margin: 2px 2px 2px 0;
    }
  }

  .v-select__selection--comma {
    white-space: nowrap;
    text-transform: capitalize;
  }
}
</style>
