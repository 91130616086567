<template>
  <Modal v-bind="$attrs" v-on="$listeners" class="modal-split" scrollable>
    <div slot="content">
      <v-layout>
        <v-flex xs11>
          <h3 class="modal-split__title">{{ lang.title }}</h3>
        </v-flex>
        <v-flex xs1 align-content-end class="text-xs-right">
          <v-icon class="close-icon item--hover" @click="$emit('esc:modal')">close</v-icon>
        </v-flex>
      </v-layout>
      <TableProductOptionsSplit
        v-if="product"
        :key="product.id"
        class="modal-split__table"
        @fetch-selected-option="onFetchSelectedOption"
      />
      <div slot="actions" class="modal-split__footer">
        <ButtonPrimary small @click:base-button="handleSplit" :loading="progress">{{ lang.split }}</ButtonPrimary>
        <ButtonPrimary small outline @click:base-button="$emit('esc:modal')">{{ lang.cancel }}</ButtonPrimary>
      </div>
    </div>
  </Modal>
</template>

<script>
import { mapGetters, mapActions, mapState } from "vuex";

import Modal from "@/components/Modal";
import ButtonPrimary from "@/components/ButtonPrimary";
import TableProductOptionsSplit from "@/components/Tables/TableProductOptionsSplit";

export default {
  inheritAttrs: false,
  components: {
    Modal,
    TableProductOptionsSplit,
    ButtonPrimary
  },
  data() {
    return {
      selectedOption: null
    };
  },
  computed: {
    ...mapState({
      successMsg: state => state.errors.notifyMessages.success,
      errorMsg: state => state.errors.notifyMessages.somethingWrong
    }),
    ...mapGetters({
      product: "getMyProduct",
      progress: "getIsProgress"
    }),
    lang() {
      return {
        title: "Split options",
        cancel: "Cancel",
        split: "Split"
      };
    }
  },
  methods: {
    ...mapActions(["splitOptions", "setNotifyMsg"]),
    onFetchSelectedOption(option) {
      this.selectedOption = option;
    },
    async handleSplit() {
      const { status } = await this.splitOptions({
        id: this.product.id,
        data: { option: this.selectedOption }
      });

      if (status) this.$router.push({ name: "dashboard.products.my" });

      this.setNotifyMsg({
        color: status ? "success" : "error",
        text: status ? this.successMsg : this.errorMsg
      });
    }
  },
  beforeDestroy() {
    this.$off();
  }
};
</script>

<style lang="scss">
.modal-split {
  &__title {
    font-size: 24px;
    margin-bottom: 20px;
  }

  &__table {
    margin-top: 50px;
  }

  &__footer {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
  }
}
</style>
