import axios from 'axios';
import BaseApi from './base';
import {
  ORDERS,
  EXPORT,
  STATISTICS
} from '@/constants/api';

export default class extends BaseApi {
  static get(filters = null) {
    return new Promise((resolve, reject) => {
      return axios.get(this.baseURI + ORDERS, {
          params: filters
        })
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  static getStatistics(filters = null) {
    return new Promise((resolve, reject) => {
      return axios.get(this.baseURI + ORDERS + '/' + STATISTICS, {
          params: filters
        })
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  static export(filters = null) {
    return new Promise((resolve, reject) => {
      return axios({
        url: this.baseURI + ORDERS + '/' + EXPORT,
        method: 'GET',
        responseType: 'blob',
        params: filters
      }).then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  static getById(id, filters = null) {
    return new Promise((resolve, reject) => {
      return axios.get(this.baseURI + ORDERS + '/' + id, {
          params: filters
        })
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  }

}
