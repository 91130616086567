<template>
  <div class="table-transactions__content" v-if="items">
    <h4 class="sm__table-title">
      {{ lang.tableTitle }}
      <div class="exports" v-if="exportItems && exportItems.length">
        <ExportTable :items="exportItems" :fields="exportCSV.fields" name="connected-stores.xls" />
      </div>
    </h4>
      <ModalChangeUser
           :show="openModal"
           v-if="isDepAdmin"
           @close='openModal = !openModal'
           @change:modal="handleChangeUserClick"
           :customData="customData"
           :salesChannel="salesChannel"
           @updateList="onUpdateList"
           @esc:modal="openModal = false"
      />
    <BaseTable
      class="hide-first-id"
      :headers="headers"
      :items="items"
      show-details
      show-remove
      show-remove-button
      actionText="Settings"
      :hide-columns="! isDepAdmin ? ['user'] : []"
      routerName="dashboard.store-management.settings"
      @get-remove="onRemove"
    >
      <th v-if="items && items.length" slot="th"></th>
      <template slot-scope="{item: {id}}" slot="conditional-button">
        <BaseButton v-if="isDepAdmin" @click:base-button="onClickModal(id)" small outline>{{ lang.changeUser }}</BaseButton>
        <BaseButton @click:base-button="getStoreUrlById(id)" small outline>{{ lang.reconnect }}</BaseButton>
      </template>
    </BaseTable>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations, mapState } from "vuex";
import {
  TYPE_SHOPIFY,
  TYPE_LANDING,
  TYPE_WOO_COMMERCE,
  PRICE_MODIFIER_ADD,
  PRICE_MODIFIER_MULTIPLY,
  STICKY_PRICE_ABSOLUTE,
  STICKY_PRICE_PERCENT,
  STICKY_PRICE_DISABLED
} from "@/constants/storeManagement";

import ModalChangeUser from "@/components/ModalChangeUser";
import ExportTable from "@/components/ExportTable";

export default {
  components: {
    ExportTable,
    ModalChangeUser
  },
  data() {
    return {
      items: [],
      salesChannel: {},
      openModal: false,
      customData: {
        title: 'Change user for Shop',
        closeButtonText: 'Close'
      },
      headers: [
        { text: "ID", value: "id" },
        { text: "Name", value: "name" },
        // { text: "Price modifier", value: "price" },
        // { text: "Price Value", value: "price_value" },
        // { text: "Compare price modifier", value: "compare_price" },
        // { text: "Sticky Price", value: "sticky_price" },
        // { text: "Modifier value", value: "mod_price_value" },
        { text: "Type", value: "type" },
        { text: "User", value: "user" }
      ],
      exportCSV: {
        fields: {
          ID: "id",
          Name: "name",
          "Price modifier": "price",
          "Price Value": "price_value",
          "Compare price modifier": "compare_price",
          "Sticky Price": "sticky_price",
          "Modifier value": "mod_price_value",
          Type: "type",
          User: "user"
        }
      },
      exportItems: []
    };
  },
  computed: {
    ...mapState({
      errorSalesChannelMsg: state =>
        state.errors.notifyMessages.salesChanelNotFound,
      errorSalesChannelAuthUrlMsg: state =>
        state.errors.notifyMessages.salesChannelStoreUrlNotFound
    }),
    ...mapGetters({
      isDepAdmin: "isDepAdmin",
      salesChannels: "getSalesChannels"
    }),
    lang() {
      return {
        tableTitle: "Connected stores",
        changeUser: "Change user",
        reconnect: "Reconnect",
        type: {
          landing: "Landing",
          shopify: "Shopify",
          wooCommerce: "WooCommerce"
        },
        priceModifier: {
          add: "Add",
          multiply: "Multiply"
        },
        stickyPrice: {
          absolute: "Absolute",
          percent: "Percent",
          disabled: "Disabled"
        }
      };
    }
  },
  methods: {
    ...mapActions([
      "fetchSalesChannels",
      "deleteSalesChannel",
      "setNotifyMsg",
    ]),
    ...mapMutations(["SET_SALE_CHANNEL_DATA"]),
    getPriceText(val) {
      if (val == PRICE_MODIFIER_ADD) {
        return this.lang.priceModifier.add;
      }

      if (val == PRICE_MODIFIER_MULTIPLY) {
        return this.lang.priceModifier.multiply;
      }
    },
    onClickModal(id) {
      this.salesChannel = this.getSalesChannelById(id);
      this.openModal = true;
    },
    onUpdateList(item) {
      let {name, type, user} = item;
      let itemIndex = this.items.findIndex(x => x.id == item.id);
      let exportItemIndex = this.exportItems.findIndex(x => x.id == item.id);

      if (user) {
        this.items[itemIndex].user = user;
        this.exportItems[exportItemIndex].user = user;
      }

      if (type) {
        this.items[itemIndex].type = type;
        this.exportItems[exportItemIndex].type = type;
      }

      if (name) {
        this.items[itemIndex].name = name;
        this.exportItems[exportItemIndex].name = name;
      }
    },
    getTypeText(val) {
      if (val == TYPE_SHOPIFY) {
        return this.lang.type.shopify;
      }

      if (val == TYPE_LANDING) {
        return this.lang.type.landing;
      }

      if (val == TYPE_WOO_COMMERCE) {
        return this.lang.type.wooCommerce;
      }
    },
    getStickyPriceText(val) {
      if (val == STICKY_PRICE_ABSOLUTE) {
        return this.lang.stickyPrice.absolute;
      }

      if (val == STICKY_PRICE_PERCENT) {
        return this.lang.stickyPrice.percent;
      }

      if (val == STICKY_PRICE_DISABLED) {
        return this.lang.stickyPrice.disabled;
      }
    },
    async onRemove(id) {
      const response = await this.deleteSalesChannel(id);
      if (response) {
        const isActiveSalesChannel = this.checkActiveSalesChannelById(id);
        if (isActiveSalesChannel) {
          const newActiveSalesChannel = this.salesChannels[0];
          if (typeof newActiveSalesChannel === "undefined") {
            this.destroyActiveSalesChannel();
          } else {
            this.setNewActiveSalesChannel(newActiveSalesChannel);
          }
        }
      }
    },
    checkActiveSalesChannelById(deletedSalesChannelId) {
      const activeSalesChannelId = this.$salesChannel.get();
      return deletedSalesChannelId == activeSalesChannelId;
    },
    setNewActiveSalesChannel(salesChannel) {
      this.$root.$emit("update-active-sales-channel", salesChannel);
      this.$salesChannel.set(salesChannel.id);
      this.SET_SALE_CHANNEL_DATA(salesChannel);
    },
    destroyActiveSalesChannel() {
      this.$salesChannel.destroy();
      this.$router.push({ name: "dashboard.dashboard" });
    },
    getSalesChannelById(id) {
      const salesChannel = this.salesChannels.filter(sc => sc.id == id)[0];

      if (typeof salesChannel === "undefined") {
        this.setNotifyMsg({
          color: "error",
          text: this.errorSalesChannelMsg
        });
      }

      return salesChannel;
    },
    getStoreUrlById(id) {
      const salesChannel = this.salesChannels.filter(sc => sc.id == id)[0];

      if (typeof salesChannel === "undefined") {
        this.setNotifyMsg({
          color: "error",
          text: this.errorSalesChannelMsg
        });
        return;
      }

      if (!salesChannel.authUrl) {
        this.setNotifyMsg({
          color: "error",
          text: this.errorSalesChannelAuthUrlMsg
        });
        return;
      }

      return (window.location = salesChannel.authUrl);
    },
    handleChangeUserClick(val) {
      this.openModal = val;
    },
  },
  watch: {
    $route: {
      immediate: true,
      handler() {
        this.fetchSalesChannels();
      }
    },
    salesChannels: {
      handler(salesChannels) {
        this.items = salesChannels.map(item => {
          return {
            id: item.id,
            name: item.name,
            type: this.getTypeText(item.typeId),
            user: item.user.email,
            conditionalButton: item.typeId === TYPE_SHOPIFY || item.typeId === TYPE_WOO_COMMERCE
          };
        });
        this.exportItems = salesChannels.map(item => {
          return {
            id: item.id,
            user: item.user.email,
            name: item.name,
            price: this.getPriceText(item.priceModifier),
            price_value: item.priceValue,
            compare_price: this.getPriceText(item.comparePriceModifier),
            sticky_price: this.getStickyPriceText(item.stickyPrice),
            mod_price_value: item.comparePriceValue,
            type: this.getTypeText(item.typeId),
            conditionalButton: item.typeId === TYPE_SHOPIFY || item.typeId === TYPE_WOO_COMMERCE
          };
        });
      }
    }
  }
};
</script>

<style scoped lang="scss">
.table-transactions {
  &__content {
    white-space: nowrap;

    /deep/ th:nth-child(3) {
      width:10% !important;
    }

    /deep/ th:nth-child(4) {
      width:10% !important;
    }

    /deep/ th:nth-child(5) {
      width:10% !important;
    }

    /deep/ th:nth-child(6) {
      width:10% !important;
    }
  }
}
.sm__table-title {
  display: flex;
  font-size: 18px;
  margin-top: 30px;
  align-items: flex-end;
  color: $text-sidebar-color;
  justify-content: space-between;
  .exports {
    margin-top: 0;
    margin-bottom: 2px;
  }
}
</style>
