<template>
  <div v-if="error && error.text">
    <v-snackbar
      v-model="snackbar"
      top
      right
      :color="error.color"
      :timeout="7000"
      :multi-line="text.includes('<br/>')"
      auto-height
    >
      <div v-html="text"></div>
    </v-snackbar>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      snackbar: false
    };
  },
  computed: {
    ...mapGetters({
      error: "getError"
    }),
    text() {
      if (this.error) {
        if (typeof this.error.text !== "object") {
          return this.error.text;
        }
        return this.renderErrors(this.error.text);
      }
    }
  },
  methods: {
    renderErrors(errors) {
      let result = [];
      Object.keys(errors).map(field => {
        let error = field[0].toUpperCase() + field.slice(1) + ": ";
        for (let i of errors[field]) {
          error += i + "; ";
        }
        result.push(error);
      });

      return result.join("<br/>");
    }
  },
  watch: {
    error: {
      immediate: true,
      handler({ text }) {
        this.snackbar = false;
        if (text) {
          this.snackbar = true;
        }
      }
    }
  }
};
</script>
