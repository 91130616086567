import api from '@/api/orders';
import fileSaver from "file-saver";

import {
  STATUS_OK
} from '@/constants/httpCodes';

export default {
  state: {
    orders: null,
    order: null
  },
  getters: {
    getOrders: state => state.orders && state.orders.items,
    getOrder: state => state.order,
    getOrdersPagination: state => state.orders && state.orders.links,
  },
  mutations: {
    SET_ORDERS_DATA(state, payload) {
      state.orders = payload;
    },
    SET_ORDER_DATA(state, payload) {
      state.order = payload;
    },
  },
  actions: {
    fetchOrders({
                  commit,
                  dispatch,
                }, payload) {
      dispatch('setIsProgressData', true);
      api.get(payload)
        .then(({
                 data,
                 status
               }) => {
          if (status === STATUS_OK) {
            commit('SET_ORDERS_DATA', data);
          }
        })
        .finally(() => {
          dispatch('setIsProgressData', false);
        })
    },
    fetchOrderById({
                     commit,
                     dispatch,
                   }, payload) {
      dispatch('setIsProgressData', true);
      api.getById(payload.id, payload.filters)
        .then(({
                 data,
                 status
               }) => {
          if (status === STATUS_OK) {
            commit('SET_ORDER_DATA', data);
          }
        })
        .finally(() => {
          dispatch('setIsProgressData', false);
        })
    },

    exportExcel({dispatch}, payload) {
      dispatch('setIsProgressData', true);
      api.export(payload)
        .then(({
                 data,
                 status
               }) => {
          if (status === STATUS_OK) {
            fileSaver.saveAs(data, "orders.xls");
          }
        })
        .finally(() => {
          dispatch('setIsProgressData', false);
        })
    },
  },
};
