<template>
  <div class="my-product">
    <ModalShopChecker />
    <Search throttle clearable />
    <template v-if="products && products.length">
      <h3>{{ lang.title }}</h3>
      <br />
      <v-layout row wrap align-center class="product-head-panel">
        <v-flex xs12 sm6 md6 class="head-checkbox-holder">
          <v-checkbox color="primary" v-model="selectAll" @change="changeSelectAll"></v-checkbox>
          <p class="checkbox__label" v-if="count">{{ checkboxText }}</p>
          <ButtonSecondary
            small
            outline
            color="#a6a8a9"
            v-if="selectAll"
            :loading="loadingRemove"
            @click:base-button="handleRemoveProducts"
          >
            {{ lang.buttons.remove }}
            <v-icon>remove</v-icon>
          </ButtonSecondary>
          <ButtonSecondary
            small
            outline
            color="#a6a8a9"
            v-if="selectAll"
            :loading="loadingImport"
            @click:base-button="handleImportSelectedProducts"
          >
            {{ lang.buttons.import }}
            <v-icon>call_made</v-icon>
          </ButtonSecondary>
          <ButtonSecondary
              small
              outline
              color="#a6a8a9"
              :loading="loadingImportAll"
              @click:base-button="handleImportAllProducts"
          >
            {{ lang.buttons.importAll }}
            <v-icon>call_made</v-icon>
          </ButtonSecondary>
        </v-flex>
        <v-flex xs12 sm6 md6 class="my-product__header-pagination">
          <template v-if="pagination">
            <Pagination :pagination="pagination" @click:pagination="handlePaginationClick" />
          </template>
        </v-flex>
      </v-layout>
      <div>
        <v-container class="no-width pa-0" grid-list-lg>
          <v-layout row wrap>
            <v-flex v-for="(product) in products" :key="product.id" xs12 sm6 md6>
              <CardMyProduct
                :product="product"
                @change:select-my-products="getSelectedProduct"
                @removed-product="removeSelectedProduct"
                @import-store-products="handleImportToStore"
                @open-store-products="handleOpenInStore"
                :select="select"
              />
            </v-flex>
          </v-layout>
        </v-container>
        <div class="my-product__footer">
          <PageSize @click:page-size-current="handlePageSizeClick" />
          <template v-if="pagination">
            <Pagination :pagination="pagination" @click:pagination="handlePaginationClick" />
          </template>
        </div>
      </div>
    </template>
    <template v-else>
      <p class="empty-products">{{ lang.noItems }}</p>
      <p
        class="empty-products--link item--hover"
        @click="$router.push({name: 'dashboard.products.search'})"
      >{{ lang.searchProducts }}</p>
    </template>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapState } from "vuex";
import * as ProductConst from "@/constants/products";
import * as ShopProductConst from "@/constants/shopProducts";
import openProductInStore from "@/mixins/openProductInStore";
import CardMyProduct from "@/components/CardMyProduct";
import ButtonSecondary from "@/components/ButtonSecondary";
import PageSize from "@/components/PageSize";
import Pagination from "@/components/Pagination";
import Search from "@/components/Search";
import ModalShopChecker from "@/components/ModalShopChecker";
import { setTimeout } from "timers";
import api from "@/api/products";
import { STATUS_ACCEPTED } from "@/constants/httpCodes";

export default {
  mixins: [openProductInStore],
  components: {
    CardMyProduct,
    ButtonSecondary,
    PageSize,
    Pagination,
    Search,
    ModalShopChecker
  },
  data() {
    return {
      pageSize: 20,
      selectAll: false,
      select: false,
      selectedProducts: {},
      checkboxText: "",
      salesChannel: null,
      loadingRemove: false,
      loadingImport: false,
      loadingImportAll: false,
      loadingSearch: false
    };
  },
  computed: {
    ...mapState({
      successImportProductMsg: state => state.errors.notifyMessages.importProductSuccess,
      successImportProductsMsg: state => state.errors.notifyMessages.importProductsSuccess,
      successMsg: state => state.errors.notifyMessages.success,
      startLoadingToStore: state => state.errors.productMessages.importToStoreInfoMsg,
      removeProductSuccessMsg: state => state.errors.notifyMessages.removeMyProductSuccess,
      removeProductsSuccessMsg: state => state.errors.notifyMessages.removeMyProductsSuccess,
      warningImportMsg: state => state.errors.notifyMessages.warningImportMsg,
      errorMsg: state => state.errors.notifyMessages.somethingWrong,
      isReconnectSalesChannel: state => state.salesChannels.isReconnectSalesChannel
    }),
    ...mapGetters({
      products: "getMyProducts",
      paginationsLinks: "getMyProductsPagination",
      count: "getMyProductsCount",
      loading: "getIsProgress",
      getActiveSalesChannels: "getActiveSalesChannels",
      salesChannels: "getSalesChannels",
    }),
    lang() {
      return {
        title: "My products",
        buttons: {
          remove: "Remove",
          import: "Import to store",
          importAll: "Import all to store"
        },
        noItems: "You have no product",
        searchProducts: "Search products"
      };
    },
    channelId() {
      return this.$salesChannel.get();
    },
    pagination() {
      if (!this.paginationsLinks) {
        return null;
      }
      return {
        first: +this.$url.getParams(this.paginationsLinks.first).page,
        last: +this.$url.getParams(this.paginationsLinks.last).page,
        self: +this.$url.getParams(this.paginationsLinks.self).page
      };
    }
  },
  methods: {
    ...mapActions([
      "fetchMyProducts",
      "removeMyProduct",
      "removeMyProducts",
      "importToStore",
      "setNotifyMsg",
      "fetchSearchedMyProducts",
      "importToStorePack",
      "fetchMyProductsByIds"
    ]),
    fetchMyProductsWithFilters(filters = {}) {
      if (!this.channelId) {
        return;
      }

      const newFilters = {
        salesChannel: this.channelId,
        groups: "ShopProduct.Product",
        page: 1,
        page_size: this.pageSize,
        ...this.$route.query,
        ...filters
      };

      this.fetchMyProducts(newFilters);
    },
    setImportedProducts(products) {
      products.map(item => {
          item.statusImport = ShopProductConst.STATUS_IMPORTED;
      });
    },
    async syncProducts(products) {
      let ids = products.map(product => product.id);
      let fetchedProducts = await this.fetchMyProductsByIds({
        salesChannel: this.channelId,
        groups: 'ShopProduct.Product',
        ids: ids.join(',')
      });

      if (fetchedProducts.length > 0) {
        fetchedProducts.forEach(fetchedProduct => {
          let index = this.products.findIndex(product => product.id === fetchedProduct.id);

          if (index >= 0) {
            this.$set(this.products, index, fetchedProduct);
            this.drawSelectedProducts();
          }
        });
      }
    },
    productNeedSync(product) {
      return product.statusImport === ShopProductConst.STATUS_IMPORT_PROCESSING ||
          product.product.status === ProductConst.STATUS_IMPORTING
        ;
    },
    filterProductsForSync(products) {
      return products.filter(product => this.productNeedSync(product));
    },
    getSelectedProduct(data) {
      if (data.value === true && data.status === ShopProductConst.STATUS_NOT_IMPORT) {
        this.selectedProducts[data.id] = data;
      } else {
        delete this.selectedProducts[data.id];
      }

      if (Object.keys(this.selectedProducts).length === 0) {
        this.selectAll = false;
      } else {
        this.selectAll = true;
      }

      this.drawSelectedProducts();
    },
    drawSelectedProducts() {
      this.checkboxText = `${
        Object.keys(this.selectedProducts).length
        } products selected`;
    },
    changeSelectAll(val) {
      this.select = val;
      if (!val) {
        this.checkboxText = `Select ${this.count} products`;
      } else {
        this.drawSelectedProducts();
      }
    },
    async handleRemoveProducts() {
      this.loadingRemove = true;

      const ids = Object.keys(this.selectedProducts);
      const { status, text } = await this.removeMyProducts(ids);

      var notifyData = {
        text: text || (status ? this.removeProductSuccessMsg : this.errorMsg),
        color: status ? "success" : "error"
      };

      this.setNotifyMsg(notifyData);
      this.resetSelectAll();
      this.loadingRemove = false;

      if (status) {
        this.pageSize = 20;
        this.$router.replace({ params: { page: 1 } });
        this.fetchMyProductsWithFilters();
      }
    },
    async removeSelectedProduct(id) {
      const { status, text } = await this.removeMyProduct(id);

      var notifyData = {
        text: text || (status ? this.removeProductSuccessMsg : this.errorMsg),
        color: status ? "success" : "error"
      };

      this.$root.$emit("send-my-products-response", notifyData);

      if (status) {
        this.fetchMyProductsWithFilters();
      }
      this.resetSelectAll();
      this.setNotifyMsg(notifyData);
    },
    handlePageSizeClick(page_size) {
      this.resetSelectAll();
      this.$router.push({
        query: { ...this.$route.query, page_size }
      });
    },
    handlePaginationClick(page) {
      this.resetSelectAll();
      this.$router.push({
        query: { ...this.$route.query, page }
      });
      window.scrollTo(0, 0);
    },
    resetSelectAll() {
      this.selectedProducts = {};
      this.selectAll = false;
      this.select = false;
      this.$root.$emit("set-my-products-checkbox-value", null);
      this.drawSelectedProducts();
    },
    async handleImportToStore(product) {
      if (!this.checkIsReconnectChannel()) {
        return;
      }

      const { status, text } = await this.importToStore(product.id);

      let notifyData = {
        text: text || (status ? this.successImportProductMsg : this.errorMsg),
        color: status ? "success" : "error"
      };

      this.$root.$emit("send-my-products-response", notifyData);

      this.resetSelectAll();

      if (status) {
        this.setImportedProducts([product]);
        this.syncProducts([product]);
      }

      this.setNotifyMsg(notifyData);
    },
    async handleImportSelectedProducts() {
      if (!this.checkIsReconnectChannel()) {
        return;
      }

      this.loadingImport = true;

      let selectedProducts = this.products.filter(product => this.selectedProducts[product.id]);
      let filteredProducts = selectedProducts.filter(product => product.statusImport === ShopProductConst.STATUS_NOT_IMPORT);
      let ids = filteredProducts.map(product => product.id);

      this.$root.$emit("disabled-import-button", {
        ids,
        value: true
      });

      if (ids.length) {
        const { status, text } = await this.importToStorePack(ids);

        if (status) {
          const notifyText = ids.length > 1 ? this.successImportProductsMsg : this.successImportProductMsg;
          this.setNotifyMsg({ text: notifyText, color: "success" });
          this.setImportedProducts(filteredProducts);
          this.syncProducts(filteredProducts);
        } else {
          this.setNotifyMsg({ text: text, color: "error" });
        }
      } else {
        this.setNotifyMsg({ text: this.warningImportMsg, color: "info" });
      }

      this.loadingImport = false;
      this.resetSelectAll();
      this.$root.$emit("disabled-import-button", false);
    },
    async handleImportAllProducts() {
      if (!this.checkIsReconnectChannel()) {
        return;
      }

      this.loadingImportAll = true;

      try {
        const { status } = await api.importAllToStore(this.salesChannel.id);

        if (status === STATUS_ACCEPTED) {
          setTimeout(() => {
            let filteredProducts = this.products.filter(product => product.statusImport === ShopProductConst.STATUS_NOT_IMPORT);
            this.setImportedProducts(filteredProducts);
            this.syncProducts(filteredProducts);
          }, 3000);

          this.setNotifyMsg({ text: this.startLoadingToStore, color: "success" });
        }
      } catch (error) {
        if (error.response.data.errors) {
          this.setNotifyMsg({ text: error.response.data.errors, color: "error" });
        } else if (error.response.data.detail) {
          this.setNotifyMsg({ text: error.response.data.title, color: "error" });
        }
      } finally {
        this.loadingImportAll = false;
        this.resetSelectAll();
      }
    },
    handleSearchMyProducts(name) {
      this.$router.push({
        query: { ...this.$route.query, page: 1, page_size: 20, name }
      });

      this.resetSelectAll();
      const ids = Object.keys(this.selectedProducts);
      this.$root.$emit("send-my-products-response", {
        ids,
        value: false
      });
    },
    checkIsReconnectChannel() {
      if (this.isReconnectSalesChannel) {
        this.$root.$emit("show-modal-checker", true);
        return false;
      }

      return true;
    }
  },
  watch: {
    "$route.query": {
      immediate: true,
      handler() {
        this.fetchMyProductsWithFilters();
        this.checkboxText = "";
      }
    },
    count: {
      immediate: true,
      deep: true,
      handler(val) {
        if (Object.keys(this.selectedProducts).length === 0) {
          this.checkboxText = `Select ${val} products`;
        } else {
          this.drawSelectedProducts();
        }
      }
    },
    salesChannels: {
      immediate: true,
      handler(val) {
        if (val) {
          this.salesChannel = this.getActiveSalesChannels(this.channelId);
        }
      }
    },
    products: {
      handler(products) {
        if (! products) {
          return;
        }

        let productsForSync = this.filterProductsForSync(products);

        if (productsForSync.length > 0) {
          setTimeout(() => {
            this.syncProducts(productsForSync);
          }, 3000);
        }
      }
    }
  },
  mounted() {
    this.$root.$on("input:search", search => {
      if (search !== this.$route.query.name) {
        this.handleSearchMyProducts(search);
      }
    });
  },
  beforeDestroy() {
    this.$off();
  }
};
</script>

<style lang="scss">
.my-product {
  max-width: $max-width;

  &__search {
    margin-bottom: 30px;
    margin-top: -18px;
  }
}

.checkbox__label {
  font-size: 14px;
  line-height: 22px;
  color: $title-color;
}
.my-product__footer {
  display: flex;
  padding: 40px 0 0;
  align-items: center;
  justify-content: space-between;
}
.my-product__header-pagination {
  display: flex;
  margin-bottom: 0;
  justify-content: flex-end;
}
.head-checkbox-holder {
  display: flex;
  flex-wrap: wrap;
  min-height: 30px;
  align-items: center;
  white-space: nowrap;
  @include breakpoint($t) {
    align-items: flex-start;
  }
  @include breakpoint($m) {
    margin: 0 0 20px;
  }
  &__btns {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    @include breakpoint($t) {
      margin: 10px -12px 0;
      width: calc(100% + 24px);
    }
  }
  .v-input--selection-controls {
    margin: 0;
    padding: 0;
  }
  .v-input--selection-controls:not(.v-input--hide-details) .v-input__slot {
    margin: 0;
  }
  .v-messages {
    display: none;
  }
  .v-input {
    flex: 0;
  }
  .v-btn {
    margin: 0 0 0 15px;
  }
}
.product-head-panel {
  margin: 0 0 15px;
}
</style>
