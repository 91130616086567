import api from '@/api/paymentSystems';

import {
  STATUS_OK,
  STATUS_VALIDATION_ERROR,
} from '@/constants/httpCodes';

export default {
  state: {
    paymentMethods: [{
        id: 1,
        name: 'PayPal'
      },
      // {
      //   id: 3,
      //   name: 'Bank card'
      // }
    ],
    paymentResponse: null
  },
  getters: {
    getPaymentMethods: state => state.paymentMethods,
    getPaymentResponse: state => state.paymentResponse,
  },
  mutations: {
    SET_PAYMENTS_RESPONSE_DATA(state, payload) {
      state.paymentResponse = payload;
    },
  },
  actions: {
    rechargeBalance({
      commit,
      dispatch,
      rootState
    }, {
      body,
      id
    }) {
      dispatch('setIsProgressData', true);
      api.rechargeBalance(body, id)
        .then(({
          data,
          status
        }) => {
          if (status === STATUS_OK) {
            commit('SET_PAYMENTS_RESPONSE_DATA', data);
          }
        })
        .catch(error => {
          let resultError = rootState.errors.notifyMessages.somethingWrong;

          if (error.response.status === STATUS_VALIDATION_ERROR) {
            resultError = error.response.data.errors;
          }

          dispatch('setNotifyMsg', {
            text: resultError,
            color: 'error'
          })
        })
        .finally(() => {
          dispatch('setIsProgressData', false);
        })
    },
    async payOrders({
      dispatch,
      rootState
    }, payload) {
      dispatch('setIsProgressData', true);
      const response = await api.payOrders(payload)
        .then(({
          status
        }) => {
          if (status === STATUS_OK) {
            return {
              text: null,
              status: true
            }
          }
        })
        .catch(error => {
          let errorResult = rootState.errors.notifyMessages.somethingWrong;

          if (error.response.status === STATUS_VALIDATION_ERROR && error.response.data.errors.orders) {
            errorResult = error.response.data.errors.orders;
          }

          return {
            text: errorResult,
            status: false
          }
        })
        .finally(() => {
          dispatch('setIsProgressData', false);
        });

      return response;
    },
  },
};
