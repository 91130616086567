<template>
  <div class="table-orders-statistics" v-if="items">
    <div name="filters" class="base-table--filters">
      <FilterProductsStatistics @click:search-btn="handleSearch"/>
      <div class="exports" v-if="items && items.length">
        <ExportTable :items="items" :fields="exportCSV.fields" name="products-statistic.xls"/>
      </div>
    </div>
    <BaseTable
      class="product-statistics-table table-orders-statistics__content hide-first-id"
      :headers="headers"
      :items="items"
      :pageSize="10000"
      :footer="itemsTotal"
    ></BaseTable>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

import FilterProductsStatistics from "@/components/Filters/FilterProductsStatistics";
import ExportTable from "@/components/ExportTable";

export default {
  components: {
    FilterProductsStatistics,
    ExportTable
  },
  data() {
    return {
      items: [],
      itemsTotal: [],
      headers: [
        { text: "ID", value: "id" },
        { text: "", value: "image" },
        { text: "Product", value: "product" },
        { text: "Pending, qnt", value: "pending_count" },
        { text: "Paid, qnt", value: "paid_count" },
        { text: "Pending income, $", value: "pending_income" },
        { text: "Incomes, $", value: "incomes" },
        { text: "Charges, $", value: "charges" }
      ],
      exportCSV: {
        fields: {
          ID: "id",
          Product: "product",
          "Pending, qnt": "pending_count",
          "Paid, qnt": "paid_count",
          "Pending income, $": "pending_income",
          "Incomes, $": "incomes",
          "Charges, $": "charges"
        }
      }
    };
  },
  computed: {
    ...mapGetters({
      statistics: "getProductsStatistics"
    })
  },
  methods: {
    ...mapActions(["fetchProductsStatistics"]),
    fetchProductsStatisticsWithFilters(filters = {}) {
      const salesChannel = this.$salesChannel.get();

      const newFilters = {
        salesChannel,
        ...filters
      };

      this.fetchProductsStatistics(newFilters);
    },
    handleSearch(filters) {
      this.setFiltersToLocalStorage(filters);
      this.fetchProductsStatisticsWithFilters(filters);
    },
    toFixed(val) {
      return Math.round(val * 100) / 100;
    },
    setFiltersToLocalStorage(filters) {
      if (filters.dateFrom && filters.dateTo) {
        this.$filters.setProductsStatisticDateFilter(
          filters.dateFrom,
          filters.dateTo
        );
      }
      this.$filters.setProductsStatisticCountryFilter(
        filters.countries.join(", ")
      );

      this.$filters.setProductsStatisticProductFilter(
        filters.productName || ""
      );
    }
  },
  watch: {
    statistics: {
      handler(statistics) {
        const tempItems = Object.keys(statistics.items).map(key => {
          let item = statistics.items[key];
          return {
            id: item.id,
            image: item.image,
            product: item.groupByValue,
            pending_count: item.pendingCount,
            paid_count: item.paidCount,
            pending_income: this.toFixed(item.pendingIncome),
            incomes: this.toFixed(item.income),
            charges: this.toFixed(item.charge)
          };
        });
        this.items = tempItems;

        const total = statistics.total;
        this.itemsTotal = [
          {
            id: "id",
            product: "Total",
            image: "",
            pending_count: total.pendingCount,
            paid_count: total.paidCount,
            pending_income: total.pendingIncome,
            incomes: this.toFixed(total.income),
            charges: this.toFixed(total.charge)
          }
        ];
      }
    }
  },
  mounted() {
    window.$(".table-orders-statistics table").stickyTableHeaders({
      cacheHeaderHeight: true,
      fixedOffset: window.$(".table-orders-statistics table thead")
    });
  },
  beforeDestroy() {
    window.$(".table-orders-statistics table").stickyTableHeaders("destroy");
  }
};
</script>

<style lang="scss">
.table-orders-statistics {
  .table-orders-statistics__content {
    .v-table {
      th {
        transition: none !important;
        &:first-child {
          padding: 0 !important;
          padding-right: 40px !important;
          padding-left: 5px !important;
        }
      }
      td {
        &:first-child {
          padding: 0 !important;
          padding-left: 5px !important;
        }
      }
    }
    th.column.sortable {
      position: relative;
      &:first-child,
      &:nth-child(2) {
        .v-icon {
          display: inline-block;
        }
      }
    }
  }
  .product-statistics-table {
    .v-table {
      tr:hover {
        td {
          &:nth-child(2) {
            > div {
              &:after {
                width: 0;
                opacity: 0;
              }
            }
          }
        }
      }
      td {
        &:nth-child(2) {
          > div {
            min-width: 400px;
            overflow: hidden;
            position: relative;
            white-space: nowrap;
            text-overflow: ellipsis;
            max-width: calc(100vw - 910px);
            @include breakpoint($ds) {
              max-width: calc(100vw - 680px);
            }
            &:after {
              top: 0;
              right: 0;
              bottom: 0;
              width: 30px;
              content: "";
              position: absolute;
              transition: all 0.35s ease;
              background: linear-gradient(
                to right,
                rgba(#fff, 0),
                rgba(#fff, 1)
              );
            }
          }
        }
      }
    }
  }
}
</style>
