<template>
  <div>
    <ModalAddPayoutAccounts
      :show="showModal"
      @change:modal="handleChangeModal"
      @esc:modal="showModal = false"
      max-width="700"
      lazy
    />
    <v-container class="no-width" grid-list-md v-if="currentUser">
      <div class="account-settings">
        <Tabs :tabs="tabs" :active="+activeTab">
          <v-tab-item :transition="false" :reverse-transition="false" :value="0">
            <v-layout wrap>
              <v-flex xs12 sm6 md3 class="base-table--filters__item">
                <InputText
                  type="email"
                  :value="formData.email"
                  @input:base-text="handleEmail"
                  backgroundColor="gray"
                  :hide-details="false"
                  :rules="[rules.validEmail]"
                  @update:error="onEmailInputError"
                  class="email-input"
                  :error-messages="emailErrors"
                >{{ lang.email }}</InputText>
              </v-flex>
            </v-layout>
<!--            <v-layout wrap>-->
<!--              <v-flex xs12 sm6 md3 class="base-table&#45;&#45;filters__item">-->
<!--                <InputSelect-->
<!--                  @change:base-select="handleLanguage"-->
<!--                  :items="languages"-->
<!--                  :value="languages[0].value"-->
<!--                  backgroundColor="gray"-->
<!--                >{{ lang.language }}</InputSelect>-->
<!--              </v-flex>-->
<!--            </v-layout>-->
<!--            <v-layout wrap>-->
<!--              <v-flex xs12 sm6 md3 class="base-table&#45;&#45;filters__item">-->
<!--                <div class="base-combobox">-->
<!--                  <v-autocomplete-->
<!--                    @change="handleTimeZone"-->
<!--                    :items="timeZones"-->
<!--                    :label="lang.timeZone"-->
<!--                    append-icon="expand_more"-->
<!--                    :value="currentUser.defaultTimezone"-->
<!--                    auto-select-first-->
<!--                  ></v-autocomplete>-->
<!--                </div>-->
<!--              </v-flex>-->
<!--            </v-layout>-->
            <v-layout wrap class="account-settings__password-holder">
              <v-flex xs12 sm4 md2 align-self-center class="tickets-settings__btn-holder">
                <InputText
                  type="password"
                  :value="formData.currentPassword"
                  @input:base-text="handleCurrentPassword"
                  :has-error="isErrorCurrentPassword"
                  :errorText="errors.currentPassword"
                  backgroundColor="gray"
                >{{ lang.curPassword }}</InputText>
              </v-flex>
              <v-flex xs12 sm4 md2 align-self-center class="tickets-settings__btn-holder">
                <InputPassword
                  :value="formData.plainPassword.first"
                  @input:base-text="handleNewPassword"
                  :has-error="isErrorPlainPasswordFirst"
                  :errorText="errors.plainPassword.first"
                  backgroundColor="gray"
                >{{ lang.newPassword }}</InputPassword>
              </v-flex>
              <v-flex xs12 sm4 md2 align-self-center class="tickets-settings__btn-holder">
                <InputPassword
                  :value="formData.plainPassword.second"
                  @input:base-text="handleRepeatNewPassword"
                  :has-error="isErrorPlainPasswordSecond"
                  :errorText="errors.plainPassword.second"
                  backgroundColor="gray"
                >{{ lang.repeatNewPassword }}</InputPassword>
              </v-flex>
            </v-layout>
            <v-layout wrap>
              <v-flex xs12 sm8 md9 class="base-table--filters__item">
                <ButtonPrimary
                  class="base-table--filters__button"
                  small
                  @click:base-button="handleSave"
                  :disabled="disabledButton"
                  :loading="loading"
                >{{ lang.save }}</ButtonPrimary>
              </v-flex>
            </v-layout>
          </v-tab-item>
          <v-tab-item :transition="false" :reverse-transition="false" :value="1">
            <h3 class="tab-item__title">{{ lang.accountsTitle }}</h3>
            <v-layout wrap>
              <v-flex md9></v-flex>
              <v-flex md3>
                <ButtonPrimary
                  small
                  block
                  @click:base-button="handleOpenModal"
                >{{ lang.createAccount }}</ButtonPrimary>
              </v-flex>
            </v-layout>
            <TablePayoutAccounts/>
          </v-tab-item>
        </Tabs>
      </div>
    </v-container>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapState } from "vuex";

import ButtonPrimary from "@/components/ButtonPrimary";
import InputSelect from "@/components/InputSelect";
import InputText from "@/components/InputText";
import InputPassword from "@/components/InputPassword";
import Tabs from "@/components/Tabs";
import ModalAddPayoutAccounts from "@/components/ModalAddPayoutAccounts";
import TablePayoutAccounts from "@/components/Tables/TablePayoutAccounts";

import { TIMEZONES } from "@/constants/timezones";

export default {
  components: {
    InputText,
    InputSelect,
    ButtonPrimary,
    InputPassword,
    Tabs,
    TablePayoutAccounts,
    ModalAddPayoutAccounts
  },
  data() {
    return {
      languages: [
        {
          text: "English",
          value: "en"
        }
      ],
      timeZones: this.getTimeZonesArray(TIMEZONES),
      formData: {
        locale: "en",
        defaultTimezone: null,
        currentPassword: null,
        plainPassword: {
          first: null,
          second: null
        },
        email: null
      },
      isErrorCurrentPassword: false,
      isErrorPlainPasswordFirst: false,
      isErrorPlainPasswordSecond: false,
      errors: {
        currentPassword: "",
        plainPassword: {
          first: "",
          second: ""
        },
        email: ""
      },
      tabs: ["General", "Payment Account"],
      showModal: false,
      activeTab: 0,
      disabledButton: false,
      emailErrors: null
    };
  },
  computed: {
    ...mapState({
      errorNewPasswordMsg: state =>
        state.errors.notifyMessages.notEqualPassword,
      successMsg: state => state.errors.notifyMessages.accountSuccess,
      rules: state => state.validations.rules
    }),
    ...mapGetters({
      currentUser: "getCurrentUser",
      loading: "getIsLoading"
    }),
    lang() {
      return {
        save: "Save",
        language: "Language",
        timeZone: "Default timezone",
        curPassword: "Current password",
        newPassword: "New password",
        repeatNewPassword: "Repeat new password",
        accountsTitle: "Payout Accounts",
        createAccount: "Create Account",
        email: "Email"
      };
    }
  },
  methods: {
    ...mapActions(["setUserSettings", "setNotifyMsg"]),
    handleLanguage(val) {
      this.formData.locale = val;
    },
    handleTimeZone(val) {
      this.formData.defaultTimezone = val;
    },
    handleCurrentPassword(val) {
      this.formData.currentPassword = val;
    },
    handleNewPassword(val) {
      this.formData.plainPassword.first = val;
    },
    handleRepeatNewPassword(val) {
      this.formData.plainPassword.second = val;
    },
    handleEmail(val) {
      this.formData.email = val;
    },
    onEmailInputError(val) {
      this.disabledButton = val;
    },
    async handleSave() {
      const { currentPassword } = this.formData;
      const { first, second } = this.formData.plainPassword;

      if (first || second || currentPassword) {
        if (this.validatePassword(currentPassword, first, second)) {
          const data = { id: this.currentUser.id, data: { ...this.formData } };
          var { status, text } = await this.setUserSettings(data);

          if (status) {
            this.setNotifyMsg({ text: this.successMsg, color: "success" });
          } else {
            this.setValidationErrors(text);
          }
        }
      } else {
        const data = {
          id: this.currentUser.id,
          data: {
            locale: this.formData.locale,
            defaultTimezone: this.formData.defaultTimezone,
            email: this.formData.email
          }
        };
        var { status, text } = await this.setUserSettings(data);

        if (status) {
          this.setNotifyMsg({ text: this.successMsg, color: "success" });
        } else {
          if (text.email) {
            this.setEmailErrors(text.email);
          }
          this.setNotifyMsg({ text: text[0], color: "error" });
        }
      }
    },
    getTimeZonesArray(obj) {
      var array = [];
      for (var key in obj) {
        var item = {
          text: obj[key],
          value: key
        };
        array.push(item);
      }
      return array;
    },
    validatePassword(current, first, second) {
      let isValid = true;
      this.isErrorCurrentPassword = false;
      this.isErrorPlainPasswordFirst = false;
      this.isErrorPlainPasswordSecond = false;

      if (current && current.length < 6) {
        this.$nextTick().then(() => {
          this.isErrorCurrentPassword = true;
          this.errors = {
            ...this.errors,
            currentPassword: "The password field length must be greater than 6"
          };
        });

        isValid = false;
      }

      if (first && first.length < 6) {
        this.$nextTick().then(() => {
          this.isErrorPlainPasswordFirst = true;
          this.errors = {
            ...this.errors,
            plainPassword: {
              ...this.errors.plainPassword,
              first: "The password field length must be greater than 6"
            }
          };
        });

        isValid = false;
      }

      if (second && second.length < 6) {
        this.$nextTick().then(() => {
          this.isErrorPlainPasswordSecond = true;
          this.errors = {
            ...this.errors,
            plainPassword: {
              ...this.errors.plainPassword,
              second: "The password field length must be greater than 6"
            }
          };
        });

        isValid = false;
      }

      if (
        first &&
        first.length > 5 &&
        second &&
        second.length > 5 &&
        first !== second
      ) {
        this.$nextTick().then(() => {
          this.isErrorPlainPasswordSecond = true;
          this.errors = {
            ...this.errors,
            plainPassword: {
              ...this.errors.plainPassword,
              second: "Password fields are not equal"
            }
          };
        });
        isValid = false;
      }

      return isValid;
    },
    setValidationErrors(errors) {
      if (errors.currentPassword) {
        this.$nextTick().then(() => {
          this.isErrorCurrentPassword = true;
          this.errors = {
            ...this.errors,
            currentPassword: errors.currentPassword[0]
          };
        });
      }

      if (errors.plainPassword && errors.plainPassword.first) {
        this.$nextTick().then(() => {
          this.isErrorPlainPasswordFirst = true;
          this.errors = {
            ...this.errors,
            plainPassword: {
              ...this.errors.plainPassword,
              first: errors.plainPassword.first[0]
            }
          };
        });
      }

      if (errors.plainPassword && errors.plainPassword.second) {
        this.$nextTick().then(() => {
          this.isErrorPlainPasswordSecond = true;
          this.errors = {
            ...this.errors,
            plainPassword: {
              ...this.errors.plainPassword,
              second: errors.plainPassword.second[0]
            }
          };
        });
      }
    },
    handleOpenModal() {
      this.showModal = true;
    },
    handleChangeModal(val) {
      this.showModal = val;
    },
    setEmailErrors(errors) {
      this.emailErrors = errors;
      setTimeout(() => {
        this.emailErrors = null;
      }, 2000);
    }
  },
  watch: {
    currentUser: {
      immediate: true,
      handler(user) {
        this.formData.defaultTimezone = user && user.defaultTimezone;
        this.formData.email = user && user.email;
      }
    }
  },
  beforeDestroy() {
    this.$off("click:base-button");
    this.$off("change:datetimepicker");
    window.localStorage.removeItem("active_tab");
  },
  created() {
    this.activeTab = window.localStorage.getItem("active_tab");
  }
};
</script>

<style lang="scss">
.account-settings {
  .base-input,
  .base-select,
  .base-combobox {
    width: 100%;
    margin: 0 0 30px;
    @include breakpoint($m) {
      margin-bottom: 20px;
    }
    .v-select__selection--comma {
      display: flex;
      max-width: none;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
  .base-input {
    .v-input__slot {
      background: #fff;
      border-radius: 4px;
    }
  }
  .v-select.v-text-field input {
    display: none;
  }
  &__password-holder {
    padding: 10px 0 0;
    @include breakpoint($m) {
      padding-top: 0;
    }
    .base-input {
      @include breakpoint($m) {
        margin-bottom: 10px;
      }
    }
  }
  .base-table--filters__button {
    margin-left: -8px;
    padding-right: -8px;
  }
  .v-autocomplete {
    margin: 0;
    padding: 0;
    height: auto !important;
    min-height: 0 !important;
    max-height: none !important;

    .v-input__slot,
    .v-select__slot {
      display: block;
      &:after,
      &:before {
        display: none;
      }
    }
    .v-label {
      display: block;
      transform: none;
      overflow: hidden;
      font-size: 0.75em;
      white-space: nowrap;
      text-overflow: ellipsis;
      color: #a6a8a9 !important;
      position: static !important;
    }
    input[type="text"] {
      height: 30px;
      display: block;
      font-size: 12px;
      padding: 0 12px;
      line-height: 1.2;
      background: $body-color;
      border-radius: 4px !important;
    }
    .v-input__append-inner {
      top: 18px;
      bottom: 0;
      width: 24px;
      right: 12px;
      display: flex;
      position: absolute;
      align-items: center;
      justify-content: center;
      .material-icons {
        font-size: 16px;
      }
    }
  }

  .base-combobox input {
    cursor: pointer;
  }

  .tab-item__title {
    margin-bottom: 30px;
    margin-top: 10px;
  }

  .email-input {
    .v-text-field__details {
      display: block;
      position: absolute;
      top: 35px;
    }
  }
}
</style>
