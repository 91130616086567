export default {
  toMoney: (amount, currencySymbol = '$', currencySymbolPosition = 'start', precision = 2) => {
    let result = Number(amount).toFixed(precision);

    if (currencySymbolPosition === 'start') {
      result = currencySymbol + result;
    } else {
      result += currencySymbol;
    }

    return result;
  }
}
