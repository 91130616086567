import {
  LANDING_SIDEBAR,
} from '@/constants/layouts';

export default {
  mode: 'history',
  routes: [{
      path: '/',
      meta: {
        title: 'Ecombo - Welcome',
        layout: LANDING_SIDEBAR,
        isAuth: true
      },
      children: [{
          path: '',
          name: 'home',
          component: require('@/views/Home/Login.vue').default,
          meta: {
            title: 'Ecombo - Welcome',
            layout: LANDING_SIDEBAR,
            isAuth: true
          },
        },
        // {
        //   path: 'register',
        //   name: 'register',
        //   component: require('@/views/Home/Register.vue').default,
        //   meta: {
        //     title: 'Ecombo - Registration',
        //     layout: LANDING_SIDEBAR,
        //     isAuth: true
        //   },
        // },
        {
          path: 'recovery',
          name: 'recovery-pass',
          component: require('@/views/Home/Recovery.vue').default,
          meta: {
            title: 'Ecombo - Password changing',
            layout: LANDING_SIDEBAR,
            isAuth: true
          },
        },
      ],
      component: require('@/views/Home/Index.vue').default,
    },
    {
      path: '/reset/:id',
      name: 'recovery',
      meta: {
        title: 'Ecombo - Recovery Password',
        layout: LANDING_SIDEBAR,
        isAuth: true
      },
      component: require('@/views/Recovery.vue').default,
    },
    {
      path: '/',
      component: require('@/views/Dashboard/Index.vue').default,
      meta: {
        title: 'Ecombo - Dashboard',
        requiresAuth: true
      },
      children: [
        {
          path: 'dashboard',
          name: 'dashboard.dashboard',
          component: require('@/views/Dashboard/Dashboard/Index.vue').default,
        }, {
          path: 'tickets',
          component: require('@/views/Dashboard/Tickets/Index.vue').default,
          children: [{
            path: '',
            name: 'dashboard.tickets',
            meta: {
              title: 'Ecombo - Tickets'
            },
            component: require('@/views/Dashboard/Tickets/TicketsList.vue').default,
          }, {
            path: ':id',
            name: 'dashboard.tickets.details',
            meta: {
              title: 'Ecombo - Tickets Details'
            },
            component: require('@/views/Dashboard/Tickets/Ticket.vue').default,
          }],
          meta: {
            requiresChannelId: true
          }
        }, {
          path: 'account-settings',
          name: 'dashboard.account-settings',
          meta: {
            title: 'Ecombo - Account Settings'
          },
          component: require('@/views/Dashboard/AccountSettings.vue').default,
        }, {
          path: 'sub-accounts',
          component: require('@/views/Dashboard/SubAccount/SubAccounts.vue').default,
          name: 'dashboard.sub-accounts',
          meta: {
            subAccountsAccess: true,
            title: 'Ecombo - Sub Accounts',
          },
        }, {
          path: 'referrals',
          component: require('@/views/Dashboard/Referrals/Referrals.vue').default,
          name: 'dashboard.referrals',
          meta: {
            title: 'Ecombo - Referrals'
          }
        }, {
          path: 'sales-channel',
          component: require('@/views/Dashboard/StoreManagement/Index.vue').default,
          meta: {
            autoAuth: true
          },
          children: [{
            path: '',
            name: 'dashboard.store-management',
            meta: {
              title: 'Ecombo - Store Management'
            },
            component: require('@/views/Dashboard/StoreManagement/StoreManagementList.vue')
              .default,
          }, {
            path: ':id',
            name: 'dashboard.store-management.settings',
            meta: {
              title: 'Ecombo - Store Management Settings'
            },
            component: require(
              '@/views/Dashboard/StoreManagement/StoreManagementSettings.vue').default,
          }]
        }, {
          path: 'products',
          component: require('@/views/Dashboard/Products/Index.vue').default,
          children: [{
            path: '',
            name: 'dashboard.products.search',
            meta: {
              title: 'Ecombo - Search Products'
            },
            component: require('@/views/Dashboard/Products/SearchProducts.vue').default,
          }, {
            path: 'category/:id',
            name: 'dashboard.products.list',
            meta: {
              title: 'Ecombo - Products'
            },
            component: require('@/views/Dashboard/Products/ProductsList.vue').default,
          }, {
            path: 'search-results',
            name: 'dashboard.products.search-results',
            component: require('@/views/Dashboard/Products/ProductSearchResults.vue')
              .default,
            meta: {
              title: 'Ecombo - Search Results'
            },
          }, {
            path: ':productId',
            name: 'dashboard.products.product-details',
            meta: {
              title: 'Ecombo - Product Details'
            },
            component: require('@/views/Dashboard/Products/ProductDetails.vue').default,
          }]
        }, {
          path: 'my-products',
          name: 'dashboard.products.my',
          component: require('@/views/Dashboard/Products/MyProducts.vue').default,
          meta: {
            requiresChannelId: true,
            title: 'Ecombo - My Products'
          }
        },
        {
          path: 'my-products/:id/edit',
          name: 'dashboard.products.my.edit',
          component: require('@/views/Dashboard/Products/MyProductsEdit.vue').default,
          meta: {
            requiresChannelId: true,
            title: 'Ecombo - My Products Edit'
          }
        }, {
          path: 'statistic-orders',
          name: 'dashboard.statistic.orders',
          component: require('@/views/Dashboard/Statistic/Orders.vue').default,
          meta: {
            requiresChannelId: true,
            title: 'Ecombo - Orders Statistic'
          }
        }, {
          path: 'statistic-products',
          name: 'dashboard.statistic.products',
          component: require('@/views/Dashboard/Statistic/Products.vue').default,
          meta: {
            requiresChannelId: true,
            title: 'Ecombo - Products Statistic'
          }
        }, {
          path: 'orders',
          component: require('@/views/Dashboard/Orders/Index.vue').default,
          meta: {
            requiresChannelId: true,
          },
          children: [{
            path: '',
            name: 'dashboard.orders',
            meta: {
              title: 'Ecombo - Orders',
            },
            component: require('@/views/Dashboard/Orders/OrdersList.vue').default,
          }, {
            path: ':id',
            name: 'dashboard.orders.details',
            meta: {
              title: 'Ecombo - Orders Details',
            },
            component: require('@/views/Dashboard/Orders/OrderDetails.vue').default,
          }]
        }, {
          path: 'balance',
          name: 'dashboard.balance',
          component: require('@/views/Dashboard/Balance.vue').default,
          meta: {
            title: 'Ecombo - Balance',
            balanceAccess: true,
          }
        },
        {
          path: 'transactions/:id',
          name: 'dashboard.transaction',
          component: require('@/views/Dashboard/Transaction.vue').default,
          meta: {
            title: 'Ecombo - Transaction',
          }
        }, {
          path: 'notifications',
          name: 'dashboard.notifications',
          component: require('@/views/Dashboard/Notifications.vue').default,
          meta: {
            notificationsAccess: true,
            title: 'Ecombo - Notifications',
          }
        }, {
          path: 'inventory',
          component: require('@/views/Dashboard/Inventory/Index.vue').default,
          children: [{
            path: '',
            name: 'dashboard.inventory',
            component: require('@/views/Dashboard/Inventory/Store.vue').default,
            meta: {
              title: 'Ecombo - Inventory',
              virtualInventoryAccess: true,
            },
          }, {
            path: 'wizzard',
            name: 'dashboard.inventory.wizzard',
            component: require('@/views/Dashboard/Inventory/Wizzard.vue').default,
            meta: {
              title: 'Ecombo - Inventory Wizzard',
            },
          }]
        }, {
          path: 'payment-solutions',
          component: require('@/views/Dashboard/PaymentSolutions/Index.vue').default,
          children: [{
            path: '',
            name: 'dashboard.payment-solutions',
            component: require('@/views/Dashboard/PaymentSolutions/Landing.vue').default,
            meta: {
              title: 'Ecombo - Payment Solutions',
              paymentSolutionsAccess: true,
            },
          }, {
            path: 'wizzard',
            name: 'dashboard.payment-solutions.wizzard',
            component: require('@/views/Dashboard/PaymentSolutions/Wizzard.vue').default,
            meta: {
              title: 'Ecombo - Payment Solutions Wizzard',
            },
          }, {
            path: ':id',
            name: 'dashboard.payment-solutions.details',
            component: require('@/views/Dashboard/PaymentSolutions/PaymentSolutionDetails.vue').default,
            meta: {
              title: 'Ecombo - Payment Solutions Finish',
            },
          }]
        },
        {
          path: 'payouts',
          name: 'dashboard.payouts',
          component: require('@/views/Dashboard/Payouts.vue').default,
          meta: {
            title: 'Ecombo - Payouts',
            payoutAccess: true,
          }
        },
      ],
    },
    {
      path: '/telegram-bot/subscribe/:token',
      component: require('@/App.vue').default,
      name: 'set-telegram-bot',
    },
  ],
};
