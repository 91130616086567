<template>
  <div class="radio-box" :class="{'radio-box-selected': isSelected}">
    <v-radio
      :color="color"
      v-bind="$attrs"
      v-on="$listeners"
      :value="value"
      :ref="'radio-' + value"
      @change="onChange"
    />
    <slot name="icon"></slot>
  </div>
</template>

<script>
export default {
  inheritAttrs: false,
  props: {
    color: {
      type: String,
      default: "warning"
    },
    value: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      isSelected: false
    };
  },
  methods: {
    onChange() {
      this.setIsSelected();
    },
    setIsSelected() {
      this.isSelected = this.$refs["radio-" + this.value].isActive;
    }
  },
  mounted() {
    this.setIsSelected();

    this.$root.$on("change:radio-group", val => {
      this.isSelected = this.value === val;
    });

    this.$root.$on("set-active-radio", val => {
      this.isSelected = this.value === val;
    });
  }
};
</script>

<style lang="scss">
.radio-box {
  padding: 10px;
  background-color: inherit;
  border: 1px solid $text-color;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 40px;

  .v-radio {
    width: 100%;
    margin-bottom: 0 !important;
  }

  label {
    width: inherit;
    color: $title-color !important;
    font-size: 14px;
    line-height: 32px;
  }

  .v-icon {
    color: $text-color !important;
  }
}

.radio-box-selected {
  border: 1px solid $warning-color;

  .v-icon {
    color: $warning-color !important;
  }
}
</style>
