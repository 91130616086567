<template>
  <div class="header">
    <v-toolbar dark app flat :height="toolbarHeight" v-resize="onResize" v-scroll="onScroll">
      <v-toolbar-side-icon class="hidden-lg-and-up" v-on:click="handlerOpenMobileMenu"></v-toolbar-side-icon>
      <v-toolbar-title class="hidden-md-and-down" v-if="canShowBalance">
        <div class="header__balance__title">Available:</div>
        <div class="header__balance__text">${{ available }}</div>
      </v-toolbar-title>
      <v-toolbar-title class="hidden-md-and-down" v-if="canShowBalance">
        <div class="header__balance__title">Hold:</div>
        <div class="header__balance__text">${{ hold }}</div>
      </v-toolbar-title>
      <v-toolbar-title v-if="!isSubAccount"
        class="header__balance hidden-md-and-down balance"
        :class="{ active: isBalancePage, 'single-balance': canShowSingleBalance }"
        @click="$router.push({name: 'dashboard.balance'})"
      >
        <div class="header__balance__title">Balance:</div>
        <div class="header__balance__text">${{ balance }}</div>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-menu
        class="header__user"
        v-if="activeSalesChannel"
        offset-y
        fixed
        full-width
        v-model="userMenuOpen"
      >
        <v-toolbar-title slot="activator">
          <span class="header__user__email">{{ activeSalesChannel.name }}</span>
          <v-icon dark color="#7a7c7e">keyboard_arrow_down</v-icon>
        </v-toolbar-title>

        <v-list class="header-sales-channel-list" dark>
          <v-list-tile
            v-for="channel in salesChannels"
            :key="channel.id"
            @click="handleChangeChannelId(channel.id)"
          >
            <v-list-tile-title>{{ channel.name }}</v-list-tile-title>
          </v-list-tile>
          <v-list-tile class="btn-v-list-tile">
            <BaseButton
              small
              dark
              outline
              class="item--hover"
              @click:base-button="$router.push({name: 'dashboard.store-management'})"
            >Store management</BaseButton>
          </v-list-tile>
        </v-list>
      </v-menu>
      <IconLogout
        class="header__logout hidden-sm-and-down"
        @click="handleLogOut"
        :stroke="logOutColor"
        @mouseover="logOutColor = '#fff'"
        @mouseleave="logOutColor = '#7A7C7E'"
      />
    </v-toolbar>
  </div>
</template>

<script>
import { logOutUser, destroyPrivileges } from "@/helpers/auth";
import { mapGetters, mapActions, mapMutations } from "vuex";
import IconLogout from "@/components/Icons/IconLogout";
import BaseButton from "@/components/BaseButton";

export default {
  components: {
    IconLogout,
    BaseButton
  },
  props: {
    mobileMenuOpen: {
      type: Boolean
    }
  },
  data() {
    return {
      logOutColor: "#7A7C7E",
      activeSalesChannel: null,
      toolbarHeight: 68,
      userMenuOpen: false,
      isBalancePage: this.checkBalancePage()
    };
  },
  computed: {
    ...mapGetters({
      isSubAccount: "isSubAccount",
      isDepAdmin: "isDepAdmin",
      salesChannels: "getSalesChannels",
      currentUser: "getCurrentUser",
      canShowBalance: "canShowBalance",
      canShowSingleBalance: "canShowSingleBalance"
    }),
    hold() {
      if (!this.currentUser) {
        return;
      }

      return Number(this.currentUser.balanceHold).toFixed(2);
    },
    available() {
      if (!this.currentUser) {
        return;
      }

      return Number(this.currentUser.balance).toFixed(2);
    },
    balance() {
      if (!this.currentUser) {
        return;
      }

      return Number(parseFloat(this.hold) + parseFloat(this.available)).toFixed(
        2
      );
    }
  },
  methods: {
    ...mapActions(["fetchCurrentUser"]),
    ...mapMutations(["SET_SALE_CHANNEL_DATA"]),
    handleLogOut() {
      const xSwitchUser = window.localStorage.getItem("x-switch-user");
      if (xSwitchUser) {
        window.localStorage.removeItem("x-switch-user");
        destroyPrivileges(this);
        window.location.reload();
        this.$salesChannel.destroy();
      } else {
        logOutUser(this);
      }
    },
    setActiveSalesChannel() {
      const channelSalesId = this.$salesChannel.get();
      if (channelSalesId) {
        this.activeSalesChannel = this.$store.getters.getActiveSalesChannels(
          channelSalesId
        );
        this.SET_SALE_CHANNEL_DATA(this.activeSalesChannel);
      } else {
        this.activeSalesChannel = this.$store.getters.getFirstSalesChannel;
        if (typeof this.activeSalesChannel !== "undefined") {
          this.$salesChannel.set(this.activeSalesChannel.id);
        }
      }
    },
    onResize() {
      if (window.innerWidth < 960) {
        this.toolbarHeight = 50;
      } else {
        this.toolbarHeight = 68;
      }
    },
    handlerOpenMobileMenu() {
      this.mobileMenu = !this.mobileMenu;
      this.$emit("togglemenu", this.mobileMenu);
    },
    handleChangeChannelId(id) {
      this.$salesChannel.set(id);
      this.setActiveSalesChannel();
      this.$router.push({ name: "dashboard.dashboard" });
      this.$root.$emit("updated-sales-channel");
    },
    onScroll() {
      this.userMenuOpen = false;
    },
    checkBalancePage() {
      if (this.$router.history.current.name === "dashboard.balance") {
        this.isBalancePage = true;
        return true;
      } else {
        this.isBalancePage = false;
        return false;
      }
    }
  },
  mounted() {
    this.mobileMenu = this.mobileMenuOpen;
    this.fetchCurrentUser();

    this.$root.$on("update-active-sales-channel", data => {
      this.activeSalesChannel = data;
    });
  },
  watch: {
    "$route.query": {
      handler() {
        this.checkBalancePage();
      }
    },
    currentUser: {
      handler() {
        this.$auth.setVirtualInventoryAccess(! this.isDepAdmin);
        this.$auth.setNotificationsAccess(! this.isDepAdmin);
        this.$auth.setPaymentSolutionsAccess(! this.isDepAdmin);
        this.$auth.setSubAccountsAccess(this.isDepAdmin);
        this.$auth.setBalanceAccess(! this.isSubAccount);
        this.$auth.setPayoutAccess(this.canShowBalance);
      }
    },
    salesChannels: {
      handler() {
        this.setActiveSalesChannel();
      }
    }
  }
};
</script>

<style lang="scss">
.header {
  .v-toolbar__content {
    @include rem($ds) {
      padding-left: 0;
    }
    @include breakpoint($t) {
      padding-right: 0;
    }
  }
  .theme--dark.v-btn {
    color: #7a7c7e;
  }

  .v-toolbar__title {
    margin-left: 30px !important;
  }
  .header__balance {
    margin-left: 0;
    cursor: pointer;
    padding: 12px 30px;
    transition: background 0.35s ease;
    &:hover,
    &.active {
      background: #15171a;
    }
    &__title {
      margin: 0 0 2px;
      font-size: 14px;
      line-height: 20px;
      color: $text-sidebar-color;
    }
    &__text {
      line-height: 1;
      font-size: em(18);
      font-family: "DIN Pro Bold";
    }
  }

  .balance {
    margin-left: 10px !important;
  }

  .single-balance {
    margin-left: 0px !important;
  }

  .header__user__email {
    font-size: 18px;
    color: $text-sidebar-color;
    @include breakpoint($m) {
      display: block;
      font-size: 14px;
      overflow: hidden;
      line-height: 20px;
      white-space: nowrap;
      text-overflow: ellipsis;
      max-width: calc(100vw - 100px);
    }
  }
  .header__logout {
    cursor: pointer;
    margin-left: 20px;
  }

  .v-menu__activator {
    .v-toolbar__title {
      margin-left: 0;
      padding: 19px 40px;
      transition: background-color 0.35s ease;
      @include breakpoint($t) {
        padding: 10px 15px 10px 10px;
      }
      @include breakpoint($m) {
        padding-top: 15px;
        padding-right: 25px;
        padding-bottom: 15px;
      }
      &:hover {
        background: #15171a;
      }
      .v-icon {
        margin-top: 5px;
        background: none;
        vertical-align: top;
        @include breakpoint($m) {
          top: 50%;
          right: 5px;
          margin-top: -10px;
          position: absolute;
        }
      }
    }
    &--active {
      .v-toolbar__title {
        background: #15171a;
      }
    }
  }
}
.header-sales-channel-list {
  max-height: 300px;
  overflow-y: auto;
  padding: 0 0 32px;
  overflow-x: hidden;

  .v-list__tile {
    height: auto;
    color: #7a7c7e;
    min-height: 60px;
    padding: 15px 40px;
    @include breakpoint($t) {
      min-height: 40px;
      padding: 5px 20px;
    }
    @include breakpoint($m) {
      font-size: 12px;
      padding-left: 10px;
      padding-right: 10px;
    }
    &:hover {
      color: #fff;
    }
  }
  .v-list__tile__title {
    height: auto;
    white-space: normal;
  }
  .btn-v-list-tile {
    .v-list__tile {
      min-height: 0;
    }
  }
  .v-btn--small {
    padding-left: 20px;
    padding-right: 20px;
  }
  .theme--dark.v-btn {
    margin: 30px 0 0;
  }
}
</style>
