<template>
  <div class="orders">
    <h3 class="orders__title">{{ lang.title }}</h3>
    <TableOrders/>
  </div>
</template>

<script>
import TableOrders from "@/components/Tables/TableOrders";

export default {
  components: {
    TableOrders
  },
  computed: {
    lang() {
      return {
        title: "Orders list"
      };
    }
  }
};
</script>

<style lang="scss">
.orders .base-table {
  white-space: nowrap;
}
</style>
