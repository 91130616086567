<template>
  <v-container class="no-width" grid-list-md>
    <v-layout wrap>
      <v-flex xs12 sm12 md4 class="filter-item">
        <InputDateTime
          @change:datetimepicker="handleDatepickerChange"
          :startedDate="startedDate"
        >{{ lang.date }}</InputDateTime>
      </v-flex>
      <v-flex xs12 sm8 md3 class="base-table--filters__item">
        <InputSelect
          v-if="statuses"
          @change:base-select="handleStatusValue"
          :items="statuses"
          :value="currentStatus"
        >{{ lang.statusLabel }}</InputSelect>
      </v-flex>
      <v-flex xs12 sm4 md2 align-self-center>
        <ButtonPrimary
          class="base-table--filters__button"
          small
          @click:base-button="handleSearch"
        >{{ lang.search }}</ButtonPrimary>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import ButtonPrimary from "@/components/ButtonPrimary";
import InputSelect from "@/components/InputSelect";
import InputDateTime from "@/components/InputDateTime";
import {
  STATUS_OPEN,
  STATUS_RESOLVED,
  STATUS_CLOSED,
  STATUS_DELETED,
  STATUS_HOLD,
  STATUS_WAITING,
  STATUS_SPAM,
  STATUS_NEW,
  STATUS_SENDING,
  SOURCE_CHAT,
  SOURCE_MAIL
} from "@/constants/tickets";

export default {
  components: {
    InputDateTime,
    InputSelect,
    ButtonPrimary
  },
  data() {
    return {
      statuses: [],
      filters: {
        from: null,
        to: null,
        status:
          typeof this.$route.query.status !== "undefined"
            ? this.$route.query.status
            : null
      },
      startedDate: {
        start: null,
        end: null
      },
      currentStatus: null
    };
  },
  computed: {
    lang() {
      return {
        search: "Search",
        date: "Creation Date",
        statusLabel: "Status",
        status: {
          open: "Open",
          resolved: "Resolved",
          closed: "Closed",
          deleted: "Deleted",
          hold: "Hold",
          waiting: "Waiting",
          spam: "Spam",
          new: "New",
          sending: "Sending"
        }
      };
    }
  },
  methods: {
    handleSearch() {
      const queryFilters = {};
      if (this.filters.from && this.filters.to) {
        queryFilters.from = this.filters.from;
        queryFilters.to = this.filters.to;
      }

      if (this.filters.status) {
        queryFilters.status = this.filters.status;
      }

      if (Object.keys(queryFilters).length) {
        this.$router.push({
          query: { ...queryFilters }
        });
      }

      this.$emit("click:search-btn", this.filters);
    },
    handleDatepickerChange(date) {
      if (date) {
        this.filters.from = date.start;
        this.filters.to = date.end;
      }
      this.handleSearch();
    },
    handleStatusValue(val) {
      this.filters.status = val;
    },
    getStatusesText() {
      const statuses = [
        STATUS_OPEN,
        STATUS_RESOLVED,
        STATUS_CLOSED,
        STATUS_DELETED,
        STATUS_HOLD,
        STATUS_WAITING,
        STATUS_SPAM,
        STATUS_NEW,
        STATUS_SENDING,
        SOURCE_CHAT,
        SOURCE_MAIL
      ];
      for (var i = 0; i < statuses.length; i++) {
        var status = statuses[i];
        var tempStatus = {
          text: this.getStatusText(status),
          value: status.toString()
        };
        statuses[i] = tempStatus;
      }
      return statuses;
    },
    getStatusText(val) {
      if (val == STATUS_OPEN) {
        return this.lang.status.open;
      }

      if (val == STATUS_RESOLVED) {
        return this.lang.status.resolved;
      }

      if (val == STATUS_CLOSED) {
        return this.lang.status.closed;
      }

      if (val == STATUS_DELETED) {
        return this.lang.status.deleted;
      }

      if (val == STATUS_HOLD) {
        return this.lang.status.hold;
      }

      if (val == STATUS_WAITING) {
        return this.lang.status.waiting;
      }

      if (val == STATUS_SPAM) {
        return this.lang.status.spam;
      }

      if (val == STATUS_NEW) {
        return this.lang.status.new;
      }

      if (val == STATUS_SENDING) {
        return this.lang.status.sending;
      }
    },
    setStartedDate() {
      const dateFrom = this.$route.query.from;
      const dateTo = this.$route.query.to;

      if (typeof dateFrom !== "undefined" && typeof dateTo !== "undefined") {
        this.startedDate = {
          start: dateFrom,
          end: dateTo
        };
      } else {
        this.startedDate = null;
      }
    }
  },

  created() {
    this.statuses = this.getStatusesText();
    const status = this.$route.query.status;

    if (typeof status !== "undefined") {
      this.currentStatus = this.statuses[status].value;
    } else {
      this.currentStatus = this.statuses[0].value;
    }

    this.setStartedDate();
  },
  beforeDestroy() {
    this.$off("click:base-button");
    this.$off("change:datetimepicker");
  }
};
</script>

<style scoped>
</style>
