import api from '@/api/users';

import {
  STATUS_OK,
  STATUS_CREATED,
  STATUS_SERVER_ERROR,
  STATUS_VALIDATION_ERROR,
  STATUS_NOT_FOUND,
  STATUS_FORBIDDEN,
  STATUS_UNAUTHORISED
} from '@/constants/httpCodes';

import {
  setUserToLocalStorage,
  setUserToLocalStorageRefresh,
} from '@/helpers/auth';

import {
  ROLE_DEPARTMENT_ADMIN,
  ROLE_SUB_ACCOUNT
} from "@/constants/roles";

export default {
  state: {
    user: null,
    currentUser: null,
    subAccounts: null,
    subAccountsLinks: null,
    referrals: null,
    referralsLinks: null
  },
  getters: {
    getUser: state => state.user,
    getCurrentUser: state => state.currentUser,
    getSubAccounts: state => state.subAccounts,
    getReferrals: state => state.referrals,
    getUserCurrentSalesChannelTypeId: state => state.currentUser && state.currentUser
      .currentSalesChannel &&
      state.currentUser.currentSalesChannel.typeId,
    isDepAdmin: state => state.currentUser && state.currentUser.roles.some(item => {
        return item === ROLE_DEPARTMENT_ADMIN
    }),
    isSubAccount: state => state.currentUser && state.currentUser.roles.some(item => {
        return item === ROLE_SUB_ACCOUNT
    }),
    canShowBalance: state => state.currentUser && !state.currentUser.roles.some(item => {
      return item === ROLE_SUB_ACCOUNT
    }) && state.currentUser.payoutEnabled,
    canShowSingleBalance: state => state.currentUser && !state.currentUser.roles.some(item => {
      return item === ROLE_SUB_ACCOUNT
    }) && !state.currentUser.payoutEnabled,
  },
  mutations: {
    SET_REFERRALS_DATA(state, payload) {
      state.referrals = payload;
    },
    SET_REFERRALS_LINKS_DATA(state, payload) {
      state.referralsLinks = payload;
    },
    SET_SUB_ACCOUNTS_DATA(state, payload) {
      state.subAccounts = payload;
    },
    SET_SUB_ACCOUNTS_LINKS_DATA(state, payload) {
      state.subAccountsLinks = payload;
    },
    SET_USER_DATA(state, payload) {
      state.user = payload;
    },
    SET_CURRENT_USER(state, payload) {
      state.currentUser = payload;
    },
    MODIFY_CURRENT_USER_UNREAD_NOTIFICATIONS(state, payload) {
      state.currentUser = {
        ...state.currentUser,
        newNotificationsCount: payload
      }
    },
    MODIFY_CURRENT_USER_BALANCE(state, payload) {
      state.currentUser = {
        ...state.currentUser,
        balance: payload
      }
    },
    MODIFY_CURRENT_USER_HOLD(state, payload) {
      state.currentUser = {
        ...state.currentUser,
        balanceHold: payload
      }
    },
  },
  actions: {
    async fetchReferrals({commit, dispatch}, payload) {
      dispatch('setIsProgressData', true);

      try {
        const result = await api.getReferrals(payload);

        if (result.status === STATUS_OK) {
          commit('SET_REFERRALS_DATA', result.data.items);
          commit('SET_REFERRALS_LINKS_DATA', result.data.links);
        }
      } catch(error) {
        if (error.response && error.response.status === STATUS_SERVER_ERROR) {
          dispatch('setNotifyMsg', {
            color: 'error',
            text: error.response.data.title
          });
        }

        return {
          status: error.response.status,
          text: error.response.data.errors
        }
      } finally {
        dispatch('setIsProgressData', false);
      }
    },

    fetchSubAccounts({
       commit,
       dispatch
     }, payload) {
      dispatch('setIsProgressData', true);
      api.getSubAccounts(payload)
      .then(({
         data: {
           items,
           links
         },
         status
       }) => {
        if (status === STATUS_OK) {
          commit('SET_SUB_ACCOUNTS_DATA', items);
          commit('SET_SUB_ACCOUNTS_LINKS_DATA', links);
        }
      }).catch(error => {
        if (STATUS_SERVER_ERROR === error.response.status) {
          dispatch('setNotifyMsg', {
            color: 'error',
            text: error.response.data.title
          });
        }

        return {
          status: error.response.status,
          text: error.response.data.errors
        }
      })
      .finally(() => {
        dispatch('setIsProgressData', false);
      })
    },
    async withdrawBalance({
      dispatch
    }, payload) {
      dispatch('setIsLoadingData', true);
      try {
        const {text, status} = await api.withdrawBalance(payload);

        if (status === STATUS_OK) {
          return {
            status: STATUS_OK,
            text: text || 'Success'
          }
        }
      } catch(error) {
        if ([STATUS_SERVER_ERROR, STATUS_FORBIDDEN, STATUS_NOT_FOUND].includes(error.response.status)) {
          dispatch('setNotifyMsg', {
            color: 'error',
            text: error.response.data.title
          });
          return;
        }

        return {
          status: error.response.status,
          text: error.response.data.errors
        }
      } finally {
        dispatch('setIsLoadingData', false);
      }
    },
    async toggleEnabled({
      dispatch
    }, payload) {
      dispatch('setIsLoadingData', true);
      try {
        const {text, status} = await api.toggleEnabled(payload);

        if (status === STATUS_OK) {
          return {
            status: STATUS_OK,
            text: text || 'Success'
          }
        }
      } catch(error) {
        if ([STATUS_SERVER_ERROR, STATUS_FORBIDDEN, STATUS_NOT_FOUND].includes(error.response.status)) {
          dispatch('setNotifyMsg', {
            color: 'error',
            text: error.response.data.title
          });
        }
      } finally {
        dispatch('setIsLoadingData', false);
      }
    },
    async signInUser({
      commit,
      dispatch,
      rootState
    }, {
      user,
      vm
    }) {
      dispatch('setIsProgressData', true);

      try {
        const {data, status} = await api.signIn(user);
        const userData = {
          ...data,
          ...user
        };

        if (status === STATUS_OK) {
          commit('SET_USER_DATA', userData);
          setUserToLocalStorage(userData, vm);

          return {
            status: true
          }
        }
      } catch(error) {

        if (! error.response) {
          dispatch('setNotifyMsg', {
            color: 'error',
            text: rootState.errors.notifyMessages.serverError
          });
        }

        if (error.response && STATUS_NOT_FOUND === error.response.status) {
          dispatch('setNotifyMsg', {
            color: 'error',
            text: rootState.errors.notifyMessages.notValidUser
          });
        }

        if (error.response && [
            STATUS_SERVER_ERROR,
            STATUS_FORBIDDEN,
            STATUS_UNAUTHORISED
        ].includes(error.response.status)) {
          dispatch('setNotifyMsg', {
            color: 'error',
            text: error.response.data.detail
          });
        }

        return {
          status: false
        }
      } finally {
        dispatch('setIsProgressData', false);
      }
    },
    refreshToken({
      dispatch,
    }, {
      token,
      vm
    }) {
      dispatch('setIsLoadingData', true);
      api.refreshToken(token)
        .then(({
          data,
          status
        }) => {
          if (status === STATUS_OK) {
            window.axios.defaults.headers.common["Authorization"] = `Bearer ${data.token}`;
            setUserToLocalStorageRefresh(data, vm);
          }
        })
        .finally(() => {
          dispatch('setIsLoadingData', false);
        })
    },
    async registerUser({
      dispatch,
    }, {
      user,
      vm
    }) {
      dispatch('setIsProgressData', true);
      const response = await api.register(user)
        .then(({
          status
        }) => {
          const userData = {
            user: {
              email: user.email,
              password: user.plainPassword.first,
            },
            vm,
          };
          if (status === STATUS_CREATED) {
            dispatch('signInUser', userData);
            return {
              status: true,
              text: null,
            }
          }
        })
        .catch(error => {
          if (error.response.status === STATUS_VALIDATION_ERROR) {
            // let validation = {};
            // if (error.response.data.errors.email) {
            //   validation.email = 'Email: ' + error.response.data.errors.email[0];
            // }
            // if (error.response.data.errors.plainPassword) {
            //   validation.password = 'Password: ' + error.response.data.errors.plainPassword.first[
            //     0];
            // }

            return {
              status: false,
              text: error.response.data.errors
            }
          } else {
            return {
              status: false,
              text: null,
            }
          }
        })
        .finally(() => {
          dispatch('setIsProgressData', false);
        });

      return response;
    },
    async resetPasswordRequest({
      dispatch,
      rootState
    }, {
      user,
    }) {
      dispatch('setIsProgressData', true);
      const response = await api.resetPasswordRequest(user)
        .then(() => {
          return {
            status: true,
            text: null
          }
        })
        .catch(error => {
          if (error.response.status === STATUS_SERVER_ERROR || error.response.status ===
            STATUS_VALIDATION_ERROR) {
            return {
              status: false,
              text: error.response.data.errors.email[0]
            }
          }
          if (error.response.status === STATUS_NOT_FOUND) {
            return {
              status: false,
              text: rootState.errors.notifyMessages.emailNotFound
            }
          }
        })
        .finally(() => {
          dispatch('setIsProgressData', false);
        });

      return response;
    },
    async checkCaptcha({
      dispatch,
    }, payload) {
      dispatch('setIsProgressData', true);
      const response = await api.checkCaptcha(payload)
        .then(({
          status
        }) => {
          if (status === STATUS_OK) {
            return true;
          }
        })
        .catch(() => {
          dispatch('setIsProgressData', false);
          return false;
        });

      return response;
    },
    async fetchCurrentUser({
      commit,
      dispatch,
    }) {
      dispatch('setIsProgressData', true);
      try {
        const {data, status} = await api.getCurrentUser();

        if (status === STATUS_OK) {
          commit('SET_CURRENT_USER', data);
        }
      } catch(error) {
        if ([STATUS_SERVER_ERROR, STATUS_FORBIDDEN, STATUS_NOT_FOUND].includes(error.response.status)) {
          dispatch('setNotifyMsg', {
            color: 'error',
            text: error.response.data.title
          });
        }
      } finally {
        dispatch('setIsProgressData', false);
      }
    },
    async setUserSettings({
      dispatch
    }, payload) {
      dispatch('setIsLoadingData', true);
      const response = await api.change(payload.id, payload.data)
        .then(({
          status
        }) => {
          if (status === STATUS_OK) {
            return {
              status: true,
              text: null
            }
          }
        })
        .catch(error => {
          if (error.response.status === STATUS_VALIDATION_ERROR) {
            return {
              status: false,
              text: error.response.data.errors
            }
          } else {
            return {
              status: false,
              text: null,
            }
          }
        })
        .finally(() => {
          dispatch('setIsLoadingData', false);
        });

      return response;
    },
    async changePassword({
      dispatch,
    }, payload) {
      dispatch('setIsProgressData', true);
      const response = await api.changePassword(payload.token, payload.data)
        .then(({
          status
        }) => {
          if (status === STATUS_OK) {
            return {
              status: true,
              text: null
            }
          }
        })
        .catch(() => {
          return {
            status: false,
            text: null
          }
        })
        .finally(() => {
          dispatch('setIsProgressData', false);
        });

      return response;
    },
    async setTelegramBot({
      dispatch,
      rootState
    }, payload) {
      const response = await api.setTelegramBot(payload)
        .then(({
          status
        }) => {
          if (status === STATUS_OK) {
            dispatch('setNotifyMsg', {
              color: 'success',
              text: rootState.errors.notifyMessages.telegramBotOk
            });
            return true;
          }
        })
        .catch((error) => {
          const {
            status
          } = error.response;

          if (status === STATUS_NOT_FOUND) {
            dispatch('setNotifyMsg', {
              color: 'error',
              text: rootState.errors.notifyMessages.telegramBotNotFound
            });
          }

          return false;
        })
        .finally(() => {
          dispatch('setIsProgressData', false);
        });

      return response;
    }
  },
};
