<template>
  <InputText
    :value=value
    :readonly=readonly
    :append-icon="showIcon ? 'file_copy' : null"
    @click:append="copyUrlText"
  >
  </InputText>
</template>

<script>
    import InputText from "@/components/InputText";
    import {mapActions} from "vuex";

    export default {
        components: {
            InputText
        },
        props: {
           message: {
               type: String,
               default: 'Copied!'
           },
           value: {
               type: String,
               required: true
           },
           readonly: {
               type: Boolean,
               default: false
           },
           showIcon: {
               type: Boolean,
               default: true
           }
        },
        methods: {
            ...mapActions([
                "setNotifyMsg"
            ]),
            copyText(val) {
                const el = document.createElement("textarea");
                el.value = val;
                el.setAttribute("readonly", "");
                el.style.position = "absolute";
                el.style.left = "-9999px";
                document.body.appendChild(el);
                el.select();
                document.execCommand("copy");
                document.body.removeChild(el);
            },
            copyUrlText() {
                this.copyText(this.value);
                this.setNotifyMsg({text: this.message, color: "success"});
            },
        }
    };
</script>

<style lang="scss">
  .v-input__append-inner {
    top: 0;
    right: 0;
    bottom: 0;
    width: 30px;
    display: flex;
    position: absolute;
    align-items: center;
    margin: 0 !important;
    padding: 0 !important;
    justify-content: center;
    .material-icons {
      color: #a6a8a9;
      cursor: pointer;
      font-size: 20px;
      transition: color 0.35s ease;
      &:hover {
        color: #222528;
      }
    }
  }
</style>
