export default function (Vue) {
  Vue.shipsFrom = {
    setLocation(val) {
      localStorage.setItem('current_location', val);
    },

    setShipmentMethod(val) {
      localStorage.setItem('shipment_method', val);
    },

    getShipmentMethod() {
      return localStorage.getItem('shipment_method');
    },

    getLocation() {
      return localStorage.getItem('current_location');
    },

    destroyLocation() {
      localStorage.removeItem('current_location');
    },

    destroyShipmentMethod() {
      localStorage.removeItem('shipment_method');
    },
  }

  Object.defineProperties(Vue.prototype, {
    $shipsFrom: {
      get() {
        return Vue.shipsFrom;
      }
    }
  })
}
