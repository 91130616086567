<template>
  <div class="payment-solutions">
    <div v-if="paymentSolutions && paymentSolutions.length > 0" class="list">
      <v-layout wrap>
        <v-flex md>
          <h3>{{ lang.list.title }}</h3>
        </v-flex>
        <v-flex>
          <div class="btn-add-wrap">
            <router-link :to="{name: 'dashboard.payment-solutions.wizzard'}">
              <ButtonPrimaryAdd class="border-gray" outline small>{{ lang.list.btnAdd }}</ButtonPrimaryAdd>
            </router-link>
          </div>
        </v-flex>
      </v-layout>
      <TablePaymentSolutions :paymentSolutions="paymentSolutions" />
    </div>
    <div v-else class="presentation">
      <PresentationalStartBlock :data="lang.presentation.startBlock"/>
      <div v-for="(block, k) in lang.presentation.secondaryBlocks" :key="k" class="secondary-block-wrapper">
        <PresentationalStartSecondaryBlock :data="block"/>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions, } from "vuex";
import TablePaymentSolutions from "@/components/Tables/TablePaymentSolutions";
import PresentationalStartBlock from "@/components/PresentationalStartBlock";
import PresentationalStartSecondaryBlock from "@/components/PresentationalStartSecondaryBlock";
import ButtonPrimaryAdd from "@/components/ButtonPrimaryAdd";

export default {
  components: {
    TablePaymentSolutions,
    PresentationalStartBlock,
    PresentationalStartSecondaryBlock,
    ButtonPrimaryAdd
  },
  computed: {
    ...mapGetters({
      paymentSolutions: "getPaymentSolutions"
    }),
    lang() {
      return {
        list: {
          title: 'Your applications',
          btnAdd: 'Add'
        },
        presentation: {
          startBlock: {
            title: "Forget about payment problems",
            subtitle: "Promote your shop, the rest is our business.",
            btnText: "Start",
            img: "payment-solutions-start.svg",
            routerName: "dashboard.payment-solutions.wizzard"
          },
          secondaryBlocks: [
            {
              title: "Stable payment solution",
              subtitle: "We will connect your shop to the payment system and help you to accept payments from customers around the world.",
              image: require("@/assets/images/payment-solutions-pic-1.png")
            },
            {
              title: "Different payment methods",
              subtitle: "Increase your store conversion by using different payment methods.",
              image: require("@/assets/images/payment-solutions-pic-2.png")
            }
          ]
        }
      };
    }
  },
  methods: {
    ...mapActions(["fetchPaymentSolutions"])
  },
  watch: {
    $route: {
      immediate: true,
      handler() {
        this.fetchPaymentSolutions();
      }
    }
  }
};
</script>

<style scoped lang="scss">
.payment-solutions {
  max-width: $max-width;
  .list {
    .title {
      margin-bottom: 20px;
    }
    .btn-add-wrap {
      float: right;
    }
  }
  .presentation {
    .secondary-block-wrapper {
      margin: 150px 0;
    }
  }
}
</style>
