<template>
  <div>
    <BaseButton
      v-bind="$attrs"
      color="transparent"
      round
      v-on="$listeners"
      class="button-transparent"
      :ripple="false"
    >
      <slot/>
    </BaseButton>
  </div>
</template>

<script>
export default {
  inheritAttrs: false
};
</script>

<style lang="scss">
.button-transparent {
  background-color: transparent;
  .v-btn__content {
    color: $text-color;
    &:hover {
      color: $hover-color !important;
    }
  }
  &:hover {
    &::before {
      background-color: transparent !important;
    }
  }
}
</style>
