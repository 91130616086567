export const STATUS_OPEN = 1;
export const STATUS_RESOLVED = 2;
export const STATUS_CLOSED = 3;
export const STATUS_DELETED = 4;
export const STATUS_HOLD = 5;
export const STATUS_WAITING = 6;
export const STATUS_SPAM = 7;
export const STATUS_NEW = 8;
export const STATUS_SENDING = 9;

export const SOURCE_MAIL = 1;
export const SOURCE_CHAT = 2;