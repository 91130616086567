<template>
  <transition name="fade">
    <div
      class="image-preview-wrapper"
      :style="{'width': width + 'px', 'height': height + 'px'}"
      v-if="show"
    >
      <div class="image-preview">
        <v-img v-if="src" :src="src" :width="width" :height="height"></v-img>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      default: true
    },
    src: {
      type: String,
      required: true
    },
    width: {
      type: String,
      default: "220"
    },
    height: {
      type: String,
      default: "220"
    }
  }
};
</script>

<style lang="scss">
.image-preview-wrapper {
  // position: absolute;
  // top: 0;
  // .image-preview {
  //   .v-image {
  //     position: fixed;
  //     bottom: 0;
  //     right: 0;
  //     z-index: 2147483010;
  //   }
  // }
}
</style>
