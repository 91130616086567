export default {
  data() {
    return {
      subcategories: null
    }
  },
  methods: {
    getRootCategory(items) {
      const rootParent = items[1];
      this.subcategories = this.$store.getters.getSubCategoriesById(
        rootParent.id
      );
    }
  }
}
