<template>
  <BaseIcon :width="width" :height="height" :stroke="stroke" fill="none" name="orders">
    <path d="M.5.5h17v17H.5zM4 4.5h10M4 7.5h10M4 10.5h10M4 13.5h10"/>
  </BaseIcon>
</template>

<script>
export default {
  props: {
    width: {
      type: [Number, String],
      default: 18
    },
    height: {
      type: [Number, String],
      default: 18
    },
    fill: {
      type: String,
      default: "none"
    },
    stroke: {
      type: String,
      default: "#7A7C7E"
    }
  }
};
</script>

<style scoped>
</style>
