import api from '@/api/countries';

import {
  STATUS_OK
} from '@/constants/httpCodes';

export default {
  state: {
    countries: null,
    mockCountries: [{
        name: "Australia",
        iso2: "AU"
      },
      {
        name: "Brazil",
        iso2: "BR"
      },
      {
        name: "China",
        iso2: "CN"
      },
      {
        name: "Czech Republic",
        iso2: "CZ"
      },
      {
        name: "Germany",
        iso2: "DE"
      },
      {
        name: "Spain",
        iso2: "ES"
      },
      {
        name: "France",
        iso2: "FR"
      },
      {
        name: "United Kingdom",
        iso2: "GB"
      },
      {
        name: "Indonesia",
        iso2: "ID"
      },
      {
        name: "Israel",
        iso2: "IL"
      },
      {
        name: "Italy",
        iso2: "IT"
      },
      {
        name: "Poland",
        iso2: "PL"
      },
      {
        name: "Russian Federation",
        iso2: "RU"
      },
      {
        name: "Turkey",
        iso2: "TR"
      },
      {
        name: "Ukraine",
        iso2: "UA"
      },
      {
        name: "United States",
        iso2: "US"
      },
    ]
  },
  getters: {
    getCountries: state => state.countries && state.countries.items,
    getMockCountries: state => state.mockCountries,
    getMockCountryByISO: state => iso => state.mockCountries && state.mockCountries.filter(item =>
      item.iso2 === iso)[0],
    getCountryByISO: state => iso => state.countries && state.countries.items.filter(item =>
      item.iso2 === iso)[0],
  },
  mutations: {
    SET_COUNTRIES_DATA(state, payload) {
      state.countries = payload;
    },
  },
  actions: {
    fetchCountries({
      commit,
      dispatch
    }) {
      dispatch('setIsProgressData', true);
      api.get()
        .then(({
          data,
          status
        }) => {
          if (status === STATUS_OK) {
            commit('SET_COUNTRIES_DATA', data);
          }
        })
        .finally(() => {
          dispatch('setIsProgressData', false);
        })
    },
  },
};
