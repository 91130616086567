<template>
  <div class="base-table">
    <div>
      <slot name="filters"></slot>
    </div>
    <ModalDeliveryPrice
      :productId="rootProductId"
      :show="showShipmentMethodModal"
      @change:modal="handleShippingClick"
      @esc:modal="handleShippingClick(false)"
      :key="rootProductId"
      lazy
    />

    <v-data-table
      v-model="selected"
      :headers="headers"
      :items="newVariants"
      :pagination.sync="pagination"
      hide-actions
      item-key="id"
      select-all
      class="variants-edit-table"
    >
      <template slot="headers" slot-scope="props">
        <tr class="base-table__headers">
          <th></th>
          <th
            class="text-xs-left table-headers-variant"
            v-for="header in props.headers"
            :key="header.text"
          >{{ header.text }}</th>
          <th></th>
        </tr>
      </template>
      <template slot="items" slot-scope="props">
        <template v-if="props.index === 0">
          <tr>
            <td>
              <v-checkbox
                :input-value="selected.length > 0"
                :indeterminate="selected.length > 0"
                color="primary"
                hide-details
                @click.stop="toggleAll"
              ></v-checkbox>
            </td>
            <td :colspan="headers.length + 1">
              <div class="base-table__selected_items">
                <template v-if="showPriceAllInput || showComparePriceAllInput">
                  <template>
                    <div class="set-price-block">
                      <InputText
                        backgroundColor="gray"
                        @input:base-text="handlePriceAllInput"
                        :value="priceAllCommon"
                        type="number"
                      ></InputText>
                      <ButtonPrimary small @click:base-button="handleHideInput">Ok</ButtonPrimary>
                    </div>
                  </template>
                </template>
                <template v-else>
                  <div class="selected-holder" v-show="selected.length > 0">
                    <span>{{ selectedCount }}</span>
                    <ButtonPrimary
                      outline
                      small
                      @click:base-button="handleClickPriceAll"
                    >{{ lang.buttons.price }}</ButtonPrimary>
                    <ButtonPrimary
                      outline
                      small
                      @click:base-button="handleClickComparePriceAll"
                    >{{ lang.buttons.comparePrice }}</ButtonPrimary>
                  </div>
                  <div v-show="!selected.length"></div>
                  <div class="delivery-block">
                    {{ lang.deliveryLabel }}
                    <p class="item--hover" @click="handleShippingClick(true)">{{ shippingText }}</p>
                  </div>
                </template>
              </div>
            </td>
          </tr>
        </template>
        <tr v-if="newVariants && !(canEditCodVariants === false && props.item.codCountry !== null)">
          <td :active="props.selected" @click="props.selected = !props.selected">
            <v-checkbox
              v-if="newVariants[props.index].codCountry === null"
              :input-value="props.selected"
              color="primary"
              hide-details
            ></v-checkbox>
          </td>
          <td>{{ props.item.available }}</td>
          <td>
            <v-tooltip top light open-delay="100" close-delay="100" lazy>
              <div style="position: relative;" slot="activator">
                <v-img
                  v-if="props.item.image"
                  :height="32"
                  :width="32"
                  :src="props.item.image.src"
                  :lazy-src="props.item.image.src"
                  @mouseover="onImageMouseOver(props.item.id, props.item.image.src)"
                  @mouseleave="onImageMouseLeave"
                ></v-img>
              </div>
              <ImagePreview :src="imagePreviewSrc"/>
            </v-tooltip>
          </td>
          <template>
            <td>{{ props.item.cost }}</td>
            <td>{{ deliveryCost.toFixed(2) }}</td>
            <td>
              <InputText
                v-if="newVariants[props.index].codCountry === null"
                class="base-input-errors--relative"
                backgroundColor="gray"
                :value="newVariants[props.index].price + ''"
                @input:base-text="handleInputTextValue($event, props.index, 'price', 'number')"
                :has-error="typeof errors[props.index] !== 'undefined' && errors[props.index].price ? true : false"
                :error-text="(typeof errors[props.index] !== 'undefined' && errors[props.index].price) ? errors[props.index].price[0] : ''"
                type="number"
              />
              <div v-else>{{ newVariants[props.index].price + '' }}</div>
            </td>
            <td>{{ calcProfit(props.index) }}</td>
            <td>
              <InputText
                v-if="newVariants[props.index].codCountry === null"
                class="base-input-errors--relative"
                backgroundColor="gray"
                :value="newVariants[props.index].comparePrice + ''"
                @input:base-text="handleInputTextValue($event, props.index, 'comparePrice', 'number')"
                :has-error="typeof errors[props.index] !== 'undefined' && errors[props.index].comparePrice ? true : false"
                :error-text="(typeof errors[props.index] !== 'undefined' && errors[props.index].comparePrice) ? errors[props.index].comparePrice[0] : ''"
                type="number"
              />
              <div v-else>{{ newVariants[props.index].comparePrice + '' }}</div>
            </td>
            <td v-if="countries && canEditCodVariants === true && isHasCod()">
              <div v-if="newVariants[props.index].codCountry !== null">
                {{ newVariants[props.index].codCountry.niceName }}
              </div>
            </td>
            <td v-if="canEditCodVariants === true && isHasCod()">
              <div v-if="newVariants[props.index].codCountry !== null">
                {{ getMockCodPaidTypeById(newVariants[props.index].codPaidType).name }}
              </div>
            </td>
          </template>
          <td v-if="props.item.option1 !== null">
            <InputText
              v-if="newVariants[props.index].codCountry === null"
              backgroundColor="gray"
              :value="newVariants[props.index].option1"
              @input:base-text="handleInputTextValue($event, props.index, 'option1')"
            />
            <div v-else>{{ newVariants[props.index].option1 }}</div>
          </td>
          <td v-if="props.item.option2 !== null">
            <InputText
              v-if="newVariants[props.index].codCountry === null"
              backgroundColor="gray"
              :value="newVariants[props.index].option2"
              @input:base-text="handleInputTextValue($event, props.index, 'option2')"
            />
            <div v-else>{{ newVariants[props.index].option2 }}</div>
          </td>
          <td v-if="props.item.option3 !== null">
            <InputText
              v-if="newVariants[props.index].codCountry === null"
              backgroundColor="gray"
              :value="newVariants[props.index].option3"
              @input:base-text="handleInputTextValue($event, props.index, 'option3')"
            />
            <div v-else>{{ newVariants[props.index].option3 }}</div>
          </td>
          <td class="justify-center">
            <v-icon
              v-if="newVariants[props.index].codCountry === null"
              @click="removeVariant(props.item.id)"
              color="#c4c4c4"
            >close</v-icon>
          </td>
        </tr>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import ButtonPrimary from "@/components/ButtonPrimary";
import InputText from "@/components/InputText";
import ModalDeliveryPrice from "@/components/ModalDeliveryPrice";
import ImagePreview from "@/components/ImagePreview";

export default {
  inheritAttrs: false,
  components: {
    ButtonPrimary,
    InputText,
    ModalDeliveryPrice,
    ImagePreview
  },
  data() {
    return {
      selected: [],
      items: [],
      headers: [],
      pagination: {
        sortBy: null,
        rowsPerPage: -1
      },
      deliveryCost: 0,
      newVariants: null,
      canEditCodVariants: false,
      priceAllSelectedInput: null,
      comparePriceAllSelectedInput: null,
      showPriceAllInput: false,
      showComparePriceAllInput: false,
      priceAllCommon: null,
      showShipmentMethodModal: false,
      selectedDeliveryIndex: null,
      showPreviewImage: false,
      imagePreviewSrc: "",
      imagePreviewId: null,
      errors: []
    };
  },
  computed: {
    ...mapGetters({
      countries: "getCountries",
      getCountryByISO: "getCountryByISO",
      getMockCodPaidTypeById: "getMockCodPaidTypeById",
      product: "getMyProduct",
      shipmentMethods: "getShipmentMethods"
    }),
    lang() {
      return {
        buttons: {
          price: "Set price",
          comparePrice: "Set compare price"
        },
        deliveryLabel: "Delivery to:"
      };
    },
    selectedCount() {
      if (this.selected.length === 1) {
        return `${this.selected.length} variant selected`;
      }
      return `${this.selected.length} variants selected`;
    },
    shippingText() {
      const countryIso2 = this.$shipsFrom.getLocation();
      const shipmentIso2 = this.$shipsFrom.getShipmentMethod();

      const currentCountry = this.getCountryByISO(countryIso2);
      const currentShipmentCountry = this.getCountryByISO(shipmentIso2);

      if (currentCountry) {
        if (!this.shipmentMethods || this.shipmentMethods.length === 0) {
          return `Ships to the ${currentCountry.niceName} is not available`;
        }
      } else {
        return "";
      }

      const leadMethod = this.shipmentMethods[this.selectedDeliveryIndex || 0];

      return `${currentCountry.niceName} from ${
        currentShipmentCountry.niceName
      } (${leadMethod.shippingCompany.name})`;
    },
    rootProductId() {
      return this.product && this.product.productId;
    }
  },
  methods: {
    toggleAll() {
      if (this.selected.length) this.selected = [];
      else this.selected = this.items.filter(item => item.codCountry === null).slice();
    },
    calcProfit(index) {
      return (
        this.newVariants[index].price -
        this.newVariants[index].cost -
        this.deliveryCost
      ).toFixed(2);
    },
    isHasCod() {
      return this.newVariants.some(
        item => item.codCountry !== null
      );
    },
    removeVariant(id) {
      this.newVariants = this.newVariants.filter(item => item.id !== id);
    },
    hiddenHideInput() {
      this.showPriceAllInput = false;
      this.showComparePriceAllInput = false;
    },
    handleHideInput() {
      if (this.priceAllCommon) {
        this.setNewPrices();
      }

      this.hiddenHideInput();
      this.priceAllCommon = null;
    },
    setNewPrices() {
      this.selected.map(selectedItem => {
        this.newVariants.find(item => {
          if (item.id === selectedItem.id) {
            item.price = this.showPriceAllInput
              ? +this.priceAllCommon
              : item.price;
            item.comparePrice = this.showComparePriceAllInput
              ? +this.priceAllCommon
              : item.comparePrice;
          }
        });
      });
      this.selected = [];
    },
    handleInputTextValue(val, index, name) {
      val = val.replace(",", ".");
      this.newVariants[index][name] = val;
    },
    handlePriceAllInput(val) {
      this.priceAllCommon = val.replace(",", ".").trim();
    },
    setHeaders() {
      this.headers = [
        { text: "Available", value: "available" },
        { text: "Image", value: "image" },
        { text: "Cost, $", value: "cost" },
        { text: "Delivery Cost, $", value: "deliveryCost" },
        { text: "Price, $", value: "price" },
        { text: "Profit, $", value: "profit" },
        { text: "Compare Price, $", value: "comparePrice" },
      ];
    },
    addCodHeaders() {
      this.headers.push({ text: "COD country", value: "codCountry" });
      this.headers.push({ text: "COD Paid type", value: "codPaidType" });
    },
    handleClickPriceAll() {
      this.showPriceAllInput = true;
    },
    handleClickComparePriceAll() {
      this.showComparePriceAllInput = true;
    },
    handleShippingClick(val) {
      this.showShipmentMethodModal = val;
    },
    getDeliveryCost(index) {
      return this.shipmentMethods[index].price;
    },
    onImageMouseOver(variantId, imageSrc) {
      this.imagePreviewSrc = imageSrc;
      this.imagePreviewId = variantId;
    },
    onImageMouseLeave() {
      this.imagePreviewSrc = "";
      this.imagePreviewId = null;
    }
  },
  watch: {
    product: {
      immediate: true,
      handler(product) {
        this.setHeaders();
        this.newVariants = product.variants;
        this.canEditCodVariants = product.salesChannel.canEditCodVariants;

        if (this.canEditCodVariants === true && this.isHasCod()) {
          this.addCodHeaders();
        }

        const tempItems = product.variants.map(item => {
          const result = {
            id: item.id,
            available: item.available,
            image: item.image ? item.image.src : "",
            cost: item.cost,
            price: item.price,
            comparePrice: item.comparePrice,
            codCountry: item.codCountry
          };

          if (item.option1) {
            result.option1 = item.option1;
          }

          if (item.option2) {
            result.option2 = item.option2;
          }

          if (item.option3) {
            result.option3 = item.option3;
          }

          return result;
        });

        if (product.option1) {
          this.headers = [
            ...this.headers,
            { text: this.product.option1, value: "option1" }
          ];
        }

        if (product.option2) {
          this.headers = [
            ...this.headers,
            { text: this.product.option2, value: "option2" }
          ];
        }

        if (product.option3) {
          this.headers = [
            ...this.headers,
            { text: this.product.option3, value: "option3" }
          ];
        }

        this.items = tempItems;
      }
    },
    selected: {
      handler(newVal, oldVal) {
        if (this.showPriceAllInput || this.showComparePriceAllInput) {
          if (newVal.length !== oldVal.length) {
            this.hiddenHideInput();
          }
        }
      }
    },
    newVariants: {
      handler(variants) {
        this.$emit("get-new-variants", variants);
      }
    }
  },
  mounted() {
    this.$root.$on("fetch-selected-item-index", deliveryIndex => {
      this.selectedDeliveryIndex = deliveryIndex;
      this.deliveryCost = this.getDeliveryCost(deliveryIndex);
    });

    this.$root.$on("variants-errors", errorsData => {
      this.errors = {};
      this.$nextTick().then(() => {
        this.errors = errorsData;
      });
    });

    window.$(".variants-edit-table table").stickyTableHeaders({
      fixedOffset: window.$(".variants-edit-table table thead"),
      cacheHeaderHeight: true
    });
  },
  beforeDestroy() {
    this.$off("click:base-button");
    this.$root.$on("variants-errors");
    window.$("table").stickyTableHeaders("destroy");
  }
};
</script>

<style lang="scss">
</style>
