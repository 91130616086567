<template>
  <div class="input-secondary-select">
    <div class="input-secondary-select__label">
      <slot></slot>
    </div>
    <v-select v-on="$listeners" v-bind="$attrs"></v-select>
  </div>
</template>

<script>
export default {
  inheritAttrs: false
};
</script>

<style lang="scss">
.input-secondary-select {
  &__label {
    font-size: 12px;
    line-height: 22px;
    color: $text-color;

    @include breakpoint($m) {
      line-height: 18px;
    }
  }

  .v-input__slot {
    border: 1px solid #a6a8a9 !important;
    border-radius: 0 !important;
    min-height: inherit !important;
  }

  .v-select__slot {
    align-self: center !important;

    .v-input__append-inner {
      margin-top: 3px !important;
    }

    .v-select__selection {
      font-size: 14px !important;
    }
  }

  .v-select__selections {
    padding: 0 !important;
  }

  .v-text-field__details {
    padding: 0 !important;
  }

  i {
    color: $text-sidebar-color !important;
  }
}
</style>
