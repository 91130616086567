<template>
  <div class="button-primary-add">
    <ButtonPrimary v-bind="$attrs" v-on="$listeners">
      <slot/>
      <v-icon class="button-primary-add__icon">add</v-icon>
    </ButtonPrimary>
  </div>
</template>

<script>
import ButtonPrimary from "@/components/ButtonPrimary";

export default {
  inheritAttrs: false,
  components: {
    ButtonPrimary
  }
};
</script>

<style lang="scss">
.button-primary-add {
  .v-btn__content {
    padding-left: 10px;
  }
}
.button-primary-add__icon {
  padding-left: 20px;
  padding-right: 10px;
  font-size: 18px !important;
}
</style>
