<template>
  <Modal v-bind="$attrs" v-on="$listeners" scrollable>
    <div slot="content">
      <div class="modal-dp">
        <h2 class="modal-dp__title">{{ lang.mainTitle }}</h2>
        <v-layout row wrap>
          <v-flex md4>
            <InputAutocomplete
              v-if="countries && currentCountry"
              :items="countries"
              :label="lang.labels.country "
              @change="reRenderCountries"
              item-text="niceName"
              item-value="iso2"
              :selected="currentCountry.iso2"
              attach
            />
          </v-flex>
        </v-layout>
        <br />
        <TableShipmentMethods />
        <div class="modal-dp__btn">
          <ButtonPrimary small @click:base-button="$emit('esc:modal')">{{ lang.btnText }}</ButtonPrimary>
        </div>
      </div>
    </div>
  </Modal>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

import TableShipmentMethods from "@/components/Tables/TableShipmentMethods";
import Modal from "@/components/Modal";
import ButtonPrimary from "@/components/ButtonPrimary";
import InputAutocomplete from "@/components/InputAutocomplete";

export default {
  inheritAttrs: false,
  props: {
    productId: {
      type: Number,
      required: true
    }
  },
  components: {
    Modal,
    TableShipmentMethods,
    ButtonPrimary,
    InputAutocomplete
  },
  data() {
    return {
      currentCountry: null,
      defaultShimpentMethod: null,
      prevIso2: null
    };
  },
  computed: {
    ...mapGetters({
      countries: "getCountries"
    }),
    lang() {
      return {
        mainTitle: "Delivery prices",
        btnText: "Ok",
        labels: {
          country: "Delivery to"
        }
      };
    }
  },
  methods: {
    ...mapActions(["fetchShipmentMethods"]),
    reRenderCountries(iso2) {
      if (this.prevIso2 === iso2 || typeof iso2 === "undefined") {
        return;
      }

      this.$shipsFrom.setLocation(iso2);
      const country = this.$shipsFrom.getLocation();
      const shipFromCountry = this.$shipsFrom.getShipmentMethod();
      this.currentCountry = this.$store.getters.getCountryByISO(country);
      this.prevIso2 = iso2;

      this.fetchShipmentMethods({
        product: this.productId,
        shipFromCountry,
        country
      });
    }
  },
  watch: {
    countries: {
      immediate: true,
      handler(val) {
        const iso2 = this.$shipsFrom.getLocation();
        val && this.reRenderCountries(iso2);
      }
    }
  }
};
</script>

<style lang="scss">
.modal-dp {
  .modal-dp__title {
    font-size: 30px;
    margin-bottom: 20px;
  }
  .modal-dp__btn {
    margin-top: 30px;
    text-align: right;
    @include breakpoint($m) {
      text-align: left;
    }
  }
}
</style>
