<template>
  <div class="breadcrumbs">
    <v-breadcrumbs :items="items" :divider="divider">
      <template slot="item" slot-scope="props">
        <a
          @click="!props.item.disabled && handleClick(props.item.href)"
          :class="{'breadcrumbs--disabled': props.item.disabled}"
        >{{ props.item.text }}</a>
      </template>
    </v-breadcrumbs>
  </div>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      required: true
    },
    divider: {
      type: String,
      default: ">"
    }
  },
  methods: {
    handleClick(params) {
      this.$router.push(params);
    }
  }
};
</script>

<style lang="scss">
.breadcrumbs {
  @include breakpoint($m) {
    margin-bottom: 10px;
  }
  .v-breadcrumbs {
    padding: 10px 0;
    font-size: 12px;

    .v-breadcrumbs__divider {
      color: $text-color !important;
    }

    .v-breadcrumbs__divider,
    a {
      color: $text-color;
    }

    a:hover {
      color: $hover-color !important;
    }

    .breadcrumbs--disabled {
      cursor: text;
      color: $text-color !important;
      &:hover {
        color: $text-color !important;
      }
    }
  }
}
</style>
