<template>
  <v-container class="pa-0 no-width base-table--filters" grid-list-md>
    <v-layout wrap>
      <v-flex xs12 sm6 md4>
        <InputText
          v-on="$listeners"
          append-icon="search"
          :value="valueTransactionId"
          @click:append="handleSearch"
          :placeholder="lang.transactionIdPlaceholder"
          @input:base-text="handleTransactionIdValue"
        >{{ lang.transactionId }}</InputText>
      </v-flex>
      <v-flex xs12 sm6 md3>
        <InputDateTime
          @change:datetimepicker="handleDatepickerChange"
          :startedDate="startedDate"
        >{{ lang.period }}</InputDateTime>
      </v-flex>
      <v-flex xs12 sm6 md2>
        <InputSelect
          @change:base-select="handleSelectedStatuses"
          :items="statuses"
          item-text="name"
          item-value="id"
          clearable
          :value="selelectedStatus"
        >{{ lang.status }}</InputSelect>
      </v-flex>
      <v-flex xs12 sm6 md2 align-self-center>
        <ButtonPrimary
          small
          class="base-table--filters__button"
          @click:base-button="handleSearch"
        >Filter</ButtonPrimary>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import * as constants from "@/constants/transactions";

import ButtonPrimary from "@/components/ButtonPrimary";
import InputDateTime from "@/components/InputDateTime";
import InputSelect from "@/components/InputSelect";
import InputText from "@/components/InputText";

export default {
  components: {
    ButtonPrimary,
    InputDateTime,
    InputSelect,
    InputText
  },
  data() {
    return {
      startedDate: {
        start: null,
        end: null
      },
      filters: {
        dateFrom: null,
        dateTo: null,
        status: null,
        id:
          typeof this.$route.query.id !== "undefined"
            ? this.$route.query.id
            : null
      },
      valueTransactionId:
        typeof this.$route.query.id !== "undefined"
          ? this.$route.query.id
          : null,
      statuses: [
        {
          id: [constants.STATUS_PENDING],
          name: "Pending"
        },
        {
          id: [constants.STATUS_APPLIED],
          name: "Applied"
        },
        {
          id: [constants.STATUS_CANCELED],
          name: "Canceled"
        },
        {
          id: [constants.STATUS_FAILED],
          name: "Failed"
        }
      ],
      selelectedStatus: {}
    };
  },
  computed: {
    lang() {
      return {
        period: "Period",
        status: "Status",
        transactionId: "Search transactions",
        transactionIdPlaceholder: "Transaction ID"
      };
    }
  },
  methods: {
    handleSearch() {
      this.$router.replace({ query: { ...this.filters } });
      this.$emit("click:search-btn", this.filters);
    },
    handleDatepickerChange({ start, end }) {
      if (start && end) {
        if (
          start !== this.$route.query.dateFrom ||
          end !== this.$route.query.dateTo
        ) {
          this.filters.dateFrom = start;
          this.filters.dateTo = end;
          this.handleSearch();
        }
      }
    },
    handleSelectedStatuses(val) {
      if (typeof val !== "undefined") {
        this.filters.status = val[0];
      } else {
        this.filters.status = null;
      }
    },
    handleTransactionIdValue(val) {
      this.valueTransactionId = val;
      this.filters.id = val;
    },
    setStartedDate() {
      const dateFrom = this.$route.query.dateFrom;
      const dateTo = this.$route.query.dateTo;

      if (typeof dateFrom !== "undefined" && typeof dateTo !== "undefined") {
        this.startedDate = {
          start: dateFrom,
          end: dateTo
        };
      } else {
        this.startedDate = {
          start: window
            .moment()
            .startOf("month")
            .format("YYYY-MM-DD"),
          end: window
            .moment()
            .endOf("month")
            .format("YYYY-MM-DD")
        };
      }

      this.filters.dateFrom = this.startedDate.start;
      this.filters.dateTo = this.startedDate.end;
    }
  },
  created() {
    const status = this.$route.query.status;
    if (typeof status !== "undefined") {
      this.filters.status = status;
      const filteredStatus = this.statuses.filter(item => item.id == status)[0];

      if (typeof filteredStatus !== "undefined") {
        this.selelectedStatus = filteredStatus.id;
      }
    }

    this.setStartedDate();
  },
  mounted() {
    this.handleSearch();
  },
  beforeDestroy() {
    this.$off("click:base-button");
    this.$off("change:datetimepicker");
  }
};
</script>

<style>
</style>
