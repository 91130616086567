import axios from 'axios';
import BaseApi from './base';

export default class extends BaseApi {
  static download(slug) {
    return new Promise((resolve, reject) => {
      return axios({
        url: this.baseURI + `files/download/${slug}`,
        method: 'GET',
        responseType: 'blob'
      }).then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  }
}
