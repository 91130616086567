<template>
  <Modal v-bind="$attrs" v-on="$listeners" scrollable>
    <div slot="content" v-if="notification">
      <div class="modal-notification">
        <div class="modal-notification__header">
          <v-layout>
            <v-flex xs11>
              <h2 class="modal-notification__title">{{ title }}</h2>
            </v-flex>
            <v-flex xs1 align-content-end class="text-xs-right">
              <v-icon class="close-icon item--hover" @click="$emit('esc:modal')">close</v-icon>
            </v-flex>
          </v-layout>
        </div>
        <template v-if="types.TYPE_STICKY_PRICE !== notification.type">
          <template v-if="notification.params.messages">
            <p v-for="(message, k) in notification.params.messages" :key="k">{{ message }}</p>
          </template>
          <template v-else>
            <p v-html="notification.params.text"></p>
          </template>
        </template>
        <template v-else-if="types.TYPE_STICKY_PRICE === notification.type">
          <v-layout wrap align-center>
            <v-flex xs2 md12>
              <v-img
                class="sticky-price-title"
                :src="notification.params.shopProductImage"
                :lazy-src="notification.params.shopProductImage"
                :height="46"
                :width="46"
              ></v-img>
            </v-flex>
            <v-flex xs10 md12>
              <h5 class v-text="notification.params.shopProductName"></h5>
            </v-flex>
          </v-layout>
          <table
            class="sticky-price-table"
            v-if="notification.params.items && notification.params.items.length"
          >
            <tr>
              <th>{{ lang.table.head1 }}</th>
              <th>{{ lang.table.head2 }}</th>
            </tr>
            <tr v-for="item in notification.params.items" :key="item.shopProductVariantId">
              <td class="sticky-price-table__sku">{{ item.shopProductVariantSku }}</td>
              <td>
                <span class="sticky-price-table__old-price">{{ '$' + item.oldPrice }}</span>
                <span class="sticky-price-table__new-price">{{ '$' + item.newPrice }}</span>
                <span
                  class="sticky-price-table__new-price"
                  :class="{'price-green': item.newPrice < item.oldPrice, 'price-red': item.newPrice > item.oldPrice}"
                >{{ getComparePrice(item.oldPrice, item.newPrice) }}</span>
              </td>
            </tr>
          </table>
        </template>
      </div>
    </div>
    <div slot="actions" v-if="notification">
      <v-layout wrap class="modal-notification-footer">
        <v-flex>
          <ButtonPrimary
            small
            outline
            class="border-gray"
            @click:base-button="$emit('esc:modal')"
          >{{ lang.buttons.gotIt }}</ButtonPrimary>
        </v-flex>
        <template v-if="types.TYPE_ORDER === notification.type">
          <v-flex>
            <ButtonPrimary
              small
              outline
              @click:base-button="handleOrderButtonClick"
            >{{ lang.buttons.toOrder }}</ButtonPrimary>
          </v-flex>
        </template>
        <template v-if="types.TYPE_STICKY_PRICE === notification.type">
          <v-flex>
            <ButtonPrimary
              v-if="productStatus !== STATUS_IMPORTED"
              small
              outline
              @click:base-button="handleEditProductButtonClick"
            >{{ lang.buttons.toEdit }}</ButtonPrimary>
          </v-flex>
          <template v-if="productStatus === STATUS_IMPORTED">
            <v-flex>
              <ButtonPrimary
                small
                outline
                @click:base-button="handleOpenInStore(storeUrl)"
                :disabled="!storeUrl"
              >
                {{ lang.buttons.toStore }}
                <v-icon class="shopify-icon">call_made</v-icon>
              </ButtonPrimary>
            </v-flex>
          </template>
        </template>
      </v-layout>
    </div>
  </Modal>
</template>

<script>
import { mapGetters, mapState, mapMutations } from "vuex";
import { STATUS_IMPORTED } from "@/constants/shopProducts";

import Modal from "@/components/Modal";
import ButtonPrimary from "@/components/ButtonPrimary";
import openProductInStore from "@/mixins/openProductInStore";

import * as types from "@/constants/notifications";

export default {
  inheritAttrs: false,
  mixins: [openProductInStore],
  components: {
    Modal,
    ButtonPrimary
  },
  data() {
    return {
      salesChannel: null,
      types: { ...types },
      STATUS_IMPORTED
    };
  },
  computed: {
    ...mapState({
      notification: state => state.notifications.notification
    }),
    ...mapGetters({
      getActiveSalesChannels: "getActiveSalesChannels",
      salesChannels: "getSalesChannels",
      user: "getCurrentUser"
    }),
    title() {
      return this.notification.description;
    },
    lang() {
      return {
        buttons: {
          gotIt: "Got It",
          toStore: "Go to store",
          toOrder: "Go to order",
          toEdit: "Go to edit product"
        },
        table: {
          head1: "SKU",
          head2: "Cost change"
        }
      };
    },
    channelId() {
      return this.$salesChannel.get();
    },
    productStatus() {
      return (
        this.notification && this.notification.params.shopProductStatusImport
      );
    },
    storeUrl() {
      return this.notification && this.notification.params.storeUrl;
    }
  },
  methods: {
    ...mapMutations([
      "CHANGE_NOTIFICATION_BY_ID",
      "MODIFY_CURRENT_USER_UNREAD_NOTIFICATIONS"
    ]),
    handleOrderButtonClick() {
      this.$router.push({
        name: "dashboard.orders.details",
        params: { id: this.notification.params.orderId }
      });
    },
    getComparePrice(oldPrice, newPrice) {
      return "$" + Math.abs((newPrice - oldPrice).toFixed(2));
    },
    handleEditProductButtonClick() {
      return this.$router.push({
        name: "dashboard.products.my.edit",
        params: { id: this.notification.params.shopProductId }
      });
    }
  },
  watch: {
    salesChannels: {
      immediate: true,
      handler(val) {
        if (val) {
          this.salesChannel = this.getActiveSalesChannels(this.channelId);
        }
      }
    },
    notification: {
      handler(val) {
        this.CHANGE_NOTIFICATION_BY_ID(val);
        if (this.user && this.user.newNotificationsCount > 0) {
          const newNotificationsCount = --this.user.newNotificationsCount;
          this.MODIFY_CURRENT_USER_UNREAD_NOTIFICATIONS(newNotificationsCount);
        }
      }
    }
  }
};
</script>

<style lang="scss">
.modal-notification {
  max-width: 100vw;

  .modal-notification__title {
    font-size: 30px;
    line-height: 32px;
  }

  .modal-notification__header {
    margin-bottom: 30px;

    .close-icon {
      color: $text-color;
      cursor: pointer;
    }
  }

  .sticky-price-title {
    margin: 15px 0;
    font-size: 14px;
    line-height: 20px;
  }

  .sticky-price-table {
    margin: 15px 0;
    border-collapse: collapse;
    overflow-x: auto;
    display: block;
    width: fit-content;
    max-width: 100%;

    th {
      text-align: left;
      font-size: 12px;
      line-height: 30px;
      color: $text-sidebar-color;
      font-family: "DIN Pro Bold" !important;
    }

    td {
      white-space: nowrap;
    }

    &__sku {
      font-size: 14px;
      line-height: 30px;
      padding-right: 10px;
    }

    &__old-price,
    &__new-price {
      font-size: 14px;
      line-height: 14px;
      margin-right: 15px;
    }

    &__old-price {
      color: $text-color !important;
      text-decoration: line-through;
    }

    &__new-price {
      color: $title-color !important;
    }

    .price-red {
      color: $warning-color !important;

      &:after {
        content: "arrow_upward";
        font-family: "Material Icons";
        vertical-align: inherit;
      }
    }

    .price-green {
      color: $success-color !important;

      &:after {
        content: "arrow_downward";
        font-family: "Material Icons";
        vertical-align: inherit;
      }
    }
  }
}
.modal-notification-footer {
  margin-top: 16px;
  margin-bottom: 16px;
  margin-left: 10px;
  width: 100%;

  button {
    margin-right: 5px !important;
  }

  .border-gray:first-child {
  }

  .shopify-icon {
    margin-left: 5px;
    font-size: 18px;
  }
}
</style>
