<template>
  <div>
    <ModalWelcome
      persistent
      :show="showModal"
      @esc:modal="onModalClose(false)"
      @change:modal="onModalClose"
    />
    <v-snackbar v-model="snackbar" :timeout="60000">
      {{ snackbarText }}
      <v-btn dark flat icon @click="handleSnackbarButton">
        <v-icon>refresh</v-icon>
      </v-btn>
    </v-snackbar>
    <v-snackbar color="error" v-model="covid" :timeout="600000">
      Please stop the traffic to your store. Due to COVID-19, we can't guarantee stable service. All new orders will be refunded.
    </v-snackbar>
    <router-view />
  </div>
</template>


<script>
import axios from "axios";
import api from "@/api/users";
import { mapActions, mapGetters, mapState } from "vuex";
import { logOutUser } from "@/helpers/auth";
import ModalWelcome from "@/components/ModalWelcome";

window.intercomSettings = {
  app_id: process.env.VUE_APP_INTERCOM_KEY
};

(function() {
  var w = window;
  var ic = w.Intercom;
  if (typeof ic === "function") {
    ic("reattach_activator");
    ic("update", w.intercomSettings);
  } else {
    var d = document;
    var i = function() {
      i.c(arguments);
    };
    i.q = [];
    i.c = function(args) {
      i.q.push(args);
    };
    w.Intercom = i;
    var l = function() {
      var s = d.createElement("script");
      s.type = "text/javascript";
      s.async = true;
      s.src =
        "https://widget.intercom.io/widget/" + process.env.VUE_APP_INTERCOM_KEY;
      var x = d.getElementsByTagName("script")[0];
      x.parentNode.insertBefore(s, x);
    };
    if (w.attachEvent) {
      w.attachEvent("onload", l);
    } else {
      w.addEventListener("load", l, false);
    }
  }
})();

export default {
  components: {
    ModalWelcome
  },
  data() {
    return {
      snackbar: false,
      covid: true,
      snackbarText: "Your current web client version is out of date. Click the refresh button or refresh the page.",
      isRefreshing: false,
      refreshSubscribers: [],
      showModal: null
    };
  },
  computed: {
    ...mapState({
      version: state => state.version.version,
      user: state => state.users.currentUser
    }),
    ...mapGetters({
      salesChannels: "getSalesChannels"
    })
  },
  methods: {
    ...mapActions([
      "fetchTranslations",
      "fetchSalesChannels",
      "refreshToken",
      "fetchCountries",
      "fetchVersion",
      "clearVersion",
      "setIsProgressData"
    ]),
    setAuthorizationHeader() {
      const token = this.$auth.getToken();
      const refreshToken = this.$auth.getRefreshToken();
      const tries = this.$auth.checkTries();

      if (token) {
        axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
      } else {
        if (tries) {
          this.$auth.destroyTries();
          this.checkTokens(refreshToken);
        } else {
          logOutUser(this);
        }
      }
    },
    handleSnackbarButton() {
      window.localStorage.removeItem("version");
      window.location.reload(true);
    },
    checkVersion(version) {
      if (!version) {
        return;
      }

      if (!window.localStorage.getItem('version')) {
        window.localStorage.setItem('version', version);

        return;
      }

      const localStorageVersion = window.localStorage.getItem("version");

      if (parseFloat(version) !== parseFloat(localStorageVersion)) {
        this.snackbar = true;
      }
    },
    subscribeTokenRefresh(cb) {
      this.refreshSubscribers.push(cb);
    },
    onRefreshed(token) {
      this.refreshSubscribers.map(cb => cb(token));
    },
    checkTokens(refreshToken) {
      axios.interceptors.response.use(
        response => {
          return response;
        },
        error => {
          const {
            config,
            response: { status }
          } = error;
          const originalRequest = config;

          if (status === 401) {
            if (!this.isRefreshing) {
              this.isRefreshing = true;

              api
                .refreshToken(refreshToken)
                .then(({ data }) => {
                  this.isRefreshing = false;
                  this.$auth.setToken(data.token, data.expire);
                  this.onRefreshed(data.token);
                })
                .finally(() => {
                  this.setIsProgressData(false);
                });
            }

            const retryOrigReq = new Promise(resolve => {
              this.subscribeTokenRefresh(token => {
                originalRequest.headers["Authorization"] = "Bearer " + token;
                resolve(axios(originalRequest));
              });
            });
            return retryOrigReq;
          } else {
            return Promise.reject(error);
          }
        }
      );
    },
    switchUser(email) {
      axios.defaults.headers.common["x-switch-user"] = email;
      this.$salesChannel.destroy();
      window.localStorage.setItem("x-switch-user", email);
      this.$router.push({ name: "dashboard.dashboard" });
    },
    onModalClose(val) {
      this.showModal = val;
    },
    setUserPractice(val) {
      this.showModal = val === null ? true : false;
    },
    runVersionUpdateTimer() {
      setInterval(() => {
          this.clearVersion();
          this.fetchVersion();
      }, 60000);
    }
  },
  watch: {
    "$route.query": {
      immediate: true,
      handler(query) {
        this.setAuthorizationHeader();

        if (query.switch_user) {
          this.switchUser(query.switch_user);
        }
      }
    },
    version: {
      immediate: true,
      handler(version) {
        this.checkVersion(version);
      }
    },
    user: {
      handler(user) {
        window.Intercom("boot", {
          app_id: process.env.VUE_APP_INTERCOM_KEY,
          name: user.username,
          email: user.email,
          created_at: +new Date(user.createdAt) / 1000
        });

        this.setUserPractice(user.practice);
      }
    }
  },
  beforeRouteUpdate(to, from, next) {
    const fullPath = to.fullPath;
    if (fullPath.slice(-1) === "/") {
      const newPath = fullPath.slice(0, -1);
      next({ path: newPath });
    } else {
      next();
    }
  },
  created() {
    //this.fetchTranslations();

    const xSwitchUser = window.localStorage.getItem("x-switch-user");

    if (xSwitchUser) {
      axios.defaults.headers.common["x-switch-user"] = xSwitchUser;
    }

    window.addEventListener("beforeunload", () => {
      const localStorageVersion = window.localStorage.getItem("version");

      if (this.version !== localStorageVersion) {
        window.localStorage.removeItem("version");
      }
    });
  },
  mounted() {
    this.runVersionUpdateTimer();
    this.fetchSalesChannels();
    this.$root.$on("set-html-title-name", name => document.title = name);
  },
  beforeDestroy() {
    this.$root.$off("set-html-title-name");
  }
};
</script>

<style scoped>
</style>
