<template>
  <BaseIcon :width="width" :height="height" stroke="none" :fill="fill" name="notifications">
    <path
      d="M16.23 14.548c-.584-.397-1.542-1.681-1.542-5.861 0-3.923-2.382-5.488-4.133-6.142A2.093 2.093 0 0 0 8.5 0a2.093 2.093 0 0 0-2.102 2.102c0 .14.024.303.047.443-1.751.63-4.133 2.219-4.133 6.142 0 4.203-.958 5.487-1.541 5.861a1.75 1.75 0 0 0 .98 3.2h3.9A2.922 2.922 0 0 0 8.5 20.081c1.378 0 2.569-.98 2.849-2.311h3.9a1.764 1.764 0 0 0 .98-3.223zM8.5.934c.654 0 1.168.514 1.168 1.168 0 .07 0 .116-.024.186-.957-.21-1.845-.093-2.312 0 0-.07-.023-.116-.023-.186A1.191 1.191 0 0 1 8.5.934zM3.246 8.687c0-3.55 2.195-4.904 4.016-5.418h.024s.513-.163 1.237-.163c.374 0 .771.046 1.215.187h.023c1.821.513 4.017 1.868 4.017 5.417 0 2.709.373 4.647 1.144 5.791H2.102c.747-1.19 1.144-3.129 1.144-5.814zM8.5 19.148a2.014 2.014 0 0 1-1.891-1.377h3.783A2.014 2.014 0 0 1 8.5 19.148zm6.749-2.311H1.75a.827.827 0 0 1-.817-.818c0-.233.093-.443.28-.607h14.618c.164.163.28.374.28.607-.046.444-.42.818-.863.818z"
    />
  </BaseIcon>
</template>

<script>
export default {
  props: {
    width: {
      type: [Number, String],
      default: 18
    },
    height: {
      type: [Number, String],
      default: 18
    },
    fill: {
      type: String,
      default: "#7A7C7E"
    },
    stroke: {
      type: String,
      default: "none"
    }
  }
};
</script>

<style scoped>
</style>
