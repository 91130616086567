<template>
  <v-btn class="base-button" v-bind="$attrs" round @click="$emit('click:base-button')">
    <slot/>
    <!-- <v-icon>
      <slot name="icon"></slot>
    </v-icon>-->
  </v-btn>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  inheritAttrs: false,
  computed: {
    ...mapGetters({
      loading: "getIsLoading"
    })
  }
};
</script>

<style lang="scss">
</style>
