import Vue from 'vue';
import App from '@/App.vue';
import Vuetify from 'vuetify';
import VueRouter from 'vue-router';
import VueCookie from 'vue-cookie';

import routes from '@/router/index';
import store from '@/store/index';

import Auth from '@/packages/Auth';
import Url from '@/packages/Url';
import SalesChannel from '@/packages/SalesChannel';
import ShipsFrom from '@/packages/ShipsFrom';
import Filters from '@/packages/Filters';

import 'vuetify/dist/vuetify.min.css';
import '@/scss/app.scss';
import 'material-design-icons-iconfont/dist/material-design-icons.css';

import LandingLayout from '@/layouts/Landing';
import DashboardLayout from '@/layouts/Dashboard';
import dotenv from 'dotenv';

/* eslint-disable */

import * as Sentry from '@sentry/browser'
import * as Integrations from '@sentry/integrations';

import * as guards from '@/router/guards';
import * as helpers from '@/router/helpers';

dotenv.config();

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: process.env.VUE_APP_SENTRY_DNS,
    debug: process.env.NODE_ENV === 'development',
    environment: 'production',
    integrations: [
      new Integrations.Vue({
        Vue,
        attachProps: true,
      }),
    ],
  });

  Vue.config.productionTip = false;
}

window.axios = require('axios');
window.moment = require('moment');
window.$ = require("jquery");

require('sticky-table-headers');
require('https://js.stripe.com/v3/');

const router = new VueRouter(routes);

Vue.use(Auth);
Vue.use(Url);
Vue.use(SalesChannel);
Vue.use(ShipsFrom);
Vue.use(Filters);
Vue.use(VueRouter);
Vue.use(VueCookie);
Vue.use(Vuetify, {
  theme: {
    primary: '#222528',
    secondary: '#fff',
    accent: '#96b8ff',
    info: '#a6a8a9',
    success: '#17cf72',
    warning: '#f03c1f',
    error: '#d32d11'
  },
});

Vue.component('landing-layout', LandingLayout);
Vue.component('dashboard-layout', DashboardLayout);

const requireComponent = require.context('./components', true, /Base[A-Z]/);

requireComponent.keys().forEach((fileName) => {
  let baseComponentConfig = requireComponent(fileName)
  baseComponentConfig = baseComponentConfig.default || baseComponentConfig
  const baseComponentName = baseComponentConfig.name || (
    fileName
    .replace(/^.+\//, '')
    .replace(/\.\w+$/, '')
  )
  Vue.component(baseComponentName, baseComponentConfig)
})

// Routes Auth Guard
router.beforeEach((to, ...rest) => {
  const next = rest[1];

  helpers.setMetaTitles(to, next);
  guards.autoAuth(to, next);
  guards.requireAuth(to, next);
  guards.isAuth(to, next);
  guards.requiresChannelId(to, next);
  guards.hasNotificationsAccess(to, next);
  guards.hasPaymentSolutionsAccess(to, next);
  guards.hasVirtualInventoryAccess(to, next);
  guards.hasBalanceAccess(to, next);
  guards.hasPayoutAccess(to, next);
  guards.hasSubAccountsAccess(to, next);

  next();
});

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app');
