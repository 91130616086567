import { render, staticRenderFns } from "./PresentationalStartSecondaryBlock.vue?vue&type=template&id=307a3baf&scoped=true&"
import script from "./PresentationalStartSecondaryBlock.vue?vue&type=script&lang=js&"
export * from "./PresentationalStartSecondaryBlock.vue?vue&type=script&lang=js&"
import style0 from "./PresentationalStartSecondaryBlock.vue?vue&type=style&index=0&id=307a3baf&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "307a3baf",
  null
  
)

component.options.__file = "PresentationalStartSecondaryBlock.vue"
export default component.exports