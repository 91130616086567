import apiOrders from '@/api/orders';
import apiTickets from '@/api/tickets';
import apiProducts from '@/api/products';

import {
  STATUS_OK
} from '@/constants/httpCodes';

export default {
  state: {
    orders: null,
    tickets: null,
    products: null,
    productsAutocomplete: []
  },
  getters: {
    getOrdersStatistics: state => state.orders,
    getOrderStatisticsPagination: state => state.orders && state.orders.links,
    getTicketsStatistics: state => state.tickets,
    getProductsStatistics: state => state.products,
  },
  mutations: {
    SET_ORDERS_STATISTICS_DATA(state, payload) {
      state.orders = payload;
    },
    SET_TICKETS_STATISTICS_DATA(state, payload) {
      state.tickets = payload;
    },
    SET_PRODUCTS_STATISTICS_DATA(state, payload) {
      state.products = payload;
    },
    SET_PRODUCTS_STATISTICS_AUTOCOMPLETE_DATA(state, payload) {
      const array = Object.keys(payload).map(i => payload[i].groupByValue)
      state.productsAutocomplete = array;
    },
  },
  actions: {
    fetchOrdersStatistics({
      commit,
      dispatch
    }, payload) {
      dispatch('setIsProgressData', true);
      apiOrders.getStatistics(payload)
        .then(({
          data,
          status
        }) => {
          if (status === STATUS_OK) {
            commit('SET_ORDERS_STATISTICS_DATA', data);
          }
        })
        .finally(() => {
          dispatch('setIsProgressData', false);
        })
    },
    fetchTicketsStatistics({
      commit,
      dispatch
    }, payload) {
      dispatch('setIsProgressData', true);
      apiTickets.getStatistics(payload)
        .then(({
          data,
          status
        }) => {
          if (status === STATUS_OK) {
            commit('SET_TICKETS_STATISTICS_DATA', data);
          }
        })
        .finally(() => {
          dispatch('setIsProgressData', false);
        })
    },
    fetchProductsStatistics({
      commit,
      dispatch
    }, payload) {
      dispatch('setIsProgressData', true);
      apiProducts.getStatistics(payload)
        .then(({
          data,
          status
        }) => {
          if (status === STATUS_OK) {
            commit('SET_PRODUCTS_STATISTICS_DATA', data);
          }
        })
        .finally(() => {
          dispatch('setIsProgressData', false);
        })
    },
    async fetchProductsStatisticsAutocomplete({
      commit,
      dispatch
    }, payload) {
      dispatch('setIsProgressData', true);
      const response = await apiProducts.getStatistics(payload)
        .then(({
          data: {
            items
          },
          status
        }) => {
          if (status === STATUS_OK) {
            commit('SET_PRODUCTS_STATISTICS_AUTOCOMPLETE_DATA', items);
            return true;
          }
        })
        .finally(() => {
          dispatch('setIsProgressData', false);
        })

      return response;
    },
  },
};
