export const BASE_URI = process.env.VUE_APP_BACKEND_HOST;
export const SIGN_IN = `tokens`;
export const API_SHOP = `shop`;
export const CATEGORIES = `categories`;
export const PRODUCTS = `products`;
export const TRANSLATIONS = `translations`;
export const COUNTRIES = `countries`;
export const ORDERS = `orders`;
export const SHOP_PRODUCTS = `shop-products`;
export const TICKETS = `tickets`;
export const USERS = `users`;
export const DASHBOARD = `dashboard`;
export const IMPORT = `import`;
export const STATISTICS = `statistics`;
export const SALES_CHANNELS = `sales-channels`;
export const GDPR_REQUESTS = `gdpr-requests`;
export const TRANSFER = `transfer`;
export const SUB_ACCOUNTS = `sub-accounts`;
export const USER_REFERRALS = `${USERS}/referrals`;
export const TRANSFER_MONEY = `transfer-money`;
export const TOGGLE_ENABLED = `toggle-enabled`;
export const CAPTCHA_CHECK = `captcha/check`;
export const REQUEST_PASSWORD_RESET = `request-password-resetting`;
export const SUPPLIERS = `shipping-companies`;
export const SHIPMENT_METHODS = `shipment-methods`;
export const CURRENT = `current`;
export const PAYMENT_SYSTEMS = `payment-systems`;
export const RECHARGE_BALANCE = `recharge-balance`;
export const TRANSACTIONS = `user-transactions`;
export const LOGIN = `login`;
export const CHAT_SCRIPTS = `chat-scripts`;
export const EXPORT_TO_STORE = `export/store`;
export const MARKETPLACE = `marketplace`;
export const RESET_PASSWORD = `reset-password`;
export const PAY_ORDERS = `pay-orders`;
export const VERSION = `application/version`;
export const NOTIFICATIONS = `notifications`;
export const NOTIFICATIONS_READ_ALL = `read-all`;
export const BRANDED_PRODUCTS = `branded-products`;
export const BANNERS = `banners`;
export const PAYMENT_SOLUTIONS = `payment-solutions`;
export const SPLIT_PRODUCTS = `split`;
export const PAYOUT_ACCOUNTS = `payout-accounts`;
export const PAYOUTS = `payouts`;
export const SPLIT_ORDERS = `split-by-option`;
export const SET_TELEGRAM_BOT = `set-telegram-bot-chat-id`;
export const IMPORT_PRODUCTS_FROM_FILE = `products/import/marketplace/file`;
export const EXPORT = 'export';
