import axios from 'axios';
import BaseApi from './base';
import {
  PAYOUT_ACCOUNTS,
} from '@/constants/api';

export default class extends BaseApi {
  static get(filters = null) {
    return new Promise((resolve, reject) => {
      return axios.get(this.baseURI + PAYOUT_ACCOUNTS, {
          params: filters
        })
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  static post(data) {
    return new Promise((resolve, reject) => {
      return axios.post(this.baseURI + PAYOUT_ACCOUNTS, data)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  static delete(id) {
    return new Promise((resolve, reject) => {
      return axios.delete(this.baseURI + PAYOUT_ACCOUNTS + '/' + id)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  }


}
