<template>
  <div class="catalog" @mouseleave="handleMouseOut">
    <v-layout row :style="{'height': height}">
      <v-flex xs12 sm12 md8 class="catalog-level-1">
        <ul class="catalog-level-1__list">
          <li
            class="catalog-level-1__list__item"
            v-for="category in categories.slice(0, defaultCategoriesLength)"
            :key="category.id"
            @mouseover="handleMouseOver(category.children)"
          >
            <router-link
              :to="{name: 'dashboard.products.list', params: { id: category.id }, query: { page: 1, page_size: 20 } }"
            >
              <div class="catalog-level-1__list__item__image-box">
                <img
                  class="catalog-level-1__list__item__image"
                  v-if="category.image"
                  :src="category.image"
                  alt="icon"
                >
              </div>
              {{ category.name }}
              <span class="catalog-level-1__list__item__icon">
                <v-icon color="#a6a8a9" size="12">chevron_right</v-icon>
              </span>
            </router-link>
          </li>
          <li
            class="catalog-level-1__list__item show-more item--hover"
            @click="handleExpandClick"
            v-if="defaultCategoriesLength > 0 && categories.length > defaultCategoriesLength"
          >
            <div class="catalog-level-1__list__item__image-box">
              <span class="expand-icon">
                <i></i>
              </span>
            </div>
            {{ categories.length - defaultCategoriesLength }} categories more
          </li>
        </ul>
      </v-flex>
      <v-flex class="catalog-level-2 hidden-sm-and-down" v-show="showCatalogLevel2">
        <ul class="catalog-level-2__list">
          <li
            class="catalog-level-2__list__item"
            v-for="child in currentCategoryChildren"
            :key="child.id"
          >
            <router-link
              :to="{name: 'dashboard.products.list', params: { id: child.id }, query: { page: 1, page_size: 20 } }"
            >
              <span class="catalog-level-2__list__item">{{ child.name }}</span>
            </router-link>

            <div class="catalog-level-3">
              <ul class="catalog-level-3__list">
                <li class="catalog-level-3__item" v-for="(c, k) in child.children" :key="k">
                  <router-link
                    :to="{name: 'dashboard.products.list', params: { id: c.id }, query: { page: 1, page_size: 20 } }"
                  >{{ c.name }}</router-link>
                </li>
              </ul>
            </div>
          </li>
        </ul>
      </v-flex>
      <v-flex class="catalog-banners hidden-sm-and-down" v-show="!showCatalogLevel2">
        <div v-if="banners && banners.length">
          <CatalogBanners :banners="banners"></CatalogBanners>
        </div>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
import CatalogBanners from "@/components/CatalogBanners";
import { mapGetters } from "vuex";

export default {
  components: {
    CatalogBanners
  },
  props: {
    categories: {
      type: Array,
      required: true
    },
    height: {
      type: String,
      default: "520px"
    }
  },
  data() {
    return {
      showCatalogLevel2: false,
      defaultCategoriesLength: 12,
      currentCategoryChildren: null
    };
  },
  computed: {
    ...mapGetters({
      banners: "getBanners"
    })
  },
  methods: {
    handleMouseOver(children) {
      this.showCatalogLevel2 = true;
      this.currentCategoryChildren = children;
    },
    handleMouseOut() {
      this.showCatalogLevel2 = false;
    },
    handleExpandClick() {
      this.defaultCategoriesLength = -1;
    }
  },
  mounted() {
    this.$root.$emit("hide-product-banners", () => alert());
  }
};
</script>

<style scoped lang="scss">
$padding: 15px;
$block-height: 518px;

.catalog {
  background-color: #fff;
  max-width: $max-width !important;
  height: $block-height;

  @include breakpoint($m) {
    padding: 16px 9px;
    margin-bottom: 23px;
  }

  @include breakpoint($t) {
    height: auto !important;
  }

  ul {
    padding: 0;
  }

  li {
    list-style: none;
  }

  .catalog-level-1 {
    height: $block-height;
    overflow: auto;
    max-width: 375px;
    border-radius: 4px !important;

    padding: $padding !important;
    @include breakpoint($t) {
      height: auto !important;
      max-width: none;
    }

    @include breakpointMin($ds) {
      max-width: 280px;
    }

    @include breakpointMin(1440px) {
      max-width: 375px;
    }

    @include breakpointMin(1500px) {
      max-width: 516px;
    }

    .catalog-level-1__list__item {
      a {
        display: flex;
        font-size: em(14);
        position: relative;
        align-items: center;
        padding: 8px 30px 8px 0;
        justify-content: flex-start;
        @include breakpoint($m) {
          padding-right: 0;
        }
        &:hover {
          color: $hover-color;
          cursor: pointer;
        }
      }
      .catalog-level-1__list__item__image-box {
        display: inline-block;
        width: 20px;
        height: 20px;
        margin-right: 10px;
        .expand-icon {
          top: 12px;
          width: 20px;
          height: 4px;
          display: block;
          position: relative;
          i {
            top: 0;
            left: 50%;
            margin-left: -2px;
            &:after,
            &:before {
              top: -1px;
              content: "";
            }
            &:after {
              left: -9px;
            }
            &:before {
              right: -9px;
            }
          }
          i,
          i:after,
          i:before {
            width: 5px;
            height: 5px;
            border-radius: 50%;
            position: absolute;
            border: solid 1px #000000;
          }
        }
      }
      .catalog-level-1__list__item__image {
        display: block;
        width: inherit;
        height: inherit;
        vertical-align: middle;
      }
    }

    .catalog-level-1__list__item__icon {
      top: 50%;
      right: 10px;
      margin-top: -10px;
      position: absolute;
      @include breakpoint($t) {
        display: none;
      }
    }
  }

  .catalog-level-2 {
    padding: $padding 0 0 $padding !important;
    .catalog-level-2__list {
      width: inherit;
      display: flex;
      height: $block-height;
      overflow: auto;
      flex-wrap: wrap;
      flex-direction: column;
      .catalog-level-2__list__item {
        font-size: em(14);
        a {
          cursor: pointer;
          max-width: 200px;
          padding: 0 0 10px;
          margin-right: 10px;
          color: $title-color;
          @include breakpoint($t) {
            max-width: none;
          }

          &:hover {
            color: $hover-color;
          }
        }

        &::after {
          content: "";
          height: 100%;
          width: 100%;
          flex-shrink: 1;
        }
      }
    }
  }

  .catalog-level-3 {
    margin: 0 0 10px;
    .catalog-level-3__list {
      .catalog-level-3__item {
        a {
          font-size: em(12);
          color: $text-color;
          cursor: pointer;
          &:hover {
            color: $hover-color;
          }
        }
      }
    }
  }

  .catalog-banners {
    width: inherit;
    display: flex;
    flex-wrap: wrap;
    overflow: hidden;
    padding-top: 0 !important;
    padding-left: 12px !important;
    background-color: $body-color;
    justify-content: flex-end;
  }

  .show-more {
    margin-top: 13px;
    cursor: pointer;
  }
}
</style>
