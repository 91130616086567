<template>
  <div class="base-select">
    <label class="base-select__label">
      <slot></slot>
    </label>
    <div class="base-select__select-text">
      <v-select
        v-bind="$attrs"
        :items="items"
        solo
        append-icon="expand_more"
        v-model="computedValue"
        :class="{[`base-select-background--${backgroundColor}`]: true}"
      ></v-select>
      <div class="base-select__icon">
        <slot name="icon"></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  inheritAttrs: false,
  props: {
    items: {
      required: true,
      type: [Object, Array]
    },
    value: {
      type: [String, Number, Object, Array]
    },
    backgroundColor: {
      type: String,
      default: "white"
    },
    height: {
      type: String,
      default: "30"
    },
    hideDetails: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    setPropsHeight() {
      let els = document.getElementsByClassName("v-select");
      for (let item of els) {
        item.style.height = this.height + "px";
        item.style["min-height"] = this.height + "px";
        item.style["max-height"] = this.height + "px";
      }
    }
  },
  computed: {
    computedValue: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("change:base-select", val);
      }
    }
  },
  mounted() {
    this.setPropsHeight();
    this.$emit("change:base-select", this.value);
  }
};
</script>

<style lang="scss">
.base-select {
  .base-select__label {
    display: block;
    font-size: em(12);
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    color: $text-color !important;
  }

  .base-select__select-text {
    .v-select {
      margin-top: 4px !important;
      * {
        font-size: 12px !important;
      }

      div,
      input {
        height: inherit !important;
        max-height: inherit !important;
        min-height: inherit !important;
      }

      .v-input__control {
        .v-input__slot {
          box-shadow: none !important;
          border-radius: 4px !important;

          label {
            color: $title-color !important;
          }

          .v-input__icon--append {
            i {
              font-size: 16px !important;
            }
          }
        }
      }

      .v-text-field__details {
        padding: 0 !important;
      }

      .v-select__selections {
        overflow: inherit;
      }

      .v-select__selection {
        margin: 0 !important;
      }
    }

    .v-select-list {
      .v-list__tile__title {
        font-size: 12px !important;
      }
    }
  }
}
.base-select-background--white {
  .v-input__control {
    .v-input__slot {
      background-color: #fff !important;
    }
  }
}
.base-select-background--gray {
  .v-input__control {
    .v-input__slot {
      background-color: $body-color !important;
    }
  }
}
.v-select__slot {
  border-radius: 4px !important;
}
</style>
