<template>
  <div v-if="salesChannels" class="dashboard">
    <DashboardMain v-if="salesChannelId"/>
    <DashboardStart v-else/>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import DashboardMain from "./DashboardMain";
import DashboardStart from "./DashboardStart";

export default {
  components: {
    DashboardMain,
    DashboardStart
  },
  computed: {
    salesChannelId() {
      return this.$salesChannel.get();
    },
    ...mapGetters({
      salesChannels: "getSalesChannels"
    })
  }
};
</script>

<style scoped lang="scss">
.dashboard {
  max-width: $max-width;
}
</style>
