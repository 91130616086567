<template>
  <BaseIcon :width="width" :height="height" name="dashboard" stroke="stroke" fill="none">
    <path :stroke="stroke" d="M.5.5h17v17H.5zM2 4.5h14"/>
    <path :fill="fill" d="M2 2h1v1H2zM4 2h1v1H4zM6 2h1v1H6z"/>
    <path :stroke="stroke" d="M3.5 7.5h3v7h-3z"/>
    <path :fill="fill" d="M9 7h6v1H9zM9 10h6v1H9zM9 13h6v1H9z"/>
  </BaseIcon>
</template>

<script>
export default {
  props: {
    width: {
      type: [Number, String],
      default: "18"
    },
    height: {
      type: [Number, String],
      default: "18"
    },
    fill: {
      type: String,
      default: "#7A7C7E"
    },
    stroke: {
      type: String,
      default: "#7A7C7E"
    }
  }
};
</script>

<style scoped>
</style>
