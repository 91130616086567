<template>
  <div class="base-table">
    <div v-if="showPagination || showPageSize" class="base-table-header-pagination">
      <Pagination
        v-if="showPagination && items && items.length"
        class="base-table_header__pagination"
        :pagination="basePagination"
        @click:pagination="handlePaginationClick"
      />
    </div>
    <div>
      <slot name="filters"></slot>
    </div>
    <div class="base-table__selected_items" v-show="selected.length > 0">
      <slot name="selected-header">
        <v-layout align-center row wrap>
          <v-flex md2>
            <div class="base-table__selected_items__count">{{ selectedCount }}</div>
          </v-flex>
          <v-flex md>
            <slot name="selected-header-button">
              <ButtonPrimary
                outline
                small
                @click:base-button="handleDelete"
              >{{ lang.buttons.delete }}</ButtonPrimary>
            </slot>
          </v-flex>
        </v-layout>
      </slot>
    </div>
    <v-data-table
      v-model="selected"
      :headers="headers"
      :items="items"
      :pagination.sync="pagination"
      hide-actions
      item-key="id"
      must-sort
      select-all
      v-bind="$attrs"
    >
      <template slot="headers" slot-scope="props">
        <tr>
          <th v-if="showCheckboxes && !showCustomCheckboxes">
            <v-checkbox
              :input-value="props.all"
              :indeterminate="props.indeterminate"
              color="primary"
              hide-details
              @click.stop="toggleAll"
            ></v-checkbox>
          </th>
          <template v-if="showCheckboxes && showCustomCheckboxes">
            <th v-if="showSelectAllCustomCheckbox">
              <v-checkbox
                :input-value="props.all"
                :indeterminate="props.indeterminate"
                color="primary"
                hide-details
                @click.stop="toggleCustomCheckboxAll"
              ></v-checkbox>
            </th>
            <th v-else></th>
          </template>
          <th v-if="showRadioButtons"></th>
          <th
            class="text-xs-left"
            :class="['column sortable', pagination.descending ? 'desc' : 'asc', header.value === pagination.sortBy ? 'active' : '', header.value]"
            v-for="header in props.headers"
            :key="header.text"
            @click="changeSort(header.value)"
            v-if="header.value != 'customCheckbox' && ! hideColumns.includes(header.value)"
          >
            {{ header.text }}
            <template v-if="header.value !== 'image'">
              <v-icon small>keyboard_arrow_up</v-icon>
            </template>
          </th>
          <th v-if="showDetails"></th>
          <th v-if="showRemove"></th>
          <slot name="th"></slot>
          <th v-if="items.length && showPay"></th>
        </tr>
      </template>
      <template slot="items" slot-scope="props">
        <tr>
          <template v-if="showCheckboxes && showCustomCheckboxes">
            <template v-if="props.item.customCheckbox">
              <td :active="props.selected" @click="props.selected = !props.selected">
                <v-checkbox :input-value="props.selected" color="primary" hide-details></v-checkbox>
              </td>
            </template>
            <template v-else>
              <td></td>
            </template>
          </template>
          <td
            v-if="showCheckboxes && !showCustomCheckboxes"
            :active="props.selected"
            @click="props.selected = !props.selected"
          >
            <v-checkbox :input-value="props.selected" color="primary" hide-details></v-checkbox>
          </td>
          <td v-if="showRadioButtons">
            <v-radio-group v-model="selectedRadio" class="base-table__radio-btn">
              <v-radio :value="props.index" color="primary"></v-radio>
            </v-radio-group>
          </td>
          <template>
            <template>
              <td
                v-for="(k, i) in props.item"
                :key="k + i"
                :class="{'base-table__item--bolder': bolder}"
                v-if="i != 'customCheckbox' &&  ! hideColumns.includes(i)"
              >
                <template v-if="i === 'image'">
                  <v-tooltip top light open-delay="100" close-delay="100" lazy>
                    <div style="position: relative;" slot="activator">
                      <v-img
                        v-if="props.item.image"
                        :height="32"
                        :width="32"
                        :src="props.item[i]"
                        :lazy-src="props.item[i]"
                        @mouseover="onImageMouseOver(props.item.id, props.item[i])"
                        @mouseleave="onImageMouseLeave"
                      ></v-img>
                    </div>
                    <ImagePreview :src="imagePreviewSrc" />
                  </v-tooltip>
                </template>

                <template v-else-if="i === 'financialStatus'">
                  <td class="financial-status-td" v-if="showPay && props.item.customCheckbox">
                    <ButtonPrimary
                      outline
                      small
                      :loading="loading"
                      @click:base-button="handlePayButton(props.item.id)"
                    >{{ lang.buttons.pay + ' $' + props.item.estimatedOrderCost }}</ButtonPrimary>
                  </td>
                  <td style="padding-left: 0" v-else v-html="props.item['financialStatus']"></td>
                </template>
                <template v-else-if="i === 'conditionalButton'">
                  <template v-if="props.item.conditionalButton">
                    <slot name="conditional-button" :item="props.item"></slot>
                  </template>
                </template>
                <div v-else v-html="props.item[i]"></div>
              </td>
            </template>
          </template>
          <td class="justify-center" v-if="showPay"></td>
          <td class="justify-center" v-if="showDetails">
            <template v-if="routerName">
              <router-link :to="{name: routerName, params: {id: props.item.id }}">
                <ButtonPrimary
                  outline
                  small
                  @click:base-button="$emit('get-action', props.item.id)"
                >{{ actionText }}</ButtonPrimary>
              </router-link>
            </template>
            <template v-else>
              <ButtonPrimary
                class="text-xs-right"
                outline
                small
                @click:base-button="$emit('get-action', props.item.id)"
              >{{ actionText }}</ButtonPrimary>
            </template>
          </td>
          <td class="justify-center" v-if="showRemove">
            <template v-if="showRemoveButton">
              <ButtonPrimary
                outline
                small
                @click:base-button="$emit('get-remove', props.item.id)"
              >Remove</ButtonPrimary>
            </template>
            <template v-else>
              <v-icon @click="$emit('get-remove', props.item.id)">close</v-icon>
            </template>
          </td>
        </tr>
      </template>
      <template slot="footer" v-if="items.length">
        <tr v-for="(footerItem, k) in footer" :key="k">
          <td v-if="showCheckboxes"></td>
          <td v-if="showRadioButtons"></td>
          <td class="text-xs-left" v-for="(text, k) in footerItem" :key="k">{{ text }}</td>
          <td v-if="showDetails"></td>
        </tr>
      </template>
    </v-data-table>
    <div class="base-table__footer" v-if="showPagination || showPageSize">
      <PageSize
        v-if="showPageSize  && items && items.length"
        @click:page-size-current="handlePageSizeClick"
      />
      <Pagination
        v-if="showPagination && items && items.length"
        class="base-table__footer__pagination"
        :pagination="basePagination"
        @click:pagination="handlePaginationClick"
      />
    </div>
  </div>
</template>

<script>
import ButtonPrimary from "@/components/ButtonPrimary";

import Pagination from "@/components/Pagination";
import PageSize from "@/components/PageSize";
import ImagePreview from "@/components/ImagePreview";
import { mapGetters } from "vuex";

export default {
  inheritAttrs: false,
  components: {
    ButtonPrimary,
    Pagination,
    PageSize,
    ImagePreview
  },
  props: {
    headers: {
      type: Array,
      required: true
    },
    items: {
      type: Array,
      required: true
    },
    footer: {
      type: Array
    },
    showDetails: {
      type: Boolean,
      default: false
    },
    showCheckboxes: {
      type: Boolean,
      default: false
    },
    showCustomCheckboxes: {
      type: Boolean,
      default: false
    },
    showRadioButtons: {
      type: Boolean,
      default: false
    },
    showPagination: {
      type: Boolean,
      default: false
    },
    showPageSize: {
      type: Boolean,
      default: false
    },
    showRemove: {
      type: Boolean,
      default: false
    },
    showPay: {
      type: Boolean,
      default: false
    },
    customPagination: {
      type: Object
    },
    actionText: {
      type: String,
      default: "Details"
    },
    bolder: {
      type: Boolean,
      default: false
    },
    routerName: {
      type: String,
      default: ""
    },
    showRemoveButton: {
      type: Boolean,
      default: false
    },
    pageSize: {
     type: Number,
     default: 20
    },
    radioButtonsNumber: {
      type: Boolean,
      default: false
    },
    hideColumns: {
      type: Array,
      default: function() {
        return [];
      }
    }
  },
  data() {
    return {
      selected: [],
      selectedRadio: 0,
      pagination: {
        sortBy: null,
        rowsPerPage: this.pageSize
      },
      showSelectAllCustomCheckbox: false,
      showPreviewImage: false,
      imagePreviewSrc: "",
      imagePreviewId: null
    };
  },
  computed: {
    ...mapGetters({
      loading: "getIsProgress"
    }),
    lang() {
      return {
        buttons: {
          refund: "Refund",
          fulfill: "Fulfill",
          delete: "Delete",
          pay: "Pay"
        }
      };
    },
    selectedCount() {
      if (this.selected.length === 1) {
        return `${this.selected.length} order selected`;
      }
      return `${this.selected.length} orders selected`;
    },
    basePagination() {
      if (!this.customPagination) {
        return null;
      }
      return {
        first: +this.$url.getParams(this.customPagination.first).page,
        last: +this.$url.getParams(this.customPagination.last).page,
        self: +this.$url.getParams(this.customPagination.self).page
      };
    }
  },
  methods: {
    toggleAll() {
      if (this.selected.length) this.selected = [];
      else this.selected = this.items.slice();
    },
    toggleCustomCheckboxAll() {
      if (this.selected.length) this.selected = [];
      else this.selected = this.items.filter(item => !!item.customCheckbox);
    },
    changeSort(column) {
      if (this.pagination.sortBy === column) {
        this.pagination.descending = !this.pagination.descending;
      } else {
        this.pagination.sortBy = column;
        this.pagination.descending = false;
      }
    },
    handlePaginationClick(page) {
      this.$router.push({
        query: { ...this.$route.query, page }
      });
      window.scrollTo(0, 0);
    },
    handlePageSizeClick(page_size) {
      this.$router.push({
        query: { ...this.$route.query, page_size }
      });
      window.scrollTo(0, 0);
    },
    handleDelete() {},
    handlePayButton(id) {
      this.selected = [];
      this.$emit("get-pay-action", id);
    },
    onImageMouseOver(variantId, imageSrc) {
      this.imagePreviewSrc = imageSrc;
      this.imagePreviewId = variantId;
    },
    onImageMouseLeave() {
      this.imagePreviewSrc = "";
      this.imagePreviewId = null;
    }
  },
  watch: {
    selectedRadio: {
      handler(itemIndex) {
        if (this.radioButtonsNumber) {
          this.$emit("fetch-selected-item-number", ++itemIndex);
        } else {
          this.$root.$emit("fetch-selected-item-index", itemIndex);
        }
      }
    },
    selected: {
      handler(arr) {
        this.$emit("fetch-selected-by-checkbox-array", arr);
      }
    },
    items: {
      immediate: true,
      handler(items) {
        if (!this.showRadioButtons) {
          this.showSelectAllCustomCheckbox = items.some(
            item => !!item.customCheckbox
          );
        }

        if (items && this.showRadioButtons) {
          this.selectedRadio = 0;
        }
      }
    }
  },
  mounted() {
    this.$root.$on("clear-selected-base-table", () => (this.selected = []));
  },
  beforeDestroy() {
    this.$off();
  }
};
</script>

<style lang="scss">
.container {
  &.no-width {
    padding: 0;
    max-width: none;
  }
}
.base-table {
  &.hide-first-id {
    td,
    th {
      &:not([colspan]) {
        &:first-child {
          display: none;
        }
      }
    }
  }

  .base-table__radio-btn {
    .v-input--selection-controls__input {
      margin-top: 8px;
    }
  }

  .base-table-image {
    width: 32px;
    height: 32px;
    vertical-align: middle;
    margin-right: 10px;
  }

  .financial-status-td {
    padding: 0 !important;
    button {
      margin: 0;
    }
  }
  .v-datatable {
    border-color: $body-color !important;
    thead {
      height: 38px;
      border-collapse: initial;
      background-color: $body-color;
      tr {
        background-color: $body-color;
      }
    }

    .tableFloatingHeader {
      opacity: 1 !important;
    }

    .v-datatable__progress {
      display: none !important;
    }

    th {
      border-left: 2px solid $body-color;
    }

    tbody {
      tr {
        &:hover {
          td {
            background: #e3e9ef !important;
          }
        }
      }
      td {
        background: #fff;
        transition: all 0.35s ease;
      }
      @include notLastChild(tr) {
        border-bottom: 1px solid $body-color !important;
      }
    }

    tr {
      border-bottom: none !important;
      font-size: 12px;
      box-sizing: padding-box;
      border-left: 2px solid transparent;

      &:hover {
        background-color: transparent !important;
        border-left: 2px solid $hover-color;
      }
    }

    td {
      font-size: 14px;
      color: $text-sidebar-color;
    }

    .base-table__item--bolder {
      font-family: "DIN Pro Bold" !important;
    }
    tfoot {
      background: $body-color;
      border: none !important;

      font-family: "DIN Pro Bold" !important;
      tr {
        border-left: 2px solid $body-color;
        border-top: none !important;
      }
    }
    .v-input--radio-group--column {
      .v-input__slot {
        margin-top: 12px;
      }
    }
  }

  .base-table__footer {
    display: flex;
    padding: 30px 10px;
    justify-content: space-between;
    align-items: center;
  }

  .base-table__footer__pagination,
  .base-table__header__pagination {
    display: flex;
  }

  .base-table-header-pagination {
    display: flex;
    justify-content: flex-end;
    padding: 0 10px;
    margin-top: 30px;
    margin-bottom: 10px;
  }

  table.v-table tfoot tr td,
  table.v-table tbody td:first-child,
  table.v-table tbody td:not(:first-child),
  table.v-table tbody th:first-child,
  table.v-table tbody th:not(:first-child),
  table.v-table thead td:first-child,
  table.v-table thead td:not(:first-child),
  table.v-table thead th:first-child,
  table.v-table thead th:not(:first-child) {
    padding-left: 12px;
    padding-right: 12px;
  }
}

.base-table__selected_items__count {
  font-size: 14px;
  font-family: "DIN Pro Bold" !important;
}

.base-table--filters {
  margin-top: 15px;

  label {
    font-size: 12px;
    color: $text-color;
  }

  .base-table--filters__button {
    margin-top: 15px;
    margin-left: 10px;
    &:first-child {
      margin-left: 20px;
    }
    @include breakpoint($t) {
      text-align: right;

      &:first-child {
        margin-left: -8px;
        margin-right: -8px;
      }
    }
    @include breakpoint($m) {
      text-align: left;
    }
    button {
      .v-btn__content {
        padding: 5px 15px;
      }
    }
  }
}
</style>
