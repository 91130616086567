import api from '@/api/products';
import * as ShopProductConst from "@/constants/shopProducts";

import {
  STATUS_OK,
  STATUS_NOT_FOUND,
  STATUS_VALIDATION_ERROR,
  STATUS_SERVER_ERROR,
  STATUS_ACCEPTED,
  STATUS_DELETED,
  STATUS_CREATED,
  STATUS_FORBIDDEN,
  STATUS_TO_MANY_REQUESTS
} from '@/constants/httpCodes';

export default {
  state: {
    products: null,
    product: null,
    myProducts: null,
    myProduct: null,
    leadProducts: [],
    codPaidTypes: [
      {
        name: "None",
        value: 0
      },
      {
        name: "Paid for approve",
        value: 1
      },
      {
        name: "Paid for delivery",
        value: 2
      }
    ]
  },
  getters: {
    getProducts: state => state.products && state.products.items,
    getMyProducts: state => state.myProducts && state.myProducts.items,
    getMockCodPaidTypes: state => state.codPaidTypes,
    getMockCodPaidTypeById: state => id => state.codPaidTypes && state.codPaidTypes.filter(item =>
      item.value === id)[0] || state.codPaidTypes[0],
    getMyProductsCount: state =>
        state.myProducts &&
        state.myProducts.items.filter(product => product.statusImport === ShopProductConst.STATUS_NOT_IMPORT).length,
    getMyProductsPagination: state => state.myProducts && state.myProducts.links,
    getProductsTotalCount: state => state.products && state.products.total,
    getProductsPagination: state => state.products && state.products.links,
    getProduct: state => state.product,
    getMyProduct: state => state.myProduct,
    getLeadProducts: state => state.leadProducts,
    getMyProductIds(state) {
      if (!state.myProducts) return null;
      return state.myProducts.items.map(product => product.id)
    },
  },
  mutations: {
    SET_PRODUCTS_DATA(state, payload) {
      state.products = payload;
    },
    SET_MY_PRODUCTS_DATA(state, payload) {
      state.myProducts = payload;
    },
    SET_PRODUCT_DATA(state, payload) {
      state.product = payload;
    },
    SET_MY_PRODUCT_DATA(state, payload) {
      state.myProduct = payload;
    },
    SET_LEAD_PRODUCTS_DATA(state, {
      data,
      index
    }) {
      state.leadProducts = [
        ...state.leadProducts
      ]
      state.leadProducts[index] = data;
    },
    REMOVE_PRODUCT_DATA(state) {
      state.product = null;
    },
  },
  actions: {
    async fetchProducts({
      commit,
      dispatch
    }, {
      filters
    }) {
      dispatch('setIsProgressData', true);
      const response = await api.get(filters)
        .then(({
          data,
          status
        }) => {
          if (status === STATUS_OK) {
            commit('SET_PRODUCTS_DATA', data);
            return {
              text: null,
              status: true
            }
          }
        })
        .catch(error => {
          if (error.response.status === STATUS_NOT_FOUND) {
            return {
              text: 'Page not found for your params',
              status: false
            }
          }
        })
        .finally(() => {
          dispatch('setIsProgressData', false);
        });

      return response;
    },
    fetchLeadProducts({
      commit,
      dispatch
    }, {
      id,
      index
    }) {
      dispatch('setIsProgressData', true);

      let filters = {
        page: 1,
        page_size: 20,
        category: id
      };

      api.get(filters)
        .then(({
          data: {
            items
          },
          status
        }) => {
          if (status === STATUS_OK) {
            commit('SET_LEAD_PRODUCTS_DATA', {
              data: items,
              index
            });
          }
        })
        .finally(() => {
          dispatch('setIsProgressData', false);
        })
    },
    async fetchProductById({
      commit,
      dispatch
    }, payload) {
      dispatch('setIsProgressData', true);
      const response = await api.getById(payload)
        .then(({
          data,
          status
        }) => {
          if (status === STATUS_OK) {
            commit('SET_PRODUCT_DATA', data);
            return data;
          }
        })
        .catch(error => {
          return error.response.status;
        })
        .finally(() => {
          dispatch('setIsProgressData', false);
        });

      return response;
    },
    fetchMyProducts({
      commit,
      dispatch
    }, payload) {
      dispatch('setIsProgressData', true);
      api.getMyProducts(payload)
        .then(({
          data,
          status
        }) => {
          if (status === STATUS_OK) {
            commit('SET_MY_PRODUCTS_DATA', data);
          }
        })
        .finally(() => {
          dispatch('setIsProgressData', false);
        })
    },
    fetchMyProductById({
      commit,
      dispatch
    }, payload) {
      dispatch('setIsProgressData', true);
      api.getMyProductById(payload)
        .then(({
          data,
          status
        }) => {
          if (status === STATUS_OK) {
            commit('SET_MY_PRODUCT_DATA', data);
          }
        })
        .finally(() => {
          dispatch('setIsProgressData', false);
        })
    },
    async fetchMyProductsByIds({
      dispatch
    }, payload) {
      const result = await api.getMyProducts(payload)
        .then(({
          data: {
            items
          },
          status
        }) => {
          if (status === STATUS_OK) {
            return items
          }
        })

      return result;
    },
    async addProductToImport({
      dispatch
    }, {
      id,
      channelId
    }) {
      dispatch('setIsProgressData', true);
      dispatch('setIsLoadingData', true);
      const response = await api.addToImport(id, channelId)
        .then(({
          status,
          data
        }) => {
          if (status === STATUS_CREATED) {
            return {
              status: true,
              text: null,
              productId: data.id
            }
          }
        })
        .catch(error => {
          return {
            status: false,
            text: error.response.data.detail
          };
        })
        .finally(() => {
          dispatch('setIsProgressData', false);
          dispatch('setIsLoadingData', false);
        });

      return response;
    },
    async removeMyProduct({
      dispatch,
      rootState
    }, payload) {
      dispatch('setIsProgressData', true);
      const response = await api.removeMyProduct(payload)
        .then(({
          status
        }) => {
          if (status === STATUS_DELETED) {
            return {
              status: true,
              text: null
            }
          }
        })
        .catch(error => {
          if (error.response.status === STATUS_SERVER_ERROR || error.response.status ===
            STATUS_VALIDATION_ERROR) {
            return {
              status: false,
              text: null
            }
          }

          if (error.response.status === STATUS_FORBIDDEN) {
            return {
              status: false,
              text: rootState.errors.notifyMessages.accessDeniedMyProducts
            }
          }
        })
        .finally(() => {
          dispatch('setIsProgressData', false);
        });

      return response;
    },
    async removeMyProducts({
      dispatch,
      rootState
    }, payload) {
      dispatch('setIsProgressData', true);
      const response = await api.removeMyProducts(payload)
        .then(({
          status
        }) => {
          if (status === STATUS_DELETED) {
            return {
              status: true,
              text: null
            }
          }
        })
        .catch(error => {
          if (error.response.status === STATUS_SERVER_ERROR || error.response.status ===
            STATUS_VALIDATION_ERROR) {
            return {
              status: false,
              text: null
            }
          }

          if (error.response.status === STATUS_FORBIDDEN) {
            return {
              status: false,
              text: rootState.errors.notifyMessages.accessDeniedMyProducts
            }
          }
        })
        .finally(() => {
          dispatch('setIsProgressData', false);
        });

      return response;
    },
    async saveMyProduct({
      dispatch,
      rootState: {
        errors
      }
    }, {
      id,
      data,
    }) {
      dispatch('setIsProgressData', true);
      const response = await api.saveMyProduct(id, data)
        .then(({
          status
        }) => {
          if (status === STATUS_OK) {
            return {
              status: true,
              text: null
            }
          }
        })
        .catch(error => {
          if (error.response.status === STATUS_FORBIDDEN) {
            return {
              status: false,
              text: [errors.notifyMessages.updatingOnShopify]
            }
          }

          if (error.response.status === STATUS_SERVER_ERROR || error.response.status ===
            STATUS_VALIDATION_ERROR) {
            return {
              status: false,
              text: error.response.data.errors
            }
          }
        })
        .finally(() => {
          dispatch('setIsProgressData', false);
        });

      return response;
    },
    async importToStore({
      dispatch,
      rootState
    }, payload) {
      dispatch('setIsProgressData', true);
      const response = await api.importToStore(payload)
        .then(({
          status
        }) => {
          if (status === STATUS_ACCEPTED) {
            return {
              status: true,
              text: null
            }
          }
        })
        .catch(error => {
          const {
            status
          } = error.response;

          if (status === STATUS_FORBIDDEN) {
            return {
              status: false,
              text: rootState.errors.notifyMessages.accessDeniedMyProducts
            }
          } else if (status === STATUS_TO_MANY_REQUESTS) {
            return {
              status: false,
              text: error.response.data.detail
            }
          }

          return {
            status: false,
            text: error.response.data.detail
          }
        })
        .finally(() => {
          dispatch('setIsProgressData', false);
        });

      return response;
    },
    async importToStorePack({
      dispatch,
      rootState
    }, payload) {
      dispatch('setIsProgressData', true);
      let result = {
        status: 'info',
        text: null
      };

      const promises = payload.map(id => {
        return api.importToStore(id);
      });

      try {
        const responses = await Promise.all(promises);
        const response = responses[responses.length - 1];

        if (response.status === STATUS_ACCEPTED) {
          return {
            status: true,
            text: null
          }
        }
      } catch (e) {
        const {
          status
        } = e.response;

        if (status === STATUS_FORBIDDEN) {
          return {
            status: false,
            text: rootState.errors.notifyMessages.accessDeniedMyProducts
          }
        } else if (status === STATUS_TO_MANY_REQUESTS) {
          return {
            status: false,
            text: e.response.data.detail
          }
        }
      } finally {
        dispatch('setIsProgressData', false);
      }

      return result;
    },
    async addNewProducts({
      dispatch
    }, payload) {
      dispatch('setIsProgressData', true);
      const response = await api.addNewProducts(payload)
        .then(({
          status
        }) => {
          if (status === STATUS_ACCEPTED) {
            return {
              status: true,
              text: null
            }
          }
        })
        .catch(error => {
          if (error.response.status === STATUS_VALIDATION_ERROR) {
            return {
              status: false,
              text: error.response.data.errors.sources[0]
            }
          }

          return {
            status: false,
            text: error.response.data.detail
          }
        })
        .finally(() => {
          dispatch('setIsProgressData', false);
        });

      return response;
    },
    async splitProducts({
      dispatch
    }, {
      id,
      data
    }) {
      dispatch('setIsProgressData', true);
      const response = await api.splitProducts(id, data)
        .then(({
          status
        }) => {
          if (status === STATUS_OK) {
            return {
              status: true,
              text: null
            }
          }
        })
        .catch(error => {
          if (error.response.status === STATUS_SERVER_ERROR || error.response.status ===
            STATUS_VALIDATION_ERROR) {
            return {
              status: false,
              text: null
            }
          }
        })
        .finally(() => {
          dispatch('setIsProgressData', false);
        });

      return response;
    },
    async splitOptions({
      dispatch
    }, {
      id,
      data
    }) {
      dispatch('setIsProgressData', true);
      const response = await api.splitOptions(id, data)
        .then(({
          status
        }) => {
          if (status === STATUS_OK) {
            return {
              status: true,
              text: null
            }
          }
        })
        .catch(error => {
          if (error.response.status === STATUS_SERVER_ERROR || error.response.status ===
            STATUS_VALIDATION_ERROR) {
            return {
              status: false,
              text: null
            }
          }
        })
        .finally(() => {
          dispatch('setIsProgressData', false);
        });

      return response;
    },
  }
};
