import * as constants from "@/constants/paymentSolutions";

export function getStatusText(status) {
    switch (status) {
        case constants.STATUS_PENDING:
            return "Pending";
        case constants.STATUS_APPROVED:
            return "Need moderaition";
        case constants.STATUS_CANCELED:
            return "Rejected";
        case constants.STATUS_MODERATE:
            return "Moderation";
        case constants.STATUS_MODERATED:
            return "Moderated";
        case constants.STATUS_MODERATE_CANCEL:
            return "Rejected after moderation";
        case constants.STATUS_CONNECTED:
            return "Connected";
        default:
            return status;
    }
}

export function getStatusStyle(status) {
    switch (status) {
        case constants.STATUS_APPROVED:
            return "before";
        case constants.STATUS_CONNECTED:
            return "success";
        case constants.STATUS_MODERATE:
        case constants.STATUS_MODERATED:
            return "waiting";
        case constants.STATUS_CANCELED:
        case constants.STATUS_MODERATE_CANCEL:
            return "danger";
        default:
            return "neutral";
    }
}

export function getStorePlatformText(storePlatform) {
    switch (storePlatform) {
        case constants.STORE_PLATFORM_SHOPIFY:
            return "Shopify store";
        case constants.STORE_PLATFORM_WOO_COMMERCE:
            return "WooCommerce store";
        default:
            return storePlatform;
    }
}
