<template>
  <div class="base-icon" v-on="$listeners">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      :width="width"
      :height="height"
      :aria-labelledby="name"
      role="presentation"
    >
      <title :id="name" lang="en">{{ name }} icon</title>
      <g :fill="fill" :stroke="stroke">
        <slot></slot>
      </g>
    </svg>
  </div>
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      required: true
    },
    width: {
      type: [Number, String],
      default: 18
    },
    height: {
      type: [Number, String],
      default: 18
    },
    fill: {
      type: String,
      default: "currentColor"
    },
    stroke: {
      type: String,
      default: "currentColor"
    }
  }
};
</script>

<style scoped lang="scss">
.base-icon {
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
