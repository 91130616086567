<template>
  <Modal v-bind="$attrs" v-on="$listeners" @change:modal="onChangeModal" scrollable>
    <div slot="content">
      <div class="modal-dp">
        <h2 class="modal-dp__title">{{ lang.mainTitle }}</h2>
        <InputTextarea
          :value="products"
          @input:base-text="handleProducts"
        >{{ lang.labels.addProduct }}</InputTextarea>
        <v-checkbox v-model="hidden" :label="lang.hidden" color="primary"></v-checkbox>
        <div class="modal-dp__btn">
          <ButtonPrimary
            small
            @click:base-button="addProducts"
            :loading="progress"
          >{{ lang.btnText }}</ButtonPrimary>
          <FileUpload
            :url="importFromFileUrl"
            accept=".txt"
            btnLabel="Select a txt file"
            :additionalData="additionalData"
            @success="onSuccessFileUpload"
            @error="onErrorFileUpload"
            :maxSize=1048576
            @validateError="onValidateErrorFileUpload"
          ></FileUpload>
          <ButtonPrimary small outline @click:base-button="onCancel">{{ lang.cancel }}</ButtonPrimary>
        </div>
      </div>
    </div>
  </Modal>
</template>

<script>
import { mapActions, mapState, mapGetters } from "vuex";

import Modal from "@/components/Modal";
import FileUpload from "@/components/FileUpload";
import ButtonPrimary from "@/components/ButtonPrimary";
import InputTextarea from "@/components/InputTextarea";
import {
    IMPORT_PRODUCTS_FROM_FILE
} from '@/constants/api';

export default {
  inheritAttrs: false,
  components: {
    Modal,
    FileUpload,
    ButtonPrimary,
    InputTextarea
  },
  data() {
    return {
      products: "",
      hidden: false,
      importFromFileUrl: IMPORT_PRODUCTS_FROM_FILE
    };
  },
  computed: {
    ...mapGetters({
      progress: "getIsProgress"
    }),
    ...mapState({
      successMsgAddPro: state => state.errors.productMessages.successAlt,
      successFileUpload: state =>
        state.errors.productMessages.successFileUpload,
      errorMsg: state => state.errors.productMessages.somethingWrong
    }),
    lang() {
      return {
        mainTitle: "Add new products",
        btnText: "Add",
        cancel: "Cancel",
        labels: {
          addProduct: "Add product links"
        },
        hidden: "Private"
      };
    },
    additionalData() {
      return {
        salesChannel: this.salesChannelId,
        hidden: this.hidden
      };
    },
    salesChannelId() {
      return this.$salesChannel.get();
    }
  },
  methods: {
    ...mapActions(["addNewProducts", "setNotifyMsg"]),
    async addProducts() {
      const data = {
        sources: this.products,
        salesChannel: this.salesChannelId,
        hidden: this.hidden
      };

      const { status, text } = await this.addNewProducts(data);

      const notifyData = {
        text: text || (status ? this.successMsgAddPro : this.errorMsg),
        color: status ? "success" : "error"
      };

      this.setNotifyMsg(notifyData);

      if (status) {
        this.$emit("productadd");
      } else {
        this.products = "";
      }
    },
    handleProducts(val) {
      this.products = val;
    },
    onCancel() {
      this.$emit("esc:modal");
    },
    onChangeModal(val) {
      if (!val) {
        this.products = "";
      }
    },
    onSuccessFileUpload() {
      this.onCancel();
      this.setNotifyMsg({
        text: this.successFileUpload,
        color: "success"
      });
    },
    onErrorFileUpload(e) {
      this.setNotifyMsg({
        text: e.currentTarget.response.errors.file ? e.currentTarget.response.errors.file.join('<br>') : this.errorMsg,
        color: "error"
      });
    },
    onValidateErrorFileUpload(e) {
        this.setNotifyMsg({
            text: e.message,
            color: "error"
        });
    }
  }
};
</script>

<style lang="scss">
.modal-dp {
  .modal-dp__title {
    font-size: 30px;
    margin-bottom: 20px;
  }
  .modal-dp__btn {
    display: flex;
    flex-direction: row-reverse;
    margin-top: 30px;
    text-align: right;
    @include breakpoint($m) {
      text-align: left;
    }
  }
  .base-input {
    .v-textarea {
      .v-input__slot {
        min-height: 0;
        max-height: none;
      }
      .v-text-field__slot {
        height: 200px !important;
        min-height: 200px !important;
        max-height: 200px !important;
      }
      textarea {
        font-size: 14px;
        padding-left: 12px;
        padding-right: 12px;
      }
    }
  }
}
</style>
