<template>
  <div class="inventory-wizzard">
    <h2 class="inventory-wizzard__title" v-if="showWizzard">{{ lang.title }}</h2>
    <v-layout wrap v-if="showWizzard">
      <v-flex md8 class="inventory-wizzard-form">
        <PresentionalStepperForm :headers="headers" :step="step">
          <div slot="main-content">
            <div v-show="step === 1">
              <h4 class="inventory-stepper-content__title">{{ contents.step1.title }}</h4>
              <div v-if="Array.isArray(salesChannels) && salesChannels.length === 0" class="error--text">
                {{ lang.errors.emptySalesChannels }}
              </div>
              <v-layout wrap>
                <v-flex xs12>
                  <InputSecondarySelect
                    outline
                    item-text="name"
                    item-value="id"
                    :items="salesChannels ? salesChannels : []"
                    height="40"
                    append-icon="expand_more"
                    :rules="[rules.required]"
                    @change="onChangeSelect($event, 'salesChannel')"
                  >{{ lang.form.salesChannelLabel }} *</InputSecondarySelect>
                </v-flex>
                <v-flex xs12>
                  <InputSecondaryText
                    outline
                    v-model="form.specification"
                    :value="form.specification"
                    :rules="[rules.required]"
                  >{{ lang.form.goodsLabel }} *</InputSecondaryText>
                </v-flex>
                <v-flex xs12>
                  <InputSecondarySelect
                    outline
                    item-text="name"
                    item-value="value"
                    :items="customerLocatedList"
                    height="40"
                    append-icon="expand_more"
                    :rules="[rules.required]"
                    @change="onChangeSelect($event, 'customersLocated')"
                  >{{ lang.form.customerLocatedLabel }} *</InputSecondarySelect>
                </v-flex>
              </v-layout>
            </div>

            <div v-show="step === 2">
              <h4 class="inventory-stepper-content__title">{{ contents.step2.title }}</h4>
              <v-layout wrap>
                <v-flex md12>
                  <InputSecondarySelect
                    outline
                    item-text="name"
                    item-value="value"
                    :items="experienceList"
                    height="40"
                    append-icon="expand_more"
                    :rules="[rules.required]"
                    @change="onChangeSelect($event, 'experience')"
                  >{{ lang.form.experienceLabel }} *</InputSecondarySelect>
                </v-flex>
                <v-flex xs6>
                  <InputSecondaryText
                    outline
                    v-model="form.typicalAmount"
                    :value="form.typicalAmount"
                    :rules="[rules.required, rules.isAbsNumber, rules.isNumber]"
                  >{{ lang.form.typicalAmountLabel }} *</InputSecondaryText>
                </v-flex>
                <v-flex xs6>
                  <InputSecondaryText
                    outline
                    v-model="form.maxAmount"
                    :value="form.maxAmount"
                    :rules="[rules.required, rules.isAbsNumber, rules.isNumber]"
                  >{{ lang.form.maxAmountLabel }} *</InputSecondaryText>
                </v-flex>
                <v-flex md12>
                  <InputSecondaryText
                    outline
                    v-model="form.avgCountPerDay"
                    :value="form.avgCountPerDay"
                    :rules="[rules.required, rules.isAbsNumber, rules.isNumber]"
                  >{{ lang.form.avgCountPerDayLabel }} *</InputSecondaryText>
                </v-flex>
                <v-flex xs6>
                  <InputSecondaryText
                    outline
                    v-model="form.actualMonthlyTurnover"
                    :placeholder="lang.form.inUSDPlaceholder"
                    :value="form.actualMonthlyTurnover"
                    :rules="[rules.required, rules.isAbsNumber, rules.isNumber]"
                  >{{ lang.form.actualMonthlyTurnoverLabel }} *</InputSecondaryText>
                </v-flex>
                <v-flex xs6>
                  <InputSecondaryText
                    outline
                    v-model="form.plannedMonthlyTurnover"
                    :placeholder="lang.form.inUSDPlaceholder"
                    :value="form.plannedMonthlyTurnover"
                    :rules="[rules.required, rules.isAbsNumber, rules.isNumber]"
                  >{{ lang.form.plannedMonthlyTurnoverLabel }} *</InputSecondaryText>
                </v-flex>
                <v-flex md12>
                  <v-radio-group
                    v-model="form.currency"
                    :value="form.currency"
                    class="inventory-stepper-radio-group"
                    @change="$root.$emit('change:radio-group', form.currency)"
                    hide-details
                  >
                    <div class="custom-label">{{ lang.form.currenciesLabel }} *</div>
                    <RadioBorderBox
                      v-for="(radio, k) in radios.step2"
                      :value="radio.value"
                      :label="radio.label"
                      :key="k"
                    >
                    </RadioBorderBox>
                  </v-radio-group>
                </v-flex>
              </v-layout>
            </div>

            <div v-show="step === 3">
              <h4 class="inventory-stepper-content__title">{{ contents.step3.title }}</h4>
              <v-layout wrap>
                <v-flex md12>
                  <InputSecondaryText
                    outline
                    v-model="form.fullName"
                    :value="form.fullName"
                    :placeholder="lang.form.fullNamePlaceholder"
                    :rules="[rules.required]"
                  >{{ lang.form.fullNameLabel }} *</InputSecondaryText>
                </v-flex>
                <v-flex md12>
                  <InputSecondaryDateOfBirthPicker
                    outline
                    :value="form.birthDate"
                    :rules="[rules.required]"
                    @change:datetime-of-birth="onChangeSelect($event, 'birthDate')"
                  >{{ lang.form.dateOfBirthLabel }} *</InputSecondaryDateOfBirthPicker>
                </v-flex>
                <v-flex md12>
                  <InputSecondarySelect
                    outline
                    item-text="niceName"
                    item-value="iso2"
                    :items="countries"
                    :rules="[rules.required]"
                    height="40"
                    append-icon="expand_more"
                    @change="onChangeSelect($event, 'country')"
                  >{{ lang.form.countryLabel }} *</InputSecondarySelect>
                </v-flex>
                <v-flex md12>
                  <InputSecondaryText
                    outline
                    v-model="form.address"
                    :value="form.address"
                    :placeholder="lang.form.addressPlaceholder"
                    :rules="[rules.required]"
                  >{{ lang.form.addressLabel }} *</InputSecondaryText>
                </v-flex>
                <v-flex md12>
                  <InputSecondaryText
                    outline
                    v-model="form.email"
                    :value="form.email"
                    :rules="[rules.validEmail]"
                  >{{ lang.form.emailLabel }}</InputSecondaryText>
                </v-flex>
                <v-flex md6>
                  <InputSecondaryText
                    outline
                    v-model="form.phone"
                    :value="form.phone"
                    :rules="[rules.required]"
                  >{{ lang.form.phoneLabel }}*</InputSecondaryText>
                </v-flex>
                <v-flex md6>
                  <InputSecondaryText
                    outline
                    v-model="form.skypeTelegram"
                    :value="form.skypeTelegram"
                    :rules="[rules.required]"
                  >{{ lang.form.skypeLabel }}*</InputSecondaryText>
                </v-flex>
                <v-flex md6>
                  <InputSecondaryText
                      outline
                      v-model="form.shopLogin"
                      :value="form.shopLogin"
                      :rules="[rules.required]"
                  >{{ lang.form.shopLoginLabel }}*</InputSecondaryText>
                </v-flex>
                <v-flex md6>
                  <InputSecondaryText
                      outline
                      v-model="form.shopPassword"
                      :value="form.shopPassword"
                      :rules="[rules.required]"
                  >{{ lang.form.shopPasswordLabel }} *</InputSecondaryText>
                </v-flex>
              </v-layout>
            </div>

            <div>
              <p>{{ lang.help }}</p>
            </div>
          </div>
          <div slot="actions" class="inventory-stepper-actions">
            <ButtonSecondary
              small
              color="#d32d11"
              outline
              v-if="step > 1"
              @click:base-button="changeStep(--step, 'prev')"
            >Prev</ButtonSecondary>
            <div v-else></div>
            <ButtonPrimary
              small
              v-if="step !== 3"
              @click:base-button="changeStep(++step, 'next')"
              :disabled="isDisabled"
            >Next</ButtonPrimary>
            <ButtonPrimary
              small
              v-if="step === 3"
              @click:base-button="handleSubmitForm"
              :loading="isLoading"
              :disabled="isDisabled"
            >Submit</ButtonPrimary>
          </div>
        </PresentionalStepperForm>
      </v-flex>
    </v-layout>
    <div v-else>
      <h2 class="inventory-wizzard-success-title" v-text="successMsg"></h2>
    </div>
  </div>
</template>

<script>
import api from "@/api/paymentSolutions";
import { mapState, mapActions, mapGetters } from "vuex";
import { STATUS_CREATED } from "@/constants/httpCodes";

import PresentionalStepperForm from "@/components/PresentionalStepperForm";
import InputSecondaryText from "@/components/InputSecondaryText";
import InputSecondarySelect from "@/components/InputSecondarySelect";
import InputSecondaryDateOfBirthPicker from "@/components/InputSecondaryDateOfBirthPicker";
import ButtonSecondary from "@/components/ButtonSecondary";
import ButtonPrimary from "@/components/ButtonPrimary";
import RadioBorderBox from "@/components/RadioBorderBox";

export default {
  components: {
    PresentionalStepperForm,
    InputSecondaryText,
    RadioBorderBox,
    InputSecondarySelect,
    InputSecondaryDateOfBirthPicker,
    ButtonSecondary,
    ButtonPrimary
  },
  data() {
    return {
      showWizzard: true,
      step: 1,
      isLoading: false,
      isDisabled: true,
      customerLocatedList: [
        { name: "Worldwide", value: "worldwide" },
        { name: "Europe", value: "europe" },
        { name: "North America", value: "north_america" },
        { name: "Latin America", value: "latam" },
        { name: "Other", value: "other" }
      ],
      storePlatformsList: [{ name: "Shopify Store", value: "shopify_store" }],
      experienceList: [
        { name: "< 1 year", value: "0_1_year" },
        { name: "1-3 years", value: "1_3_years" },
        { name: "3-5 years", value: "3_5_years" }
      ],
      radios: {
        step2: [
          {
            label: "USD",
            value: "USD",
            icon: ""
          },
          {
            label: "EUR",
            value: "EUR",
            icon: ""
          }
        ]
      },
      headers: [
        {
          step: 1,
          title: "Shop information"
        },
        {
          step: 2,
          title: "Business information"
        },
        {
          step: 3,
          title: "Personal information"
        }
      ],
      contents: {
        step1: {
          title: "Shop information"
        },
        step2: {
          title: "Business information"
        },
        step3: {
          title: "Personal information"
        }
      },
      form: {
        salesChannel: null,
        specification: "",
        customersLocated: "",
        experience: "",
        typicalAmount: null,
        maxAmount: null,
        avgCountPerDay: null,
        actualMonthlyTurnover: null,
        plannedMonthlyTurnover: null,
        currency: {
          eur : "EUR",
          usd : "USD"
        },
        fullName: "",
        birthDate: "",
        country: "",
        address: "",
        email: "",
        phone: "",
        skypeTelegram: "",
        shopLogin: "",
        shopPassword: ""
      },
      validations: {}
    };
  },
  computed: {
    ...mapState({
      rules: state => state.validations.rules
    }),
    ...mapGetters({
      countries: "getCountries",
      salesChannels: "getSalesChannels"
    }),
    ...mapState({
      successMsg: state => state.errors.notifyMessages.wizzardSucces.toUpperCase()
    }),
    lang() {
      return {
        title: "Connect your shop to the payment systems",
        form: {
          salesChannelLabel: "Store",
          productUrlPlaceholder: "example.com",
          goodsLabel: "Specification of goods",
          customerLocatedLabel: "Your customers located",
          experienceLabel: "Your Dropshipping experience",
          typicalAmountLabel: "Typical transaction amount",
          maxAmountLabel: "Maximum transaction amount",
          avgCountPerDayLabel: "Average of transactions per day",
          actualMonthlyTurnoverLabel: "Actual monthly turnover",
          plannedMonthlyTurnoverLabel: "Planned monthly turnover",
          currenciesLabel: "Required transaction currencies",
          inUSDPlaceholder: "in USD",
          fullNameLabel: "Your full name",
          dateOfBirthLabel: "Date of birth",
          countryLabel: "Country of residence",
          addressLabel: "Residence address",
          emailLabel: "E-mail",
          phoneLabel: "Telephone number",
          skypeLabel: "Skype / Telegram contact",
          fullNamePlaceholder: "John Doe",
          shopLoginLabel: "Shopify / WooCommerce login",
          shopPasswordLabel: "Shopify / WooCommerce password"
        },
        help: "* - required",
        errors: {
          emptySalesChannels: "You need to connect a store"
        }
      };
    },
  },
  methods: {
    ...mapActions([
        "setNotifyMsg",
        "fetchSalesChannels"
    ]),
    changeStep(val, direction) {
      this.step = val;

      if (direction === "next") {
        this.isDisabled = true;
        this.checkIsDisabled(this.form);
      }

      if (direction === "prev") {
        this.isDisabled = false;
      }
    },
    onChangeSelect(val, key) {
      this.form[key] = val;
    },
    async handleSubmitForm() {
      this.isLoading = true;

      try {
        const { status } = await api.post(this.form);

        if (status === STATUS_CREATED) {
          this.$router.push({name: 'dashboard.payment-solutions'})
        }
      } catch (error) {
        this.setNotifyMsg({ text: error.response ? error.response.data.errors : 'Unhandled error', color: "error" });
      } finally {
        this.isLoading = false;
      }
    },
    checkIsDisabled(form) {
      if (this.step === 1) {
        this.isDisabled = !(
          form.salesChannel &&
          form.specification &&
          form.customersLocated
        );
      }

      if (this.step === 2) {
        if (
          form.experience &&
          form.currency.length &&
          !this.rules.isNumber(form.typicalAmount) &&
          !this.rules.isNumber(form.maxAmount) &&
          !this.rules.isNumber(form.avgCountPerDay) &&
          !this.rules.isNumber(form.actualMonthlyTurnover) &&
          !this.rules.isNumber(form.plannedMonthlyTurnover) &&
          !this.rules.isAbsNumber(form.typicalAmount) &&
          !this.rules.isAbsNumber(form.maxAmount) &&
          !this.rules.isAbsNumber(form.avgCountPerDay) &&
          !this.rules.isAbsNumber(form.actualMonthlyTurnover) &&
          !this.rules.isAbsNumber(form.plannedMonthlyTurnover)
        ) {
          this.isDisabled = false;
        } else {
          this.isDisabled = true;
        }
      }

      if (this.step === 3) {
        if (
          form.fullName &&
          form.birthDate &&
          form.address &&
          form.country &&
          form.skypeTelegram &&
          form.phone &&
          form.shopLogin &&
          form.shopPassword
        ) {
          this.isDisabled = false;
        } else {
          this.isDisabled = true;
        }
      }
    }
  },
  watch: {
    form: {
      deep: true,
      immediate: true,
      handler(form) {
        this.checkIsDisabled(form);
      }
    }
  },
  created() {
    this.fetchSalesChannels();
  },
  mounted() {
    this.$root.$on("hide-wizzard", () => {
      this.showWizzard = false;
    });
  },
  beforeDestroy() {
    this.$off();
  }
};
</script>

<style scoped lang="scss">
.inventory-wizzard {
  max-width: $max-width;

  &__title {
    margin-bottom: 32px;
    max-width: 30%;
    font-size: 30px;
    line-height: 32px;

    @include breakpoint($m) {
      max-width: 70%;
    }
  }

  .inventory-wizzard-success-title {
    color: #979797;
    font-size: 30px;
    line-height: 32px;
    text-align: center;
    margin-top: 100px;
  }

  .custom-label {
    font-size: 12px;
    line-height: 22px;
    color: $text-color;
  }

  /deep/ .checkbox-box {
    margin-bottom: 10px !important;
  }
}
</style>
