<template>
  <div class="bootstrap">
    <div class="text-editor-holder">
      <textarea class="form-control"></textarea>
    </div>
  </div>
</template>

<script>
window.jQuery = require("jquery");
var $ = window.jQuery;
require("codemirror");
require("bootstrap");
require("summernote");

require("summernote/dist/summernote.css");
require("codemirror/lib/codemirror.css");
require("codemirror/theme/monokai.css");

export default {
  components: {},
  props: {
    model: {
      required: true
    },
    height: {
      type: String,
      default: "450"
    }
  },
  data() {
    return {
      conentModel: this.model
    };
  },
  mounted() {
    let config = {
      height: this.height,
      codemirror: {
        theme: "monokai"
      }
    };
    let vm = this;
    config.callbacks = {
      onInit: function() {
        $(vm.$el).summernote("code", vm.conentModel);
      },
      onChange: function() {
        vm.$emit("update:model", $(vm.$el).summernote("code"));
      }
    };
    $(this.$el).summernote(config);
  }
};
</script>

<style lang="scss">
span,
p {
  color: inherit !important;
}
.panel-heading.note-toolbar {
  z-index: auto;
  position: relative !important;
}
.note-editor {
  z-index: 1;
  &.note-frame {
    &.fullscreen {
      z-index: 3000;
    }
  }
}
</style>
