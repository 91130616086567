<template>
  <div class="sub-accounts">
    <h4 class="sub-accounts__title">{{ lang.title }}</h4>
      <TableSubAccounts />
  </div>
</template>

<script>

import TableSubAccounts from "@/components/Tables/TableSubAccounts";

export default {
  components: {
    TableSubAccounts
  },
  computed: {
    lang() {
      return {
        title: "Withdraw money",
        withdrawButton: "To my balance"
      };
    },
  },
};
</script>

<style lang="scss">
  .sub-accounts {
    &__title {
      font-size: 18px !important;
      color: #222528;
      margin-bottom: 20px !important;
    }
  }
</style>
