<template>
  <v-container class="pa-0 no-width base-table--filters" grid-list-md @enter.prevent="handleSearch">
    <v-layout wrap>
      <v-flex xs12 md3 class="base-table--filters__item">
        <InputDateTime
          @change:datetimepicker="handleDatepickerChange"
          :startedDate="startedDate"
          :key="keys.date"
        >{{ lang.period }}</InputDateTime>
      </v-flex>
      <v-flex xs12 sm6 md2 class="base-table--filters__item">
        <SearchAutocomplete
          :label="lang.product"
          :items="searchedProducts"
          @input:search-autocomplete="handleSearchProducts($event)"
          @change="onChangeProductNameSearch"
          @click:clear="clearProductNameSearch"
          cache-items
          allow-overflow
          hide-no-data
          dense
          hide-append-icon
          dont-fill-mask-blanks
        ></SearchAutocomplete>
      </v-flex>
      <v-flex xs12 sm6 md3 class="base-table--filters__item">
        <InputAutocomplete
          multiple
          clearable
          v-if="countries"
          :items="countries"
          :label="lang.country"
          @change="handleSelectedCountries"
          item-text="niceName"
          item-value="iso2"
          :selected="filters.countries"
          :key="keys.countries"
        />
      </v-flex>
      <v-flex xs12 md4>
        <ButtonPrimary
          small
          @click:base-button="handleSearch"
          class="base-table--filters__button"
        >Filter</ButtonPrimary>
        <ButtonPrimary
          small
          outline
          @click:base-button="handleResetFilters"
          class="base-table--filters__button button-reset"
        >Reset filters</ButtonPrimary>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";

import ButtonPrimary from "@/components/ButtonPrimary";
import InputDateTime from "@/components/InputDateTime";
import InputAutocomplete from "@/components/InputAutocomplete";
import SearchAutocomplete from "@/components/SearchAutocomplete";

export default {
  components: {
    ButtonPrimary,
    InputDateTime,
    InputAutocomplete,
    SearchAutocomplete
  },
  data() {
    return {
      startedDate: {
        start: null,
        end: null
      },
      filters: {
        productName: null,
        dateFrom: null,
        dateTo: null,
        countries: []
      },
      isInitPage: true,
      keys: {
        countries: +new Date(),
        date: +new Date()
      },
      currentProductName: null
    };
  },
  computed: {
    ...mapState({
      searchedProducts: state => state.statistics.productsAutocomplete
    }),
    ...mapGetters({
      countries: "getCountries"
    }),
    lang() {
      return {
        product: "Product",
        period: "Period",
        country: "Country"
      };
    },
    salesChannelId() {
      return this.$salesChannel.get();
    }
  },
  methods: {
    ...mapActions(["fetchProductsStatisticsAutocomplete"]),
    handleSearch() {
      if (this.isInitPage) {
        return;
      }

      this.$router.replace({ query: { ...this.filters } });
      this.$emit("click:search-btn", this.filters);
    },
    handleProductNameValue(val) {
      this.filters.productName = val;
    },
    handleDatepickerChange({ start, end }) {
      this.filters.dateFrom = start;
      this.filters.dateTo = end;
      this.$filters.setProductsStatisticDateFilter(start, end);
      this.handleSearch();
    },
    handleSelectedCountries(val) {
      this.filters.countries = val;
    },
    initCountryFilter() {
      const coutriesFilter = this.$filters.getProductsStatisticCountryFilter();
      let queryCoutriesFilter = this.$route.query.countries;

      if (typeof queryCoutriesFilter === "undefined") {
        queryCoutriesFilter = coutriesFilter ? coutriesFilter.split(", ") : [];
      } else if (typeof queryCoutriesFilter === "string") {
        queryCoutriesFilter = [queryCoutriesFilter];
      }

      this.keys = { date: +new Date(), countries: +new Date() };

      this.filters = {
        ...this.filters,
        ...this.$route.query,
        countries: queryCoutriesFilter
      };
    },
    handleResetFilters() {
      const start = window
        .moment()
        .startOf("month")
        .format("YYYY-MM-DD");
      const end = window
        .moment()
        .endOf("month")
        .format("YYYY-MM-DD");

      this.keys = { date: +new Date(), countries: +new Date() };
      this.startedDate = {
        start,
        end
      };
      this.filters = {
        dateFrom: start,
        dateTo: end,
        countries: [],
        productName: ""
      };
      this.$filters.setProductsStatisticCountryFilter("");
    },
    async handleSearchProducts(productName) {
      if (!productName || this.currentProductName === productName) {
        return;
      }

      const newFilters = {
        ...this.filters,
        productName,
        page_size: 5,
        salesChannel: this.salesChannelId
      };

      this.fetchProductsStatisticsAutocomplete(newFilters);
    },
    onChangeProductNameSearch(productName) {
      if (typeof productName === "undefined") {
        return;
      }

      this.currentProductName = productName;
      this.filters = { ...this.filters, productName };
      this.handleSearch();
    },
    clearProductNameSearch() {
      this.filters = { ...this.filters, productName: "" };
      this.handleSearch();
    },
    setStartedDate() {
      const dateFrom = this.$route.query.dateFrom;
      const dateTo = this.$route.query.dateTo;
      var result = { start: dateFrom, end: dateTo };

      if (typeof dateFrom === "undefined" && typeof dateTo === "undefined") {
        result = { ...this.$filters.getProductsStatisticDateFilter() };
      }

      this.startedDate = result;
    }
  },
  watch: {
    filters: {
      handler() {
        if (this.isInitPage) {
          this.isInitPage = false;
          this.handleSearch();
        }
      }
    }
  },
  created() {
    this.initCountryFilter();
    this.setStartedDate();
  },
  beforeDestroy() {
    this.$off();
  }
};
</script>

<style scoped>
</style>
