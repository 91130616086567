<template>
  <div class="card-details">
    <h6 class="card-details__title">{{ title }}</h6>
    <!-- <div v-for="(item, k) in data" :key="k" class="card-details__wrap">
      <div class="card-details__key">{{ k }}</div>
      <div class="card-details__value" v-html="item"></div>
    </div>-->
    <table class="card-details__table">
      <tr v-for="(item, k) in data" :key="k" class="card-details__table__tr">
        <td class="card-details__table__key">{{ k }}</td>
        <td class="card-details__table__value" v-html="item"></td>
      </tr>
    </table>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true
    },
    data: {
      type: Object,
      required: true
    }
  }
};
</script>

<style scoped lang="scss">
.card-details {
  padding: 20px 30px;
  background-color: #fff;
  @include breakpoint($m) {
    padding: 15px 10px;
  }

  .card-details__title {
    font-size: 18px;
    color: $text-sidebar-color;
    padding-bottom: 5px;
    border-bottom: 1.5px solid $body-color;
    margin-bottom: 20px;
  }

  .card-details__table {
    min-width: 100%;
    overflow-x: auto;
    @include breakpoint($m) {
      display: block;
      overflow: visible;
    }

    .card-details__table__tr {
      margin: 5px 0;
      @include breakpoint($m) {
        margin: 0;
        display: block;
      }
    }

    .card-details__table__key {
      font-size: 14px;
      color: $text-sidebar-color;
      @include breakpoint($m) {
        display: inline;
        margin: 0 5px 0 0;
        line-height: 25px;
      }
    }

    .card-details__table__value {
      font-size: 14px;
      color: $text-color;
      @include breakpoint($m) {
        display: inline;
        line-height: 25px;
      }
    }
  }
}
</style>
