import api from '@/api/shipmentMethods';

import {
  STATUS_OK
} from '@/constants/httpCodes';

export default {
  state: {
    shipmentMethods: null,
    selectedISO2: null,
    selectedShipmentMethod: null,
    defaultShipmentMethods: [{
        name: 'China',
        iso2: 'CN',
      },
      {
        name: 'Russian Federation',
        iso2: 'RU'
      },
      {
        name: 'United States',
        iso2: 'US'
      },
      {
        name: 'Germany',
        iso2: 'DE'
      },
      {
        name: 'Spain',
        iso2: 'ES'
      },
      {
        name: 'Australia',
        iso2: 'AU'
      },
      {
        name: 'United Kingdom',
        iso2: 'GB'
      },
      {
        name: 'Australia',
        iso2: 'AU'
      },
      {
        name: 'France',
        iso2: 'FR'
      },
      {
        name: 'Czech Republic',
        iso2: 'CZ'
      },
      {
        name: 'Italy',
        iso2: 'IT'
      },
      {
        name: 'Ukraine',
        iso2: 'UA'
      },
      {
        name: 'Poland',
        iso2: 'PL'
      },
      {
        name: 'Indonesia',
        iso2: 'ID'
      },
      {
        name: 'Brazil',
        iso2: 'BR'
      },
      {
        name: 'Turkey',
        iso2: 'TR'
      },
      {
        name: 'Israel',
        iso2: 'IL'
      },
      {
        name: 'America',
        iso2: 'US'
      },
      {
        name: 'Russia',
        iso2: 'RU'
      },
    ]
  },
  getters: {
    getShipmentMethods: state => state.shipmentMethods,
    getSelectedISO2: state => state.selectedISO2,
    getDefaultShipmentMethodByISO2: state => iso => state.defaultShipmentMethods.filter(item => {
      return item.iso2 === iso;
    })[0],
    getDefaultShipmentMethodByName: state => name => state.defaultShipmentMethods.filter(item => {
      return item.name === name;
    })[0],
  },
  mutations: {
    SET_SHIPMENT_METHODS_DATA(state, payload) {
      state.shipmentMethods = payload;
    },
    SET_SELECTED_ISO_DATA(state, payload) {
      state.selectedISO2 = payload;
    },
  },
  actions: {
    fetchShipmentMethods({
      commit,
      dispatch
    }, payload) {
      dispatch('setIsProgressData', true);
      api.get(payload)
        .then(({
          data: {
            items
          },
          status
        }) => {
          if (status === STATUS_OK) {
            commit('SET_SHIPMENT_METHODS_DATA', items);
          }
        })
        .finally(() => {
          dispatch('setIsProgressData', false);
        })
    },
  },
};
