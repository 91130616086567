<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="width"
    :height="height"
    fill="none"
    :viewBox="`0 0 ${width} ${height}`"
  >
    <g :fill="stroke" clip-path="url(#a)">
      <path
        d="M24.156 18.323h-4.62a2.295 2.295 0 0 0-2.293 2.293v3.543a2.295 2.295 0 0 0 2.293 2.293h4.62a2.295 2.295 0 0 0 2.292-2.293v-3.543a2.295 2.295 0 0 0-2.292-2.293zm.625 5.836a.627.627 0 0 1-.625.626h-4.62a.627.627 0 0 1-.626-.626v-3.543c0-.347.285-.625.626-.625h4.62c.347 0 .625.285.625.625v3.543z"
      ></path>
      <path
        d="M34 11.883a.773.773 0 0 0-.111-.41L28.83 2.581a.838.838 0 0 0-.722-.424H5.884a.824.824 0 0 0-.722.424l-5.05 8.892a.843.843 0 0 0-.112.41 4.877 4.877 0 0 0 2.918 4.46V31.01c0 .459.375.834.834.834H30.24a.836.836 0 0 0 .834-.834V16.448c0-.035 0-.063-.007-.09A4.89 4.89 0 0 0 34 11.882zM6.37 3.831h21.252l3.953 6.955H2.425L6.37 3.83zm17.827 8.615a3.202 3.202 0 0 1-3.154 2.64 3.202 3.202 0 0 1-3.154-2.64h6.308zm-8.08 0a3.202 3.202 0 0 1-3.153 2.64 3.215 3.215 0 0 1-3.161-2.64h6.315zm-14.394 0h6.315a3.21 3.21 0 0 1-3.161 2.64 3.196 3.196 0 0 1-3.154-2.64zm12.283 17.73H9.212v-8.997c0-.653.528-1.188 1.188-1.188h2.425c.653 0 1.188.528 1.188 1.188v8.997h-.007zm15.402 0H15.673v-8.997a2.86 2.86 0 0 0-2.855-2.856h-2.425a2.86 2.86 0 0 0-2.855 2.856v9.003H4.585V16.753a4.87 4.87 0 0 0 4.335-2.147 4.87 4.87 0 0 0 8.087 0 4.858 4.858 0 0 0 8.073 0 4.87 4.87 0 0 0 4.043 2.154c.097 0 .188-.007.285-.007v13.422zm-.285-15.083a3.202 3.202 0 0 1-3.154-2.64h6.315a3.222 3.222 0 0 1-3.161 2.64z"
      ></path>
    </g>
    <defs>
      <clipPath id="a">
        <path :fill="fill" d="M0 0h34v34H0z"></path>
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  props: {
    width: {
      type: [Number, String],
      default: 34
    },
    height: {
      type: [Number, String],
      default: 34
    },
    fill: {
      type: String,
      default: "none"
    },
    stroke: {
      type: String,
      default: "#000"
    }
  }
};
</script>

<style scoped>
</style>
