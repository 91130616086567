import axios from 'axios';
import BaseApi from './base';
import {GDPR_REQUESTS} from "@/constants/api";

export default class extends BaseApi {
  static async getAll(salesChannelId) {
    return axios.get(this.baseURI + GDPR_REQUESTS + '/' + salesChannelId)
  }

  static async download(id) {
    return axios({
      url: this.baseURI + `gdpr-requests/download/${id}`,
      method: 'GET',
      responseType: 'blob'
    });
  }
}
