export default {
  state: {
    error: {
      text: null,
      color: 'error'
    },
    notifyMessages: {
      success: 'Success!',
      editProductSuccess: 'Your product has been successfully saved',
      somethingWrong: 'Something wrong',
      serverError: 'Server error',
      notValidUrl: 'Not valid URL',
      emailNotFound: 'This email not found',
      resetPasswordSuccess: 'We sent you a password reset email',
      notValidUser: 'Incorrect email or password',
      importProductSuccess: 'The product puts in a queue for export',
      importProductsSuccess: 'Products put in a queue for export',
      removeMyProductSuccess: 'The product has been successfully removed',
      removeMyProductsSuccess: 'Products have been successfully removed',
      exportCVSSuccess: 'File successfully downloaded',
      noValidTransferAmount: 'The amount must be at least 10.00',
      notEqualPassword: 'New password values are not equal',
      updatingOnShopify: 'Updating info in Shopify, saved canceled. Please, try later',
      emailCopied: 'Email copied',
      chatCodeCopied: 'Chat code copied',
      accountSuccess: 'Your account has been successfully updated',
      validationError: 'Validation Error. Check your form',
      markAsReadNotification: 'Notifications has been successfully marked as read',
      wizzardSucces: "Thanks for your request! Please, wait for our manager reply",
      accessDeniedMyProducts: 'Export product to store failed! Try again or contact support',
      warningImportMsg: 'These products have already been imported',
      payoutAccountsSuccess: 'Payout accounts has been successfully saved',
      payoutNewRequest: 'New request has been successfully created',
      removePayoutAccount: 'Payout account has been successfully removed',
      removeSalesChannel: 'The sales channel has been successfully removed',
      telegramBotOk: 'You successfully attached chat with your account',
      telegramBotNotFound: 'Chat not found or already attached',
      supportEmailCreated: 'The support email has been successfully created',
      salesChanelNotFound: 'This sales channel not found',
      subAccountNotFound: 'Sub account not found',
      salesChannelStoreUrlNotFound: 'Store auth url not found',
    },
    productMessages: {
      success: 'Product successfully added!',
      successAlt: 'Your products will appear in a few seconds after loading in my products section',
      importToStoreInfoMsg: 'These products will appear in a few seconds after loading in store',
      somethingWrong: 'Something wrong',
      successFileUpload: 'File successfully imported'
    }
  },
  getters: {
    getError: state => state.error,
  },
  mutations: {
    SET_ERROR(state, {
      text,
      color
    }) {
      state.error = {
        ...state.error,
        text,
        color
      };
    },
  },
  actions: {
    setNotifyMsg({
      commit,
    }, payload) {
      commit('SET_ERROR', payload);
    },
  },
};
