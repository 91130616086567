import api from '@/api/dashboard';

import {
  STATUS_OK
} from '@/constants/httpCodes';

export default {
  state: {
    dashboard: null,
  },
  getters: {
    getDashboard: state => state.dashboard,
  },
  mutations: {
    SET_DASHBOARD_DATA(state, payload) {
      state.dashboard = payload;
    },
  },
  actions: {
    fetchDashboard({
      commit,
      dispatch
    }, payload) {
      dispatch('setIsProgressData', true);
      api.get(payload)
        .then(({
          data,
          status
        }) => {
          if (status === STATUS_OK) {
            commit('SET_DASHBOARD_DATA', data);
          }
        })
        .finally(() => {
          dispatch('setIsProgressData', false);
        })
    },
  },
};
