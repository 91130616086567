import { render, staticRenderFns } from "./FilterProductsStatistics.vue?vue&type=template&id=f1b7c7b2&scoped=true&"
import script from "./FilterProductsStatistics.vue?vue&type=script&lang=js&"
export * from "./FilterProductsStatistics.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f1b7c7b2",
  null
  
)

component.options.__file = "FilterProductsStatistics.vue"
export default component.exports