import { render, staticRenderFns } from "./TableTickets.vue?vue&type=template&id=24491e46&scoped=true&"
import script from "./TableTickets.vue?vue&type=script&lang=js&"
export * from "./TableTickets.vue?vue&type=script&lang=js&"
import style0 from "./TableTickets.vue?vue&type=style&index=0&id=24491e46&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "24491e46",
  null
  
)

component.options.__file = "TableTickets.vue"
export default component.exports