<template>
  <div class="start">
    <h2 class="start__title">{{ lang.title }}</h2>
    <h4 class="start__subtitle">{{ lang.subtitle }}</h4>
    <div class="start-block" v-for="(block, k) in blocks" :key="k">
      <div class="start-block__title-holder">
        <span class="start-block__ico">
          <component v-if="block.icon" :is="'icon-' + block.icon" height="34" width="34"/>
        </span>
        <h5 class="start-block__title">{{ block.title }}</h5>
      </div>
      <div class="start-block__btn">
        <ButtonPrimary outline small @click:base-button="block.btnAction">{{ block.button }}</ButtonPrimary>
      </div>
    </div>
  </div>
</template>

<script>
import ButtonPrimary from "@/components/ButtonPrimary";

import IconShop from "@/components/Icons/IconShop";
import IconTag from "@/components/Icons/IconTag";
import IconCoin from "@/components/Icons/IconCoin";

const SHOPIFY_WEBSITE = "https://www.shopify.com";

export default {
  components: {
    ButtonPrimary,
    IconShop,
    IconTag,
    IconCoin
  },
  data() {
    return {
      blocks: [
        {
          title: "Create a Shopify store",
          button: "Create a store",
          icon: "shop",
          btnAction: () => {
            return window.open(SHOPIFY_WEBSITE, "_blank");
          }
        },
        {
          title: "Connect your Shopify store",
          button: "Connect a store",
          icon: "shop",
          btnAction: () => {
            return this.$router.push({ name: "dashboard.store-management" });
          }
        },
        {
          title: "Find and add your first product",
          button: "Find products",
          icon: "tag",
          btnAction: () => {
            return this.$router.push({ name: "dashboard.products.search" });
          }
        },
        {
          title: "Connect payment system to your Store",
          button: "Connect a payment system",
          icon: "coin",
          btnAction: () => {
            return this.$router.push({ name: "dashboard.payment-solutions" });
          }
        }
      ]
    };
  },
  computed: {
    lang() {
      return {
        title: "Build your business with Ecombo",
        subtitle: "Let’s get started!"
      };
    }
  }
};
</script>

<style scoped lang="scss">
.start {
  .start__title {
    font-size: 30px;
    line-height: 32px;
    margin-bottom: 15px;
  }
  .start__subtitle {
    font-size: 18px;
    color: $text-sidebar-color;
    line-height: 30px;
    margin-bottom: 15px;
  }
  .start-block {
    display: flex;
    background: #fff;
    padding: 12px 19px;
    align-items: center;
    margin-bottom: 10px;
    justify-content: space-between;
    @include breakpoint($m) {
      display: block;
      text-align: center;
      padding-top: 19px;
      padding-bottom: 23px;
    }

    &__title-holder {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      @include breakpoint($m) {
        display: block;
        margin: 0 0 6px;
      }
    }

    &__ico {
      width: 34px;
      flex-shrink: 0;
      margin: 10px 25px 0 0;
      @include breakpoint($m) {
        margin: 0 0 12px;
      }
    }

    &__title {
      font-size: 14px;
      line-height: 1.2;
    }

    &__btn {
    }
  }
}
</style>
