import api from '@/api/version';

import {
  STATUS_OK
} from '@/constants/httpCodes';

export default {
  state: {
    version: null,
  },
  getters: {
    getVersion: state => state.version,
  },
  mutations: {
    SET_VERSION_DATA(state, payload) {
      state.version = payload;
    },
    CLEAR_VERSION_DATA(state) {
      state.version = null;
    },
  },
  actions: {
    fetchVersion({
      commit,
    }, payload) {
      api.get(payload)
        .then(({
          status,
          data: {
            spa
          }
        }) => {
          if (status === STATUS_OK) {
            commit('SET_VERSION_DATA', spa);
          }
        })
        .finally(() => {})
    },
    clearVersion({
      commit
    }) {
      commit('CLEAR_VERSION_DATA');
    }
  },
};
