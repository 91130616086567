export const ROLE_SUPER_ADMIN = 'ROLE_SUPER_ADMIN';
export const ROLE_SHOPIFY_ADMIN = 'ROLE_SHOPIFY_ADMIN';
export const ROLE_USER = 'ROLE_USER';
export const ROLE_API = 'ROLE_API';
export const ROLE_SUPPORT_MANAGER = 'ROLE_SUPPORT_MANAGER';
export const ROLE_FINANCIAL_MANAGER = 'ROLE_FINANCIAL_MANAGER';
export const ROLE_DEPARTMENT_ADMIN = 'ROLE_DEPARTMENT_ADMIN';
export const ROLE_SUB_ACCOUNT = 'ROLE_SUB_ACCOUNT';
export const ROLE_DROPSHIP = 'ROLE_DROPSHIP';
export const ROLE_TRANSLATOR = 'ROLE_TRANSLATOR';
export const ROLE_PURCHASER = 'ROLE_PURCHASER';
export const ROLE_LOGIST = 'ROLE_LOGIST';
export const ROLE_ALLOWED_TO_SWITCH = 'ROLE_ALLOWED_TO_SWITCH';
