<template>
  <div class="table-transactions" v-if="items">
    <div class="table-transactions__filters-panel">
      <div name="filters" class="base-table--filters">
        <FilterTransactions @click:search-btn="handleSearch"/>
      </div>
      <div class="exports" v-if="items && items.length">
        <ExportTable :items="items" :fields="exportCSV.fields" name="balance.xls"/>
      </div>
    </div>
    <BaseTable
      v-if="paginationsLinks"
      class="table-transactions__content"
      :headers="headers"
      :items="items"
      show-pagination
      show-page-size
      :custom-pagination="paginationsLinks"
    ></BaseTable>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapState } from "vuex";
import * as constants from "@/constants/transactions";

import FilterTransactions from "@/components/Filters/FilterTransactions";
import ExportTable from "@/components/ExportTable";

export default {
  components: {
    FilterTransactions,
    ExportTable
  },
  data() {
    return {
      items: [],
      headers: [
        { text: "ID", value: "id" },
        { text: "Date Billed", value: "updatedAt" },
        { text: "Note", value: "note" },
        { text: "Amount Billed ($)", value: "totalSum" },
        { text: "Status", value: "status" }
      ],
      paymentMethods: {
        [constants.PAYMENT_PAYPAL]: "PayPal",
        [constants.PAYMENT_CARD]: "Bank Card"
      },
      statuses: {
        [constants.STATUS_PENDING]: "Pending",
        [constants.STATUS_APPLIED]: "Applied",
        [constants.STATUS_CANCELED]: "Canceled",
        [constants.STATUS_FAILED]: "Failed"
      },
      exportCSV: {
        fields: {}
      }
    };
  },
  computed: {
    ...mapState({
      exportCVSSuccessMsg: state => state.errors.notifyMessages.exportCVSSuccess
    }),
    ...mapGetters({
      transactions: "getTransactions",
      paginationsLinks: "getTransactionsPagination"
    }),
    lang() {
      return {};
    }
  },
  methods: {
    ...mapActions(["fetchTransactions", "setNotifyMsg"]),
    fetchTransactionsWithFilters(filters = {}) {
      const newFilters = {
        page: 1,
        page_size: 20,
        ...filters,
        ...this.$route.query
      };

      this.fetchTransactions(newFilters);
    },
    handleSearch(filters) {
      this.fetchTransactionsWithFilters(filters);
    },
    setExportCSVHeaders() {
      this.headers.map(({ text, value }) => {
        this.exportCSV.fields[text + ""] = value;
      });
    },
    finishDownloadCSV() {
      this.setNotifyMsg({ text: this.exportCVSSuccessMsg, color: "success" });
    },
    getAmount(type, amount) {
      if (
        type === constants.TYPE_RECHARGE_BALANCE ||
        type === constants.TYPE_ORDER_PAYMENT_REFUND ||
        type === constants.TYPE_ORDER_PROFIT ||
        type === constants.TYPE_MANUAL_PLUS ||
        type === constants.TYPE_REFERRER_FEE_PLUS ||
        type === constants.TYPE_TRANSFER_TO_BALANCE
      ) {
        return amount;
      } else {
        return "-" + amount;
      }
    }
  },
  watch: {
    transactions: {
      handler(transactions) {
        const statusTag = status => {
          switch (status) {
            case constants.STATUS_APPLIED:
              return "success";
            case constants.STATUS_CANCELED:
              return "danger";
            case constants.STATUS_FAILED:
              return "danger";
            case constants.STATUS_PENDING:
              return "neutral";
            default: {
              return "neutral";
            }
          }
        };

        this.items = transactions.map(item => {
          return {
            id: item.id,
            updatedAt: window.moment(item.updatedAt).format("LLL"),
            note: item.note,
            totalSum: this.getAmount(item.type, item.totalSum.toFixed(2)),
            status: `<div class="tag-status tag-status--${statusTag(
              item.status
            )}">${this.statuses[item.status]}</div>`
          };
        });
      }
    },
    "$route.query": {
      handler(val) {
        if (val.page || val.page_size) {
          this.fetchTransactionsWithFilters();
        }
      }
    },
    headers: {
      immediate: true,
      handler() {
        this.setExportCSVHeaders();
      }
    }
  },
  mounted() {
    this.$root.$on("update:transactions", () =>
      this.fetchTransactionsWithFilters()
    );
  },
  beforeDestroy() {
    this.$root.$off("update:transactions");
  }
};
</script>

<style lang="scss">
.balance-history__title {
  margin: 0 0 20px;
}
.table-transactions {
  &__filters-panel {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    @include breakpoint($t) {
      display: block;
    }
    .base-table--filters {
      width: 100%;
      margin-top: 0;
    }
  }
}
.table-transactions__content {
  white-space: nowrap;
}
</style>
