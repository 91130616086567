<template>
  <div class="referrals">
    <h4 class="referrals__title">{{ lang.title }}</h4>
    <v-flex xs12 sm8 md5 class="base-table--filters__item email-input-holder">
      <InputTextCopy :value="referralLink" :readonly=true>
        Referral link
      </InputTextCopy>
    </v-flex>
    <TableReferrals/>
  </div>
</template>

<script>
  import TableReferrals from "@/components/Tables/TableReferrals";
  import InputTextCopy from "@/components/InputTextCopy";
  import { MAIN_DOMAIN } from "@/constants/main";
  import md5 from 'md5';
  import {mapActions, mapGetters} from "vuex";

  export default {
    components: {
      InputTextCopy,
      TableReferrals
    },
    computed: {
      ...mapGetters({
        currentUser: "getCurrentUser"
      }),
      lang() {
        return {
          title: "Referrals"
        };
      },
      referralLink() {
        const hash = md5(String(this.currentUser && this.currentUser.id));
        return 'http://' + MAIN_DOMAIN + '/?refid=' + hash;
      }
    },
    methods: {
      ...mapActions([
        'getCurrentUser'
      ])
    }
  };
</script>

<style lang="scss">
  .referrals {
    &__title {
      font-size: 18px !important;
      color: #222528;
      margin-bottom: 20px !important;
    }
  }
</style>
