export const STATUS_PENDING = 1;
export const STATUS_APPLIED = 2;
export const STATUS_CANCELED = 3;
export const STATUS_FAILED = 4;

export const PAYMENT_PAYPAL = 1;
export const PAYMENT_CARD = 3;

// Пополнение баланса
export const TYPE_RECHARGE_BALANCE = 1;
// Оплата заказа
export const TYPE_ORDER_PAYMENT = 2;
// Списание с баланса после перерасчета за заказ
export const TYPE_ADDITIONAL_ORDER_PAYMENT = 3;
// Пополнение баланса за заказ после перерасчета себестоимости
export const TYPE_ORDER_PAYMENT_REFUND = 4;
// Списание за рефанд по заказу
export const TYPE_REFUND = 5;
// Списание за запрос на выплату
export const TYPE_PAYOUT = 6;
// Прибыль по заказу на баланс
export const TYPE_ORDER_PROFIT = 7;
// Оплата за подключение нашей платежки
export const TYPE_CONNECT_ECOMBO = 8;
// Ручное пополнение
export const TYPE_MANUAL_PLUS = 9;
// Ручное списание
export const TYPE_MANUAL_MINUS = 10;

// Обмен товара из существующего списка
export const TYPE_ORDER_PRODUCT_REPLACEMENT = 11;

// Зачисление партнерской комиссии
export const TYPE_REFERRER_FEE_PLUS = 12;

// Списание партнерской комиссии
export const TYPE_REFERRER_FEE_MINUS = 13;

// Списание всех денег с баланса
export const TYPE_TRANSFER_FROM_BALANCE = 14;

// Начисление всех денег на баланс
export const TYPE_TRANSFER_TO_BALANCE = 15;
