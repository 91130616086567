import axios from 'axios';
import BaseApi from './base';
import {
  TICKETS,
  STATISTICS,
  CHAT_SCRIPTS
} from '@/constants/api';

export default class extends BaseApi {
  static get(filters = null) {
    return new Promise((resolve, reject) => {
      return axios.get(this.baseURI + TICKETS, {
          params: filters
        })
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  static getStatistics(filters = null) {
    return new Promise((resolve, reject) => {
      return axios.get(this.baseURI + TICKETS + '/' + STATISTICS, {
          params: filters
        })
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  static getById(id, filters = null) {
    return new Promise((resolve, reject) => {
      return axios.get(this.baseURI + TICKETS + '/' + id, {
          params: filters
        })
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  static getChatScripts() {
    return new Promise((resolve, reject) => {
      return axios.get(this.baseURI + TICKETS + '/' + CHAT_SCRIPTS)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  }

}
