export default function (Vue) {
  Vue.filters = {
    setProductsStatisticDateFilter(start, end) {
      localStorage.setItem('date_products_statistic_start', start);
      localStorage.setItem('date_products_statistic_end', end);
    },

    setOrdersStatisticDateFilter(start, end) {
      localStorage.setItem('date_orders_statistic_start', start);
      localStorage.setItem('date_orders_statistic_end', end);
    },

    setOrdersStatisticGroupByFilter(groupBy) {
      localStorage.setItem('group_by_orders_statistic', groupBy);
    },

    setOrdersStatisticProductFilter(product) {
      localStorage.setItem('product_orders_statistic', product);
    },

    setProductsStatisticProductFilter(product) {
      localStorage.setItem('product_products_statistic', product);
    },

    setProductsStatisticCountryFilter(product) {
      localStorage.setItem('country_products_statistic', product);
    },

    setOrdersStatisticCountryFilter(product) {
      localStorage.setItem('country_orders_statistic', product);
    },

    getProductsStatisticDateFilter() {
      return {
        start: localStorage.getItem('date_products_statistic_start'),
        end: localStorage.getItem('date_products_statistic_end'),
      }
    },

    getOrdersStatisticDateFilter() {
      return {
        start: localStorage.getItem('date_orders_statistic_start'),
        end: localStorage.getItem('date_orders_statistic_end'),
      }
    },

    getOrdersStatisticGroupByFilter() {
      return localStorage.getItem('group_by_orders_statistic');
    },

    getOrdersStatisticProductFilter() {
      return localStorage.getItem('product_orders_statistic');
    },

    getProductsStatisticProductFilter() {
      return localStorage.getItem('product_products_statistic');
    },

    getProductsStatisticCountryFilter() {
      return localStorage.getItem('country_products_statistic');
    },

    getOrdersStatisticCountryFilter() {
      return localStorage.getItem('country_orders_statistic');
    },

  }

  Object.defineProperties(Vue.prototype, {
    $filters: {
      get() {
        return Vue.filters;
      }
    }
  })
}
