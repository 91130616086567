import api from "@/api/paymentSolutions";

import {
    STATUS_OK
} from '@/constants/httpCodes';

export default {
    state: {
        paymentSolutions: null
    },
    getters: {
        getPaymentSolutions: state => state.paymentSolutions
    },
    mutations: {
        SET_PAYMENT_SOLUTIONS_DATA(state, payload) {
            state.paymentSolutions = payload;
        }
    },
    actions: {
        fetchPaymentSolutions({
            commit,
            dispatch
        }, payload) {
            dispatch('setIsProgressData', true);
            api.get(payload)
                .then(({
                    data: {
                        items,
                    },
                    status
                }) => {
                    if (status === STATUS_OK) {
                        commit('SET_PAYMENT_SOLUTIONS_DATA', items);
                    }
                })
                .finally(() => {
                    dispatch('setIsProgressData', false);
                })
            ;
        }
    }
};
