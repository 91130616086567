<template>
  <v-menu offset-y class="product-sorting">
    <div class="product-sorting__heading" slot="activator">
      {{ lang.title }}
      <span class="product-sorting__title">{{ sortBy.name }}</span>
      <v-icon>expand_more</v-icon>
    </div>
    <v-list>
      <v-list-tile v-for="item in items" :key="item.value" @click="handleChange(item)">
        <v-list-tile-title>{{ item.name }}</v-list-tile-title>
      </v-list-tile>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      sortBy: {
        name: null,
        value: null
      },
      open: false
    };
  },
  computed: {
    lang() {
      return {
        title: "Sort by"
      };
    }
  },
  methods: {
    handleChange(item) {
      this.sortBy.value = item.value;
      if (item.value === null) {
        this.sortBy.name = null;
      } else {
        this.sortBy.name = item.name;
      }
      this.$emit("change:sorting", item.value);
    }
  },
  watch: {
    "$route.query.sort": {
      immediate: true,
      handler(val) {
        if (typeof val === "undefined") {
          this.sortBy = { name: null, value: null };
        } else {
          const filteredItem = this.items.filter(item => item.value === val)[0];

          if (typeof filteredItem !== "undefined") {
            this.sortBy = filteredItem;
          }
        }
      }
    }
  }
};
</script>

<style scoped lang="scss">
.product-sorting {
  font-size: 14px;
  line-height: 1.2;
  margin: 0 0 12px;
  &:hover {
    .product-sorting__heading {
      color: #222528;
    }
  }
  &__heading {
    display: flex;
    color: #a6a8a9;
    cursor: pointer;
    margin: 0 0 0 20px;
    white-space: nowrap;
    align-items: center;
    justify-content: flex-end;
    transition: color 0.35s ease;
    .material-icons {
      font-size: 16px;
      margin: 0 0 -3px 4px;
    }
  }
  &__title {
    color: #222528;
    margin: 0 0 0 4px;
    vertical-align: top;
    display: inline-block;
  }
}
</style>
