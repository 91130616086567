import axios from 'axios';
import BaseApi from './base';
import {
  SIGN_IN,
  USERS,
  TRANSFER_MONEY,
  TOGGLE_ENABLED,
  REQUEST_PASSWORD_RESET,
  CAPTCHA_CHECK,
  CURRENT,
  RESET_PASSWORD,
  SET_TELEGRAM_BOT,
  SUB_ACCOUNTS,
  USER_REFERRALS
} from '@/constants/api';

export default class extends BaseApi {
  static getSubAccounts(filters = null) {
      return axios.get(this.baseURI + USERS + '/' + SUB_ACCOUNTS, {
        params: filters
      });
  }

  static withdrawBalance(data) {
      return axios.put(this.baseURI + USERS + '/' + TRANSFER_MONEY + '/' + data.fromAccount)
  }

  static toggleEnabled(data) {
    return axios.put(this.baseURI + USERS + '/' + TOGGLE_ENABLED + '/' + data.toggleUser)
  }

  static async getReferrals(filters = null) {
    return axios.get(this.baseURI + USER_REFERRALS, {
      params: filters
    });
  }

  static signIn(data) {
    return new Promise((resolve, reject) => {
      return axios.post(this.baseURI + SIGN_IN, null, {
          auth: {
            username: data.email,
            password: data.password,
          }
        })
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    })
  }

  static refreshToken(data) {
    return new Promise((resolve, reject) => {
      return axios.put(this.baseURI + SIGN_IN, {
          refreshToken: data
        })
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    })
  }

  static register(data) {
    return axios.post(this.baseURI + USERS, data);
  }

  static resetPasswordRequest(data) {
    return new Promise((resolve, reject) => {
      return axios.post(this.baseURI + USERS + '/' + REQUEST_PASSWORD_RESET, data)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    })
  }

  static checkCaptcha(data) {
    return new Promise((resolve, reject) => {
      return axios.post(this.baseURI + CAPTCHA_CHECK, data)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    })
  }

  static getCurrentUser() {
    return axios.get(this.baseURI + USERS + '/' + CURRENT);
  }

  static change(id, data) {
    return new Promise((resolve, reject) => {
      return axios.patch(this.baseURI + USERS + '/' + id, data)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    })
  }

  static changePassword(token, data) {
    return new Promise((resolve, reject) => {
      return axios.put(this.baseURI + USERS + '/' + RESET_PASSWORD + '/' + token, data)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    })
  }

  static setTelegramBot(token) {
    return new Promise((resolve, reject) => {
      return axios.put(this.baseURI + USERS + '/' + SET_TELEGRAM_BOT + '/' + token)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    })
  }
}
