<template>
  <div class="product-main">
    <ModalDeliveryPrice
      :productId="productId"
      :show="showShipmentMethodModal"
      @change:modal="handleShippingClick"
      @esc:modal="handleShippingClick(false)"
      lazy
      :key="productId"
    ></ModalDeliveryPrice>
    <h2 class="product-main__title hidden-md-and-up">{{ product.name }}</h2>
    <v-container grid-list-xl fluid>
      <v-layout wrap>
        <v-flex xs12 sm12 md6>
          <div class="product-main__image-main">
            <transition name="fade">
              <v-img v-if="product.mainImage" :src="imageSrc" :lazy-src="imageSrc"></v-img>
            </transition>
            <div class="product-main__image-main__images">
              <v-container grid-list- fluid>
                <v-layout row wrap class="pr-0 pl-0">
                  <template v-if="product.images && product.images.length > 0">
                    <v-flex v-for="image in product.images" :key="image.id">
                      <v-img
                        :width="subImages.width"
                        :height="subImages.height"
                        :ref="`image-${image.id}`"
                        :src="image.src"
                        :lazy-src="image.src"
                        class="product-main__image-main__images__image"
                        @click="handleImageClick(image.src, image.id)"
                      ></v-img>
                    </v-flex>
                  </template>
                </v-layout>
              </v-container>
            </div>
          </div>
        </v-flex>
        <v-flex xs12 sm12 md6>
          <h2 class="product-main__title hidden-sm-and-down">{{ product.name }}</h2>
          <div class="product-price-panel">
            <h4 class="product-main__price">{{ priceNotAvailable || totalPrice }}</h4>
            <div class="product-ali-link" v-if="product.aliexpressUri">
              <a :href="product.aliexpressUri" target="_blank">{{ lang.toAli }}</a>
            </div>
            <div class="product-main__rating">
              <span class="product-main__rating__reviews">({{ product.reviews }})</span>
              <Rating :rating="product.rating" :size="22" />
            </div>
          </div>
          <div class="product-main__content">
            <div class="product-main__content__item" v-if="product.option1">
              <!-- Option 1 -->
              <span class="product-main__content__item__title">{{ product.option1 }}</span>
              <template v-if="product.option1 === lang.color">
                <ProductOptionImage :items="productOptions[1]" option-number="1" />
              </template>
              <template v-else>
                <ProductOptionText :items="productOptions[1]" option-number="1" />
              </template>
            </div>

            <div class="product-main__content__item" v-if="product.option2">
              <!-- Option 2 -->
              <span class="product-main__content__item__title">{{ product.option2 }}</span>
              <template v-if="product.option2 === lang.color">
                <ProductOptionImage :items="productOptions[2]" option-number="2" />
              </template>
              <template v-else>
                <ProductOptionText :items="productOptions[2]" option-number="2" />
              </template>
            </div>

            <div class="product-main__content__item" v-if="product.option3">
              <!-- Option 3 -->
              <span class="product-main__content__item__title">{{ product.option3 }}</span>
              <template v-if="product.option3 === lang.color">
                <ProductOptionImage :items="productOptions[3]" option-number="3" />
              </template>
              <template v-else>
                <ProductOptionText :items="productOptions[3]" option-number="3" />
              </template>
            </div>

            <div class="product-main__content__item">
              <span class="product-main__content__item__title">{{ lang && lang.shipping }}</span>
              <div class="product-main__content__item__content">
                <span
                  class="product-main__content__item__value item--hover"
                  @click="handleShippingClick(true)"
                >{{ shippingText }}</span>
                <div class="product-main__content__item__content">
                  <div class="product-main__content__item__value__desc">{{ shippingDescText }}</div>
                </div>
              </div>
            </div>
            <!-- <div class="product-main__content__item">
              <span class="product-main__content__item__title">{{ lang && lang.supplier }}</span>
              <span class="product-main__content__item__value"></span>
            </div>
            <div class="product-main__content__item">
              <span class="product-main__content__item__title">{{ lang && lang.shipsWithin }}</span>
              <span class="product-main__content__item__value"></span>
            </div>
            <div class="product-main__content__item">
              <span class="product-main__content__item__title">{{ lang && lang.availability }}</span>
              <span class="product-main__content__item__value"></span>
            </div>-->
            <div class="product-main__content__item">
              <span class="product-main__content__item__title">{{ lang && lang.reviews }}</span>
              <div class="product-main__content__item__content">
                <span class="product-main__content__item__value">{{ product.reviews }}</span>
              </div>
            </div>
            <div class="product-main__content__item">
              <span class="product-main__content__item__title">{{ lang && lang.orders }}</span>
              <div class="product-main__content__item__content">
                <span class="product-main__content__item__value">{{ product.orders }}</span>
              </div>
            </div>
            <div class="product-main__content__item">
              <span class="product-main__content__item__title">{{ lang && lang.availability }}</span>
              <div class="product-main__content__item__content">
                <span class="product-main__content__item__value">{{ available}}</span>
              </div>
            </div>
            <template v-if="channelId">
              <ButtonPrimaryAdd
                v-if="!showGoToEdt"
                @click:base-button="handleImportClick"
                small
                class="product-main__content__btn"
                :loading="loading"
              >{{ lang && lang.btnText }}</ButtonPrimaryAdd>
              <ButtonPrimary
                v-else
                @click:base-button="handleGoToEditClick"
                small
                class="product-main__content__btn"
                :loading="loading"
              >{{ lang && lang.btnEditText }}</ButtonPrimary>
            </template>
          </div>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import { DEFAULT_SHIPS_FROM_ISO2 } from "@/constants/shopProducts";

import Rating from "@/components/Rating";
import ModalDeliveryPrice from "@/components/ModalDeliveryPrice";
import ButtonPrimaryAdd from "@/components/ButtonPrimaryAdd";
import ButtonPrimary from "@/components/ButtonPrimary";
import ProductOptionText from "@/components/ProductOptionText";
import ProductOptionImage from "@/components/ProductOptionImage";

export default {
  components: {
    Rating,
    ButtonPrimaryAdd,
    ModalDeliveryPrice,
    ProductOptionText,
    ProductOptionImage,
    ButtonPrimary
  },
  props: {
    product: {
      type: Object,
      required: true
    },
    subImages: {
      type: Object,
      default() {
        return {
          width: 75,
          height: 75
        };
      }
    }
  },
  data() {
    return {
      imageSrc: null,
      showShipmentMethodModal: false,
      price: null,
      selectedOption1: null,
      selectedOption2: null,
      selectedOption3: null,
      priceNotAvailable: null,
      available: null,
      productOptions: {
        1: null,
        2: null,
        3: null
      },
      showGoToEdt: false,
      selectedDeliveryIndex: null
    };
  },
  computed: {
    ...mapState({
      successMsg: state => state.errors.productMessages.success,
      errorMsg: state => state.errors.productMessages.somethingWrong
    }),
    ...mapGetters({
      shipmentMethods: "getShipmentMethods",
      countries: "getCountries",
      progress: "getIsProgress",
      loading: "getIsLoading"
    }),
    channelId() {
      return this.$salesChannel.get();
    },
    shippingText() {
      const countryIso2 = this.$shipsFrom.getLocation();
      const shipmentIso2 = this.$shipsFrom.getShipmentMethod();

      const currentCountry = this.$store.getters.getCountryByISO(countryIso2);
      const currentShipmentCountry = this.$store.getters.getCountryByISO(
        shipmentIso2
      );

      if (currentCountry) {
        if (!this.shipmentMethods || this.shipmentMethods.length === 0) {
          return `Ships to the ${currentCountry.niceName} is not available`;
        }
      } else {
        return "";
      }

      const leadMethod = this.shipmentMethods[this.selectedDeliveryIndex || 0];

      let finalPrice = leadMethod.price ? "$" + leadMethod.price : "Free";

      return `${finalPrice} to ${currentCountry.niceName} from ${currentShipmentCountry.niceName} via ${leadMethod.shippingCompany.name}`;
    },
    shippingDescText() {
      if (!this.shipmentMethods || this.shipmentMethods.length === 0) {
        return "";
      }

      const leadMethod = this.shipmentMethods[this.selectedDeliveryIndex || 0];

      return `Estimated delivery time ${leadMethod.shipday} days`;
    },
    totalPrice: {
      get() {
        if (this.available === 0) {
          return "$" + this.available;
        }
        return "$" + this.price;
      },
      set(val) {
        this.price = val;
      }
    },
    lang() {
      return {
        color: "Color",
        size: "Size",
        plugType: "Plug Type",
        reviews: "Reviews",
        orders: "Orders",
        shipping: "Shipping",
        supplier: "Supplier",
        shipsWithin: "Ships within",
        availability: "Availability",
        btnText: "Add to my products",
        notAvailable: "Not available",
        btnEditText: "Go to edit",
        shipsFrom: "Ships From",
        toAli: "Open on AliExpress"
      };
    },
    productId() {
      return this.product.id;
    }
  },
  methods: {
    ...mapActions([
      "addProductToImport",
      "setNotifyMsg",
      "fetchShipmentMethods"
    ]),
    handleImageClick(src, id) {
      const el = this.$refs[`image-${id}`][0].$el;
      this.clearImageSelectedClass();
      this.setLeadImageSrc(src);

      if (typeof el !== "undefined") {
        this.addImageSelectedClass(el);
      }
    },
    handleShippingClick(val) {
      this.showShipmentMethodModal = val;
    },
    setLeadImageSrc(src) {
      this.imageSrc = src;
    },
    getOptionByName(name) {
      return Object.keys(this.product).filter(item => {
        return this.product[item] === name;
      })[0];
    },
    addImageSelectedClass(el) {
      el.classList.add("image--selected");
    },
    clearImageSelectedClass() {
      const els = document.querySelectorAll(".image--selected");
      if (els.length > 0) {
        for (let i in els) {
          els[i].classList && els[i].classList.remove("image--selected");
        }
      }
    },
    setProductOption(optionNum) {
      const variants = this.product.variants;

      let result = [];
      const uniqueVariants = new Map();
      if (variants && variants.length) {
        variants.map(varaint => {
          if (!uniqueVariants.has(varaint[`option${optionNum}`])) {
            uniqueVariants.set(varaint[`option${optionNum}`], true);
            result.push({
              id: varaint.id,
              name: varaint[`option${optionNum}`],
              imageSrc: varaint.image,
              type: this.product[`option${optionNum}`]
            });
          }
        });

        this.productOptions[optionNum] = result;
      }
    },
    async handleImportClick() {
      const { status, text, productId } = await this.addProductToImport({
        channelId: this.channelId,
        id: this.productId
      });

      if (status) {
        this.setNotifyMsg({ text: text || this.successMsg, color: "success" });
        this.showGoToEdt = true;
        this.myProductId = productId;
      } else {
        this.setNotifyMsg({ text: text || this.errorMsg, color: "error" });
      }
    },
    setMainPrice() {
      const max = this.product.maxPrice;
      const min = this.product.minPrice;

      this.price = max !== min ? `${min} - ${max}` : max;
    },
    setOptionTotalByName() {
      const variants = this.product.variants;
      let available = 0;
      let price = 0;
      let isVariants = null;

      if (variants && variants.length) {
        variants.map(varaint => {
          if (
            this.product.option1 &&
            this.product.option2 &&
            this.product.option3
          ) {
            if (
              this.selectedOption1 &&
              this.selectedOption2 &&
              this.selectedOption3
            ) {
              isVariants = variants.some(
                item =>
                  this.selectedOption1.name === item.option1 &&
                  this.selectedOption2.name === item.option2 &&
                  this.selectedOption3.name === item.option3
              );
              if (
                this.selectedOption1.name === varaint.option1 &&
                this.selectedOption2.name === varaint.option2 &&
                this.selectedOption3.name === varaint.option3
              ) {
                available += varaint.available;
                price += varaint.price;
                this.price = price;
                this.available = available;
              }
            }
          } else if (
            this.product.option1 &&
            this.product.option2 &&
            !this.product.option3
          ) {
            if (this.selectedOption1 && this.selectedOption2) {
              isVariants = variants.some(
                item =>
                  this.selectedOption1.name === item.option1 &&
                  this.selectedOption2.name === item.option2
              );
              if (
                this.selectedOption1.name === varaint.option1 &&
                this.selectedOption2.name === varaint.option2
              ) {
                available += varaint.available;
                price += varaint.price;
                this.price = price;
                this.available = available;
              }
            }
          } else if (
            this.product.option1 &&
            !this.product.option2 &&
            !this.product.option3
          ) {
            if (
              this.selectedOption1 &&
              this.selectedOption1.name === varaint.option1
            ) {
              available += varaint.available;
              price += varaint.price;
              this.price = price;
              this.available = available;
            }
          }
        });
      }

      if (isVariants === false) {
        this.priceNotAvailable = this.lang.notAvailable;
        this.available = this.lang.notAvailable;
      }
    },
    handleGoToEditClick() {
      this.$router.push({
        name: "dashboard.products.my.edit",
        params: { id: this.myProductId }
      });
    },
    getShipsFromCountry(countryName) {
      const selectedShipsFrom = this.$store.getters.getDefaultShipmentMethodByName(
        countryName
      );

      const shipsFromIso2 =
        typeof selectedShipsFrom !== "undefined"
          ? selectedShipsFrom.iso2
          : DEFAULT_SHIPS_FROM_ISO2;

      return shipsFromIso2;
    },
    fetchNewShipmentMethods(option) {
      const country = this.$shipsFrom.getLocation();
      const shipFromCountry = this.getShipsFromCountry(option.name);
      this.$shipsFrom.setShipmentMethod(shipFromCountry);

      this.fetchShipmentMethods({
        product: this.productId,
        shipFromCountry,
        country
      });
    }
  },
  mounted() {
    this.$root.$on("fetch-selected-item-index", deliveryIndex => {
      this.selectedDeliveryIndex = deliveryIndex;
    });

    this.$root.$on("clicked-option", data => {
      if (data.option == 1) {
        this.selectedOption1 = data;
      }

      if (data.option == 2) {
        this.selectedOption2 = data;
      }

      if (data.option == 3) {
        this.selectedOption3 = data;
      }

      if (data.type === this.lang.shipsFrom) {
        this.fetchNewShipmentMethods(data);
      }
    });
  },
  watch: {
    product: {
      immediate: true,
      handler(product) {
        this.setLeadImageSrc(product.mainImage.src);
        this.setMainPrice();

        if (product.option1) {
          this.setProductOption(1);
        }
        if (product.option2) {
          this.setProductOption(2);
        }
        if (product.option3) {
          this.setProductOption(3);
        }

        this.$shipsFrom.setShipmentMethod(DEFAULT_SHIPS_FROM_ISO2);
      }
    },
    selectedOption1: {
      handler(data) {
        this.setOptionTotalByName();
        if (
          (data.type === this.lang.color || data.type === this.lang.plugType) &&
          data.imageSrc
        ) {
          this.setLeadImageSrc(data.imageSrc);
        }
      }
    },
    selectedOption2: {
      handler(data) {
        this.setOptionTotalByName();
        if (
          (data.type === this.lang.color || data.type === this.lang.plugType) &&
          data.imageSrc
        ) {
          this.setLeadImageSrc(data.imageSrc);
        }
      }
    },
    selectedOption3: {
      handler(data) {
        this.setOptionTotalByName();
        if (
          (data.type === this.lang.color || data.type === this.lang.plugType) &&
          data.imageSrc
        ) {
          this.setLeadImageSrc(data.imageSrc);
        }
      }
    }
  },
  beforeDestroy() {
    this.$off("esc:modal");
    this.$off("change:modal");
  }
};
</script>

<style lang="scss">
.product-main {
  padding: 30px;
  background-color: #fff;
  border-radius: 4px;
  @include breakpoint($t) {
    padding: 0;
    background: none;
    border-radius: 0;
  }

  > .container {
    @include breakpoint($t) {
      background: #fff;
      padding: 16px !important;
    }
    @include breakpoint($m) {
      padding: 10px !important;
    }
  }

  .product-main__title {
    font-size: em(30);
    line-height: 32px;
    @include breakpoint($t) {
      margin: 0 0 20px;
    }
  }

  .product-price-panel {
    @include breakpoint($t) {
      display: flex;
      flex-wrap: wrap;
      align-items: flex-end;
      justify-content: space-between;
      align-items: center;
    }
  }

  .product-main__price {
    margin-top: 40px;
    font-size: em(30);
    font-family: "DIN Pro Bold";
    color: $warning-color;
    display: inline-block;
    @include breakpoint($t) {
      margin: 0;
    }
    @include breakpoint($m) {
      font-size: em(20);
    }
  }

  .product-main__rating {
    // float: right;
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
    @include breakpoint($m) {
      margin-bottom: 2px;
      flex: 1;
    }

    .product-main__rating__reviews {
      // font-size: 20px;
      @include breakpoint($m) {
        font-size: 14px;
      }
    }
  }

  .product-size {
    display: inline-block;
    // display: table-row;
    // width: 35px;
    // height: 35px;
    margin-right: 10px;
    margin-bottom: 10px;
    background-color: $body-color;
    text-align: center;
    color: $title-color;
    // padding-top: 5px;
    padding: 5px;
    cursor: pointer;
  }

  .product-main__image-main {
    @include breakpoint($t) {
      margin: 0 auto;
      max-width: 600px;
    }

    > .v-responsive.v-image {
      @include breakpoint($t) {
        margin-bottom: 10px;
      }
    }

    .product-main__image-main__images {
      padding: 10px 0 0;
      > .container {
        padding: 0;
        .layout {
          margin: -5px !important;
          .flex {
            flex: 0;
            padding: 0;
            margin: 5px;
          }
        }
      }
      .product-main__image-main__images__image {
        cursor: pointer;
        @include breakpoint($m) {
          max-width: 65px;
          max-height: 65px;
        }
      }
    }
  }

  .product-main__content {
    margin-top: 20px;
    .product-main__content__item {
      display: flex;
      margin: 15px 0;
      font-size: 12px;
    }

    .color-picker {
      margin: -5px !important;
      > .flex {
        flex: 0;
        padding: 5px !important;
      }
    }

    .product-main__content__item__value--select {
      cursor: pointer;
    }

    .product-main__content__item__value__alt {
      font-size: 12px;
      padding: 5px 7px;
      color: $title-color;
      white-space: nowrap;
      background-color: $body-color;
    }

    .product-main__content__item__title {
      width: 100px;
      flex-shrink: 0;
      display: inline-block;
    }
    .product-main__content__item__value {
      display: inline-block;
      color: $title-color;
      font-size: 14px;
      .layout {
        margin: -6px !important;
      }
      .flex {
        padding: 6px !important;
      }
    }
    .product-main__content__item__value__desc {
      display: block;
      color: #979797;
      font-size: 12px;
    }
    .product-main__content__btn {
      margin-top: 50px;
      margin-left: -10px;
    }
  }

  // .product-size--active {
  //   border: 2px solid $warning-color !important;
  // }

  .product-options {
    .product-option,
    .product-option-image {
      cursor: pointer;
    }
    .product-option {
      display: inline-block;
      margin-right: 10px;
      background-color: $body-color;
      padding: 5px;
      min-width: 30px;
      min-height: 30px;
      margin-bottom: 10px;
      text-align: center;
      white-space: nowrap;
    }

    .product-option--active {
      border: 2px solid $warning-color !important;
    }
  }

  .product-ali-link {
    display: inline-block;
    vertical-align: super;
    font-size: 12px;
    margin-left: 25px;
    text-decoration: underline;
  }
}
</style>
