<template>
  <v-container class="search-products pa-0 no-width base-table--filters" grid-list-md>
    <ModalAddProduct
      :show="showAddProductModal"
      @change:modal="handleAddProductClick"
      @productadd="handleProductAdded"
      @esc:modal="showAddProductModal = false"
    ></ModalAddProduct>
    <v-layout wrap class="search-block">
      <template>
        <v-flex xs12 :sm7="channelId" :sm12="!channelId" :md8="channelId" :md12="!channelId">
          <div class="search-products__search">
            <Search :placeholder="lang.searchText" />
          </div>
        </v-flex>
      </template>
      <v-flex xs12 sm5 md4 v-if="channelId">
        <div class="search-products__search-btn">
          <ButtonPrimaryAdd @click:base-button="openAppProductModal" small>{{ lang.btnText }}</ButtonPrimaryAdd>
        </div>
      </v-flex>
    </v-layout>
    <template>
      <router-view />
    </template>
  </v-container>
</template>

<script>
import { mapActions } from "vuex";
import productsBreadcrumbsMixin from "@/mixins/productsBreadcrumbsMixin";

import Search from "@/components/Search";
import ButtonPrimaryAdd from "@/components/ButtonPrimaryAdd";
import ModalAddProduct from "@/components/ModalAddProduct";

export default {
  mixins: [productsBreadcrumbsMixin],
  components: {
    Search,
    ButtonPrimaryAdd,
    ModalAddProduct
  },
  data() {
    return {
      showAddProductModal: false
    };
  },
  computed: {
    lang() {
      return {
        btnText: "Add products from Aliexpress",
        searchText: "Search"
      };
    },
    channelId() {
      return this.$salesChannel.get();
    }
  },
  methods: {
    ...mapActions(["fetchCategories"]),
    openAppProductModal() {
      this.handleAddProductClick(true);
    },
    handleAddProductClick(val) {
      this.showAddProductModal = val;
    },
    handleProductAdded() {
      this.handleAddProductClick(false);
    }
  },
  created() {
    this.fetchCategories();
  },
  watch: {
    "$route.query": {
      handler() {
        this.$root.$emit("clear-search");
      }
    },
    "$route.query.params.id": {
      immediate: true,
      deep: true,
      handler() {
        window.scrollTo({ top: 0 });
      }
    }
  },
  beforeDestroy() {
    window.localStorage.removeItem("countryISO");
  }
};
</script>

<style scope lang="scss">
.search-products {
  max-width: $max-width;
  &.base-table--filters {
    margin-top: 0;
  }
  .search-products__search {
    height: 100%;
  }
  .search-products__search-btn {
    float: right;
    @include breakpoint($m) {
      float: none;
      margin-bottom: 15px;
    }

    .search-products__search-btn__icon {
      padding-left: 20px;
    }
    .v-btn {
      margin: 0;
    }
  }
  .v-input {
    padding: 0;
  }

  .search-block {
    max-width: $max-width;
  }
}
</style>
