<template>
  <BaseIcon :width="width" :height="height" :stroke="stroke" fill="none" name="statistic">
    <path d="M.5 0v18M18 17.5H0M3.5 15v-5M6.5 15V5M9.5 15V7M12.5 15V3M15.5 15V1"/>
  </BaseIcon>
</template>

<script>
export default {
  props: {
    width: {
      type: [Number, String],
      default: 10
    },
    height: {
      type: [Number, String],
      default: 10
    },
    fill: {
      type: String,
      default: "none"
    },
    stroke: {
      type: String,
      default: "#7A7C7E"
    }
  }
};
</script>

<style scoped>
</style>
