import axios from 'axios';
import BaseApi from './base';
import {
  VERSION,
} from '@/constants/api';

export default class extends BaseApi {
  static get() {
    return new Promise((resolve, reject) => {
      return axios.get(this.baseURI + VERSION)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  }
}
