<template>
  <BaseIcon :width="width" :height="height" stroke="none" fill="none" name="arrow-right">
    <path :stroke="stroke" stroke-width="2" d="M0 5.82h11.538M7.51.721l5.048 5.048-5.048 5.048"/>
  </BaseIcon>
</template>

<script>
export default {
  props: {
    width: {
      type: [Number, String],
      default: "14"
    },
    height: {
      type: [Number, String],
      default: "12"
    },
    fill: {
      type: String,
      default: "none"
    },
    stroke: {
      type: String,
      default: "#fff"
    }
  }
};
</script>

<style scoped>
</style>
