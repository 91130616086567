<template>
  <div v-if="salesChannel">
    <router-view />
  </div>
</template>

<script>
import { mapState } from "vuex";
import { PAYMENT_TYPE_USER_PS } from "@/constants/storeManagement";

export default {
  data() {
    return {
      PAYMENT_TYPE_USER_PS
    };
  },
  computed: {
    ...mapState({
      salesChannel: state => state.salesChannels.salesChannel
    })
  },
  watch: {
    salesChannel: {
      immediate: true,
      handler(data) {
        if (data && data.paymentType === this.PAYMENT_TYPE_USER_PS) {
          this.$router.back();
        }
      }
    }
  }
};
</script>

<style lang="scss">
</style>
