<template>
  <div class="table-orders" v-if="items && paginationsLinks">
    <div name="filters" class="base-table--filters table-transactions__filters-panel">
      <FilterOrders @click:search-btn="handleSearch" @click:export-btn="handleExportToExcel"/>
    </div>
    <BaseTable
      class="table-orders__content"
      :headers="headers"
      show-details
      show-checkboxes
      show-custom-checkboxes
      show-pagination
      show-page-size
      show-pay
      :items="items"
      :custom-pagination="paginationsLinks"
      @get-pay-action="handlePayOrder"
      @fetch-selected-by-checkbox-array="handleSelected"
      routerName="dashboard.orders.details"
      :footer="itemsTotal"
      :hide-columns="['id']"
    >
      <div slot="selected-header">
        <div slot="selected-header-button">
          <ButtonPrimary
            :loading="loading"
            outline
            small
            @click:base-button="handlePayOrders"
          >{{ lang.pay }}</ButtonPrimary>
        </div>
      </div>
    </BaseTable>
  </div>
</template>

<script>
  import { mapGetters, mapActions, mapState } from "vuex";
  import FilterOrders from "@/components/Filters/FilterOrders";
  import ButtonPrimary from "@/components/ButtonPrimary";
  import * as statuses from "@/constants/orders";
  import * as fraudRisks from "@/constants/fraudRisks";

  export default {
    components: {
      FilterOrders,
      ButtonPrimary,
    },
    data() {
      return {
        selected: [],
        items: [],
        itemsTotal: [],
        totalSum: 0,
        headers: [
          { text: "", value: "customCheckbox" },
          { text: "ID", value: "id" },
          { text: "ID", value: "idName" },
          { text: "Customer", value: "customer" },
          { text: "Amount, $", value: "totalPriceUsd" },
          { text: "Estimated cost, $", value: "estimatedOrderCost" },
          { text: "Real cost, $", value: "totalOrderCost" },
          { text: "Profit, $", value: "profit" },
          { text: "Created At", value: "createdAt" },
          { text: "Status", value: "financialStatus" },
          { text: "Fraud risk", value: "fraudRisk" }
        ],
        exportCSV: {
          fields: {
            ID: "idName",
            Customer: "customer",
            Amount: "totalPriceUsd",
            "Estimated order cost": "estimatedOrderCost",
            "Real cost": "totalOrderCost",
            "Created At": "createdAt",
            Status: "financialStatus"
          }
        },
        statusesText: {
          [statuses.STATUS_PENDING]: "Pending",
          [statuses.STATUS_PAID]: "Paid",
          [statuses.STATUS_PROCESSING]: "Processing",
          [statuses.STATUS_FULFILLED]: "Fulfilled",
          [statuses.STATUS_PARTIAL_FULFILLED]: "Partial fulfiled",
          [statuses.STATUS_REFUNDED]: "Refunded",
          [statuses.STATUS_PARTIAL_REFUNDED]: "Partial refunded",
          [statuses.STATUS_PARTIALLY_PAID]: "Partially paid",
          [statuses.STATUS_ABANDONED_CHECKOUT]: "Abonded checkout",
          [statuses.STATUS_TEST]: "Test",
          [statuses.STATUS_CHECK_FRAUD]: "Fraud check",
          [statuses.STATUS_PAYMENT_NOT_FOUND]: "Payment not found",
          [statuses.STATUS_CALL_CENTER]: "On call",
          [statuses.STATUS_APPROVE]: "Approved",
          [statuses.STATUS_TRASH]: "Trash",
          [statuses.STATUS_CANCELED]: "Cancel",
        },
        fraudRiskText: {
          [fraudRisks.LOW]: "Low",
          [fraudRisks.MEDIUM]: "Medium",
          [fraudRisks.HIGH]: "High"
        }
      };
    },
    computed: {
      ...mapState({
        successMsg: state => state.errors.notifyMessages.success,
        errorMsg: state => state.errors.notifyMessages.somethingWrong
      }),
      ...mapGetters({
        orders: "getOrders",
        paginationsLinks: "getOrdersPagination",
        loading: "getIsProgress"
      }),
      lang() {
        return {
          pay: `Pay $${this.totalSum.toFixed(2)} for selected orders`
        };
      }
    },
    methods: {
      ...mapActions([
        "fetchOrders",
        "payOrders",
        "exportExcel",
        "setNotifyMsg",
        "getCurrentUser"
      ]),
      handleSearch(filters) {
        filters.page = 1;
        this.fetchOrdersWithFilters(filters);
      },
      handleExportToExcel(filters) {
        const salesChannel = this.$salesChannel.get();

        const newFilters = {
          groups: "delivery,customer,Order.Calculation",
          salesChannel,
          ...this.$route.query,
          ...filters
        };

        this.exportExcel(newFilters);
      },
      fetchOrdersWithFilters(filters = {}) {
        const salesChannel = this.$salesChannel.get();

        const newFilters = {
          page: 1,
          page_size: 20,
          groups: "delivery,customer,Order.Calculation",
          salesChannel,
          ...this.$route.query,
          ...filters
        };

        this.fetchOrders(newFilters);
      },
      handleSelected(selectedArr) {
        this.selected = selectedArr;
      },
      async handlePayOrders() {
        const ordersIds = this.selected.map(item => item.id);
        const { text, status } = await this.payOrders({ orders: ordersIds });

        if (status) {
          this.setNotifyMsg({ text: this.successMsg, color: "success" });
          this.fetchOrdersWithFilters();
          this.getCurrentUser();
        } else {
          this.setNotifyMsg({ text: text[0]? text[0] : this.errorMsg, color: "error" });
        }

        this.clearAllSelected();
      },
      async handlePayOrder(id) {
        const { text, status } = await this.payOrders({ orders: [id] });

        if (status) {
          this.setNotifyMsg({ text: this.successMsg, color: "success" });
          this.fetchOrdersWithFilters();
          this.getCurrentUser();
        } else {
          this.setNotifyMsg({ text: text[0]? text[0] : this.errorMsg, color: "error" });
        }

        this.clearAllSelected();
      },
      calcTotalSum(arr) {
        return arr.length
          ? arr.reduce((accumulator, item) => {
            return accumulator + +item.estimatedOrderCost;
          }, 0)
          : 0;
      },
      clearAllSelected() {
        this.selected = [];
        this.$root.$emit("clear-selected-base-table");
      },
      setFooterData() {
        const total = [
          {
            id: "Total",
            customer: null,
            totalPriceUsd: 0,
            estimatedOrderCost: 0,
            totalOrderCost: 0,
            profit: 0,
            createdAt: null,
            financialStatus: null,
            customCheckbox: null,
            fraudRisk: null
          }
        ];

        this.items.map(item => {
          total[0].totalPriceUsd += +item.totalPriceUsd;
          total[0].estimatedOrderCost += +item.estimatedOrderCost;
          total[0].totalOrderCost += +item.totalOrderCost;
          total[0].profit += +item.profit;
        });

        this.itemsTotal = total;

        this.itemsTotal[0].totalPriceUsd = this.itemsTotal[0].totalPriceUsd.toFixed(
          2
        );
        this.itemsTotal[0].estimatedOrderCost = this.itemsTotal[0].estimatedOrderCost.toFixed(
          2
        );
        this.itemsTotal[0].totalOrderCost = this.itemsTotal[0].totalOrderCost.toFixed(
          2
        );
        this.itemsTotal[0].profit = this.itemsTotal[0].profit.toFixed(2);
      },
      setProfit(item) {
        const { orderCalculation, totalPriceUsd } = item;
        if (!orderCalculation || !orderCalculation.totalCostUsd) return "";

        return (totalPriceUsd - orderCalculation.totalCostUsd).toFixed(2);
      }
    },
    watch: {
      orders: {
        handler(orders) {
          const statusTag = status => {
            switch (status) {
              case statuses.STATUS_PAID:
              case statuses.STATUS_PROCESSING:
              case statuses.STATUS_PARTIALLY_PAID:
              case statuses.STATUS_APPROVE:
                return "success";
              case statuses.STATUS_FULFILLED:
              case statuses.STATUS_PARTIAL_FULFILLED:
                return "waiting";
              case statuses.STATUS_REFUNDED:
              case statuses.STATUS_PARTIAL_REFUNDED:
              case statuses.STATUS_PAYMENT_NOT_FOUND:
              case statuses.STATUS_CANCELED:
              case statuses.STATUS_TRASH:
                return "danger";
              default: {
                return "neutral";
              }
            }
          };

          const statusFraud = fraudRisk => {
            switch (fraudRisk) {
              case fraudRisks.LOW:
                return "success";
              case fraudRisks.MEDIUM:
                return "danger";
              case fraudRisks.HIGH:
                return "danger";
            }
          };

          const tempItems = orders.map(item => {
            return {
              customCheckbox:
                item.orderCalculation &&
                (item.status === statuses.STATUS_PAID &&
                  item.orderCalculation.shipmentPaymentStatus == 10),
              id: item.id,
              idName: item.name ? item.name.slice(1) : item.id,
              customer:
                item.delivery &&
                item.delivery.firstName + " " + item.delivery.lastName,
              totalPriceUsd: item.totalPriceUsd.toFixed(2),
              estimatedOrderCost:
                item.orderCalculation &&
                item.orderCalculation.advanceTotalCostUsd.toFixed(2),
              totalOrderCost:
                item.orderCalculation &&
                item.orderCalculation.totalCostUsd &&
                item.orderCalculation.totalCostUsd.toFixed(2),
              profit: this.setProfit(item),
              createdAt: window.moment(item.createdAt).format("YYYY-MM-DD hh:ss"),
              financialStatus: `<div class="tag-status tag-status--${statusTag(
                item.status
              )}">${this.statusesText[item.status]}</div>`,
              fraudCheck: `<div class="tag-status tag-status--small tag-status--${statusFraud(
                item.fraudRisk
              )}">${this.fraudRiskText[item.fraudRisk]}</div>`
            };
          });
          this.items = tempItems;

          this.setFooterData();
        }
      },
      "$route.query": {
        handler(newVal) {
          if (newVal.page || newVal.page_size) {
            this.fetchOrdersWithFilters();
          }
        }
      },
      selected: {
        handler(arr) {
          this.totalSum = this.calcTotalSum(arr);
        }
      }
    },
    mounted() {
      this.fetchOrdersWithFilters();
    },
    beforeDestroy() {
      this.$off("get-action");
      this.$off("click:base-button");
      this.$off("fetch-selected-by-checkbox-array");
    }
  };
</script>

<style  lang="scss">
  .table-orders {
    /deep/ th,
    td {
      padding-right: 0 !important;
      padding-left: 2px !important;
    }

    /deep/ .justify-center {
      padding: 0 !important;
    }

    /deep/ .tag-status {
      width: 80px !important;
    }
  }
</style>
