<template>
  <div class="input-wrapper">
    <input :id="fileUploadInputName" class="file-upload-input" type="file" name="file"
           @change="onChangeInputFile"
           :accept="accept"
           :disabled="uploading"
           ref="input"
    >
    <ButtonPrimary small outline>
      <label :for="fileUploadInputName" class="file-upload-label">
        <div>{{ uploading ? `${btnUploadingLabel} ${this.progress}%` : btnLabel }}</div>
      </label>
    </ButtonPrimary>
  </div>
</template>

<script>
    import FileUpload from '@/helpers/FileUpload';
    import BaseApi from '@/api/base';
    import ButtonPrimary from "@/components/ButtonPrimary";

    export default {
        components: {
            ButtonPrimary
        },
        props: {
            url: {
                type: String,
                required: true
            },
            accept: {
                type: String,
                default: '.png,.jpg'
            },
            btnLabel: {
                type: String,
                default: 'Select a file'
            },
            btnUploadingLabel: {
                type: String,
                default: 'Uploading file'
            },
            maxSize: {
                type: Number
            },
            additionalData: {
                type: Object,
                default: () => {
                    return {}
                }
            },
            requestType: {
                type: String,
                default: 'POST'
            },
            uniqueId: {
                type: String,
                default: '000'
            }
        },
        data() {
            return {
                progress: 0,
                anexo: {}
            }
        },
        computed: {
            uploading() {
                return this.progress > 0
            },
            fileUploadInputName() {
                return 'file-upload-input' + this.uniqueId
            }
        },
        methods: {
            onChangeInputFile(e) {
                let files = e.target.files || e.dataTransfer.files;

                if (!files.length) {
                    return;
                }

                const file = files[0];

                if (file.size > this.maxSize) {
                    this.$emit('validateError', {
                        code: 'max_size_exceded',
                        message: `The file is too large (${(file.size / 1024).toFixed(0)} kB). Allowed maximum size is ${(this.maxSize / 1024).toFixed(0)} kB`
                    });

                    return
                }

                this.upload(file)
            },
            upload(file) {
                this.progress = 0.1;
                let fileUpload = new FileUpload(BaseApi.baseURI + this.url, {'Authorization' : 'Bearer ' + this.$auth.getToken()}, this.onProgress, this.requestType);
                fileUpload
                    .upload(file, this.additionalData)
                    .then(e => {
                        this.anexo = e.target.response;
                        this.onChangeAnexo();
                        this.$emit('success', e);
                        this.progress = 0;
                        this.cleanInput()
                    })
                    .catch(e => {
                        this.$emit('error', e);
                        this.progress = 0;
                        this.cleanInput()
                    })
            },
            cleanInput() {
                let input = this.$refs.input;

                if (input) {
                    input.value = ''
                }
            },
            onProgress(e) {
                this.progress = parseInt(e.loaded * 100 / e.total);
                this.$emit('progress', this.progress)
            },
            onChangeAnexo() {
                this.$emit('change', this.anexo)
            }
        }
    }
</script>
<style lang="scss">
  .file-upload-input {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
  }

  .file-upload-label {
    :hover {
      cursor: pointer;
    }
  }
</style>
