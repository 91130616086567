<template>
  <div
    class="base-input base-password-input"
    :class="{'base-input--error': hasError && showErrors, [`base-input-background--${backgroundColor}`]: true}"
  >
    <label class="base-input__label">
      <slot></slot>
    </label>
    <div class="base-input__input-text">
      <v-text-field
        hide-details
        v-bind="$attrs"
        v-on="$listeners"
        v-model="computedValue"
        :type="passVisible ? 'text' : 'password'"
        :class="{[`base-input-background--${backgroundColor}`]: true}"
        @input="showErrors = false"
      />
      <span class="base-input__subtitle" v-if="subtitle">{{ subtitle }}</span>
      <div class="base-input-errors" v-if="hasError && showErrors">{{ errorText }}</div>
      <div class="base-input__icon icon-password-input" @click="changeVisible">
        <v-icon v-if="passVisible">visibility_off</v-icon>
        <v-icon v-else>visibility</v-icon>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  inheritAttrs: false,
  props: {
    type: {
      type: String,
      default: "text"
    },
    value: {
      required: true,
      validator: prop => typeof prop === "string" || prop === null
    },
    backgroundColor: {
      type: String,
      default: "white"
    },
    height: {
      type: String,
      default: "30"
    },
    subtitle: {
      type: String
    },
    hasError: {
      type: Boolean,
      default: false
    },
    errorText: {
      type: String,
      default: "Error"
    }
  },
  data() {
    return {
      passVisible: false,
      showErrors: true
    };
  },
  methods: {
    setPropsHeight() {
      let el = document.querySelector(".base-input .v-input__control");
      el.style.height = this.height + "px";
      el.style["min-height"] = this.height + "px";
      el.style["max-height"] = this.height + "px";
    },
    changeVisible() {
      this.passVisible = !this.passVisible;
    }
  },
  computed: {
    computedValue: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input:base-text", val);
      }
    }
  },
  mounted() {
    this.setPropsHeight();
  },
  watch: {
    hasError: {
      immediate: true,
      handler(val) {
        this.showErrors = val;
      }
    }
  }
};
</script>

<style lang="scss">
.base-password-input {
  .material-icons {
    font-size: 16px;
  }
  &__icon--append {
    cursor: pointer;
  }

  .icon-password-input {
    cursor: pointer;
    margin-top: 2px;
    margin-right: 5px;
  }
}
.base-select-background--gray {
  .v-input__control {
    .v-input__slot {
      background-color: $body-color !important;
    }
  }
}
</style>
