<template>
  <Modal v-bind="$attrs" v-on="$listeners" @change:modal="onChangeModal" scrollable>
    <div slot="content">
      <div class="modal-dp">
        <div class="modal-dp__title">{{ customData.title }}
          <div class="modal-dp__title__sub_title">{{ salesChannel.name }}</div>
        </div>
        <v-container grid-list-lg class="pa-0">
          <v-layout wrap>
            <v-flex md8>
              <InputSelect
                :hide-details="false"
                :items="items"
                item-text="email"
                item-value="id"
                @change:base-select="handleSubAccount"
                :value="form.user"
                :error-messages="Object.keys(errors) ? errors.subAccount : []"
              >{{ lang.labels.subAccount }}</InputSelect>
            </v-flex>
          </v-layout>
        </v-container>

        <div class="modal-dp__btn">
          <ButtonPrimary
            small
            @click:base-button="handleCreateRequest"
            :loading="progress"
          >{{ lang.btnText }}</ButtonPrimary>
          <ButtonPrimary small outline @click:base-button="close">{{ customData.closeButtonText }}</ButtonPrimary>
        </div>
      </div>
    </div>
  </Modal>
</template>

<script>
import Modal from "@/components/Modal";
import ButtonPrimary from "@/components/ButtonPrimary";
import InputSelect from "@/components/InputSelect";
import { mapGetters, mapActions, mapState } from "vuex";
import {STATUS_OK, STATUS_VALIDATION_ERROR} from "../constants/httpCodes";

export default {
  props: {
    salesChannel: {
      type: Object,
      required: true
    },
    customData: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      form: {
        id: null,
        user: null
      },
      items: [],
      errors: {}
    };
  },
  methods: {
    ...mapActions([
      "setNotifyMsg",
      "fetchSubAccounts",
      "updateSalesChannelUser"
    ]),
    async handleCreateRequest() {
      if (this.validForm()) {
        const {status, text} = await this.updateSalesChannelUser(this.form);

        if (status === STATUS_OK) {
          const subAccount = this.findSubAccountById(this.form.user);

          this.$emit('updateList', {
            id: this.salesChannel.id,
            user: subAccount.email
          });

          this.setNotifyMsg({ color: "success", text: text});
          this.close();
        } else if (status === STATUS_VALIDATION_ERROR) {
          this.errors = {...this.errors, subAccount: text };
        }
      }
    },
    onChangeModal(modal) {
      if (modal === false) {
        this.form = {user: null, id: null };
        this.errors = {subAccount: "" };
      }
    },
    handleSubAccount(subAccountId) {
      if (subAccountId === null) {
        return;
      }

      this.form = {...this.form, user: subAccountId, id: this.salesChannel.id };
      this.validForm();
    },
    validForm() {
      if (this.form.user === null) {
        this.errors = {...this.errors, subAccount: "The field is required."};

        return false;
      }

      this.errors = {};

      return true;
    },
    findSubAccountById(id) {
      const subAccount = this.subAccounts.filter(sa => sa.id == id)[0];

      if (typeof subAccount === "undefined") {
        this.setNotifyMsg({
          color: "error",
          text: this.errorNotFoundSubAccountMsg
        });
      }

      return subAccount;
    },
    close() {
      this.$emit('close');
    }
  },
  mounted() {
      this.fetchSubAccounts();
  },
  watch: {
    subAccounts: {
      handler(subAccounts) {
        this.items = subAccounts.map(item => {
          return {
            id: item.id,
            email: item.email
          };
        });
      }
    }
  },
  components: {
    Modal,
    InputSelect,
    ButtonPrimary
  },
  computed: {
    ...mapGetters({
      progress: "getIsProgress",
      subAccounts: "getSubAccounts"
    }),
    ...mapState({
      errorNotFoundSubAccountMsg: state => state.errors.notifyMessages.subAccountNotFound,
    }),
    lang() {
      return {
        subAccount: "Select a Sub Account",
        mainTitle: "Change user",
        btnText: "Save",
        cancel: "Cancel",
        labels: {
          selectUser: "Select User"
        },
      };
    },
  },
};
</script>

<style lang="scss">
.modal-dp {
  &__title {
    font-size: 22px !important;
    color: #222528;
    margin-bottom: 20px !important;
    &__sub_title {
      display: inline-block;
    }
  }
  &__btn {
    display: flex;
    flex-direction: row-reverse;
    margin-top: 30px;
    text-align: right;
    @include breakpoint($m) {
      text-align: left;
    }
  }
  &__text {
    font-size: 16px;
  }
}
</style>
