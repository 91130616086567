<template>
  <div class="product-details">
    <div class="product-details__breadcrumbs" v-if="product && breadcrumbs">
      <Breadcrumbs :items="breadcrumbs"></Breadcrumbs>
    </div>
    <template v-if="product">
      <ProductMain :product="product" />
      <br />
      <ProductDesc :description="product.bodyHtml" />
    </template>
    <template v-else-if="notFoundProduct">
      <div class="product-not-found" v-text="lang.notFound"></div>
    </template>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import productsBreadcrumbsMixin from "@/mixins/productsBreadcrumbsMixin";
import { STATUS_NOT_FOUND } from "@/constants/httpCodes";

import ProductMain from "@/components/ProductMain";
import ProductDesc from "@/components/ProductDesc";
import Breadcrumbs from "@/components/Breadcrumbs";

export default {
  mixins: [productsBreadcrumbsMixin],
  components: {
    ProductMain,
    ProductDesc,
    Breadcrumbs
  },
  data() {
    return {
      searchedProductName: this.$route.params.name,
      notFoundProduct: null
    };
  },
  computed: {
    ...mapGetters({
      product: "getProduct"
    }),
    productsTotalCount() {
      return "";
    },
    lang() {
      return {
        notFound: "Product not found or is not available more"
      };
    }
  },
  methods: {
    ...mapActions(["fetchProductById", "fetchCategoryById"]),
    ...mapMutations(["REMOVE_PRODUCT_DATA", "REMOVE_CATEGORY_DATA"]),
    handleFilterNameChange(name) {
      this.$router.push({
        name: "dashboard.products.search-results",
        query: {
          name,
          page: 1,
          page_size: 20
        }
      });
    }
  },
  watch: {
    "$route.params.productId": {
      immediate: true,
      async handler(productId) {
        const response = await this.fetchProductById(productId);
        if (response === STATUS_NOT_FOUND) {
          this.notFoundProduct = true;
        }
      }
    },
    product: {
      handler(product) {
        if (!product) return;

        if (product.category) {
          this.fetchCategoryById(product.category.id);
        }

        this.$root.$emit("set-html-title-name", `Ecombo - ${product.name}`);
      }
    }
  },
  mounted() {
    this.$root.$on("input:search", name => this.handleFilterNameChange(name));
    this.$root.$emit("clear-search");
  },
  beforeDestroy() {
    this.REMOVE_PRODUCT_DATA();
    this.REMOVE_CATEGORY_DATA();
    this.$root.$off("input:search");
  }
};
</script>

<style scoped lang="scss">
.product-details {
  max-width: $max-width;
}

.product-not-found {
  display: flex;
  min-height: 50vh;
  justify-content: center;
  align-items: center;
  font-size: 32px;
  text-align: center;
}
</style>
