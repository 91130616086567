<template>
  <div v-on="$listeners">
    <BaseIcon
      :width="width"
      :height="height"
      :stroke="stroke"
      fill="none"
      name="logout"
    >
      <path
        stroke-linecap="square"
        d="M12.324 5.649V.514H.514v17.973h11.81v-5.65M7.19 9.243H19m-3.081-4.108l4.108 4.108-4.108 4.108"
      />
    </BaseIcon>
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    width: {
      type: [Number, String],
      default: 21
    },
    height: {
      type: [Number, String],
      default: 19
    },
    fill: {
      type: String,
      default: "none"
    },
    stroke: {
      type: String,
      default: "#7A7C7E"
    }
  }
};
</script>

<style scoped>
</style>
