export function setUserToLocalStorage(user, vm) {
  vm.$auth.setToken(user.token, user.expire);
  vm.$auth.setRefreshToken(user.refreshToken, user.refreshExpire);
  user.email && vm.$auth.setUser(user.email);
  vm.$auth.setTries(1);
}

export function setUserToLocalStorageRefresh(user, vm) {
  vm.$auth.setToken(user.token, user.expire);
  vm.$auth.destroyTries();
  user.email && vm.$auth.setUser(user.email);
}

export function destroyPrivileges(vm) {
  vm.$auth.destroyPrivileges();
}

export function logOutUser(vm) {
  vm.$auth.destroyToken();
  vm.$auth.destroyRefreshToken();
  vm.$auth.destroyTries();
  vm.$auth.destroySwitchUser();
  vm.$auth.destroyPrivileges();
  vm.$salesChannel.destroy();
  window.location.href = '/';
}
