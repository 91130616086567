<template>
  <div class="base-input">
    <label class="base-input__label">
      <slot></slot>
    </label>
    <div class="base-input__input-text">
      <v-textarea
        v-bind="$attrs"
        :type="type"
        v-on="$listeners"
        v-model="computedValue"
        :no-resize="noResize"
        :class="{[`base-input-background--${backgroundColor}`]: true}"
        :hide-details="hideDetails"
      ></v-textarea>
      <span class="base-input__subtitle" v-if="subtitle">{{ subtitle }}</span>
      <div class="base-input__icon">
        <slot name="icon"></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  inheritAttrs: false,
  props: {
    type: {
      type: String,
      default: "text"
    },
    value: {
      required: true,
      validator: prop => typeof prop === "string" || prop === null
    },
    backgroundColor: {
      type: String,
      default: "white"
    },
    subtitle: {
      type: String
    },
    hideDetails: {
      type: Boolean,
      default: true
    },
    noResize: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    computedValue: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input:base-text", val);
      }
    }
  }
};
</script>

<style lang="scss">
.base-input {
  .base-input__label {
    font-size: em(12);
    color: $text-color;
  }

  .base-input__input-text {
    position: relative;
  }

  .base-input__icon {
    position: absolute;
    top: -2px;
    right: 0;
  }

  .v-text-field {
    padding-top: 0;

    .v-input__slot {
      max-height: none !important;

      &:before {
        border: none !important;
      }

      input {
        max-height: inherit;
        min-height: inherit;
        text-indent: 10px;
        font-size: 12px;
        border-radius: 4px;
      }

      &:after {
        display: none !important;
      }
    }
  }

  .base-input-background--white {
    .v-input__slot {
      input {
        background-color: #fff !important;
      }
    }
  }

  .base-input-background--gray {
    .v-input__slot {
      textarea {
        background-color: $body-color !important;
      }
    }
  }

  .v-text-field__slot {
    height: auto !important;
    min-height: 0 !important;
    max-height: none !important;
  }

  &__subtitle {
    display: block;
    color: #a6a8a9;
    font-size: 12px;
    font-family: "DIN Pro Bold" !important;
    line-height: 1.42;
    padding: 12px 0 0;
  }
}
</style>
