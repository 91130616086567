import { render, staticRenderFns } from "./IconStatistic.vue?vue&type=template&id=7b351260&scoped=true&"
import script from "./IconStatistic.vue?vue&type=script&lang=js&"
export * from "./IconStatistic.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7b351260",
  null
  
)

component.options.__file = "IconStatistic.vue"
export default component.exports