<template>
  <div class="search">
    <v-form @submit.prevent="handleInput">
      <v-text-field
        required
        append-icon="search"
        type="text"
        v-model="search"
        class="search__input"
        :placeholder="placeholder"
        v-on="$listeners"
        @click:append="handleInput"
        v-bind="$attrs"
      ></v-text-field>
    </v-form>
  </div>
</template>

<script>
import { throttle } from "@/helpers/common";

export default {
  inheritAttrs: false,
  components: {},
  props: {
    placeholder: {
      type: String,
      default: "Search"
    },
    watch: {
      type: String,
      default: "name"
    },
    throttle: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      search: null
    };
  },
  computed: {
    throttledSave: function() {
      return throttle(this.handleInput, 500);
    }
  },
  methods: {
    handleInput() {
      this.$root.$emit("input:search", this.search);
    }
  },
  watch: {
    search(search) {
      this.input = search;
      if (this.throttle) {
        this.throttledSave();
      }
    },
    "$route.query": {
      immediate: true,
      handler(query) {
        if (typeof query[this.watch] !== "undefined") {
          this.search = query[this.watch];
        }
      }
    },
    "$route.params": {
      immediate: true,
      handler(params) {
        if (params.name) {
          this.search = params.name;
        }
      }
    }
  },
  mounted() {
    this.$root.$on("clear-search", () => (this.search = ""));
  },
  beforeDestroy() {
    this.$root.$off("clear-search");
  }
};
</script>

<style lang="scss">
.search__input {
  margin-top: 0 !important;

  .v-text-field__slot {
    background-color: #fff;
    border-radius: 4px !important;
  }
  .v-input__slot {
    border-radius: 4px !important;
    background-color: #fff;
    &:before {
      display: none;
    }

    input {
      height: 30px;
      max-height: 30px;
      text-indent: 12px;
      font-size: 14px;
    }

    &:after {
      display: none;
    }
  }
  .v-input__append-inner {
    cursor: pointer;

    .v-input__icon {
      i {
        margin-right: 10px;
      }
    }
  }
}
</style>
