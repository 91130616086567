export default function (Vue) {
  Vue.auth = {
    setToken(token, expiration) {
      localStorage.setItem('token', token);
      localStorage.setItem('expiration', expiration);
    },

    setRefreshToken(token, expiration) {
      localStorage.setItem('refresh_token', token);
      localStorage.setItem('refresh_expiration', expiration);
    },

    setUser(username) {
      localStorage.setItem('username', username);
    },

    getUser() {
      return {
        username: localStorage.getItem('username'),
      };
    },

    setBalanceAccess(balanceAccess) {
      localStorage.setItem('balanceAccess', balanceAccess);
    },

    hasBalanceAccess() {
      return localStorage.getItem('balanceAccess') === 'true';
    },

    setPayoutAccess(payoutAccess) {
      localStorage.setItem('payoutAccess', payoutAccess);
    },

    hasPayoutAccess() {
      return localStorage.getItem('payoutAccess') === 'true';
    },

    setSubAccountsAccess(subAccountsAccess) {
      localStorage.setItem('subAccountsAccess', subAccountsAccess);
    },

    hasSubAccountsAccess() {
      return localStorage.getItem('subAccountsAccess') === 'true';
    },

    hasVirtualInventoryAccess() {
      return localStorage.getItem('virtualInventoryAccess') === 'true';
    },

    setVirtualInventoryAccess(virtualInventoryAccess) {
      localStorage.setItem('virtualInventoryAccess', virtualInventoryAccess);
    },

    hasNotificationsAccess() {
      return localStorage.getItem('notificationsAccess') === 'true';
    },

    setNotificationsAccess(notificationsAccess) {
      localStorage.setItem('notificationsAccess', notificationsAccess);
    },

    hasPaymentSolutionsAccess() {
      return localStorage.getItem('paymentSolutionsAccess') === 'true';
    },

    setPaymentSolutionsAccess(paymentSolutionsAccess) {
      localStorage.setItem('paymentSolutionsAccess', paymentSolutionsAccess);
    },

    getToken() {
      const token = localStorage.getItem('token');
      const expiration = localStorage.getItem('expiration');

      if (!token || !expiration) return null;
      if (Math.round(new Date().getTime() / 1000) > expiration) {
        this.destroyToken();
        return null;
      }

      return token;
    },

    getRefreshToken() {
      const token = localStorage.getItem('refresh_token');
      const expiration = localStorage.getItem('refresh_expiration');

      if (!token || !expiration) return null;
      if (Math.round(new Date().getTime() / 1000) > expiration) {
        this.destroyRefreshToken();
        return null;
      }

      return token;
    },

    destroyToken() {
      localStorage.removeItem('token');
      localStorage.removeItem('expiration');
    },

    destroyRefreshToken() {
      localStorage.removeItem('refresh_token');
      localStorage.removeItem('refresh_expiration');
    },

    isAuthenticated() {
      return !!this.getToken() || !!this.getRefreshToken();
    },

    checkTries() {
      return localStorage.getItem('tries');
    },

    getTries() {
      return localStorage.getItem('tries');
    },

    setTries(tries) {
      return localStorage.setItem('tries', tries);
    },

    destroyTries() {
      return localStorage.removeItem('tries');
    },

    destroySwitchUser() {
      localStorage.removeItem('x-switch-user');
    },

    destroyPrivileges() {
      localStorage.removeItem('balanceAccess');
      localStorage.removeItem('payoutAccess');
      localStorage.removeItem('subAccountsAccess');
      localStorage.removeItem('virtualInventoryAccess');
      localStorage.removeItem('notificationsAccess');
      localStorage.removeItem('paymentSolutionsAccess');
    }
  };

  Object.defineProperties(Vue.prototype, {
    $auth: {
      get() {
        return Vue.auth;
      }
    }
  })
}
