import api from '@/api/payoutAccounts';

import {
  STATUS_OK,
  STATUS_CREATED,
  STATUS_VALIDATION_ERROR,
  STATUS_DELETED
} from '@/constants/httpCodes';


export default {
  state: {
    payoutAccounts: null,
    payoutAccountsLinks: null,
  },
  getters: {},
  mutations: {
    SET_PAYOUT_ACCOUNTS_DATA(state, payload) {
      state.payoutAccounts = payload;
    },
    SET_PAYOUT_ACCOUNTS_LINKS_DATA(state, payload) {
      state.payoutAccountsLinks = payload;
    },
  },
  actions: {
    fetchPayoutAccounts({
      commit,
      dispatch,
    }, payload) {
      dispatch('setIsProgressData', true);
      api.get(payload)
        .then(({
          data: {
            items,
            links,
          },
          status
        }) => {
          if (status === STATUS_OK) {
            commit('SET_PAYOUT_ACCOUNTS_DATA', items);
            commit('SET_PAYOUT_ACCOUNTS_LINKS_DATA', links);
          }
        })
        .finally(() => {
          dispatch('setIsProgressData', false);
        })
    },
    async postPayoutAccounts({
      dispatch,
      rootState
    }, payload) {
      dispatch('setIsProgressData', true);
      const response = await api.post(payload)
        .then(({
          status
        }) => {
          if (status === STATUS_CREATED) {
            return {
              status: true,
              text: rootState.errors.notifyMessages.payoutAccountsSuccess
            }
          }
        })
        .catch(error => {
          let errorResult = rootState.errors.notifyMessages.somethingWrong;

          if (error.response.status === STATUS_VALIDATION_ERROR) {
            errorResult = error.response.data.errors.data;
          }

          return {
            text: errorResult,
            status: false
          }
        })
        .finally(() => {
          dispatch('setIsProgressData', false);
        });

      return response;
    },
    async deletePayoutAccount({
      dispatch,
      rootState
    }, payload) {
      dispatch('setIsProgressData', true);
      const response = await api.delete(payload)
        .then(({
          status
        }) => {
          if (status === STATUS_DELETED) {
            return {
              status: true,
              text: rootState.errors.notifyMessages.removePayoutAccount
            }
          }
        })
        .catch(() => {
          return {
            status: false,
            text: rootState.errors.notifyMessages.somethingWrong
          }
        })
        .finally(() => {
          dispatch('setIsProgressData', false);
        });

      return response;
    },
  },
};
