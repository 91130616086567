import axios from 'axios';
import BaseApi from './base';
import {
  BRANDED_PRODUCTS,
} from '@/constants/api';

export default class extends BaseApi {
  static post(data) {
    return new Promise((resolve, reject) => {
      return axios.post(this.baseURI + BRANDED_PRODUCTS, data)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  }

}
