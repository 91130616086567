<template>
  <v-card class="product-card" flat max-width="500">
    <router-link
      :to="{ name: 'dashboard.products.product-details', params: { productId: productId } }"
    >
      <v-img
        @click="handleProductClick"
        :src="product.mainImage.src"
        :lazy-src="product.mainImage.src"
        :aspect-ratio="ratio"
        contain
      ></v-img>
    </router-link>
    <v-card-title class="product-card__content">
      <router-link
        :to="{ name: 'dashboard.products.product-details', params: { productId: productId } }"
      >
        <h5 class="product-card__content__title hover--red">{{ product.name }}</h5>
      </router-link>
    </v-card-title>
    <v-card-text class="pt-0 pb-2">
      <div class="product-card__subcontent">
        <p class="product-card__subcontent__title" style="margin-left: -3px">
          <Rating :rating="product.rating || 0" dense half-increments/>
        </p>
        <!-- <p class="product-card__subcontent__count">{{ product.reviews || 0 }}</p> -->
      </div>
    </v-card-text>
    <v-card-text class="pt-0 pb-2">
      <div class="product-card__subcontent">
        <p class="product-card__subcontent__title">{{lang.reviews}}</p>
        <p class="product-card__subcontent__count">{{ product.reviews || 0 }}</p>
      </div>
    </v-card-text>
    <v-card-text class="pt-0 pb-2">
      <div class="product-card__subcontent">
        <p class="product-card__subcontent__title">{{ lang.orders }}</p>
        <p class="product-card__subcontent__count">{{ product.orders || 0 }}</p>
      </div>
    </v-card-text>
    <v-card-text class="product-card__content">
      <h4 class="product-card__content__price">{{ totalPrice }}</h4>
    </v-card-text>
    <v-card-text class="product-card__action">
      <template v-if="channelId">
        <PrimaryButton
          v-if="!showGoToEdt"
          outline
          small
          block
          :loading="loading"
          @click:base-button="handleImportClick"
        >
          {{ btnText }}
          <v-icon class="product-card__action__icon">add</v-icon>
        </PrimaryButton>
        <PrimaryButton
          v-else
          outline
          small
          block
          :loading="loading"
          @click:base-button="handleGoToEditClick"
        >
          {{ btnEditText }}
          <v-icon class="product-card__action__icon">trending_flat</v-icon>
        </PrimaryButton>
      </template>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";

import PrimaryButton from "@/components/ButtonPrimary";
import Rating from "@/components/Rating";

export default {
  components: {
    PrimaryButton,
    Rating
  },
  props: {
    product: {
      type: Object,
      required: true
    },
    cardHeight: {
      type: String,
      default: "550"
    },
    imgHeight: {
      type: String,
      default: "300"
    },
    ratio: {
      type: String,
      default: "1"
    }
  },
  data() {
    return {
      btnText: "Add to my products",
      btnEditText: "Go to edit",
      btnClicked: false,
      loading: false,
      showGoToEdt: false,
      myProductId: null
    };
  },
  computed: {
    ...mapState({
      successMsg: state => state.errors.productMessages.success,
      errorMsg: state => state.errors.productMessages.somethingWrong
    }),
    ...mapGetters({
      progress: "getIsProgress"
    }),
    totalPrice() {
      const max = this.product.maxPrice;
      const min = this.product.minPrice;

      return "$" + (max !== min ? `${min} - ${max}` : max);
    },
    lang() {
      return {
        reviews: "Reviews",
        orders: "Orders"
      };
    },
    productId() {
      return this.product.id;
    },
    channelId() {
      return this.$salesChannel.get();
    }
  },
  methods: {
    ...mapActions(["addProductToImport", "setNotifyMsg"]),
    async handleImportClick() {
      this.loading = true;
      const { status, text, productId } = await this.addProductToImport({
        channelId: this.channelId,
        id: this.productId
      });
      this.loading = false;

      if (status) {
        this.setNotifyMsg({ text: text || this.successMsg, color: "success" });
        this.showGoToEdt = true;
        this.myProductId = productId;
      } else {
        this.setNotifyMsg({ text: text || this.errorMsg, color: "error" });
      }
    },
    handleProductClick() {
      if (!this.product.category) {
        this.$emit("click:product", this.product.id);
      } else {
        this.$emit("click:product", this.product.id, this.product.category.id);
      }
    },
    handleGoToEditClick() {
      this.$router.push({
        name: "dashboard.products.my.edit",
        params: { id: this.myProductId }
      });
    }
  }
};
</script>

<style lang="scss">
.product-card {
  border-radius: 4px !important;

  .product-card__content {
    .product-card__content__price,
    .product-card__content__title {
      font-family: "DIN Pro Bold" !important;
      font-size: 22px;
    }
    .product-card__content__price {
      color: $warning-color;
    }
    .product-card__content__title {
      display: block;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      font-size: 14px;
      line-height: 21px;
      text-overflow: ellipsis;
      overflow: hidden;
      width: 100%;
      cursor: pointer;
      min-height: 42px;
      &:hover {
        -webkit-line-clamp: 10;
      }
    }
  }

  .product-card__subcontent {
    display: flex;
    justify-content: space-between;
    * {
      font-size: 12px;
      display: inline-block;
    }
  }

  > .v-image {
    cursor: pointer;
  }

  .product-card__action {
    .v-btn__content {
      display: flex;
      justify-content: space-between;
      padding: 0 10px;
    }
    .product-card__action__icon {
      text-align: right;
      color: $title-color;
      font-size: 18px;
    }
  }
}
</style>
