<template>
  <Modal
    v-bind="$attrs"
    v-on="$listeners"
    @change:modal="onChangeModal"
    class="modal-add-payout"
    scrollable
  >
    <div slot="content">
      <h2 class="modal-add-payout__title">{{ lang.mainTitle }}</h2>
      <FormPaymentAccount :key="key" large class="modal-add-payout-form"/>
      <div class="modal-add-payout__btn">
        <ButtonPrimary small outline @click:base-button="onCancel">{{ lang.cancel }}</ButtonPrimary>
      </div>
    </div>
  </Modal>
</template>

<script>
import Modal from "@/components/Modal";
import FormPaymentAccount from "@/components/FormPaymentAccount";
import ButtonPrimary from "@/components/ButtonPrimary";

export default {
  inheritAttrs: false,
  components: {
    Modal,
    FormPaymentAccount,
    ButtonPrimary
  },
  data() {
    return {
      key: +new Date()
    };
  },
  computed: {
    lang() {
      return {
        mainTitle: "New accounts",
        cancel: "Cancel"
      };
    }
  },
  methods: {
    onCancel() {
      this.$emit("esc:modal");
    },
    onChangeModal(val) {
      if (!val) {
        this.key = +new Date();
      }
    }
  }
};
</script>

<style lang="scss">
.modal-add-payout {
  &__title {
    margin-bottom: 25px;
  }

  &__btn {
    text-align: right;
  }

  &-form {
    .payment-block {
      margin-bottom: 35px !important;

      .payment-accounts-button {
        margin-top: 15px;
        margin-left: 35px;
      }

      .button-webmoney {
        margin-top: 10px;
      }
    }
  }
}
</style>
