<template>
  <div class="table-sm" v-if="payouts && paginationsLinks">
    <BaseTable
      class="table-sm__content"
      :headers="headers"
      :items="items"
      show-pagination
      show-page-size
      :custom-pagination="paginationsLinks"
    ></BaseTable>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import * as statuses from "@/constants/payouts";
import getPaymentMethodMixin from "@/mixins/getPaymentMethodMixin";

export default {
  mixins: [getPaymentMethodMixin],
  data() {
    return {
      items: [],
      headers: [
        { text: "Created at", value: "datetime" },
        { text: "Payout date", value: "date" },
        { text: "Amount, $", value: "amount" },
        { text: "Commission, $", value: "commission" },
        { text: "Method", value: "method" },
        { text: "Status", value: "status" },
        { text: "Reject reason", value: "rejectReason" }
      ],
      statusesText: {
        [statuses.STATUS_PENDING]: "Pending",
        [statuses.STATUS_CONFIRMED]: "Confirmed",
        [statuses.STATUS_PAID]: "Paid",
        [statuses.STATUS_FAILED]: "Failed",
        [statuses.STATUS_CANCELED]: "Canceled",
        [statuses.STATUS_REJECTED]: "Rejected",
        [statuses.STATUS_PROCESSING]: "Processing"
      }
    };
  },
  computed: {
    ...mapState({
      payouts: state => state.payouts.payouts,
      paginationsLinks: state => state.payouts.payoutsLinks
    })
  },
  methods: {
    ...mapActions(["fetchPayouts"]),
    fetchPayoutsWithFilters(filters = {}) {
      const newFilters = {
        page: 1,
        page_size: 20,
        ...this.$route.query,
        ...filters
      };

      this.fetchPayouts(newFilters);
    }
  },
  watch: {
    payouts: {
      handler(payouts) {
        const statusTag = status => {
          switch (status) {
            case statuses.STATUS_PAID:
              return "success";
            case statuses.STATUS_CONFIRMED:
              return "waiting";
            case statuses.STATUS_FAILED:
              return "failed";
            case statuses.STATUS_REJECTED:
              return "danger";
            case statuses.STATUS_CANCELED:
              return "danger";
            case statuses.STATUS_PROCESSING:
              return "waiting";
            default: {
              return "neutral";
            }
          }
        };

        const tempItems =
          payouts &&
          payouts.map(item => {
            return {
              datetime: window.moment(item.createdAt).format("LL"),
              date: window.moment(item.payoutDate).format("LL"),
              amount: item.totalSum.toFixed(2),
              commission: item.commission !== null ? item.commission.toFixed(2) : 0,
              method: this.getPaymentMethod(item.payoutAccount),
              status: `<div class="tag-status tag-status--${statusTag(
                item.status
              )}">${this.statusesText[item.status]}</div>`,
              rejectReason: item.rejectReason
            };
          });
        this.items = tempItems;
      }
    },
    "$route.query": {
      immediate: true,
      handler(newVal) {
        if (newVal) {
          this.fetchPayoutsWithFilters();
        }
      }
    }
  }
};
</script>

<style lang="scss">
.table-sm {
  .v-table thead tr {
    height: 40px;
  }
}
</style>
