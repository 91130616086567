export default {
  state: {
    isLoading: false,
    isProgress: false,
  },
  getters: {
    getIsLoading: state => state.isLoading,
    getIsProgress: state => state.isProgress,
  },
  mutations: {
    SET_IS_LOADING_DATA(state, payload) {
      state.isLoading = payload;
    },
    SET_IS_PROGRESS_DATA(state, payload) {
      state.isProgress = payload;
    },
  },
  actions: {
    setIsLoadingData({
      commit,
    }, payload) {
      commit('SET_IS_LOADING_DATA', payload);
    },
    setIsProgressData({
      commit,
    }, payload) {
      commit('SET_IS_PROGRESS_DATA', payload);
    },
  },
};
