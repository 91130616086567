<template>
  <v-card class="card-my-product" :class="{ selected: selected }" flat>
    <v-layout row wrap>
      <v-flex xs12 sm12 md3 class="pt-0 hidden-md-and-up">
        <div v-if="product.statusImport === ShopProductConst.STATUS_IMPORTED">
          <h6 class="card-my-product__title">{{ product.name }}</h6>
        </div>
        <div v-else>
          <router-link :to="{ name: 'dashboard.products.my.edit', params: { id: product.id } }">
            <h6 class="card-my-product__title">{{ product.name }}</h6>
          </router-link>
        </div>
        <router-link
          class="hidden-md-and-up"
          :to="{ name: 'dashboard.products.product-details', params: { productId: product.product.id } }"
        >
          <div class="original-link original-link--mobile">{{ lang.buttons.originalLink }}</div>
        </router-link>
      </v-flex>
      <v-flex xs5 sm4 md3 class="pt-0">
        <div v-if="product.statusImport === ShopProductConst.STATUS_IMPORTED">
          <v-img
            v-if="product.mainImage"
            :src="product.mainImage.src"
            :lazy-src="product.mainImage.src"
            class="card-my-product__img"
            max-width="118"
            max-height="118"
            contain
          ></v-img>
        </div>
        <div v-else>
          <router-link :to="{ name: 'dashboard.products.my.edit', params: { id: product.id } }">
            <v-img
              v-if="product.mainImage"
              :src="product.mainImage.src"
              :lazy-src="product.mainImage.src"
              class="card-my-product__img"
              max-width="118"
              max-height="118"
              contain
            ></v-img>
          </router-link>
        </div>
      </v-flex>
      <v-flex xs7 sm8 md9>
        <div v-if="product.statusImport === ShopProductConst.STATUS_IMPORTED">
          <h6 class="card-my-product__title hidden-sm-and-down">{{ product.name }}</h6>
        </div>
        <div v-else>
          <router-link :to="{ name: 'dashboard.products.my.edit', params: { id: product.id } }">
            <h6 class="card-my-product__title hidden-sm-and-down">{{ product.name }}</h6>
          </router-link>
        </div>
        <router-link
          class="hidden-xs-and-down"
          :to="{ name: 'dashboard.products.product-details', params: { productId: product.product.id } }"
        >
          <div class="original-link">{{ lang.buttons.originalLink }}</div>
        </router-link>
        <v-layout row wrap class="product-price-panel">
          <v-flex xs12 sm12 md3>
            <div class="card-my-product__label">{{ lang.labels.price }}</div>
            <h6 class="card-my-product__value">{{ totalPrice }}</h6>
          </v-flex>
          <v-flex xs12 sm12 md3>
            <div class="card-my-product__label">{{ lang.labels.cost }}</div>
            <h6 class="card-my-product__value">{{ totalCost }}</h6>
          </v-flex>
          <v-flex xs12 sm12 md3>
            <div class="card-my-product__label">{{ lang.labels.profit }}</div>
            <h6 class="card-my-product__value">{{ totalProfit }}</h6>
          </v-flex>
          <v-flex xs12 sm12 md3>
            <!-- <div class="card-my-product__label">{{ lang.labels.availability }}</div>
            <h6 class="card-my-product__value">{{ totalPrice }}</h6>-->
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
    <v-layout row wrap>
      <v-flex xs4 sm4 md3>
        <v-checkbox
          v-if="product.statusImport === ShopProductConst.STATUS_NOT_IMPORT"
          class="select-holder"
          :label="lang.labels.select"
          v-model="selected"
          color="primary"
          @change="handleCheckboxChange"
        />
      </v-flex>
      <v-flex xs8 sm8 md9 class="btn-panel">
        <router-link :to="{ name: 'dashboard.products.my.edit', params: { id: product.id } }">
          <ButtonPrimary
            v-if="product.statusImport === ShopProductConst.STATUS_NOT_IMPORT"
            small
            outline
            @click:base-button="handleEdit"
          >{{ lang.buttons.edit }}</ButtonPrimary>
        </router-link>
        <ButtonSecondary
          v-if="product.statusImport === ShopProductConst.STATUS_NOT_IMPORT"
          class="button-fixed-small"
          :loading="loadingRemove"
          small
          outline
          color="#a6a8a9"
          @click:base-button="handleRemoveProduct"
        >
          {{ lang.buttons.remove }}
          <v-icon>remove</v-icon>
        </ButtonSecondary>
        <ButtonSecondary
          v-if="product.product.status === ProductConst.STATUS_IMPORTED"
          class="button-fixed"
          :class="{'button-white': product.statusImport !== ShopProductConst.STATUS_NOT_IMPORT}"
          small
          color="#d32d11"
          :loading="loadingImport || product.statusImport === ShopProductConst.STATUS_IMPORT_PROCESSING"
          :outline="product.statusImport === ShopProductConst.STATUS_NOT_IMPORT"
          :disabled="product.statusImport === ShopProductConst.STATUS_IMPORTED && !product.storeUrl"
          @click:base-button="handleStoreButton"
        >
          {{ product.statusImport === ShopProductConst.STATUS_NOT_IMPORT ? lang.buttons.import : lang.buttons.open }}
          <v-icon v-if="product.statusImport === ShopProductConst.STATUS_IMPORTED">store</v-icon>
          <v-icon v-else>call_made</v-icon>
        </ButtonSecondary>
      </v-flex>
    </v-layout>
    <template  v-if="product.product.status === ProductConst.STATUS_IMPORTING">
      <v-layout row wrap>
        <v-flex xs12 sm12 md12 class="btn-panel">
          <Loader></Loader>&nbsp;Uploading images
        </v-flex>
      </v-layout>
    </template>
  </v-card>
</template>

<script>
import * as ProductConst from "@/constants/products";
import * as ShopProductConst from "@/constants/shopProducts";
import { TYPE_LANDING } from "@/constants/salesChannel";
import ButtonPrimary from "@/components/ButtonPrimary";
import ButtonSecondary from "@/components/ButtonSecondary";
import Loader from "@/components/Loader";

export default {
  components: {
    ButtonPrimary,
    ButtonSecondary,
    Loader
  },
  props: {
    product: {
      type: Object,
      required: true
    },
    select: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      selected: null,
      loadingRemove: false,
      loadingImport: false,
      ProductConst,
      ShopProductConst,
      TYPE_LANDING
    };
  },
  computed: {
    lang() {
      return {
        labels: {
          price: "Price",
          cost: "Cost",
          profit: "Profit",
          availability: "Availability",
          select: "Select"
        },
        buttons: {
          edit: "Edit",
          remove: "Remove",
          import: "Import to Store",
          open: "Edit In Store",
          originalLink: "Original link"
        }
      };
    },
    totalCost() {
      const max = this.product.maxCost;
      const min = this.product.minCost;

      return "$" + (max !== min ? `${min} - ${max}` : max);
    },
    totalPrice() {
      const max = this.product.maxPrice;
      const min = this.product.minPrice;

      return "$" + (max !== min ? `${min} - ${max}` : max);
    },
    totalProfit() {
      const max = this.product.maxProfit;
      const min = this.product.minProfit;

      return "$" + (max !== min ? `${min} - ${max}` : max);
    }
  },
  methods: {
    handleCheckboxChange() {
      this.$emit("change:select-my-products", {
        id: this.product.id,
        value: this.selected,
        status: this.product.statusImport
      });
    },
    async handleRemoveProduct() {
      this.loadingRemove = true;
      this.$emit("removed-product", this.product.id);
    },
    handleEdit() {
      this.$router.push({
        name: "dashboard.products.my.edit",
        params: { id: this.product.id }
      });
    },
    async handleStoreButton() {
      if (this.product.statusImport === ShopProductConst.STATUS_NOT_IMPORT) {
        this.loadingImport = true;
        this.$emit("import-store-products", this.product);
      }

      if (this.product.statusImport === ShopProductConst.STATUS_IMPORTED) {
        this.$emit("open-store-products", this.product.storeUrl);
      }
    }
  },
  mounted() {
    this.$root.$on("set-my-products-checkbox-value", val => {
      this.selected = val;
    });
    this.$root.$on("send-my-products-response", () => {
      this.loadingImport = false;
      this.loadingRemove = false;
      this.selected = null;
    });

    this.$root.$on("disabled-import-button", ({ ids, value }) => {
      if (value) {
        if (ids.includes(this.product.id)) this.loadingImport = value;
      } else {
        this.loadingImport = value;
      }
    });
  },
  beforeDestroy() {
    this.$root.$off("checkbox-select-all");
    this.$root.$off("click:base-button");
    this.$root.$off("set-my-products-checkbox-value");
  },
  watch: {
    select: {
      handler(val) {
        this.selected = val;
        this.handleCheckboxChange();
      }
    }
  }
};
</script>

<style lang="scss">
$padding-mobile: 7px;

.card-my-product {
  margin: 0;
  padding: 8px 0;
  overflow: hidden;
  min-height: 100%;
  border-radius: 4px;
  background-color: #fff;
  transition: border-color 0.35s ease;
  &.selected {
    border-color: #020101;
  }

  .card-my-product__title {
    font-size: 14px;
    line-height: 20px;
    padding-right: 10px;
    @include breakpoint($t) {
      margin: 0 0 12px;
      padding: $padding-mobile $padding-mobile 0;
    }
  }

  .card-my-product__label {
    width: 60px;
    flex-shrink: 0;
    font-size: 12px;
    line-height: 16px;
    color: $text-sidebar-color;
  }

  .card-my-product__value {
    font-size: 14px;
    line-height: 16px;
    font-weight: 500;
  }
  .v-input--selection-controls {
    margin: 0;
    padding: 0;
  }
  .btn-panel {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin: -8px -8px 0;
    justify-content: flex-end;
    @include breakpoint($ds) {
      margin: -6px -6px 0;
    }
    @include breakpoint($t) {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
    .v-btn {
      @include breakpoint($ds) {
        margin: 6px;
      }
    }
  }
  .v-messages {
    display: none;
  }
  .v-input--selection-controls:not(.v-input--hide-details) .v-input__slot {
    margin: 0;
  }
  .original-link {
    font-size: 8pt;
    margin-top: 5px;
  }
  .original-link--mobile {
    padding: 0 $padding-mobile;
  }
  .select-holder {
    margin-bottom: 8px;
    padding-left: 15px;
    @include breakpoint($t) {
      padding-left: $padding-mobile;
    }
    .v-label {
      color: #020101;
      font-size: 14px;
      line-height: 1.2;
    }
  }
  .product-price-panel {
    @include breakpoint($t) {
      margin: 0 !important;
    }
    > .flex {
      @include breakpoint($t) {
        display: flex;
        margin: 0 0 8px;
        padding: 0 !important;
        align-items: flex-start;
        justify-content: flex-start;
      }
    }
  }
}
</style>
