<template>
  <div class="pagination" v-if="pagination">
    <v-pagination
      class="pagination__list hidden-xs-only"
      v-model="page"
      :length="pagination.last"
      :total-visible="totalVisible"
      @input="$emit('click:pagination', page)"
    ></v-pagination>
    <template v-if="pagination.self != pagination.last">
      <div
        class="pagination__link hidden-sm-and-up"
      >{{ lang.page + " " + pagination.self + " " + lang.of + " " + pagination.last }}</div>
      <div class="pagination__link" @click="$emit('click:pagination', ++page)">{{ lang.next }}</div>
      <div
        class="pagination__link"
        @click="$emit('click:pagination', pagination.last)"
      >{{ lang.last }}</div>
    </template>
    <template v-else-if="pagination.self != pagination.first">
      <div
        class="pagination__link hidden-sm-and-up"
        @click="$emit('click:pagination', --page)"
      >{{ lang.prev }}</div>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    pagination: {
      type: Object,
      required: true
    },
    totalVisible: {
      type: String,
      default: "10"
    }
  },
  data() {
    return {
      page: null
    };
  },
  computed: {
    lang() {
      return {
        next: "Next",
        last: "Last",
        page: "Page",
        prev: "Prev",
        of: "of"
      };
    }
  },
  watch: {
    pagination: {
      immediate: true,
      handler() {
        this.page = this.pagination.self;
      }
    }
  }
};
</script>

<style lang="scss">
.pagination {
  display: flex;
  align-items: center;
  .pagination__list {
    .v-pagination__navigation {
      display: none;
    }
    .v-pagination__item {
      margin: 0;
      outline: none !important;
      border: none;
      font-size: 14px;
      box-shadow: none;
      min-width: 24px !important;
      height: 24px !important;
    }
    .v-pagination__item--active {
      color: $text-sidebar-color !important;
      background-color: transparent !important;
    }
  }
  .pagination__link {
    display: inline-block;
    padding: 0 10px;
    cursor: pointer;
    @include breakpoint($m) {
      padding: 0 5px;
    }
  }

  .v-pagination__item,
  .pagination__link {
    color: $text-color !important;
    font-size: 14px !important;
    background: transparent !important;

    &:hover {
      color: $hover-color !important;
    }

    &:last-child {
      padding-right: 0;
    }
  }
}
</style>
