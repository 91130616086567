<template>
  <Modal v-bind="$attrs" v-on="$listeners" @change:modal="onChangeModal" scrollable>
    <div slot="content">
      <div class="modal-request">
        <h2 class="modal-request__title">{{ lang.mainTitle }}</h2>
        <v-container grid-list-lg class="pa-0">
          <div class="commission">
            <p>Commission: webmoney 2%, bank wire $25</p>
          </div>
          <v-layout wrap>
            <v-flex md4>
              <InputText
                :hide-details="false"
                :value="form.totalSum"
                @input:base-text="onInputAmount"
                :error-messages="Object.keys(errors) ? errors.totalSum : []"
              >{{ lang.amount }}</InputText>
            </v-flex>
            <v-flex md8>
              <InputSelect
                :hide-details="false"
                :items="paymentSystems"
                item-text="name"
                item-value="id"
                @change:base-select="handlePaymentSystems"
                :value="form.payoutAccount"
                :error-messages="Object.keys(errors) ? errors.payoutAccount : []"
              >{{ lang.paymentSystem }}</InputSelect>
              <div class="manage-account">
                <p class="hover--red" @click="toAccountSettings">{{ lang.manageAccounts }}</p>
              </div>
            </v-flex>
          </v-layout>
        </v-container>
        <div class="modal-request__btn">
          <ButtonPrimary
            small
            @click:base-button="handleCreateRequest"
            :loading="progress"
          >{{ lang.btnText }}</ButtonPrimary>
          <ButtonPrimary small outline @click:base-button="onCancel">{{ lang.cancel }}</ButtonPrimary>
        </div>
      </div>
    </div>
  </Modal>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";

import Modal from "@/components/Modal";
import ButtonPrimary from "@/components/ButtonPrimary";
import InputText from "@/components/InputText";
import InputSelect from "@/components/InputSelect";
import getPaymentMethodMixin from "@/mixins/getPaymentMethodMixin";

export default {
  inheritAttrs: false,
  mixins: [getPaymentMethodMixin],
  components: {
    Modal,
    ButtonPrimary,
    InputText,
    InputSelect
  },
  data() {
    return {
      form: {
        currency: "USD",
        totalSum: "",
        payoutAccount: null
      },
      paymentSystems: [],
      errors: {}
    };
  },
  computed: {
    ...mapState({
      payoutAccounts: state => state.payoutAccounts.payoutAccounts
    }),
    ...mapGetters({
      progress: "getIsProgress"
    }),
    lang() {
      return {
        mainTitle: "New Request",
        btnText: "Create",
        cancel: "Cancel",
        amount: "Amount, $",
        paymentSystem: "Payment system",
        manageAccounts: "Manage accounts"
      };
    }
  },
  methods: {
    ...mapActions([
      "postPayout",
      "setNotifyMsg",
      "fetchPayouts",
      "fetchPayoutAccounts"
    ]),
    async handleCreateRequest() {
      this.errors = {};
      const { status, text } = await this.postPayout(this.form);

      if (status) {
        this.setNotifyMsg({ color: "success", text });
        this.fetchPayouts();
        this.$emit("esc:modal");
      } else {
        this.errors = { ...text };
        this.isDisabledCreated = false;
      }
    },
    onInputAmount(totalSum) {
      this.form = { ...this.form, totalSum };
    },
    onCancel() {
      this.$emit("esc:modal");
    },
    onChangeModal(val) {
      if (!val) {
        this.form = { ...this.form, totalSum: "", payoutAccount: null };
      }
    },
    handlePaymentSystems(payoutAccount) {
      if (typeof payoutAccount !== "undefined") {
        this.form = { ...this.form, payoutAccount };
      }
    },
    toAccountSettings() {
      window.localStorage.setItem("active_tab", 1);
      this.$router.push({ name: "dashboard.account-settings" });
    }
  },
  watch: {
    payoutAccounts: {
      immediate: true,
      handler(items) {
        this.paymentSystems =
          items &&
          items.map(item => {
            return {
              name: this.getPaymentMethod(item),
              id: item.id
            };
          });
      }
    }
  }
};
</script>

<style lang="scss">
.modal-request {
  .modal-request__title {
    font-size: 30px;
    margin-bottom: 20px;
  }
  .modal-request__btn {
    display: flex;
    flex-direction: row-reverse;
    margin-top: 30px;
    text-align: right;
    @include breakpoint($m) {
      text-align: left;
    }
  }

  .v-text-field__details {
    position: absolute;
    top: 35px;
  }

  .manage-account {
    margin-top: 10px;
    font-size: 12px;
    cursor: pointer;
  }

  .commission {
    font-size: 12px;
  }
}
</style>
