<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="width"
    :height="height"
    fill="none"
    :viewBox="`0 0 ${width} ${height}`"
  >
    <path :stroke="stroke" d="M8.5.592l7.988 4.611V16.99H.512V5.203L8.5.592z"></path>
    <path
      :stroke="stroke"
      d="M3.376 11.793H8.5v5.124H3.376zM8.5 11.793h5.124v5.124H8.5zM3.376 6.669H8.5v5.124H3.376z"
    ></path>
  </svg>
</template>

<script>
export default {
  props: {
    width: {
      type: [Number, String],
      default: 17
    },
    height: {
      type: [Number, String],
      default: 18
    },
    fill: {
      type: String,
      default: "#000"
    },
    stroke: {
      type: String,
      default: "#7A7C7E"
    }
  }
};
</script>

<style scoped>
</style>
