<template>
  <div>
    <h3>{{ lang.title }}</h3>
    <br class="hidden-xs-only" />
    <Breadcrumbs :items="breadcrumbs"></Breadcrumbs>
    <v-container grid-list-xl class="pa-0 no-width" v-if="order">
      <div
        class="pay-btn"
        v-if="order.orderCalculation && (order.status == 2 && order.orderCalculation.shipmentPaymentStatus == 10)"
      >
        <ButtonPrimary
          small
          outline
          :loading="loading"
          @click:base-button="handlePayOrder(order.id)"
        >{{ lang.pay }}</ButtonPrimary>
      </div>
      <v-layout row wrap>
        <v-flex xs12 sm6 md5>
          <CardDetails v-if="cardData.order" :title="lang.cardOrder.title" :data="cardData.order" />
        </v-flex>
        <v-flex xs12 sm6 md5>
          <v-layout column>
            <v-flex>
              <CardDetails
                v-if="cardData.delivery"
                :title="lang.cardDelivery.title"
                :data="cardData.delivery"
              />
            </v-flex>
            <v-flex>
              <CardDetails
                v-if="cardData.customer"
                :title="lang.cardCustomer.title"
                :data="cardData.customer"
              />
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
    </v-container>
    <v-container class="pa-0 no-width">
      <v-layout>
        <v-flex>
          <TableProductsOfOrders />
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import CardDetails from "@/components/CardDetails";
import Breadcrumbs from "@/components/Breadcrumbs";
import TableProductsOfOrders from "@/components/Tables/TableProductsOfOrders";
import ButtonPrimary from "@/components/ButtonPrimary";

export default {
  components: {
    CardDetails,
    Breadcrumbs,
    TableProductsOfOrders,
    ButtonPrimary
  },
  data() {
    return {
      orderFilters: {
        groups: "items,Items.trackers,delivery,customer,Order.Calculation"
      },
      cardData: {
        order: null,
        delivery: null,
        customer: null
      }
    };
  },
  computed: {
    ...mapState({
      successMsg: state => state.errors.notifyMessages.success,
      errorMsg: state => state.errors.notifyMessages.somethingWrong
    }),
    ...mapGetters({
      order: "getOrder",
      loading: "getIsProgress"
    }),
    orderId() {
      return this.$route.params.id;
    },
    lang() {
      return {
        title: "Order Details",
        cardOrder: {
          title: `Order ${
            this.cardData.order && this.cardData.order.Name
              ? this.cardData.order.Name
              : `#${this.orderId}`
          }`
        },
        cardDelivery: {
          title: "About delivery"
        },
        cardCustomer: {
          title: "About customer"
        },
        pay: "Pay order"
      };
    },
    breadcrumbs() {
      if (this.cardData && this.cardData.order) {
        return [
          {
            text: "Orders",
            disabled: false,
            href: {
              name: "dashboard.orders"
            }
          },
          {
            text: `Order ${
              this.cardData.order && this.cardData.order.Name
                ? this.cardData.order.Name
                : `#${this.orderId}`
            }`,
            disabled: true,
            href: { name: "" }
          }
        ];
      }

      return [];
    }
  },
  methods: {
    ...mapActions([
      "fetchOrderById",
      "payOrders",
      "getCurrentUser",
      "setNotifyMsg"
    ]),
    prepareDataToCards(order) {
      this.prepareCardOrderData(order);
      this.prepareCardDeliveryData(order.delivery);
      this.prepareCardCustomerData(order.customer);
    },
    prepareCardOrderData(data) {
      if (data) {
        this.cardData.order = {
          ID: data.name ? data.name.slice(1) : data.id,
          Email: data.email,
          "Closed at":
            data.closedAt && window.moment(data.closedAt).format("LLL"),
          "Created at":
            data.createdAt && window.moment(data.createdAt).format("LLL"),
          "Updated at":
            data.updatedAt && window.moment(data.updatedAt).format("LLL"),
          Number: data.number,
          Note: data.note,
          Test: data.test ? "Yes" : "No",
          "Total price": data.totalPrice,
          "Subtotal price": data.subtotalPrice,
          "Total weight": data.totalWeight,
          "Total tax": data.totalTax,
          "Taxes included": data.taxesIncluded ? "Yes" : "No",
          "Financial status": data.financialStatus,
          Confirmed: data.confirmed ? "Yes" : "No",
          Name: data.name,
          "Canceled at":
            data.cancelledAt && window.moment(data.cancelledAt).format("LLL"),
          "Canceled reason": data.cancelReason,
          "Total price USD": data.totalPriceUsd,
          "Processed at":
            data.processedAt && window.moment(data.processedAt).format("LLL"),
          Phone: data.phone
        };
      }
    },
    prepareCardDeliveryData(data) {
      if (data) {
        this.cardData.delivery = {
          "First Name": data.firstName,
          "Last Name": data.lastName,
          Country: data.country ? data.country.name : "",
          City: data.city,
          ZIP: data.zip,
          Province: data.province,
          Phone: data.phone,
          "Address 1": data.address1,
          "Address 2": data.address2,
          Company: data.company
        };
      }
    },
    prepareCardCustomerData(data) {
      if (data) {
        this.cardData.customer = {
          "First Name": data.firstName,
          "Last Name": data.lastName,
          Email: data.email
        };
      }
    },
    async handlePayOrder(id) {
      const { text, status } = await this.payOrders({ orders: [id] });
      if (status) {
        this.setNotifyMsg({ text: this.successMsg, color: "success" });
        this.getCurrentUser();
        const data = { id: this.orderId, filters: { ...this.orderFilters } };
        this.fetchOrderById(data);
      } else {
        this.setNotifyMsg({ text: text[0] || this.errorMsg, color: "error" });
      }
    }
  },
  watch: {
    "$route.params": {
      immediate: true,
      handler() {
        const data = { id: this.orderId, filters: { ...this.orderFilters } };
        this.fetchOrderById(data);
      }
    },
    order: {
      handler(order) {
        if (order) {
          this.prepareDataToCards(order);
          this.$root.$emit(
            "set-html-title-name",
            `Ecombo - Order Details #${order.id}`
          );
        }
      }
    }
  },
  beforeDestroy() {
    this.$off("click:base-button");
  }
};
</script>

<style scoped>
.pay-btn {
  margin: 10px 0;
}
</style>
