import { render, staticRenderFns } from "./IconInventory.vue?vue&type=template&id=069a2ae6&scoped=true&"
import script from "./IconInventory.vue?vue&type=script&lang=js&"
export * from "./IconInventory.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "069a2ae6",
  null
  
)

component.options.__file = "IconInventory.vue"
export default component.exports