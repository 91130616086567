<template>
  <div class="table-pa" v-if="items && payoutAccountsLinks">
    <BaseTable
      class="table-pa__content hide-first-id"
      :headers="headers"
      :items="items"
      show-pagination
      show-page-size
      :custom-pagination="payoutAccountsLinks"
      show-details
      action-text="Delete"
      @get-action="onRemovePayoutAccount"
    ></BaseTable>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import getPaymentMethodMixin from "@/mixins/getPaymentMethodMixin";

export default {
  mixins: [getPaymentMethodMixin],
  data() {
    return {
      items: [],
      headers: [
        { text: "ID", value: "id" },
        { text: "Method", value: "method" }
      ]
    };
  },
  computed: {
    ...mapState({
      payoutAccounts: state => state.payoutAccounts.payoutAccounts,
      payoutAccountsLinks: state => state.payoutAccounts.payoutAccountsLinks
    })
  },
  methods: {
    ...mapActions([
      "fetchPayoutAccounts",
      "deletePayoutAccount",
      "setNotifyMsg"
    ]),
    fetchPayoutAccountsWithFilters(filters = {}) {
      const newFilters = {
        page: 1,
        page_size: 20,
        ...this.$route.query,
        ...filters
      };

      this.fetchPayoutAccounts(newFilters);
    },
    async onRemovePayoutAccount(id) {
      const { status, text } = await this.deletePayoutAccount(id);

      this.setNotifyMsg({ color: status ? "success" : "error", text });

      if (status) {
        this.fetchPayoutAccountsWithFilters();
      }
    }
  },
  watch: {
    payoutAccounts: {
      handler(payoutAccounts) {
        const tempItems = payoutAccounts.map(item => {
          return {
            id: item.id,
            method: this.getPaymentMethod(item)
          };
        });
        this.items = tempItems;
      }
    },
    "$route.query": {
      immediate: true,
      handler(newVal) {
        if (newVal) {
          this.fetchPayoutAccountsWithFilters();
        }
      }
    }
  },
  mounted() {}
};
</script>

<style lang="scss">
</style>
