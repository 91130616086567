<template>
  <div class="inventory-start-block">
    <v-layout row wrap>
      <v-flex md6 xs12>
        <div class="inventory-start-main">
          <h2 class="inventory-start-main__title" v-text="data.title"/>
          <h3 class="inventory-start-main__subtitle" v-text="data.subtitle"/>
        </div>
        <div>
          <v-btn
            left
            dark
            block
            color="#d32d11"
            class="inventory-start-main__button"
            :to="{name: data.routerName}"
          >
            {{ data.btnText }}
            <IconArrowRight class="inventory-start-main__button-icon"/>
          </v-btn>
        </div>
      </v-flex>
      <v-flex md6>
        <v-img
          class="inventory-start-block__image hidden-sm-and-down"
          :src="require(`@/assets/images/${data.img}`)"
          :max-width="408"
          :max-height="402"
          contain
        ></v-img>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
import IconArrowRight from "@/components/Icons/IconArrowRight";

export default {
  components: {
    IconArrowRight
  },
  props: {
    data: {
      type: Object,
      required: true
    }
  }
};
</script>

<style lang="scss">
.inventory-start-block {
  position: relative;
  background-color: #fff;
  height: 402px;

  &__image {
    margin-left: auto;
  }

  .inventory-start-main {
    margin: 44px 0 46px 23px;

    @include breakpoint($ds) {
      margin: 0;
      padding: 15px 30px;
    }

    &__title {
      font-size: 44px;
      line-height: 46px;
      margin-bottom: 20px;

      @include breakpoint($t) {
        font-size: 30px;
        line-height: 32px;
      }
    }

    &__subtitle {
      color: #979797;
      font-size: 30px;
      line-height: 32px;
      // margin-bottom: 54px;
      @include breakpoint($ds) {
        font-size: 22px;
        line-height: 28px;
      }

      @include breakpoint($t) {
        font-size: 18px;
        line-height: 22px;
      }
    }

    &__button {
      position: absolute;
      bottom: 25px;
      max-width: 232px;
      border-radius: 100px;
      margin-left: 10px;

      .v-btn__content {
        font-size: 16px;
        justify-content: flex-start;
      }

      @include breakpoint($t) {
        // margin-bottom: 44px;
      }

      @include breakpoint($ds) {
        margin-left: 15px;
      }

      &:hover {
        position: absolute;
      }
    }

    &__button-icon {
      margin-left: auto;
    }
  }
}
</style>
