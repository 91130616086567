<template>
  <div class="product-desc">
    <h4 class="product-desc__title">{{ lang.title }}</h4>
    <div
      class="product-desc-main"
      v-html="description"
      :class="{'product-desc-main--mini': isMiniDesc}"
    ></div>
    <div class="product-desc__button" v-if="isMiniDesc">
      <ButtonTransparent @click:base-button="handleReadMoreClick">
        {{ lang.desc_more }}
        <v-icon>keyboard_arrow_down</v-icon>
      </ButtonTransparent>
    </div>
  </div>
</template>

<script>
import ButtonTransparent from "@/components/ButtonTransparent";

export default {
  components: {
    ButtonTransparent
  },
  props: {
    description: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      isMiniDesc: true
    };
  },
  computed: {
    lang() {
      return {
        title: "Product description",
        desc_more: "Read full description"
      };
    }
  },
  methods: {
    handleReadMoreClick() {
      this.isMiniDesc = false;
    }
  }
};
</script>

<style lang="scss">
.product-desc {
  padding: 25px 30px;
  background-color: #fff;
  border-radius: 4px;
  @include breakpoint($m) {
    padding: 12px;
  }

  .product-desc__title{
    padding: 25px 30px;
    @include breakpoint($t) {
      padding: 0;
      margin: 0 0 30px;
    }
    @include breakpoint($m) {
      margin-bottom: 15px;
    }
  }

  .product-desc {
    font-size: em(18);
    color: $text-sidebar-color;
  }

  .product-desc-main {
    margin-left: 25px;
    position: relative;
    @include breakpoint($t) {
      margin-left: 0;
    }
    img{
      display: block;
      max-width: 100%;
    }
  }

  .product-desc__button {
    text-align: center;
  }

  .product-desc-main--mini {
    max-height: 200px;
    overflow: hidden;

    :after {
      background-image: linear-gradient(
        to bottom,
        rgba(255, 255, 255, 0),
        rgba(255, 255, 255, 0.12) 92%,
        #ffffff
      );
      pointer-events: none;
      position: absolute;
      bottom: 0;
      height: 100%;
      width: 100%;
      content: "";
    }
  }
}
</style>
