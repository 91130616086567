<template>
  <div class="search-autocomplete">
    <v-autocomplete
      clearable
      class="autocoplete-select"
      :class="{'hide-append-icon': hideAppendIcon}"
      :items="items"
      :placeholder="placeholder"
      v-bind="$attrs"
      v-on="$listeners"
      :search-input.sync="search"
      v-model="model"
    ></v-autocomplete>
  </div>
</template>

<script>
import { throttle } from "@/helpers/common";

export default {
  inheritAttrs: false,
  components: {},
  props: {
    placeholder: {
      type: String,
      default: "Search"
    },
    items: {
      type: Array,
      required: true
    },
    hideAppendIcon: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      search: null,
      model: null
    };
  },
  computed: {
    throttledSave: function() {
      return throttle(this.handleInput, 500);
    }
  },
  methods: {
    handleInput() {
      this.$emit("input:search-autocomplete", this.search);
    }
  },
  watch: {
    search: {
      handler() {
        this.throttledSave();
      }
    }
  }
};
</script>

<style lang="scss">
.search-autocomplete {
  .autocoplete-select {
    input {
      font-size: 12px !important;
      max-height: 30px;
    }

    i {
      font-size: 24px !important;
    }

    .v-input__icon--clear {
      margin-right: 5px;
      i {
        font-size: 12px !important;
      }
    }
  }

  .hide-append-icon {
    .v-input__icon--append {
      display: none;
    }
  }
}
</style>
