<template>
  <v-container class="pa-0 no-width base-table--filters" grid-list-md @enter.prevent="handleSearch">
    <v-layout wrap>
      <v-flex xs12 sm6 md1 class="base-table--filters__item">
        <InputSelect
          class="filter-group-by"
          @change:base-select="handleSelectedGroupBy"
          :items="groupBy"
          :value="filters.groupBy"
        >{{ lang.groupBy }}</InputSelect>
      </v-flex>
      <v-flex xs12 sm6 md3 class="base-table--filters__item">
        <InputDateTime
          @change:datetimepicker="handleDatepickerChange"
          :startedDate="startedDate"
          :key="keys.date"
        >{{ lang.period }}</InputDateTime>
      </v-flex>
      <v-flex xs12 sm12 md4 class="base-table--filters__item">
        <InputAutocomplete
          multiple
          clearable
          v-if="countries"
          :items="countries"
          :label="lang.country"
          @change="handleSelectedCountries"
          item-text="niceName"
          item-value="iso2"
          :selected="filters.countries"
          :key="keys.countries"
        />
      </v-flex>

      <v-flex xs12 md4>
        <ButtonPrimary
          small
          @click:base-button="handleSearch"
          class="base-table--filters__button"
        >Filter</ButtonPrimary>
        <ButtonPrimary
          small
          outline
          @click:base-button="handleResetFilters"
          class="base-table--filters__button button-reset"
        >Reset filters</ButtonPrimary>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import {
  GROUP_BY_DAY,
  GROUP_BY_MONTH,
  GROUP_BY_COUNTRY
} from "@/constants/groupBy";

import ButtonPrimary from "@/components/ButtonPrimary";
import InputSelect from "@/components/InputSelect";
import InputDateTime from "@/components/InputDateTime";
import InputAutocomplete from "@/components/InputAutocomplete";

export default {
  components: {
    InputSelect,
    ButtonPrimary,
    InputDateTime,
    InputAutocomplete
  },
  data() {
    return {
      groupBy: [GROUP_BY_DAY, GROUP_BY_MONTH, GROUP_BY_COUNTRY],
      startedDate: {
        start: null,
        end: null
      },
      filters: {
        dateFrom: null,
        dateTo: null,
        countries: [],
        groupBy: this.$filters.getOrdersStatisticGroupByFilter()
      },
      isInitPage: true,
      keys: {
        countries: +new Date(),
        date: +new Date()
      },
      enableCountriesFilterRequest: true
    };
  },
  computed: {
    ...mapGetters({
      countries: "getCountries"
    }),
    lang() {
      return {
        product: "Product",
        period: "Period",
        country: "Country",
        groupBy: "Group by"
      };
    }
  },
  methods: {
    handleSearch() {
      if (this.isInitPage) {
        return;
      }

      this.$router.replace({ query: { ...this.filters } });
      this.$emit("click:search-btn", this.filters);
    },
    handleDatepickerChange({ start, end }) {
      this.filters.dateFrom = start;
      this.filters.dateTo = end;
      this.$filters.setOrdersStatisticDateFilter(start, end);
      this.handleSearch();
    },
    handleSelectedCountries(val) {
      this.filters.countries = val;
    },
    handleSelectedGroupBy(val) {
      if (typeof val !== "undefined") {
        this.filters.groupBy = val;
        this.$filters.setOrdersStatisticGroupByFilter(val);
      } else {
        this.filters.groupBy = "";
      }

      this.handleSearch();
    },
    initCountryFilter() {
      const coutriesFilter = this.$filters.getOrdersStatisticCountryFilter();
      let queryCoutriesFilter = this.$route.query.countries;

      if (typeof queryCoutriesFilter === "undefined") {
        queryCoutriesFilter = coutriesFilter ? coutriesFilter.split(", ") : [];
      } else if (typeof queryCoutriesFilter === "string") {
        queryCoutriesFilter = [queryCoutriesFilter];
      }

      this.filters = {
        ...this.filters,
        ...this.$route.query,
        countries: queryCoutriesFilter
      };
    },
    handleResetFilters() {
      const start = window
        .moment()
        .startOf("month")
        .format("YYYY-MM-DD");
      const end = window
        .moment()
        .endOf("month")
        .format("YYYY-MM-DD");

      this.keys = { date: +new Date(), countries: +new Date() };
      this.startedDate = {
        start,
        end
      };
      this.filters = {
        dateFrom: start,
        dateTo: end,
        countries: [],
        groupBy: GROUP_BY_DAY
      };
    },
    setStartedDate() {
      const dateFrom = this.$route.query.dateFrom;
      const dateTo = this.$route.query.dateTo;
      var result = { start: dateFrom, end: dateTo };

      if (typeof dateFrom === "undefined" && typeof dateTo === "undefined") {
        result = { ...this.$filters.getOrdersStatisticDateFilter() };
      }

      this.startedDate = result;
    }
  },
  watch: {
    filters: {
      handler() {
        if (this.isInitPage) {
          this.isInitPage = false;
          this.handleSearch();
        }
      }
    }
  },
  created() {
    this.initCountryFilter();
    this.setStartedDate();
  },
  beforeDestroy() {
    this.$off();
  }
};
</script>

<style lang="scss">
</style>
