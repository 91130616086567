<template>
  <form class="payment-account" autocomplete="off">
    <v-layout
      wrap
      align-center
      class="payment-block"
      align-content-space-between
      justify-space-between
    >
      <v-flex xs7 :md4="!large" :md9="large">
        <InputText
          :value="webmoneyAccount"
          @input:base-text="handleWebmoneyAccount"
          placeholder="ZXXXXXXXXXXXX"
          :hideDetails="false"
          :rules="[rules.validWebmoney]"
          @update:error="onInputWebmoneyError"
          :background-color="inputsColor"
          browser-autocomplete="false"
        >{{ lang.webmoney }}</InputText>
      </v-flex>
      <v-flex xs3 md3>
        <ButtonPrimary
          small
          @click:base-button="handleSaveWebmoney"
          :loading="isLoadingWebmoney"
          :disabled="isDisabledWebmoney"
          class="payment-accounts-button button-webmoney"
        >{{ lang.save }}</ButtonPrimary>
      </v-flex>
    </v-layout>
    <v-layout
      wrap
      align-center
      class="payment-block"
      align-content-space-between
      justify-space-between
    >
      <v-flex xs7 :md4="!large" :md9="large">
        <InputText
          :value="capitalistAccount"
          @input:base-text="handleCapitalistAccount"
          placeholder="UXXXXXXXX"
          :hideDetails="false"
          :rules="[rules.validCapitalist]"
          @update:error="onInputCapitalistError"
          :background-color="inputsColor"
          browser-autocomplete="false"
        >{{ lang.capitalistAccount }}</InputText>
      </v-flex>
      <v-flex xs3 md3>
        <ButtonPrimary
          small
          @click:base-button="handleSaveCapitalist"
          :loading="isLoadingCapitalist"
          :disabled="isDisabledCapitalist"
          class="payment-accounts-button button-webmoney"
        >{{ lang.save }}</ButtonPrimary>
      </v-flex>
    </v-layout>
    <v-layout
      wrap
      align-center
      class="payment-block"
      align-content-space-between
      justify-space-between
    >
      <v-flex xs7 :md="!large" :md9="large">
        <InputText
          type="email"
          :value="paypalAccount"
          @input:base-text="handlePaypalAccount"
          :rules="[rules.validEmail]"
          @update:error="onInputPaypalError"
          :hideDetails="false"
          :background-color="inputsColor"
        >{{ lang.paypal }}</InputText>
      </v-flex>
      <v-flex xs3 md3>
        <ButtonPrimary
          small
          @click:base-button="handleSavePaypal"
          class="payment-accounts-button button-paypal"
          :disabled="isDisabledPaypal"
          :loading="isLoadingPaypal"
        >{{ lang.save }}</ButtonPrimary>
      </v-flex>
    </v-layout>
    <v-layout
      wrap
      align-center
      class="payment-block"
      align-content-space-between
      justify-space-between
    >
      <v-flex xs7 :md4="!large" :md9="large">
        <InputTextarea
          backgroundColor="white"
          :value="bankAccount"
          :rules="[rules.required]"
          :hide-details="false"
          @update:error="onInputBankAccountError"
          @input:base-text="handleBankAccount"
          browser-autocomplete="false"
          :background-color="inputsColor"
        >{{ lang.bankAccount }}</InputTextarea>
      </v-flex>
      <v-flex xs3 md3>
        <ButtonPrimary
          small
          :loading="isLoadingBankAccount"
          :disabled="isDisabledBankAccount"
          @click:base-button="handleSaveBankAccount"
          class="payment-accounts-button button-webmoney"
        >{{ lang.save }}</ButtonPrimary>
      </v-flex>
    </v-layout>
<!--    <v-layout-->
<!--      wrap-->
<!--      align-center-->
<!--      class="payment-block"-->
<!--      align-content-space-between-->
<!--      justify-space-between-->
<!--    >-->
<!--      <v-flex xs7 :md4="!large" :md9="large">-->
<!--        <InputText-->
<!--          :value="ePaymentsAccount"-->
<!--          @input:base-text="handleEPaymentsAccount"-->
<!--          placeholder="000-000000"-->
<!--          maxlength="10"-->
<!--          :hideDetails="false"-->
<!--          :rules="[rules.validEPayments]"-->
<!--          @update:error="onInputEPaymentsError"-->
<!--          :background-color="inputsColor"-->
<!--          browser-autocomplete="false"-->
<!--        >{{ lang.ePayments }}</InputText>-->
<!--      </v-flex>-->
<!--      <v-flex xs3 md3>-->
<!--        <ButtonPrimary-->
<!--          small-->
<!--          @click:base-button="handleSaveEPayments"-->
<!--          :loading="isLoadingEPayments"-->
<!--          :disabled="isDisabledEPayments"-->
<!--          class="payment-accounts-button"-->
<!--        >{{ lang.save }}</ButtonPrimary>-->
<!--      </v-flex>-->
<!--    </v-layout>-->
  </form>
</template>

<script>
import {
  PAYOUT_WEBMONEY,
  PAYOUT_PAYPAL,
  PAYOUT_BANK_ACOOUNT,
  PAYOUT_EPAYMENTS,
  PAYOUT_CAPITALIST
} from "@/constants/payoutSystems";
import { mapGetters, mapActions, mapState } from "vuex";
import { gaPayoutAccountSubmit } from "@/helpers/gaEvents";

import ButtonPrimary from "@/components/ButtonPrimary";
import InputText from "@/components/InputText";
import InputTextarea from "@/components/InputTextarea";

export default {
  props: {
    inputsColor: {
      type: String,
      default: "white"
    },
    large: {
      type: Boolean,
      default: false
    }
  },
  components: {
    InputText,
    InputTextarea,
    ButtonPrimary
  },
  data() {
    return {
      paypalAccount: null,
      webmoneyAccount: null,
      capitalistAccount: null,
      bankAccount: null,
      ePaymentsAccount: null,
      isDisabledPaypal: true,
      isDisabledCapitalist: true,
      isDisabledWebmoney: true,
      isDisabledEPayments: true,
      isDisabledBankAccount: true,
      isLoadingPaypal: false,
      isLoadingWebmoney: false,
      isLoadingEPayments: false,
      isLoadingBankAccount: false,
      isLoadingCapitalist: false,
    };
  },
  computed: {
    ...mapState({
      rules: state => state.validations.rules,
      payoutAccounts: state => state.payoutAccounts.payoutAccounts
    }),
    ...mapGetters({
      loading: "getIsLoading",
      paypal: "getPaypalPayoutAccount",
      webmoney: "getWebmoneyPayoutAccount"
    }),
    lang() {
      return {
        save: "Save",
        remove: "Remove",
        paypal: "Paypal account",
        webmoney: "Webmoney wallet",
        ePayments: "ePayments wallet",
        bankAccount: "Bank account",
        capitalistAccount: "Capitalist"
      };
    }
  },
  methods: {
    ...mapActions([
      "setNotifyMsg",
      "fetchPayoutAccounts",
      "postPayoutAccounts",
      "setNotifyMsg",
      "deletePayoutAccount"
    ]),
    handlePaypalAccount(val) {
      this.paypalAccount = val;
    },
    handleWebmoneyAccount(val) {
      this.webmoneyAccount = val;
    },
    handleCapitalistAccount(val) {
      this.capitalistAccount = val;
    },
    handleBankAccount(val) {
        this.bankAccount = val;
    },
    handleEPaymentsAccount(val) {
      this.ePaymentsAccount = val;
    },
    onInputPaypalError(val) {
      this.isDisabledPaypal = val;
    },
    onInputWebmoneyError(val) {
      this.isDisabledWebmoney = val;
    },
    onInputCapitalistError(val) {
      this.isDisabledCapitalist = val;
    },
    onInputBankAccountError(val) {
      this.isDisabledBankAccount = val;
    },
    onInputEPaymentsError(val) {
      this.isDisabledEPayments = val;
    },
    async handleSavePaypal() {
      this.isLoadingPaypal = true;

      const data = {
        paymentSystem: PAYOUT_PAYPAL,
        data: { email: this.paypalAccount }
      };
      const { status, text } = await this.postPayoutAccounts(data);

      this.setNotifyMsg({ color: status ? "success" : "error", text });
      this.isLoadingPaypal = false;

      if (status) {
        this.fetchPayoutAccounts({ ...this.$route.query });
        this.isDisabledPaypal = true;
        this.paypalAccount = null;
        gaPayoutAccountSubmit();
      }
    },
    async handleSaveWebmoney() {
      this.isLoadingWebmoney = true;

      const data = {
        paymentSystem: PAYOUT_WEBMONEY,
        data: { wallet: this.webmoneyAccount }
      };
      const { status, text } = await this.postPayoutAccounts(data);

      this.setNotifyMsg({ color: status ? "success" : "error", text });
      this.isLoadingWebmoney = false;

      if (status) {
        this.fetchPayoutAccounts({ ...this.$route.query });
        this.isDisabledWebmoney = true;
        this.webmoneyAccount = null;
        gaPayoutAccountSubmit();
      }
    },
    async handleSaveCapitalist() {
      this.isDisabledCapitalist = true;

      const data = {
        paymentSystem: PAYOUT_CAPITALIST,
        data: { wallet: this.capitalistAccount }
      };
      const { status, text } = await this.postPayoutAccounts(data);

      this.setNotifyMsg({ color: status ? "success" : "error", text });
      this.isLoadingWebmoney = false;

      if (status) {
        this.fetchPayoutAccounts({ ...this.$route.query });
        this.isDisabledCapitalist = true;
        this.capitalistAccount = null;
        gaPayoutAccountSubmit();
      }
    },
    async handleSaveBankAccount() {
        this.isLoadingBankAccount = true;

        const data = {
            paymentSystem: PAYOUT_BANK_ACOOUNT,
            data: { bankAccount: this.bankAccount }
        };
        const { status, text } = await this.postPayoutAccounts(data);

        this.setNotifyMsg({ color: status ? "success" : "error", text });
        this.isLoadingBankAccount = false;

        if (status) {
            this.fetchPayoutAccounts({ ...this.$route.query });
            this.isDisabledWebmoney = true;
            this.bankAccount = null;
            gaPayoutAccountSubmit();
        }
    },
    async handleSaveEPayments() {
      this.isLoadingEPayments = true;

      const data = {
        paymentSystem: PAYOUT_EPAYMENTS,
        data: { epid: this.ePaymentsAccount }
      };
      const { status, text } = await this.postPayoutAccounts(data);

      this.setNotifyMsg({ color: status ? "success" : "error", text });
      this.isLoadingEPayments = false;

      if (status) {
        this.fetchPayoutAccounts({ ...this.$route.query });
        this.isDisabledEPayments = true;
        this.ePaymentsAccount = null;
        gaPayoutAccountSubmit();
      }
    }
  }
};
</script>

<style lang="scss">
.payment-account {
  .payment-block {
    max-height: 90px;
  }

  .payment-accounts-button {
    float: right;
  }

  .button-webmoney {
    margin-top: 21px !important;
  }
}
</style>
