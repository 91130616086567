<template>
  <div class="balance">
    <h3 class="balance__title">{{ lang.title }}</h3>
    <v-container class="pa-0 no-width" grid-list-lg>
      <v-layout row wrap>
        <v-flex xs12 sm6 md2>
          <InputText
            :value="valueTotalSum"
            @input:base-text="handleTotalSumValue"
            type="number"
          >{{ lang.labelTotalSum }}</InputText>
        </v-flex>
        <v-flex xs12 sm6 md2>
          <InputSelect
            v-if="paymentMethods"
            :items="paymentMethods"
            item-text="name"
            item-value="id"
            @change:base-select="handleChangePaymentMethods"
            :value="paymentMethodId"
          >{{ lang.labelPaymentMethods }}</InputSelect>
        </v-flex>
      </v-layout>
    </v-container>
    <template v-if="paymentMethodId == STRIPE">
      <div class="balance-stripe-block">
        <v-flex row wrap>
          <v-flex md3>
            <FormStripeNative
              @stripe:success="handlePaymentSubmit"
              @repeat-transaction="isRepeatTransaction = $event"
            />
          </v-flex>
        </v-flex>
      </div>
    </template>
    <template v-else>
      <div class="balance__btn">
        <ButtonPrimary
          small
          :loading="loading"
          @click:base-button="handlePaymentSubmit"
        >{{ lang.btnPaymentText }}</ButtonPrimary>
      </div>
      <p
        class="balance__desc"
      >{{ paymentMethodId === PAYPAL ? lang.balancePaypalDesc : lang.balanceStripeDesc }}</p>
    </template>
    <div class="balance-history">
      <h4 class="balance-history__title">{{ lang.titleHistory }}</h4>
      <TableTransactions/>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapState } from "vuex";

import InputText from "@/components/InputText";
import TableTransactions from "@/components/Tables/TableTransactions";
import InputSelect from "@/components/InputSelect";
import ButtonPrimary from "@/components/ButtonPrimary";
import FormStripeNative from "@/components/FormStripeNative";

export default {
  components: {
    InputText,
    InputSelect,
    ButtonPrimary,
    TableTransactions,
    FormStripeNative
  },
  data() {
    return {
      valueTotalSum: "10.00",
      clientSecret: null,
      PAYPAL: 1,
      STRIPE: 3,
      paymentMethodId: 1,
      isRepeatTransaction: false
    };
  },
  computed: {
    ...mapState({
      errorAmount: state => state.errors.notifyMessages.noValidTransferAmount
    }),
    ...mapGetters({
      paymentMethods: "getPaymentMethods",
      apiResponse: "getPaymentResponse",
      loading: "getIsProgress"
    }),
    lang() {
      return {
        title: "Balance",
        titleHistory: "Billing History",
        labelTotalSum: "Amount you want to transfer",
        labelPaymentMethods: "Payment method",
        btnPaymentText: "Submit",
        balancePaypalDesc: "You’ll be redirected to Paypal for processing",
        balanceStripeDesc: "You’ll be redirected to Stripe for processing"
      };
    }
  },
  methods: {
    ...mapActions(["rechargeBalance", "setNotifyMsg"]),
    handleTotalSumValue(val) {
      this.valueTotalSum = val.replace(",", ".");
    },
    handleChangePaymentMethods(val) {
      this.paymentMethodId = val;
    },
    handlePaymentSubmit() {
      if (!this.checkTransferAmount(this.valueTotalSum)) {
        return;
      }

      let body = { totalSum: this.valueTotalSum };

      if (this.isRepeatTransaction) {
        const userTransaction = window.localStorage.getItem(
          "user_transaction_id"
        );

        if (userTransaction) {
          body = { ...body, userTransaction };
        }
      }

      this.rechargeBalance({
        body,
        id: this.paymentMethodId
      });
    },
    checkTransferAmount(amount) {
      if (amount >= 10) {
        return true;
      }

      this.setNotifyMsg({ text: this.errorAmount, color: "error" });

      return false;
    }
  },
  watch: {
    apiResponse: {
      handler({ redirectUrl, clientSecret, userTransactionId }) {
        if (this.paymentMethodId === this.PAYPAL) {
          if (typeof redirectUrl !== "undefined") {
            window.location.href = redirectUrl;
          }
        } else {
          if (
            typeof clientSecret !== "undefined" &&
            typeof redirectUrl !== "undefined"
          ) {
            this.$root.$emit("client-secret", {
              token: clientSecret,
              redirect: redirectUrl
            });
          }
          if (typeof userTransactionId !== "undefined") {
            window.localStorage.setItem(
              "user_transaction_id",
              userTransactionId
            );
          }
        }

        this.isRepeatTransaction = false;
      }
    }
  },
  beforeDestroy() {
    this.$off("stripe:success");
    this.$off("repeat-transaction");
    window.localStorage.removeItem("user_transaction_id");
  }
};
</script>

<style lang="scss">
.balance {
  max-width: $max-width;

  .balance__title {
    font-size: 30px;
    margin-bottom: 13px;
  }
  .balance__btn {
    margin-top: 40px;
    margin-left: -10px;
  }
  .balance__desc {
    font-size: 12px;
    line-height: 32px;
    margin-top: -5px;
  }

  .balance-stripe-block {
    margin-top: 10px;
  }

  .balance-history {
    margin-top: 60px;

    .balance-history__title {
      font-size: 18px;
      line-height: 32px;
      color: $text-sidebar-color;
    }
  }
}
</style>
