<template>
  <v-tabs v-model="activeTab" class="base-tabs" v-bind="$attrs" v-on="$listeners">
    <v-tab
      v-for="tab in tabs"
      :key="tab"
      v-bind="$attrs"
      v-on="$listeners"
      :class="[hiddenTabs]"
    >{{ tab }}</v-tab>
    <v-tabs-items v-model="activeTab" v-bind="$attrs" v-on="$listeners">
      <slot class="base-tabs__content"></slot>
    </v-tabs-items>
  </v-tabs>
</template>

<script>
export default {
  inheritAttrs: false,
  props: {
    tabs: {
      type: Array,
      required: true
    },
    hiddenTabs: {
      type: String
    },
    active: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      activeTab: this.active
    };
  },
  created() {}
};
</script>

<style lang="scss">
.base-tabs {
  .v-tabs {
    @include breakpoint($m) {
      margin-bottom: 36px;
    }
    &__bar {
      font-size: 14px;
      line-height: 1.2;
      background: none;

      &.theme--light {
        background: none;
        .v-tabs__item {
          color: #020101;
          background: #e1e2e2;
          &--active {
            background: #fff;
          }
        }
      }
    }

    &__container {
      height: 30px;
    }
    &__slider-wrapper {
      display: none;
    }
    &__item {
      font-weight: 500;
      padding: 4px 22px;
      text-transform: none;
    }
  }

  .v-window__container {
    background-color: #fff;
    padding: 15px 23px;
  }
}
</style>
