import Vuex from 'vuex';
import Vue from 'vue';

import common from './modules/common';
import forms from './modules/forms';
import categories from './modules/categories';
import translations from './modules/translations';
import products from './modules/products';
import countries from './modules/countries';
import orders from './modules/orders';
import salesChannels from './modules/salesChannels';
import statistics from './modules/statistics';
import dashboard from './modules/dashboard';
import suppliers from './modules/suppliers';
import shipmentMethods from './modules/shipmentMethods';
import tickets from './modules/tickets';
import paymentMethods from './modules/paymentMethods';
import transactions from './modules/transactions';
import errors from './modules/errors';
import version from './modules/version';
import notifications from './modules/notifications';
import banners from './modules/banners';
import validations from './modules/validations';
import payoutAccounts from './modules/payoutAccounts';
import payouts from './modules/payouts';
import paymentSolutions from './modules/paymentSolutions';
import users from './modules/users';
import gdprRequests from "@/store/modules/gdprRequests";

Vue.use(Vuex);

const modules = {
  common,
  forms,
  categories,
  translations,
  products,
  countries,
  orders,
  salesChannels,
  users,
  statistics,
  dashboard,
  suppliers,
  shipmentMethods,
  tickets,
  paymentMethods,
  transactions,
  errors,
  version,
  notifications,
  banners,
  validations,
  payoutAccounts,
  payouts,
  paymentSolutions,
  gdprRequests
};

export default new Vuex.Store({
  modules,
});
