import api from '@/api/gdprRequests';

export default {
  state: {
    requests: null
  },

  getters: {
    getRequests: state => state.requests
  },

  mutations: {
    SET_REQUESTS_DATA(state, payload) {
      state.requests = payload;
    }
  },

  actions: {
    async fetchRequests({
      commit,
      dispatch
    }, payload) {
      dispatch('setIsProgressData', true);

      const response = await api.getAll(payload.id);
      commit('SET_REQUESTS_DATA', response.data);

      dispatch('setIsProgressData', false);
    }
  }
};
