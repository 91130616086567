<template>
  <div class="sm">
    <h3 class="sm__title">{{ lang.title }}</h3>
    <v-container class="pa-0 no-width base-table--filters" grid-list-md>
      <v-layout wrap>
        <v-flex xs12 sm7 md3>
          <InputSelect
            :value="newSalesChannel.typeId"
            :items="newSalesChannelTypeIdChoices"
            @change:base-select="handleSalesChannelTypeId"
          >{{ lang.newSalesChannel.typeId.label }}</InputSelect>
        </v-flex>
        <v-flex xs12 sm7 md3>
          <InputText
            :value="newSalesChannel.name"
            :placeholder="lang.newSalesChannel.name.placeholder[newSalesChannel.typeId]"
            @input:base-text="handleSalesChannelName"
          >{{ lang.newSalesChannel.name.label }}</InputText>
        </v-flex>
        <v-flex xs12 sm5 align-self-center>
          <ButtonPrimaryAdd
            :loading="progress"
            small
            class="base-table--filters__button"
            @click:base-button="handleAddNewStore"
          >{{ lang.newSalesChannel.button }}</ButtonPrimaryAdd>
        </v-flex>
      </v-layout>
    </v-container>
    <TableStoreManagement />
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from "vuex";
import { gaConnectStoreSubmit } from "@/helpers/gaEvents";

import TableStoreManagement from "@/components/Tables/TableStoreManagement";
import InputText from "@/components/InputText";
import InputSelect from "@/components/InputSelect";
import ButtonPrimaryAdd from "@/components/ButtonPrimaryAdd";
import * as salesChannelTypes from "@/constants/salesChannel";
import { getTypeIdText } from "@/helpers/salesChannel";

export default {
  components: {
    TableStoreManagement,
    InputText,
    InputSelect,
    ButtonPrimaryAdd
  },
  data() {
    return {
      newSalesChannel: {
          name: '',
          typeId: salesChannelTypes.TYPE_SHOPIFY
      }
    };
  },
  computed: {
    ...mapState({
      noValidURLMsg: state => state.errors.notifyMessages.notValidUrl,
      successMsg: state => state.errors.notifyMessages.success,
      errorMsg: state => state.errors.notifyMessages.somethingWrong
    }),
    ...mapGetters({
      progress: "getIsProgress",
      currentUser: "getCurrentUser"
    }),
    lang() {
      return {
        title: "Store management",
        newSalesChannel: {
          name: {
            label: 'Store URL',
            placeholder: {
              [salesChannelTypes.TYPE_SHOPIFY]: 'your-store-name.myshopify.com',
              [salesChannelTypes.TYPE_WOO_COMMERCE]: 'https://your-store-domain'
            }
          },
          typeId: {
            label: 'Store platform'
          },
          button: 'Connect a new store'
        }
      };
    },
    newSalesChannelTypeIdChoices() {
      let result = [
        {
          value: salesChannelTypes.TYPE_SHOPIFY,
          text: getTypeIdText(salesChannelTypes.TYPE_SHOPIFY)
        }
      ];

      if (this.currentUser && this.currentUser.wooCommerceEnabled) {
        result.push({
          value: salesChannelTypes.TYPE_WOO_COMMERCE,
          text: getTypeIdText(salesChannelTypes.TYPE_WOO_COMMERCE)
        });
      }

      return result;
    }
  },
  methods: {
    ...mapActions([
      "postChannel",
      "setNotifyMsg",
      'getCurrentUser'
    ]),
    handleSalesChannelName(val) {
      this.newSalesChannel.name = val;
    },
    handleSalesChannelTypeId(val) {
      this.newSalesChannel.typeId = val;
    },
    async handleAddNewStore() {
      if (this.newSalesChannel.typeId === salesChannelTypes.TYPE_SHOPIFY &&
        this.newSalesChannel.name.indexOf("myshopify.com") === -1
      ) {
          this.setNotifyMsg({ text: this.noValidURLMsg, color: "error" });

          return;
      }

      const { status, text } = await this.postChannel({
        name: this.newSalesChannel.name,
        typeId: this.newSalesChannel.typeId,
        redirectUrl: window.location.href
      });

      if (status) {
        this.setNotifyMsg({
          text: text || this.successMsg,
          color: "success"
        });
        gaConnectStoreSubmit();
      } else {
        this.setNotifyMsg({ text: text || this.errorMsg, color: "error" });
      }
    }
  }
};
</script>

<style scoped lang="scss">
.sm {
  max-width: $max-width;
  margin: 0 auto;
  .sm__title {
    margin-bottom: 20px;
  }
  .sm__input {
    margin-bottom: 30px;
  }
}
</style>
