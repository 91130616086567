import axios from 'axios';
import BaseApi from './base';
import {
  SALES_CHANNELS,
  TRANSFER,
  LOGIN
} from '@/constants/api';

export default class extends BaseApi {
  static get() {
    return new Promise((resolve, reject) => {
      return axios.get(this.baseURI + SALES_CHANNELS)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  static getById(id, filters = null) {
    return new Promise((resolve, reject) => {
      return axios.get(this.baseURI + SALES_CHANNELS + '/' + id, {
          params: filters
        })
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  static postNewStore(data) {
    return new Promise((resolve, reject) => {
      return axios.post(this.baseURI + SALES_CHANNELS, data)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  static updateStore(data) {
    return new Promise((resolve, reject) => {
      return axios.put(this.baseURI + SALES_CHANNELS + '/' + data.id, data)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  static transferShop(data) {
    return axios.put(this.baseURI + SALES_CHANNELS + '/' + data.id + '/' + TRANSFER, data);
  }

  static setChannelLogin(id, data) {
    return new Promise((resolve, reject) => {
      return axios.post(this.baseURI + SALES_CHANNELS + '/' + id + '/' + LOGIN, data)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  static delete(id) {
    return new Promise((resolve, reject) => {
      return axios.delete(this.baseURI + SALES_CHANNELS + '/' + id)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  }
}
