<template>
  <BaseIcon
    :width="width"
    :height="height"
    stroke="none"
    :fill="fill"
    name="store"
    style="margin-left: -3px;"
  >
    <path
      d="M6.444 12.93H17.07a.586.586 0 0 0 .564-.425l2.343-8.203a.586.586 0 0 0-.563-.747H5.092L4.674 1.67a.586.586 0 0 0-.572-.46H.586a.586.586 0 1 0 0 1.173h3.046l2.115 9.52a1.76 1.76 0 0 0-1.06 1.613c0 .969.79 1.757 1.758 1.757H17.07a.586.586 0 1 0 0-1.171H6.445a.587.587 0 0 1-.001-1.172zm12.193-8.203l-2.009 7.03H6.915l-1.562-7.03h13.284z"
    />
    <path
      d="M5.86 17.031c0 .97.788 1.758 1.757 1.758a1.76 1.76 0 0 0 1.758-1.758 1.76 1.76 0 0 0-1.758-1.758 1.76 1.76 0 0 0-1.758 1.758zm1.757-.586a.587.587 0 1 1-.001 1.174.587.587 0 0 1 .001-1.174zM14.14 17.031c0 .97.79 1.758 1.758 1.758a1.76 1.76 0 0 0 1.758-1.758 1.76 1.76 0 0 0-1.758-1.758 1.76 1.76 0 0 0-1.757 1.758zm1.758-.586a.587.587 0 1 1 0 1.174.587.587 0 0 1 0-1.174z"
    />
  </BaseIcon>
</template>

<script>
export default {
  props: {
    width: {
      type: [Number, String],
      default: 18
    },
    height: {
      type: [Number, String],
      default: 18
    },
    fill: {
      type: String,
      default: "#7A7C7E"
    },
    stroke: {
      type: String,
      default: "none"
    }
  }
};
</script>

<style scoped>
</style>
