export const TYPE_SHOPIFY = 1;
export const TYPE_LANDING = 2;
export const TYPE_WOO_COMMERCE = 3;

export const PRICE_MODIFIER_ADD = 1;
export const PRICE_MODIFIER_MULTIPLY = 2;

export const STICKY_PRICE_ABSOLUTE = 1;
export const STICKY_PRICE_PERCENT = 2;
export const STICKY_PRICE_DISABLED = 3;

export const PAYMENT_TYPE_ECOMBO_PS = 1;
export const PAYMENT_TYPE_USER_PS = 2;
