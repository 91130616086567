<template>
  <v-container class="pa-0 no-width" grid-list-md>
    <v-layout wrap>
      <v-flex xs12 sm6 md4>
        <InputText
          v-on="$listeners"
          append-icon="search"
          :value="filters.email"
          @input:base-text="handleMatchText"
          @click:append="handleSearch"
          :placeholder="lang.matchTextPlaceholder"
        >{{ lang.matchText }}</InputText>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>

import InputText from "@/components/InputText";

export default {
  components: {
    InputText
  },
  data() {
    return {
      filters: {
        email:
          typeof this.$route.query.email !== "undefined"
            ? this.$route.query.email
            : null,
      }
    };
  },
  computed: {
    lang() {
      return {
        matchText: "Search sub accounts",
        matchTextPlaceholder: "Email"
      };
    }
  },
  methods: {
    handleMatchText(matchText) {
      this.filters.email = matchText;
    },
    handleSearch() {
      const queryFilters = {};
      const { email } = this.filters;

      if (email) {
        queryFilters.email = email;
      }

      this.$router.replace({ query: { ...queryFilters } });
      this.$emit("click:search-btn", this.filters);
    },
  },
  beforeDestroy() {
    this.$off("click:append");
  }
};
</script>

<style>
</style>
