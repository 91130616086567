<template>
  <div class="base-table base-table-notification">
    <ModalNotification
      :show="showModal"
      @esc:modal="onModalClose(false)"
      @change:modal="onModalClose"
    />
    <div class="base-table-header-pagination">
      <Pagination
        v-if="basePagination && items && items.length"
        :pagination="basePagination"
        @click:pagination="handlePaginationClick"
        class="base-table_header__pagination"
      />
    </div>
    <div class="base-table__selected_items" v-show="selected.length > 0">
      <slot name="selected-header">
        <!--  -->
      </slot>
    </div>
    <template v-if="$vuetify.breakpoint.xsOnly || $vuetify.breakpoint.smOnly">
      <div class="notification-mobile-wrapper">
        <div class="notification-mobile" v-for="(item, k) in items" :key="k">
          <div class="notification-mobile__header">
            <div :class="{'notification-read': item.isRead, 'notification-unread': !item.isRead}">•</div>
            <span v-html="item.datetime"></span>
          </div>
          <div class="notification-mobile__main" v-html="item.event"></div>
          <div class="notification-mobile__footer">
            <ButtonPrimary
              class="notification-mobile__footer-btn border-gray"
              outline
              small
              @click:base-button="showView(item.id)"
            >{{ lang.btnText }}</ButtonPrimary>
          </div>
        </div>
      </div>
    </template>
    <v-data-table
      v-else
      v-model="selected"
      :headers="headers"
      :items="items"
      hide-actions
      item-key="id"
      must-sort
      select-all
      :pagination.sync="pagination"
      :custom-sort="customSort"
      v-bind="$attrs"
    >
      <template slot="headers" slot-scope="props">
        <tr>
          <th
            class="text-xs-left"
            :class="['column sortable', pagination.descending ? 'desc' : 'asc', header.value === pagination.sortBy ? 'active' : '']"
            v-for="header in props.headers"
            :key="header.text"
            @click="changeSort(header.value)"
          >
            {{ header.text }}
            <v-icon small>keyboard_arrow_up</v-icon>
          </th>
          <th></th>
        </tr>
      </template>
      <template slot="items" slot-scope="props">
        <tr>
          <td class="notification-td">
            <div
              :class="{'notification-read': props.item.isRead, 'notification-unread': !props.item.isRead}"
            >•</div>
          </td>
          <td style="width: 200px" v-html="props.item.datetime"></td>
          <td v-html="props.item.event"></td>
          <td style="text-align: right; width: 100px">
            <ButtonPrimary
              class="border-gray"
              outline
              small
              @click:base-button="showView(props.item.id)"
            >{{ lang.btnText }}</ButtonPrimary>
          </td>
        </tr>
      </template>
    </v-data-table>
    <div class="base-table__footer">
      <PageSize
        v-if="basePagination && items && items.length"
        @click:page-size-current="handlePageSizeClick"
      />
      <Pagination
        v-if="basePagination && items && items.length"
        class="base-table__footer__pagination"
        :pagination="basePagination"
        @click:pagination="handlePaginationClick"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

import Pagination from "@/components/Pagination";
import PageSize from "@/components/PageSize";
import ButtonPrimary from "@/components/ButtonPrimary";
import ModalNotification from "@/components/ModalNotification";

export default {
  inheritAttrs: false,
  components: {
    ButtonPrimary,
    Pagination,
    PageSize,
    ModalNotification
  },
  data() {
    return {
      selected: [],
      items: [],
      pagination: {
        rowsPerPage: 20
      },
      headers: [
        { text: "", value: "isRead" },
        { text: "Date & Time", value: "datetime" },
        { text: "Event", value: "event" }
      ],
      selectedNotification: null,
      showModal: false
    };
  },
  computed: {
    ...mapGetters({
      loading: "getIsProgress",
      notifications: "getNotifications",
      notificationsPagination: "getNotificationsPagination"
    }),
    lang() {
      return {
        btnText: "View"
      };
    },
    basePagination() {
      if (!this.notificationsPagination) {
        return null;
      }
      return {
        first: +this.$url.getParams(this.notificationsPagination.first).page,
        last: +this.$url.getParams(this.notificationsPagination.last).page,
        self: +this.$url.getParams(this.notificationsPagination.self).page
      };
    }
  },
  methods: {
    ...mapActions(["fetchNotifications", "fetchNotificationById"]),
    changeSort(column) {
      if (this.pagination.sortBy === column) {
        this.pagination.descending = !this.pagination.descending;
      } else {
        this.pagination.sortBy = column;
        this.pagination.descending = false;
      }
    },
    handlePaginationClick(page) {
      this.$router.push({
        query: { ...this.$route.query, page }
      });
      window.scrollTo(0, 0);
    },
    handlePageSizeClick(page_size) {
      this.$router.push({
        query: { ...this.$route.query, page_size }
      });
      window.scrollTo(0, 0);
    },
    fetchNotificationsWithFilters(filters = {}) {
      const newFilters = {
        page_size: 20,
        page: 1,
        ...filters
      };

      this.fetchNotifications(newFilters);
    },
    onModalClose(val) {
      this.showModal = val;
    },
    showView(notificationId) {
      this.showModal = true;
      this.fetchNotificationById(notificationId);
    },
    customSort(items, index, isDescending) {
      items.sort((a, b) => {
        if (index === "datetime") {
          if (isDescending) {
            return window.moment(b.unixTime) - window.moment(a.unixTime);
          } else {
            return window.moment(a.unixTime) - window.moment(b.unixTime);
          }
        }
      });

      return items;
    }
  },
  watch: {
    notifications: {
      handler(notifications) {
        const tempItems = notifications.map(item => {
          return {
            id: item.id,
            isRead: item.isRead,
            unixTime: +new Date(item.createdAt),
            datetime:
              item.createdAt &&
              `
              <div class="datetime">
              <p class="datetime-date">${window
                .moment(item.createdAt)
                .format("DD MMM YYYY")}<p>
                ${window.moment(item.createdAt).format("hh:mm:ss")}
              </div>`,
            event: `
            <div class="event-desc">
              <p class="event-desc__main">${item.description}:</p>
              <p>${item.params.text ||
                item.params.shopProductName ||
                item.params.title ||
                ""}</p>
            </div>
            `
          };
        });

        this.items = tempItems;
      }
    },
    "$route.query": {
      immediate: true,
      handler(query) {
        this.fetchNotificationsWithFilters(query);
      }
    }
  },
  mounted() {},
  beforeDestroy() {
    this.$off();
  }
};
</script>

<style lang="scss">
.base-table-notification {
  td {
    color: $text-color !important;
  }

  .notification-mobile-wrapper {
    .notification-mobile:first-child {
      border-top-left-radius: 4px !important;
      border-top-right-radius: 4px !important;
    }
    .notification-mobile:last-child {
      border-bottom-left-radius: 4px !important;
      border-bottom-right-radius: 4px !important;
    }

    .notification-mobile {
      background-color: #fff;
      padding: 7px;
      font-size: 14px;
      color: $text-color !important;

      .notification-mobile__header {
        display: flex;
        align-items: center;
        span {
          margin-left: 10px;
        }

        .notification-mobile__main {
          line-height: 19px;
        }
      }

      .notification-mobile__footer {
        margin-top: 12px;

        .notification-mobile__footer-btn {
          margin-left: -5px;
        }
      }
    }
  }

  .event-desc {
    max-height: 48px;
    overflow: hidden;
    p {
      display: inline;
      color: $text-color !important;
    }

    .event-desc__main {
      color: $text-sidebar-color !important;
    }
  }
}

.notification-td {
  width: 8px;
}

.notification-read {
  color: $notification-read-color;
}

.notification-unread {
  color: $notification-unread-color;
}
</style>

