<template>
  <div>
    <div class="transaction" v-if="transaction">
      <h4 class="transaction__title">Transaction {{ transaction.foreignId }}</h4>
      <h4 class="transaction__title">Amount: {{ amount}}</h4>
      <h4 class="transaction__title">Status: {{ statuses[transaction.status] }}</h4>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import * as constants from "@/constants/transactions";

export default {
  data() {
    return {
      statuses: {
        [constants.STATUS_PENDING]: "Pending",
        [constants.STATUS_APPLIED]: "Applied",
        [constants.STATUS_CANCELED]: "Canceled",
        [constants.STATUS_FAILED]: "Failed"
      }
    };
  },
  computed: {
    ...mapGetters({
      transaction: "getTransaction"
    }),
    transactionId() {
      return this.$route.params.id;
    },
    amount() {
      return this.transaction && "$" + this.transaction.totalSum;
    }
  },
  methods: {
    ...mapActions(["fetchTransactionsById"])
  },
  watch: {
    "$route.query": {
      immediate: true,
      handler() {
        this.fetchTransactionsById(this.transactionId);
      }
    }
  }
};
</script>

<style scoped lang="scss">
.transaction {
  margin-top: 50px;
  background-color: #fff;
  padding: 20px 50px;

  .transaction__title {
    text-align: center;
  }
}
</style>
