<template>
  <div>
    <h3 class="home-right-form__title" v-html="lang.form.title"></h3>
    <FormSignIn class="v-text-field--flat v-text-field--flat--gray" @submit:form="handleSubmitForm"></FormSignIn>
<!--    <p class="home-right-form__no-account">-->
<!--      {{ lang.form.noAccount }}-->
<!--      <router-link :to="{name: 'register'}">-->
<!--        <a class="hover&#45;&#45;red">{{ lang.form.createAccount }}</a>-->
<!--      </router-link>-->
<!--    </p>-->
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";

import FormSignIn from "@/components/FormSignIn";

export default {
  components: {
    FormSignIn
  },
  data() {
    return {
      requestData: null
    };
  },
  computed: {
    ...mapState({
      successMsg: state => state.errors.notifyMessages.success,
      notValidUserMsg: state => state.errors.notifyMessages.notValidUser,
      form: state => state.forms.forms
    }),
    lang() {
      return {
        form: {
          title: "Welcome <br> to Ecombo",
          noAccount: "Don’t have an account? ",
          createAccount: "Create"
        }
      };
    }
  },
  methods: {
    ...mapActions(["setNotifyMsg", "signInUser", "setTelegramBot"]),
    async handleSubmitForm(data) {
      const requestData = {
        user: data,
        vm: this
      };

      const { status } = await this.signInUser(requestData);

      if (status) {
        const telegramBotToken = window.localStorage.getItem(
          "telegram-bot-token"
        );
        if (telegramBotToken) {
          this.setTelegramBot(telegramBotToken);
          window.localStorage.removeItem("telegram-bot-token");
        }
      }
    }
  }
};
</script>

<style scoped>
</style>
