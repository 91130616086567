import api from '@/api/payouts';

import {
  STATUS_OK,
  STATUS_VALIDATION_ERROR,
  STATUS_CREATED,
} from '@/constants/httpCodes';

export default {
  state: {
    payouts: null,
    payoutsLinks: null,
  },
  getters: {},
  mutations: {
    SET_PAYOUTS_DATA(state, payload) {
      state.payouts = payload;
    },
    SET_PAYOUTS_LINKS_DATA(state, payload) {
      state.payoutsLinks = payload;
    },
  },
  actions: {
    fetchPayouts({
      commit,
      dispatch,
    }, payload) {
      dispatch('setIsProgressData', true);
      api.get(payload)
        .then(({
          data: {
            items,
            links
          },
          status
        }) => {
          if (status === STATUS_OK) {
            commit('SET_PAYOUTS_DATA', items);
            commit('SET_PAYOUTS_LINKS_DATA', links);
          }
        })
        .finally(() => {
          dispatch('setIsProgressData', false);
        })
    },
    async postPayout({
      dispatch,
      rootState,
      commit
    }, payload) {
      dispatch('setIsProgressData', true);
      const response = await api.post(payload)
        .then(({
          status,
          data: {
            user: {
              balance,
              balanceHold
            }
          }
        }) => {
          if (status === STATUS_CREATED) {
            commit('MODIFY_CURRENT_USER_BALANCE', balance, {
              root: true
            });
            commit('MODIFY_CURRENT_USER_HOLD', balanceHold, {
              root: true
            });

            return {
              status: true,
              text: rootState.errors.notifyMessages.payoutNewRequest
            }
          }
        })
        .catch(error => {
          let errorResult = rootState.errors.notifyMessages.somethingWrong;

          if (error.response.status === STATUS_VALIDATION_ERROR) {
            errorResult = error.response.data.errors;
          }

          return {
            status: false,
            text: errorResult
          }
        })
        .finally(() => {
          dispatch('setIsProgressData', false);
        })

      return response;
    },
  },

};
