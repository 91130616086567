<template>
  <div>
    <BaseButton v-bind="$attrs" :color="color" round v-on="$listeners" class="button-primary">
      <slot/>
    </BaseButton>
  </div>
</template>

<script>
export default {
  inheritAttrs: false,
  props: {
    color: {
      type: String,
      default: "primary"
    }
  }
};
</script>

<style lang="scss">
.button-primary {
  &:hover {
    background-color: $hover-color !important;
    border-color: $hover-color !important;
  }
  border-radius: 100px !important;
}
.v-btn--active:before,
.v-btn:focus:before,
.v-btn:hover:before {
  background-color: inherit !important;
}
.border-gray {
  button {
    border-color: $text-color !important;
  }
}
</style>
