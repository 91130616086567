import api from '@/api/banners';

import {
  STATUS_OK
} from '@/constants/httpCodes';

export default {
  state: {
    banners: null
  },
  getters: {
    getBanners: state => state.banners
  },
  mutations: {
    SET_BANNERS_DATA(state, payload) {
      state.banners = payload;
    },
  },
  actions: {
    fetchBanners({
      commit,
      dispatch
    }, payload) {
      dispatch('setIsProgressData', true);
      api.get(payload)
        .then(({
          data: {
            items
          },
          status
        }) => {
          if (status === STATUS_OK) {
            commit('SET_BANNERS_DATA', items);
          }
        })
        .finally(() => {
          dispatch('setIsProgressData', false);
        })
    },
  },
};
