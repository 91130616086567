<template>
  <v-navigation-drawer
    fixed
    app
    dark
    width="240"
    class="sidebar"
    :mini-variant.sync="mini"
    v-model="drawerOpen"
    v-resize="onResize"
  >
    <v-list-tile class="header-logo-holder">
      <v-list-tile-action class="hidden-md-and-down"></v-list-tile-action>
      <template v-if="!mini">
        <v-list-tile-content>
          <v-list-tile-title>
            <div class="sidebar__logo">
              <Logo />
            </div>
          </v-list-tile-title>
        </v-list-tile-content>
      </template>
      <v-layout class="hidden-lg-and-up header-cost" wrap row>
        <v-flex xs v-if="canShowBalance">
          <v-toolbar-title class="header__balance">
            <div class="header__balance__title">Available:</div>
            <div class="header__balance__text">{{ available }}</div>
          </v-toolbar-title>
        </v-flex>
        <v-flex xs v-if="canShowBalance">
          <v-toolbar-title class="header__balance">
            <div class="header__balance__title">Hold:</div>
            <div class="header__balance__text">{{ hold }}</div>
          </v-toolbar-title>
        </v-flex>
        <v-flex xs>
          <v-toolbar-title v-if="!isSubAccount"
            class="header__balance hidden-lg-and-up"
            @click="$router.push({name: 'dashboard.balance'})"
            v-bind:class="{ active: isBalancePage }"
          >
            <div class="header__balance__title">Balance:</div>
            <div class="header__balance__text">{{ balance }}</div>
          </v-toolbar-title>
        </v-flex>
      </v-layout>
    </v-list-tile>
    <v-list>
      <div v-for="(item, k) in nav" :key="k" :class="[...item.class]">
        <v-list-tile
          :to="item.to && {name: `dashboard.${item.to}`}"
          :href="item.href && item.href"
          active-class="sidebar-tile--active"
          @mouseover="onHoverMenuItem(k)"
          @mouseleave="isHoverItem = {}"
          :target="item.isBlank && '_blank'"
          v-if="item.show"
          @click="item.onClick"
        >
          <v-list-tile-action>
            <component
              v-if="item.icon"
              :is="'icon-' + item.icon"
              height="20"
              width="25"
              :class="['sibebar-icon-' + item.icon]"
              :fill="isHoverItem[k] ? isActiveColor : isNotActiveColor"
              :stroke="isHoverItem[k] ? isActiveColor : isNotActiveColor"
            />
          </v-list-tile-action>
          <v-list-tile-content>
            <v-list-tile-title>{{ item.title }}</v-list-tile-title>
          </v-list-tile-content>
        </v-list-tile>
      </div>
    </v-list>
    <v-list-tile>
      <IconLogout
        class="header__logout hidden-md-and-up"
        @click="handleLogOut"
        :stroke="logOutColor"
        @mouseover="logOutColor = '#fff'"
        @mouseleave="logOutColor = '#7A7C7E'"
      >Exit</IconLogout>
    </v-list-tile>
  </v-navigation-drawer>
</template>

<script>
import { logOutUser } from "@/helpers/auth";
import { mapGetters, mapActions } from "vuex";

import IconDashboard from "@/components/Icons/IconDashboard";
import IconOrders from "@/components/Icons/IconOrders";
import IconAccount from "@/components/Icons/IconAccount";
import IconProduct from "@/components/Icons/IconProduct";
import IconStatistic from "@/components/Icons/IconStatistic";
import IconStoreManagement from "@/components/Icons/IconStoreManagement";
import IconNotification from "@/components/Icons/IconNotification";
import IconLogout from "@/components/Icons/IconLogout";
import IconBalance from "@/components/Icons/IconBalance";
import IconCoinBalance from "@/components/Icons/IconCoinBalance";
import IconInventory from "@/components/Icons/IconInventory";
import IconPaymentSolutions from "@/components/Icons/IconPaymentSolutions";
import IconTickets from "@/components/Icons/IconTickets";
import IconDocumentation from "@/components/Icons/IconDocumentation";
import IconPayouts from "@/components/Icons/IconPayouts";
import IconTelegram from "@/components/Icons/IconTelegram";
import IconSubAccounts from "@/components/Icons/IconSubAccounts";
import IconReferrals from "@/components/Icons/IconReferrals";
import Logo from "@/components/Logo";

import { gaDocumentationLinkClick } from "@/helpers/gaEvents";
import { PAYMENT_TYPE_USER_PS } from "@/constants/storeManagement";

export default {
  components: {
    IconDashboard,
    IconOrders,
    IconAccount,
    IconProduct,
    IconStatistic,
    IconStoreManagement,
    IconNotification,
    IconLogout,
    IconBalance,
    IconCoinBalance,
    IconInventory,
    IconPaymentSolutions,
    IconTickets,
    IconDocumentation,
    IconPayouts,
    IconTelegram,
    IconSubAccounts,
    IconReferrals,
    Logo
  },
  props: {
    mobileMenuOpen: {
      type: Boolean
    }
  },
  data() {
    return {
      mini: false,
      drawerOpen: false,
      isBalancePage: this.checkBalancePage(),
      logOutColor: "#7A7C7E",
      isHoverItem: {},
      isActiveColor: "#fff",
      isNotActiveColor: "#7A7C7E",
      PAYMENT_TYPE_USER_PS,
      telegramBotName: process.env.VUE_APP_TELEGRAM_BOT_NAME
    };
  },
  computed: {
    ...mapGetters({
      salesChannel: "getSalesChannel",
      canShowBalance: "canShowBalance",
      currentUser: "getCurrentUser",
      isSubAccount: "isSubAccount",
      isDepAdmin: "isDepAdmin",
    }),
    balance() {
      if (!this.currentUser) {
        return;
      }

      return "$" + Number(this.currentUser.balance).toFixed(2);
    },
    hold() {
      if (!this.currentUser) {
        return;
      }

      return "$" + Number(this.currentUser.balanceHold).toFixed(2);
    },
    available() {
      if (!this.currentUser) {
        return;
      }

      return "$" + Number(this.currentUser.balance).toFixed(2);
    },
    nav() {
      return [
        {
          title: "Dashboard",
          icon: "dashboard",
          to: "dashboard",
          show: true,
          onClick() {}
        },
        {
          title: "Statistic",
          icon: "statistic",
          to: "statistic.orders",
          class: "sidebar-item-parent",
          show: true,
          onClick() {}
        },
        {
          title: "Orders",
          icon: "",
          to: "statistic.orders",
          class: "sidebar-item-child",
          show: true,
          onClick() {}
        },
        {
          title: "Products",
          icon: "",
          to: "statistic.products",
          children: [],
          class: ["sidebar-item-child", "sidebar-item-child--last"],
          show: true,
          onClick() {}
        },
        {
          title: "Product",
          icon: "product",
          to: "products.search",
          class: "sidebar-item-parent",
          show: true,
          onClick() {}
        },
        {
          title: "Search products",
          icon: "",
          to: "products.search",
          class: "sidebar-item-child",
          show: true,
          onClick() {}
        },
        {
          title: "My Products",
          icon: "",
          to: "products.my",
          class: ["sidebar-item-child", "sidebar-item-child--last"],
          show: true,
          onClick() {}
        },
        {
          title: "Orders",
          icon: "orders",
          to: "orders",
          show: true,
          onClick() {}
        },
        {
          title: "Tickets",
          icon: "tickets",
          to: "tickets",
          class: ["item-tickets"],
          show:
            this.salesChannel &&
            this.salesChannel.paymentType !== this.PAYMENT_TYPE_USER_PS,
          onClick() {}
        },
        {
          title: `Notifications${this.unreadNotifications}`,
          icon: "notification",
          to: "notifications",
          show: ! this.isDepAdmin,
          onClick() {}
        },
        {
          title: "Sub accounts",
          icon: "sub-accounts",
          to: "sub-accounts",
          show: this.isDepAdmin,
          onClick() {}
        },
        {
          title: "Store management",
          icon: "store-management",
          to: "store-management",
          show: true,
          onClick() {}
        },
        {
          title: "Payouts",
          icon: "payouts",
          to: "payouts",
          show: this.canShowBalance,
          onClick() {}
        },
        {
          title: "Account settings",
          icon: "account",
          to: "account-settings",
          show: true,
          onClick() {}
        },
        {
          title: "Balance",
          icon: "coin-balance",
          to: "balance",
          show: ! this.isSubAccount,
          onClick() {}
        },
        {
          title: "Virtual Inventory",
          icon: "inventory",
          to: "inventory",
          show: ! this.isDepAdmin,
          onClick() {}
        },
        {
          title: "Payment Solutions",
          icon: "payment-solutions",
          to: "payment-solutions",
          class: ["item-payment-solutions"],
          show: ! this.isDepAdmin,
          onClick() {}
        },
        {
          title: "FAQ",
          icon: "documentation",
          href: "http://help.ecombo.com",
          isBlank: true,
          show: true,
          onClick() {
            gaDocumentationLinkClick();
          }
        },
        {
          title: "Telegram Bot",
          icon: "telegram",
          href: `https://t.me/${this.telegramBotName}`,
          isBlank: true,
          show: true,
          onClick() {}
        },
        {
          title: "Referrals",
          icon: "referrals",
          to: "referrals",
          show: true,
          onClick() {}
        }
      ];
    },
    unreadNotifications: {
      get() {
        if (!this.currentUser) {
          return "";
        }

        return ` (${this.currentUser.newNotificationsCount})`;
      }
    }
  },
  methods: {
    ...mapActions(["fetchCurrentUser"]),
    handleLogOut() {
      logOutUser(this);
    },
    onResize() {
      if (window.innerWidth >= 1264) {
        this.drawerOpen = true;
      }
    },
    checkBalancePage() {
      if (this.$router.history.current.name === "dashboard.balance") {
        this.isBalancePage = true;
        return true;
      } else {
        this.isBalancePage = false;
        return false;
      }
    },
    onHoverMenuItem(index) {
      this.isHoverItem = { [index]: true };
    }
  },
  watch: {
    "$route.query": {
      handler() {
        this.checkBalancePage();
      }
    },
    mobileMenuOpen: {
      handler() {
        this.drawerOpen = this.mobileMenuOpen;
      }
    },
    drawerOpen: {
      handler() {
        if (this.drawerOpen === false) {
          this.$emit("menutoggled");
        }
      }
    }
  }
};
</script>

<style lang="scss">
.sidebar {
  .header-logo-holder {
    @include breakpoint($ds) {
      padding-top: 24px;
    }
    @include breakpoint($t) {
      padding-top: 10px;
    }
    .v-list__tile {
      @include breakpoint($ds) {
        height: auto;
        display: block;
      }
    }
  }

  .header__logout {
    width: 100%;
    display: flex;
    color: #7a7c7e;
    cursor: pointer;
    font-size: 14px;
    line-height: 1.2;
    align-items: center;
    justify-content: flex-start;
    transition: color 0.35s ease;
    &:hover {
      color: #fff;
    }
    .base-icon {
      min-width: 30px;
      margin: 0 15px 0 0;
    }
    svg g {
      transition: all 0.35s ease;
    }
  }

  .header__balance {
    color: #7a7c7e;
    font-size: 10px;
    line-height: 18px;
    padding: 0 16px 10px;
    margin: 0 -16px 0;
    transition: background 0.35s ease;
    @include breakpoint($t) {
      padding-top: 10px;
    }
    &__text {
      color: #fff;
      font-size: 12px;
    }
  }

  .sidebar__icon {
    display: inline-block;
    color: $text-sidebar-color;
    margin-top: 3px;
    margin-right: 2px;
  }

  .v-list__tile {
    height: 52px;
    &:hover {
      .v-list__tile__content {
        color: #fff;
      }
    }

    svg {
      &:hover {
        transition: background-color 0.35s ease !important;
      }
    }

    div[role="listitem"] {
      height: inherit;

      a {
        height: inherit;
      }
    }

    &--link {
      &:hover {
        background: none !important;
      }
    }
  }

  &.v-navigation-drawer > .v-list .v-list__tile {
    transition: background-color 0.35s ease;
  }

  &.v-navigation-drawer--mini-variant {
    .v-list__tile__action {
      margin-right: 0;
      margin-left: 10px;
    }
  }

  .v-list__tile__content {
    color: $text-sidebar-color;
  }

  .v-navigation-drawer__border {
    background-color: transparent !important;
  }

  .v-list__group__header__append-icon {
    display: none;
  }

  .sidebar-tile--active {
    .v-list__tile__content {
      color: #fff;
    }
  }

  .v-list__tile__action {
    min-width: 30px;
    margin-right: 15px;
  }

  .v-list__group {
    &::after {
      background: none !important;
    }
  }

  .sidebar-item-child {
    height: 30px;

    div[role="listitem"] {
      height: inherit;

      a {
        height: inherit;
      }
    }

    &--last {
      margin-bottom: 15px;
    }
  }

  .sidebar-item-parent {
    margin-bottom: -10px;
  }

  .sibebar-icon-inventory {
    margin-left: 5px;
  }

  .item-tickets svg {
    margin-top: 5px;
  }

  .item-payment-solutions svg {
    margin-top: 3px;
  }

  .sibebar-icon-account,
  .sibebar-icon-coin-balance,
  .sibebar-icon-inventory {
    margin-top: 3px;
  }

  .sibebar-icon-coin-balance {
    margin-left: 4px;
  }

  .header-cost {
    margin-top: 10px;
  }
}
</style>
