<template>
  <BaseIcon :width="width" :height="height" :stroke="stroke" fill="none" name="payment-solutions">
    <path :stroke="stroke" d="M4.5 3.5h7m1.5 0h2M4.5 6.5h6m2.5 0h2M4.5 9.5h7m1.5 0h2"/>
    <path
      :stroke="stroke"
      stroke-linejoin="round"
      d="M18 .5v16l-2.667-2-2.666 2-2.667-2-2.667 2-2.666-2L2 16.5V.5"
    />
    <path :stroke="stroke" d="M0 .5h20"/>
  </BaseIcon>
</template>

<script>
export default {
  props: {
    width: {
      type: [Number, String],
      default: 20
    },
    height: {
      type: [Number, String],
      default: 17
    },
    fill: {
      type: String,
      default: "none"
    },
    stroke: {
      type: String,
      default: "#7A7C7E"
    }
  }
};
</script>

<style scoped>
</style>
