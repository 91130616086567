import { render, staticRenderFns } from "./BaseIcon.vue?vue&type=template&id=321855c4&scoped=true&"
import script from "./BaseIcon.vue?vue&type=script&lang=js&"
export * from "./BaseIcon.vue?vue&type=script&lang=js&"
import style0 from "./BaseIcon.vue?vue&type=style&index=0&id=321855c4&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "321855c4",
  null
  
)

component.options.__file = "BaseIcon.vue"
export default component.exports