<template>
  <v-app id="app">
    <TheSnackbar />
    <component :is="layout">
      <router-view />
    </component>
  </v-app>
</template>

<script>
import { DEFAULT_SIDEBAR } from "@/constants/layouts";
import { DEFAULT_LOCATION_ISO2 } from "@/constants/shopProducts";
import { GROUP_BY_DAY } from "@/constants/groupBy";
import { gaIntercomClick } from "@/helpers/gaEvents";
import { MAIN_DOMAIN } from "@/constants/main";
import userSourceHelper from "@/helpers/userSource";

import TheSnackbar from "@/components/TheSnackbar";
import { mapActions } from "vuex";

require("https://www.google-analytics.com/analytics.js");

const TOKEN_GOOGLE_ANALYTICS = process.env.VUE_APP_GOOGLE_ANALYTICS_TOKEN;

/* eslint-disable */

export default {
  name: "App",
  components: {
    TheSnackbar
  },
  computed: {
    layout() {
      return (this.$route.meta.layout || DEFAULT_SIDEBAR) + "-layout";
    }
  },
  methods: {
    ...mapActions(["setTelegramBot"]),
    setShipsFromData() {
      if (!this.$shipsFrom.getLocation()) {
        this.$shipsFrom.setLocation(DEFAULT_LOCATION_ISO2);
      }
    },
    setDateFilters() {
      const productsStatisticDateFilter = this.$filters.getProductsStatisticDateFilter();
      const ordersStatisticDateFilter = this.$filters.getOrdersStatisticDateFilter();
      const ordersStatisticGroubByFilter = this.$filters.getOrdersStatisticGroupByFilter();

      const start = window
        .moment()
        .startOf("month")
        .format("YYYY-MM-DD");
      const end = window
        .moment()
        .endOf("month")
        .format("YYYY-MM-DD");

      if (
        (!productsStatisticDateFilter.start ||
          productsStatisticDateFilter.start === "null") &&
        (!productsStatisticDateFilter.end ||
          productsStatisticDateFilter.end === "null")
      ) {
        this.$filters.setProductsStatisticDateFilter(start, end);
      }

      if (
        (!ordersStatisticDateFilter.start ||
          ordersStatisticDateFilter.start === "null") &&
        (!ordersStatisticDateFilter.end ||
          ordersStatisticDateFilter.end === "null")
      ) {
        this.$filters.setOrdersStatisticDateFilter(start, end);
      }

      if (
        !ordersStatisticGroubByFilter ||
        ordersStatisticGroubByFilter === "null"
      ) {
        this.$filters.setOrdersStatisticGroupByFilter(GROUP_BY_DAY);
      }
    },
    initGoogleAnalytics() {
      window.ga =
        window.ga ||
        function() {
          (ga.q = ga.q || []).push(arguments);
        };
      ga.l = +new Date();
      ga("create", TOKEN_GOOGLE_ANALYTICS, "auto");
      ga("send", "pageview");
    },
    setUserCookies() {
      const cookieExpires = new Date();
      const referer = document.referrer;

      cookieExpires.setMonth(cookieExpires.getMonth() + 6);

      userSourceHelper.getUtmKeys().forEach(
        function(utmKey) {
          let utmValue = this.$route.query[utmKey];

          if (utmValue && typeof utmValue !== "undefined") {
            this.$cookie.set(utmKey, utmValue, {
              expires: cookieExpires,
              domain: "." + MAIN_DOMAIN
            });
          }
        }.bind(this)
      );

      if (
        typeof referer === "string" &&
        referer.length > 0 &&
        referer.search(MAIN_DOMAIN) < 0
      ) {
        this.$cookie.set("referer", referer, {
          expires: cookieExpires,
          domain: "." + MAIN_DOMAIN
        });
      }
    },
    connectTelegramBot(token) {
      const isAuth = this.$auth.isAuthenticated();

      if (isAuth) {
        const authToken = this.$auth.getToken();
        axios.defaults.headers.common["Authorization"] = `Bearer ${authToken}`;
        this.setTelegramBot(token);
        this.$router.push({ name: "dashboard.dashboard" });
      } else {
        window.localStorage.setItem("telegram-bot-token", token);
        this.$router.push({ name: "home" });
      }
    }
  },
  mounted() {
    this.initGoogleAnalytics();
    this.setShipsFromData();
    this.setDateFilters();
    this.setUserCookies();
    gaIntercomClick();
  },
  watch: {
    $route: {
      immediate: true,
      handler(route) {
        const {
          name,
          params: { token }
        } = route;
        if (name === "set-telegram-bot" && token) {
          this.connectTelegramBot(token);
        }
      }
    }
  }
};
</script>
