export default {
    getUtmKeys: () => {
        return [
            'utm_source',
            'utm_medium',
            'utm_campaign',
            'utm_content',
            'utm_term'
        ];
    },
    getCookieKeysToAttributes: () => {
        return {
            'utm_source': 'utmSource',
            'utm_medium': 'utmMedium',
            'utm_campaign': 'utmCampaign',
            'utm_content': 'utmContent',
            'utm_term': 'utmTerm',
            'referer': 'referer'
        };
    }
};
