<template>
  <div class="my-product" v-if="product">
    <ModalSplitProducts
      :show="showModal"
      @esc:modal="showModal = false"
      @change:modal="onChangeModal"
      width="1000"
    />
    <ModalSplitOptions
      :show="showOptionsModal"
      @esc:modal="showOptionsModal = false"
      @change:modal="onChangeOptionsModal"
      width="1000"
      lazy
    />
    <v-layout row wrap class="my-product-navigation">
      <v-flex xs>
        <div class="back-nav" @click="$router.push({ name: 'dashboard.products.my'})">
          <v-icon>chevron_left</v-icon>
          <span class="my-product-navigation__title">{{ lang.backNavTitle }}</span>
        </div>
      </v-flex>
      <v-flex xs>
        <div class="arrow-buttons">
          <v-icon v-if="prevProduct" @click="navigateToMyProduct(prevProduct)">chevron_left</v-icon>
          <v-icon v-if="nextProduct" @click="navigateToMyProduct(nextProduct)">chevron_right</v-icon>
        </div>
      </v-flex>
    </v-layout>
    <v-layout row wrap class="product-page-heading">
      <v-flex xs12 sm9 md9>
        <h1 class="my-product__title">{{ product.name }}</h1>
        <ButtonPrimary
          v-if="product.variants && product.variants.length > 1"
          class="header-buttons"
          :loading="progress"
          outline
          small
          @click:base-button="handleSplitProducts"
        >{{ lang.buttons.split }}</ButtonPrimary>
        <ButtonPrimary
          v-if="product.option1 || product.option2 || product.option3"
          class="header-buttons"
          :loading="progress"
          outline
          small
          @click:base-button="handleSplitOptions"
        >{{ lang.buttons.splitOptions }}</ButtonPrimary>
      </v-flex>
      <v-flex xs12 sm3 md3 class="product-page-heading__btn-holder">
        <ButtonPrimary
          :loading="progress"
          outline
          small
          @click:base-button="handleSaveProduct"
        >{{ lang.buttons.save }}</ButtonPrimary>
      </v-flex>
    </v-layout>
    <div class="my-product-block">
      <h5 class="my-product-block__title">{{ lang.blocks.product }}</h5>
      <div class="my-product-block__content">
        <v-layout row wrap>
          <v-flex xs12 sm6 md4>
            <v-img :src="product.mainImage.src" :lazy-src="product.mainImage.src"></v-img>
          </v-flex>
          <v-flex xs12 sm12 md8>
            <div class="my-product-block__content__form">
              <div class="my-product-input">
                <InputText
                  backgroundColor="gray"
                  @input:base-text="handleValue($event, 'name')"
                  :value="form.name"
                  :has-error="!!errors.name"
                  :errorText="errors.name"
                  maxlength="255"
                >{{ lang.labels.title }}</InputText>
              </div>
              <div class="my-product-input">
                <InputCombobox
                  @change:base-combobox="handleTagsChange"
                  :value="form.tags"
                >{{ lang.labels.tags }}</InputCombobox>
              </div>
              <div class="my-product-input__checkbox">
                <v-checkbox
                  color="primary"
                  v-model="form.stickyPriceEnabled"
                  :label="lang.labels.stickyPrice"
                ></v-checkbox>
              </div>
            </div>
          </v-flex>
        </v-layout>
      </div>
    </div>
    <div class="my-product-block">
      <h5 class="my-product-block__title">{{ lang.blocks.desc }}</h5>
      <div
        class="my-product-block__content__editor bootstrap"
        v-if="form.bodyHtml"
        :key="product.id"
      >
        <TextEditor :model="form.bodyHtml" @update:model="handleEditorUpdate"></TextEditor>
      </div>
    </div>
    <div class="my-product-block">
      <h5 class="my-product-block__title">{{ lang.blocks.variants }}</h5>
      <div class="my-product-block__content variants-block">
        <TableProductVariantsEdit @get-new-variants="handleValue($event, 'variants')"/>
      </div>
    </div>
    <div class="my-product-block" v-if="filteredImages.length">
      <h5 class="my-product-block__title">{{ lang.blocks.images }}</h5>
      <div class="my-product-block__content">
        <v-container grid-list-lg class="my-product-block__content__images-list no-width">
          <v-layout row wrap>
            <v-flex xs4 sm3 md2 v-for="image in filteredImages" :key="image.id">
              <v-img
                :class="{'image--selected--icon': image.active}"
                :ref="`image-${image.id}` "
                @click="handleImageClick(`image-${image.id}`, image.id)"
                :src="image.src"
              ></v-img>
            </v-flex>
          </v-layout>
        </v-container>
      </div>
    </div>
    <v-layout row wrap>
      <v-flex md12>
        <ButtonPrimary
          class="my-product__btn-footer"
          :loading="progress"
          outline
          small
          @click:base-button="handleSaveProduct"
        >{{ lang.buttons.save }}</ButtonPrimary>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState, mapMutations } from "vuex";
import { STATUS_IMPORTED, DEFAULT_SHIPS_FROM_ISO2 } from "@/constants/shopProducts";

import InputText from "@/components/InputText";
import InputCombobox from "@/components/InputCombobox";
import ButtonPrimary from "@/components/ButtonPrimary";
import TableProductVariantsEdit from "@/components/Tables/TableProductVariantsEdit";
import TextEditor from "@/components/TextEditor";
import ModalSplitProducts from "@/components/ModalSplitProducts";
import ModalSplitOptions from "@/components/ModalSplitOptions";

export default {
  components: {
    InputText,
    InputCombobox,
    ButtonPrimary,
    TableProductVariantsEdit,
    TextEditor,
    ModalSplitProducts,
    ModalSplitOptions
  },
  data() {
    return {
      form: {
        name: null,
        stickyPriceEnabled: null,
        tags: null,
        bodyHtml: null,
        variants: null
      },
      classes: {
        "image--selected": true
      },
      notIncludedImages: {},
      hiddenRequestFields: [
        "maxCost",
        "maxPrice",
        "maxProfit",
        "minCost",
        "minPrice",
        "minProfit",
        "productId",
        "id",
        "foreignId",
        "statusImport",
        "mainImage",
        "category"
      ],
      errors: {
        name: null
      },
      showModal: false,
      showOptionsModal: false
    };
  },
  computed: {
    ...mapState({
      successMsg: state => state.errors.notifyMessages.editProductSuccess,
      errorMsg: state => state.errors.notifyMessages.somethingWrong,
      validationErrorMsg: state => state.errors.notifyMessages.validationError
    }),
    ...mapGetters({
      product: "getMyProduct",
      progress: "getIsProgress"
    }),
    productId() {
      return this.$route.params.id;
    },
    filteredImages() {
      let filteredImages = [];
      this.product.images.map(image => {
        filteredImages.push(image);
        if (!image.active) {
          this.notIncludedImages[image.id] = image.id;
        }
      });

      return filteredImages;
    },
    lang() {
      return {
        blocks: {
          product: "Product",
          desc: "Description",
          variants: "Variants",
          images: "Images"
        },
        labels: {
          title: "Title",
          tags: "Tags",
          stickyPrice: "Sticky price enabled"
        },
        buttons: {
          save: "Save",
          split: "Split products",
          splitOptions: "Split options"
        },
        backNavTitle: "My products"
      };
    },
    prevProduct() {
      return this.product && this.product.nextId;
    },
    nextProduct() {
      return this.product && this.product.prevId;
    }
  },
  methods: {
    ...mapActions(["fetchMyProductById", "saveMyProduct", "setNotifyMsg"]),
    ...mapMutations(["SET_SELECTED_SHIPMENT_METHOD_DATA"]),
    handleValue(val, field) {
      this.form[field] = val;
    },
    setProductData(product) {
      for (let i in this.form) {
        this.form[i] = product[i];
      }
    },
    handleTagsChange(val) {
      this.form.tags = val.join(", ");
    },
    handleImageClick(refName, imageId) {
      const el = this.$refs[refName][0].$el;

      el.classList.toggle("image--selected--icon");

      if (el.classList.value.includes("image--selected--icon")) {
        if (this.notIncludedImages[imageId]) {
          delete this.notIncludedImages[imageId];
        }
      } else {
        this.notIncludedImages[imageId] = imageId;
      }
    },
    handleEditorUpdate(val) {
      this.form.bodyHtml = val;
    },
    async handleSaveProduct() {
      let productEdited = {};

      Object.keys(this.product).filter(k => {
        if (! this.hiddenRequestFields.includes(k)) {
          productEdited[k] = this.product[k];
        }
      });

      for (let i in productEdited) {
        if (this.form.hasOwnProperty(i)) {
          productEdited[i] = this.form[i];
        }
      }

      productEdited.images = this.product.images.map(item => {
        return {
          id: item.id,
          active: this.notIncludedImages[item.id] ? false : true
        };
      });

      const { status, text } = await this.saveMyProduct({
        id: this.product.id,
        data: productEdited
      });

      if (status) {
        this.setNotifyMsg({ text: text || this.successMsg, color: "success" });
        this.notIncludedImages = {};
      } else {
        this.setErrors(text);
        this.setNotifyMsg({
          text: this.validationErrorMsg,
          color: "error"
        });
      }
    },
    setErrors(errorsData) {
      this.errors = {
        name: null
      };

      if (errorsData.name) {
        this.$nextTick().then(() => {
          this.errors = { ...this.errors, name: errorsData.name[0] };
          window.scrollTo(0, 0);
        });
      }

      if (errorsData.variants) {
        this.$root.$emit("variants-errors", errorsData.variants);
      }
    },
    navigateToMyProduct(id) {
      this.$router.replace({ params: { id } });
    },
    handleSplitProducts() {
      this.showModal = true;
    },
    handleSplitOptions() {
      this.showOptionsModal = true;
    },
    onChangeModal(val) {
      this.showModal = val;
    },
    onChangeOptionsModal(val) {
      this.showOptionsModal = val;
    }
  },
  beforeDestroy() {
    this.$off();
  },
  mounted() {
    window.scrollTo(0, 0);
  },
  watch: {
    "$route.query": {
      immediate: true,
      handler() {
        this.fetchMyProductById(this.productId);
      }
    },
    product: {
      handler(product) {
        if (product) {
          this.setProductData(product);
          this.$root.$emit(
            "set-html-title-name",
            `Ecombo - My Products - ${product.name}`
          );
        }

        if (product.statusImport === STATUS_IMPORTED) {
          this.$router.push({ name: "dashboard.products.my" });
        }
      }
    }
  },
  created() {
    this.$shipsFrom.setShipmentMethod(DEFAULT_SHIPS_FROM_ISO2);
  }
};
</script>

<style lang="scss">
.product-page-heading {
  &__btn-holder {
    text-align: right;
    @include breakpoint($m) {
      text-align: left;
    }
    > div {
      margin-left: -8px;
      margin-right: -8px;
    }
  }
}
.my-product {
  .my-product__title {
    font-size: 30px;
  }

  .header-buttons {
    display: inline-block;
    margin-top: 20px;
    button {
      padding-left: 15px;
      padding-right: 15px;
      margin-left: 0;
    }
  }

  .my-product-block {
    margin: 30px 0;

    .my-product-block__title {
      font-size: 18px;
      color: $text-sidebar-color;
      margin-bottom: 18px;
    }

    .my-product-block__content {
      background-color: #fff;
    }

    .my-product-block__content__images-list {
      padding: 20px;

      .v-image {
        cursor: pointer;
      }
    }
    .my-product-block__content__form {
      padding: 10px 20px;

      .my-product-input {
        margin-bottom: 15px;
      }

      .my-product-input__checkbox {
        margin-top: 35px;

        label {
          font-size: 14px;
          color: $title-color !important;
        }
      }
    }
    .my-product-block__content__editor {
      padding: 20px 18px;
      background-color: #fff;
    }
  }

  .my-product__btn-footer {
    text-align: left;
    @include breakpointMin($t) {
      text-align: right;
    }
  }

  .my-product-navigation {
    margin-bottom: 10px;
    margin-left: -4px;

    * {
      color: $text-sidebar-color !important;
    }
    &__title {
      vertical-align: text-bottom;

      &:hover {
        color: $hover-color !important;
      }
    }

    .back-nav {
      &:hover {
        * {
          cursor: pointer;
          color: $hover-color !important;
        }
      }
    }

    i {
      cursor: pointer;

      &:hover {
        color: $hover-color !important;
      }
    }

    .arrow-buttons {
      float: right;
      margin-right: -4px;
    }
  }
}
</style>
