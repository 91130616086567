<template>
  <div class="table-tickets">
    <div class="tickets-filter-panel">
      <v-container class="pa-0 no-width base-table--filters" grid-list-md>
        <v-layout wrap name="filters" class="base-table--filters">
          <v-flex xs12 sm7 md9>
            <h2>{{ lang.ticketList }}</h2>
            <FilterTickets @click:search-btn="handleSearch" />
          </v-flex>
          <v-flex xs12 sm5 md3 class="statistic-holder" v-if="items && items.length">
            <h2>{{ lang.statistic }}</h2>
            <v-container class="pa-0 no-width base-table--filters" grid-list-md>
              <v-layout>
                <v-flex xs4 sm4 md4 class="statistic">
                  <span class="statistic__value">{{ statisticsInfo.totalCount }}</span>
                  <span class="statistic__title">{{ lang.statisticTitles.total }}</span>
                </v-flex>
                <v-flex xs4 sm4 md4 class="statistic">
                  <span class="statistic__value">{{ statisticsInfo.mailCount }}</span>
                  <span class="statistic__title">{{ lang.statisticTitles.mails }}</span>
                </v-flex>
                <v-flex xs4 sm4 md4 class="statistic">
                  <span class="statistic__value">{{ statisticsInfo.chatCount }}</span>
                  <span class="statistic__title">{{ lang.statisticTitles.chats }}</span>
                </v-flex>
              </v-layout>
            </v-container>
          </v-flex>
        </v-layout>
      </v-container>
    </div>
    <div class="exports" v-if="items && items.length">
      <ExportTable :items="items" :fields="exportCSV.fields" name="tickets.xls" />
    </div>
    <BaseTable
      class="table-orders__content hide-first-id"
      :headers="headers"
      :items="items"
      :show-details="showDetails"
      show-pagination
      show-page-size
      v-if="items && items.length"
      :custom-pagination="paginationsLinks"
      routerName="dashboard.tickets.details"
    ></BaseTable>
    <div class="empty-holder" v-else>
      <div class="empty-holder__icon">
        <IconEmpty />
      </div>
      <h2>{{ lang.emptyTitle }}</h2>
      <h3>{{ lang.emptySubtitle }}</h3>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import {
  STATUS_OPEN,
  STATUS_RESOLVED,
  STATUS_CLOSED,
  STATUS_DELETED,
  STATUS_HOLD,
  STATUS_WAITING,
  STATUS_SPAM,
  STATUS_NEW,
  STATUS_SENDING,
  SOURCE_CHAT,
  SOURCE_MAIL
} from "@/constants/tickets";
import FilterTickets from "@/components/Filters/FilterTickets";
import IconEmpty from "@/components/Icons/IconEmpty";
import ExportTable from "@/components/ExportTable";

export default {
  components: {
    FilterTickets,
    IconEmpty,
    ExportTable
  },
  data() {
    return {
      items: [],
      statisticsInfo: [],
      headers: [
        { text: "ID", value: "id" },
        { text: "Ticket", value: "ticket" },
        { text: "", value: "subject" },
        { text: "Email", value: "clientEmail" },
        { text: "Date & Time", value: "createdAt" },
        { text: "Source", value: "source" },
        { text: "Status", value: "status" }
      ],
      exportCSV: {
        fields: {
          ID: "id",
          Ticket: "ticket",
          Subject: "subject",
          Email: "clientEmail",
          "Date & Time": "createdAt",
          Source: "source",
          Status: "status"
        }
      },
      showDetails: true
    };
  },
  computed: {
    ...mapGetters({
      tickets: "getTickets",
      statistics: "getStatistics",
      paginationsLinks: "getTicketsPagination"
    }),
    salesChannelId() {
      return this.$salesChannel.get();
    },
    lang() {
      return {
        source: {
          chat: "Chat",
          mail: "Mail"
        },
        status: {
          open: "Open",
          resolved: "Resolved",
          closed: "Closed",
          deleted: "Deleted",
          hold: "Hold",
          waiting: "Waiting",
          spam: "Spam",
          new: "New",
          sending: "Sending"
        },
        ticketList: "Ticket list",
        statistic: "Statistic",
        statisticTitles: {
          total: "Total",
          mails: "Mails",
          chats: "Chats"
        },
        emptyTitle: "Your Support Tickets Inbox is Empty",
        emptySubtitle:
          "Check and reply to a supplier message and see updates about things you reported"
      };
    }
  },
  methods: {
    ...mapActions(["fetchTickets", "fetchStatistics"]),
    handleSearch(filters) {
      this.fetchTicketsWithFilters(filters);
    },
    fetchTicketsWithFilters(filters = {}) {
      const data = {
        page: 1,
        page_size: 20,
        salesChannel: this.salesChannelId,
        ...this.$route.query,
        ...filters
      };

      this.fetchTickets(data);
    },
    getTicketIdName(id) {
      return "Ticket #" + id;
    },
    getSourceText(val) {
      if (val == SOURCE_CHAT) {
        return this.lang.source.chat;
      }

      if (val == SOURCE_MAIL) {
        return this.lang.source.mail;
      }
    },
    getStatusText(val) {
      if (val == STATUS_OPEN) {
        return this.lang.status.open;
      }

      if (val == STATUS_RESOLVED) {
        return this.lang.status.resolved;
      }

      if (val == STATUS_CLOSED) {
        return this.lang.status.closed;
      }

      if (val == STATUS_DELETED) {
        return this.lang.status.deleted;
      }

      if (val == STATUS_HOLD) {
        return this.lang.status.hold;
      }

      if (val == STATUS_WAITING) {
        return this.lang.status.waiting;
      }

      if (val == STATUS_SPAM) {
        return this.lang.status.spam;
      }

      if (val == STATUS_NEW) {
        return this.lang.status.new;
      }

      if (val == STATUS_SENDING) {
        return this.lang.status.sending;
      }
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler() {
        const data = {
          page: 1,
          page_size: 20,
          salesChannel: this.salesChannelId,
          ...this.$route.query
        };
        this.fetchTickets(data);
        this.fetchStatistics({ salesChannel: this.salesChannelId });
      }
    },
    tickets: {
      handler(tickets) {
        const statusTag = status => {
          switch (status) {
            case STATUS_RESOLVED:
              return "success";
            case STATUS_OPEN:
              return "success";
            case STATUS_NEW:
              return "success";
            case STATUS_WAITING:
              return "waiting";
            case STATUS_SENDING:
              return "waiting";
            case STATUS_SPAM:
              return "danger";
            default: {
              return "neutral";
            }
          }
        };

        const tempItems = tickets.map(item => {
          return {
            id: item.id,
            ticket: this.getTicketIdName(item.id),
            subject: item.subject,
            clientEmail: item.clientEmail,
            createdAt: window.moment(item.createdAt).format("LLL"),
            source: this.getSourceText(item.source),
            status: `<div class="tag-status tag-status--${statusTag(
              item.status
            )}">${this.getStatusText(item.status)}</div>`
          };
        });
        this.items = tempItems;
      }
    },
    statistics: {
      handler(statistics) {
        this.statisticsInfo = statistics;
      }
    }
  }
};
</script>

<style scoped lang="scss">
.table-orders__content {
  white-space: nowrap;
}
.table-tickets {
  h2 {
    color: #7e7e7e;
    font-size: 18px;
    line-height: 1.2;
    margin: 0 0 10px;
  }

  /deep/ td {
    padding-right: 0 !important;
    padding-left: 3px !important;
  }

  /deep/ th {
    padding-right: 0 !important;
    padding-left: 2px !important;
  }

  /deep/ .justify-center {
    padding: 0 !important;
  }

  /deep/ .tag-status {
    width: 80px !important;
  }
}

.statistic {
  color: #7e7e7e;
  &__value {
    display: block;
    color: #222528;
    font-size: 22px;
    line-height: 1.2;
  }
  &__title {
    display: block;
  }
}
.statistic-holder {
  @include breakpoint($m) {
    padding-top: 30px !important;
  }
  h2,
  .layout {
    @include rem($m) {
      padding-left: 30px;
    }
  }
}

.empty-holder {
  text-align: center;
  padding: 30px 0 0;
  &__icon {
    margin: 50px 0 40px;
    @include breakpoint($m) {
      margin-bottom: 20px;
    }
    svg {
      height: auto;
      display: block;
      margin: 0 auto;
      max-width: 100%;
      @include breakpoint($m) {
        width: 200px;
      }
    }
  }
  h2 {
    color: #020101;
    line-height: 1;
    font-size: 30px;
    margin: 0 0 25px;
    @include breakpoint($m) {
      font-size: 18px;
    }
  }
  h3 {
    color: #7a7c7e;
    font-size: 17px;
    line-height: 1.2;
    font-weight: normal;
    @include breakpoint($m) {
      font-size: 14px;
    }
  }
}
</style>
