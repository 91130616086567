<template>
  <div class="choose-supplier">
    <h3>{{ lang.blockTitle }}</h3>
    <ul class="suppliers-list">
      <li v-for="item in orderedItems" :key="item.id">
        <v-layout row wrap>
          <v-flex xs12 sm5 md4 class="base-table--filters__item">
            <v-select
              v-if="suppliersItems"
              :items="suppliersItems"
              item-text="name"
              item-value="id"
              :label="lang.supplier"
              v-model="item.shippingCompany.id"
              v-on:change="handleSupplierChange(item.id)"
              :rules="[rules.required]"
              @update:error="onErrorSupplier($event, item.id)"
            ></v-select>
          </v-flex>
          <v-flex xs5 sm3 md2 class="base-table--filters__item">
            <v-text-field
              :label="lang.maxPrice"
              placeholder="$"
              v-model="item.maxCost"
              v-on:change="handleSupplierChange"
              :rules="[rules.requiredNumber, rules.isAbsNumber, rules.isNumber]"
              @update:error="onErrorMaxCost($event, item.id)"
              type="number"
            ></v-text-field>
          </v-flex>
          <v-flex xs7 sm1 md1 class="base-table--filters__item">
            <v-btn
              class="delete-button"
              flat
              icon
              color="red"
              v-on:click="handleDeleteSupplier(item.id)"
            >
              <v-icon>delete</v-icon>
            </v-btn>
          </v-flex>
        </v-layout>
      </li>
      <li>
        <ButtonPrimaryAdd
          v-if="items.length < maxCount"
          @click:base-button="handleAddSupplier"
        >Add new supplier</ButtonPrimaryAdd>
      </li>
    </ul>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import ButtonPrimaryAdd from "@/components/ButtonPrimaryAdd";
import _ from "lodash";

export default {
  components: {
    ButtonPrimaryAdd
  },
  props: {
    items: {
      type: Array,
      required: false
    }
  },
  data() {
    return {
      suppliersItems: [],
      maxCount: 5,
      validMaxCostValues: {},
      validSupllierValues: {}
    };
  },
  computed: {
    ...mapState({
      rules: state => state.validations.rules
    }),
    ...mapGetters({
      suppliers: "getSuppliers"
    }),
    suppliersFilters() {
      return false;
    },
    lang() {
      return {
        blockTitle: "Choose Suppliers",
        supplier: "Supplier",
        maxPrice: "Max shipping price"
      };
    },
    orderedItems: {
      get() {
        return _.orderBy(this.items, "priority");
      },
      set(val) {
        this.$emit("suppliers:change", val);
      }
    }
  },
  methods: {
    ...mapActions(["fetchSuppliers"]),
    handleSupplierChange(id = null) {
      if (id) {
        this.validSupllierValues = { ...this.validSupllierValues, [id]: true };
      }
    },
    handleAddSupplier() {
      let emptyId = 1;
      while (this.checkEmptyId(emptyId, this.items)) {
        emptyId++;
      }
      const newItem = {
        id: emptyId,
        priority: 0,
        shippingCompany: {}
      };
      this.items.push(newItem);
      this.handleSetPriority();

      this.validMaxCostValues = {
        ...this.validMaxCostValues,
        [newItem.id]: false
      };
      this.validSupllierValues = {
        ...this.validSupllierValues,
        [newItem.id]: false
      };
    },
    checkEmptyId(id, array) {
      var busy = false;
      array.forEach(item => {
        if (id == item.id) {
          busy = true;
        }
      });
      return busy;
    },
    handleDeleteSupplier(val) {
      this.items.forEach((item, index) => {
        if (item.id === val) {
          this.items.splice(index, 1);
          return false;
        }
      });
      this.handleSetPriority();
      this.handleSupplierChange();

      delete this.validMaxCostValues[val];
      delete this.validSupllierValues[val];
      this.validValues = { ...this.validValues };
      this.validSupllierValues = { ...this.validSupllierValues };
    },
    handleSetPriority() {
      this.items.map((item, index) => {
        item.priority = index + 1;
      });
    },
    handleSuppliers(suppliers) {
      const tempItems = suppliers.map(item => {
        var computed = {
          id: item.id,
          name: item.name
        };
        this.items.map(selectedItem => {
          if (selectedItem.id == item.id) {
            computed.selected = true;
          }
        });
        return computed;
      });
      this.suppliersItems = tempItems;
    },
    onErrorMaxCost(e, id) {
      this.validMaxCostValues = {
        ...this.validMaxCostValues,
        [id]: !e
      };
    },
    onErrorSupplier(e, id) {
      this.validSupllierValues = {
        ...this.validSupllierValues,
        [id]: !e
      };
    },
    setIsValidFields() {
      const resultMaxCost = Object.keys(this.validMaxCostValues).some(
        item => this.validMaxCostValues[item] === false
      );
      const resultSupplier = Object.keys(this.validSupllierValues).some(
        item => this.validSupllierValues[item] === false
      );
      this.$root.$emit(
        "is-active-save-store-button",
        resultMaxCost || resultSupplier
      );
    }
  },
  watch: {
    "$route.params": {
      immediate: true,
      handler() {
        const data = { filters: { ...this.suppliersFilters } };
        this.fetchSuppliers(data);
      }
    },
    suppliers: {
      handler: "handleSuppliers"
    },
    validMaxCostValues: {
      immediate: true,
      handler: "setIsValidFields"
    },
    validSupllierValues: {
      immediate: true,
      handler: "setIsValidFields"
    }
  }
};
</script>

<style lang="scss">
.choose-supplier {
  margin: 0 0 50px;
  @include breakpoint($m) {
    margin-bottom: 30px;
  }
  h3 {
    font-size: 14px;
    line-height: 1.2;
    font-weight: 500;
    margin: 0 0 10px;
  }
  .suppliers-list {
    margin: 0;
    padding: 0;
    list-style: none;
    > li {
      margin: 0 0 16px;
    }
    .delete-button {
      top: 3px;
      margin: 0;
      position: relative;
    }
    .v-input,
    .v-text-field.v-text-field--enclosed {
      margin-bottom: 0;
    }
    .v-input,
    .v-select {
      margin: 0;
      padding: 0;
      input {
        border: none;
        height: 36px;
        padding: 0 10px;
        font-size: 12px;
        max-height: none;
        line-height: 1.2;
        background: #f0f0f1;
        &::placeholder {
          opacity: 1;
          color: #7a7c7e;
        }
        &:-ms-input-placeholder {
          opacity: 1;
          color: #7a7c7e;
        }
        &::-ms-input-placeholder {
          opacity: 1;
          color: #7a7c7e;
        }
      }
      &__slot {
        margin: 0;
        display: block;
        &:after,
        &:before {
          display: none;
        }
      }
      .v-text-field__slot {
        display: block !important;
      }
      .v-label {
        height: auto;
        display: block;
        margin: 0 0 4px;
        transform: none;
        font-size: 12px;
        line-height: 1.2;
        color: #a6a8a9 !important;
        position: static !important;
      }
      .theme--light.v-text-field--solo > .v-input__control > .v-input__slot {
        background: #f0f0f1 !important;
      }
      .base-input-background--white .v-input__slot input {
        background: #f0f0f1 !important;
      }
      &__selections {
        display: block;
        position: relative;
        &--comma {
          margin: 0;
        }
      }
      &__selection {
        &--comma {
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          margin: 0;
          z-index: 1;
          padding: 0 10px;
          font-size: 12px;
          overflow: hidden;
          line-height: 1.2;
          position: absolute;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }
      .v-select__slot {
        .v-input__append-inner {
          top: 18px;
          right: 0;
          bottom: 0;
          margin: 0;
          display: flex;
          position: absolute;
          align-items: center;
          justify-content: center;
        }
      }
    }
    .v-btn {
      margin: 0;
      @include breakpoint($m) {
        float: right;
      }
    }
    .button-primary-add {
      .v-btn {
        float: none;
      }
    }
  }

  .v-messages__message {
    margin-top: 7px;
  }

  .delete-button {
    margin-top: 12px !important;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
  }
}
</style>
