<template>
    <div class="finish">
        <h3 class="title">{{ lang.title }}</h3>
        <Breadcrumbs :items="breadcrumbs"></Breadcrumbs>
        <div class="description">
            <p>{{ lang.description }}</p>
        </div>
        <div>
            <ul class="files">
                <li v-for="file in files" class="file">
                    <a href="#" @click.prevent="downloadFile(file.slug)"><IconPdf :width="24" :height="24" /> {{ file.name }}</a>
                </li>
            </ul>
        </div>
        <div>
            <v-checkbox :label="lang.checkbox" v-model="agree" />
            <ButtonPrimary :disabled="! agree" :loading="isLoading" @click:base-button="sendToModeration()">{{ lang.btnSend }}</ButtonPrimary>
        </div>
    </div>
</template>

<script>
import { mapActions } from "vuex";
import fileSaver from "file-saver";
import IconPdf from "@/components/Icons/IconPdf";
import Breadcrumbs from "@/components/Breadcrumbs";
import ButtonPrimary from "@/components/ButtonPrimary";
import api from "@/api/paymentSolutions";
import fileApi from "@/api/files";
import { STATUS_OK } from "@/constants/httpCodes";
import { STATUS_MODERATE } from "@/constants/paymentSolutions";

export default {
    components: {
        IconPdf,
        Breadcrumbs,
        ButtonPrimary
    },
    data() {
        return {
            paymentSolution: null,
            files: [
                {
                    slug: "privacy-policy",
                    name: "Privacy policy"
                },
                {
                    slug: "shipping-delivery-conditions",
                    name: "Shipping & Delivery"
                },
                {
                    slug: "service-terms-policy",
                    name: "Terms of service"
                },
                {
                    slug: "returns-and-refunds",
                    name: "Returns and Refunds"
                },
                {
                    slug: "nastroika-shopify-dlia-podkliucheniia",
                    name: "Настройка Shopify для подключения"
                },
                {
                    slug: "store-connection-requirements-en",
                    name: "Store Connection Requirements"
                },
            ],
            agree: false,
            isLoading: false
        };
    },
    computed: {
        lang() {
            return {
                title: "Payment Solution Finish",
                description: "To finish the application, you should study service requirements and place documents from the list below to the store.",
                checkbox: "I agree with ​service requirements, terms and agreements",
                btnSend: "Send to the moderation"
            };
        },
        breadcrumbs() {
            let result = [
                {
                    text: "Payment Solutions",
                    disabled: false,
                    href: {
                        name: 'dashboard.payment-solutions'
                    }
                }
            ];

            if (this.paymentSolution !== null) {
                result.push({
                    text: `Payment Solution #${this.paymentSolution.id} Finish`,
                    disabled: true
                });
            }

            return result;
        }
    },
    methods: {
        ...mapActions(["setNotifyMsg"]),
        async sendToModeration() {
            if (! this.agree) {
                return;
            }

            this.isLoading = true;

            try {
                const { status } = await api.changeStatus(this.paymentSolution.id, STATUS_MODERATE);

                if (status === STATUS_OK) {
                    this.$router.push({name: 'dashboard.payment-solutions'});
                }
            } catch (error) {
                this.setNotifyMsg({ text: error.response.data.errors, color: "error" });
            } finally {
                this.isLoading = false;
            }
        },
        downloadFile(slug) {
            fileApi
                .download(slug)
                .then(function (response) {
                    fileSaver.saveAs(response.data, slug);
                })
                .catch(function (error) {
                    this.setNotifyMsg({ text: error.message, color: "error" });
                }.bind(this));
        }
    },
    watch: {
        "$route.params": {
            immediate: true,
            handler(params) {
                this.paymentSolution = {
                    id: params.id
                };
            }
        },
    }
}
</script>

<style scoped lang="scss">
.finish {
    max-width: $max-width;
    .title {
        margin-bottom: 20px;
    }
    .description {
        padding-bottom: 20px;
    }
    .files {
        list-style-type: none;
        padding: 0;
        .file {
            margin: 3px 0;
        }
    }
}
</style>
