import api from '@/api/salesChannel';

import {
  STATUS_OK,
  STATUS_CREATED,
  STATUS_SERVER_ERROR,
  STATUS_VALIDATION_ERROR,
  STATUS_DELETED,
} from '@/constants/httpCodes';

export default {
  state: {
    salesChannels: null,
    salesChannel: null,
    isReconnectSalesChannel: false
  },
  getters: {
    getSalesChannels: state => state.salesChannels,
    getSalesChannel: state => state.salesChannel,
    getFirstSalesChannel: state => state.salesChannels && state.salesChannels[0],
    getActiveSalesChannels: state => id => state.salesChannels && state.salesChannels.filter(item =>
      item.id == id)[0],
  },
  mutations: {
    SET_SALES_CHANNELS_DATA(state, payload) {
      state.salesChannels = payload;
    },
    SET_SALE_CHANNEL_DATA(state, payload) {
      state.salesChannel = payload;
    },
    FILTER_SALES_CHANNELS_BY_ID(state, payload) {
      state.salesChannels = state.salesChannels.filter(item => item.id !== payload);
    },
    SET_IS_RECONNECT_SALES_CHANNEL_DATA(state, payload) {
      state.isReconnectSalesChannel = payload;
    },
  },
  actions: {
    fetchSalesChannels({
      commit,
      dispatch
    }, payload) {
      dispatch('setIsProgressData', true);
      api.get(payload)
        .then(({
          data: {
            items
          },
          status
        }) => {
          if (status === STATUS_OK) {
            commit('SET_SALES_CHANNELS_DATA', items);
          }
        })
        .finally(() => {
          dispatch('setIsProgressData', false);
        })
    },
    fetchSalesChannelById({
      commit,
      dispatch,
    }, payload) {
      dispatch('setIsProgressData', true);
      api.getById(payload.id, payload.filters)
        .then(({
          data,
          status
        }) => {
          if (status === STATUS_OK) {
            commit('SET_SALE_CHANNEL_DATA', data);
          }
        })
        .finally(() => {
          dispatch('setIsProgressData', false);
        })
    },
    async postChannel({
      dispatch
    }, payload) {
      dispatch('setIsProgressData', true);
      const response = await api.postNewStore(payload)
        .then(({
          data: {
            authUrl
          },
          status
        }) => {
          if (status === STATUS_CREATED) {
            window.location.href = authUrl;
            return {
              status: true,
              text: null
            }
          }
        })
        .catch(error => {
          if (error.response.status === STATUS_SERVER_ERROR || error.response.status ===
            STATUS_VALIDATION_ERROR) {
            return {
              status: false,
              text: error.response.data.errors
            }
          }
        })
        .finally(() => {
          dispatch('setIsProgressData', false);
        });

      return response;
    },
    updateSalesChannel({
      commit,
      dispatch,
      rootState
    }, payload) {
      dispatch('setIsLoadingData', true);
      api.updateStore(payload)
        .then(({
          data,
          status
        }) => {
          if (status === STATUS_OK) {
            commit('SET_SALE_CHANNEL_DATA', data);
          }
        })
        .catch(error => {
          let errorResult = rootState.errors.notifyMessages.somethingWrong;

          if (error.response.status === STATUS_VALIDATION_ERROR) {
            errorResult = error.response.data.errors;
          }

          // dispatch('setNotifyMsg', {
          //   color: 'error',
          //   text: errorResult
          // });
        })
        .finally(() => {
          dispatch('setIsLoadingData', false);
        })
    },
    async updateSalesChannelUser({
      dispatch
    }, payload) {
      dispatch('setIsProgressData', true);

      try {
        const {data, status} = await api.transferShop(payload);

        if (status === STATUS_OK) {
          return {
            status: status,
            text: data.text || 'Success'
          }
        }
      } catch (error) {
        if (STATUS_SERVER_ERROR === error.response.status) {
          dispatch('setNotifyMsg', {
            color: 'error',
            text: error.response.data.title
          });

          return;
        }

        return {
          status: error.response.status,
          text: error.response.data.errors
        }
      } finally {
        dispatch('setIsProgressData', false);
      }
    },
    async setChannelLogin({
      dispatch,
      rootState
    }, payload) {
      dispatch('setIsLoadingData', true);
      const result = await api.setChannelLogin(payload.id, payload.data)
        .then(() => {
          dispatch('setNotifyMsg', {
            color: 'success',
            text: rootState.errors.notifyMessages.supportEmailCreated
          });
          return {
            status: true
          };
        })
        .catch(error => {
          let errorResult = rootState.errors.notifyMessages.somethingWrong;

          if (error.response.status === STATUS_VALIDATION_ERROR) {
            errorResult = error.response.data.errors;
          }

          dispatch('setNotifyMsg', {
            color: 'error',
            text: errorResult
          });
        })
        .finally(() => {
          dispatch('setIsLoadingData', false);
        });

      return result;
    },
    async deleteSalesChannel({
      dispatch,
      rootState,
      commit
    }, payload) {
      dispatch('setIsLoadingData', true);
      const result = await api.delete(payload)
        .then(({
          status
        }) => {
          if (status === STATUS_DELETED) {
            dispatch('setNotifyMsg', {
              text: rootState.errors.notifyMessages.removeSalesChannel,
              color: 'success'
            });

            return true;
          }
        })
        .catch(error => {
          let resultError = rootState.errors.notifyMessages.somethingWrong;
          if (error.response.status ===
            STATUS_VALIDATION_ERROR) {
            resultError = error.response.data.errors;
          }
          dispatch('setNotifyMsg', {
            text: resultError,
            color: 'error'
          });

          return false;
        })
        .finally(() => {
          dispatch('setIsLoadingData', false);
        });

      if (result) {
        commit('FILTER_SALES_CHANNELS_BY_ID', payload);

      }

      return result;
    }
  },
};
