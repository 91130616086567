<template>
  <div class="base-input" :class="{'base-input--error': hasError && showErrors}">
    <label class="base-input__label">
      <slot></slot>
    </label>
    <div class="base-input__input-text">
      <v-text-field
        v-bind="$attrs"
        :type="type"
        v-on="$listeners"
        v-model="computedValue"
        :class="{[`base-input-background--${backgroundColor}`]: true}"
        @input="showErrors = false"
        :placeholder="placeholder"
        :hide-details="hideDetails"
        :readonly=readonly
      />
      <span class="base-input__subtitle" v-if="subtitle">{{ subtitle }}</span>
      <div class="base-input-errors" v-if="hasError && showErrors">{{ errorText }}</div>
      <div class="base-input__icon">
        <slot name="icon"></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  inheritAttrs: false,
  props: {
    type: {
      type: String,
      default: "text"
    },
    readonly: {
      type: Boolean,
      default: false
    },
    value: {
      required: true,
      validator: prop => typeof prop === "string" || prop === null
    },
    placeholder: {
      type: String,
      default: ""
    },
    backgroundColor: {
      type: String,
      default: "white"
    },
    subtitle: {
      type: String
    },
    hideDetails: {
      type: Boolean,
      default: true
    },
    hasError: {
      type: Boolean,
      default: false
    },
    errorText: {
      type: String,
      default: "Error"
    }
  },

  data() {
    return {
      showErrors: true
    };
  },
  computed: {
    computedValue: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input:base-text", val);
      }
    }
  },

  watch: {
    hasError: {
      immediate: true,
      handler(val) {
        this.showErrors = val;
      }
    }
  }
};
</script>

<style lang="scss">
.base-input {
  .base-input__label {
    display: block;
    font-size: em(12);
    overflow: hidden;
    color: $text-color;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .base-input__input-text {
    position: relative;
  }

  .base-input__icon {
    position: absolute;
    top: -2px;
    right: 0;
  }

  .v-text-field {
    padding-top: 0;

    .v-input__slot {
      border-radius: 4px !important;
      max-height: 30px;
      min-height: 30px;
      height: inherit;
      background: #fff;

      &:before {
        border: none !important;
      }

      .v-text-field__slot {
        max-height: inherit !important;
        min-height: inherit !important;
        height: inherit !important;
      }

      input {
        max-height: inherit;
        min-height: inherit;
        height: 30px;
        text-indent: 10px;
        font-size: 12px;
        border-radius: 4px;
      }

      &:after {
        display: none !important;
      }
    }
  }

  .base-input-background--white {
    .v-input__slot {
      input {
        background-color: #fff !important;
      }
    }
  }

  .base-input-background--gray {
    .v-input__slot {
      input {
        background-color: $body-color !important;
      }
    }
  }
  &__subtitle {
    display: block;
    color: #a6a8a9;
    font-size: 12px;
    font-weight: 500;
    line-height: 1.42;
    padding: 12px 0 0;
    @include breakpoint($m) {
      padding-top: 6px;
    }
  }

  .base-input--error {
    .base-input__label {
      color: $warning-color !important;
    }
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
  }
}
</style>
