import axios from 'axios';
import BaseApi from './base';
import {
  PRODUCTS,
  STATISTICS,
  SHOP_PRODUCTS,
  IMPORT,
  EXPORT_TO_STORE,
  MARKETPLACE,
  SPLIT_PRODUCTS,
  SPLIT_ORDERS
} from '@/constants/api';

export default class extends BaseApi {
  static get(filters = null) {
    return new Promise((resolve, reject) => {
      return axios.get(this.baseURI + PRODUCTS, {
          params: filters,
        })
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  static getStatistics(filters = null) {
    return new Promise((resolve, reject) => {
      return axios.get(this.baseURI + SHOP_PRODUCTS + '/' + STATISTICS, {
          params: filters
        })
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  static getById(id) {
    return new Promise((resolve, reject) => {
      return axios.get(this.baseURI + PRODUCTS + '/' + id)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  static getMyProducts(filters = null) {
    return new Promise((resolve, reject) => {
      return axios.get(this.baseURI + SHOP_PRODUCTS, {
          params: filters,
        })
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  static getMyProductById(id) {
    return new Promise((resolve, reject) => {
      return axios.get(this.baseURI + SHOP_PRODUCTS + '/' + id, {
          params: {
            groups: 'variants,images,ShopProduct.salesChannel,PrevNext'
          }
        })
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  static addToImport(id, channelId) {
    return new Promise((resolve, reject) => {
      return axios.post(this.baseURI + PRODUCTS + '/' + id + '/' + IMPORT + '/' + channelId)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    })
  }

  static removeMyProduct(id) {
    return new Promise((resolve, reject) => {
      return axios.delete(this.baseURI + SHOP_PRODUCTS + '/' + id)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    })
  }

  static removeMyProducts(ids) {
    return new Promise((resolve, reject) => {
      return axios.delete(this.baseURI + SHOP_PRODUCTS, {
          data: ids
        })
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    })
  }

  static saveMyProduct(id, data) {
    return new Promise((resolve, reject) => {
      return axios.put(this.baseURI + SHOP_PRODUCTS + '/' + id, data)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    })
  }

  static importToStore(id) {
    return new Promise((resolve, reject) => {
      return axios.post(this.baseURI + SHOP_PRODUCTS + '/' + id + '/' + EXPORT_TO_STORE)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    })
  }

  static importAllToStore(salesChannelId) {
    return new Promise((resolve, reject) => {
      return axios.post(this.baseURI + SHOP_PRODUCTS + '/all/' + EXPORT_TO_STORE, {salesChannel: salesChannelId})
          .then(response => {
            resolve(response);
          })
          .catch(error => {
            reject(error);
          });
    });
  }

  static addNewProducts(data) {
    return new Promise((resolve, reject) => {
      return axios.post(this.baseURI + PRODUCTS + '/' + IMPORT + '/' + MARKETPLACE, data)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    })
  }

  static splitProducts(id, data) {
    return new Promise((resolve, reject) => {
      return axios.post(this.baseURI + SHOP_PRODUCTS + '/' + id + '/' + SPLIT_PRODUCTS, data)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    })
  }

  static splitOptions(id, data) {
    return new Promise((resolve, reject) => {
      return axios.post(this.baseURI + SHOP_PRODUCTS + '/' + id + '/' + SPLIT_ORDERS, data)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    })
  }
}
