<template>
  <json-excel
    class="export-csv item--hover"
    :data="items"
    :fields="fields"
    :meta="exportCSV.meta"
    :worksheet="worksheet"
    :name="name"
    :type="type"
  >
    <v-icon>insert_drive_file</v-icon>
    <span class="text">{{ title }}</span>
  </json-excel>
</template>

<script>
import JsonExcel from "vue-json-excel";

export default {
  components: {
    JsonExcel
  },
  props: {
    items: {
      type: Array,
      requred: true
    },
    fields: {
      type: Object,
      default: null
    },
    name: {
      type: String,
      default: "table.xls"
    },
    type: {
      type: String,
      default: "xls"
    },
    worksheet: {
      type: String,
      default: "My Worksheet"
    },
    title: {
      type: String,
      default: "Export to XLS"
    }
  },
  data() {
    return {
      exportCSV: {
        meta: [
          [
            {
              key: "charset",
              value: "utf-8"
            }
          ]
        ]
      }
    };
  }
};
</script>

<style lang="scss">
.exports {
  text-align: right;
  margin: 22px 0 0 20px;
  @include breakpoint($t) {
    margin-left: 0;
  }
}
.export-csv {
  display: inline-flex;
  font-size: 14px;
  cursor: pointer;
  font-weight: 500;
  line-height: 21px;
  white-space: nowrap;
  align-items: center;
  justify-content: flex-end;
  .text {
    color: #222528;
    text-decoration: underline;
    transition: color 0.35s ease;
  }
  .material-icons {
    color: #a6a8a9;
    display: block;
    font-size: 19px;
    margin: 0 4px 0 0;
  }
  &:hover {
    .text {
      color: #d32d11;
    }
  }
}
</style>
