<template>
  <div class="logo">
    <router-link :to="{path}">
      <img src="@/assets/icons/logo.svg" alt="logo" :style="styleObj">
    </router-link>
  </div>
</template>

<script>
export default {
  props: {
    width: {
      type: [Number, String],
      default: "120px"
    },
    height: {
      type: [Number, String],
      default: "auto"
    },
    path: {
      type: String,
      default: "/"
    }
  },
  data() {
    return {
      styleObj: {
        width: this.width,
        height: this.height
      }
    };
  }
};
</script>

<style scoped lang="scss">
.logo {
  img {
    cursor: pointer;
  }
}
</style>
