export default {
  data() {
    return {
      selectedItem: null
    };
  },
  methods: {
    handleClickOption(item) {
      this.selectedItem = item.id;
      this.$root.$emit("clicked-option", {
        ...item,
        option: this.optionNumber
      });
    }
  },
  watch: {
    items() {
      this.selectedItem = null;
    }
  }
}
