<template>
  <BaseIcon :width="width" :height="height" :stroke="stroke" fill="none" name="product">
    <path d="M8.5.592l7.987 4.611v9.224L8.5 19.038.512 14.427V5.203L8.5.592z"/>
    <path d="M16.488 5.203L8.5 9.815.512 5.203"/>
    <path stroke-linecap="square" d="M8.455 9.815v8.199"/>
  </BaseIcon>
</template>

<script>
export default {
  props: {
    width: {
      type: [Number, String],
      default: 18
    },
    height: {
      type: [Number, String],
      default: 18
    },
    fill: {
      type: String,
      default: "none"
    },
    stroke: {
      type: String,
      default: "#7A7C7E"
    }
  }
};
</script>

<style scoped>
</style>
