<template>
  <div>
    <h3 class="home-right-form__title" v-html="lang.form.recoveryTitle"></h3>
    <FormRecovery
      class="v-text-field--flat v-text-field--flat--gray"
      @submit:form="handleSubmitForm"
    ></FormRecovery>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";

import FormRecovery from "@/components/FormRecovery";

export default {
  components: {
    FormRecovery
  },
  computed: {
    ...mapState({
      successResetPassMsg: state =>
        state.errors.notifyMessages.resetPasswordSuccess,
      errorMsg: state => state.errors.notifyMessages.somethingWrong,
      form: state => state.forms.forms
    }),
    lang() {
      return {
        form: {
          recoveryTitle: "Password changing"
        }
      };
    }
  },
  methods: {
    ...mapActions(["setNotifyMsg", "resetPasswordRequest"]),
    async handleSubmitForm(data) {
      const requestData = {
        user: data,
        vm: this
      };

      const { status, text } = await this.resetPasswordRequest(requestData);

      var notifyData = {
        text: text || (status ? this.successResetPassMsg : this.errorMsg),
        color: status ? "success" : "error"
      };

      this.setNotifyMsg(notifyData);
    }
  }
};
</script>

<style scoped>
</style>
