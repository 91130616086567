import axios from 'axios';
import BaseApi from './base';

import {
  PAYMENT_SOLUTIONS,
} from '@/constants/api';

export default class extends BaseApi {
  static post(data) {
    return new Promise((resolve, reject) => {
      return axios.post(this.baseURI + PAYMENT_SOLUTIONS, data)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  static get() {
    return new Promise((resolve, reject) => {
      return axios.get(this.baseURI + PAYMENT_SOLUTIONS)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  static changeStatus(id, status) {
    return new Promise((resolve, reject) => {
      return axios.put(this.baseURI + PAYMENT_SOLUTIONS + `/${id}/change-status/${status}`)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  }
}
