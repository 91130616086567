import {
  mapGetters
} from 'vuex';

import fetchSubcategoriesMixins from './fetchSubcategoriesMixins';

export default {
  mixins: [fetchSubcategoriesMixins],
  computed: {
    ...mapGetters({
      category: "getCategory",
      productsTotalCount: "getProductsTotalCount",
    }),
    breadcrumbs() {
      let items = [{
        text: "Catalog",
        disabled: false,
        href: {
          name: "dashboard.products.search"
        }
      }];

      if (this.category) {
        var {
          name,
          id,
          parent
        } = this.category;

        const lastChild = {
          id,
          text: this.productsTotalCount ? `${name} (${this.productsTotalCount} items)` : name,
          disabled: this.productsTotalCount && true,
          href: {
            name: "dashboard.products.list",
            params: {
              id: id
            }
          }
        };

        let parentsArr = this.renderParentBreadcrumbs(parent).reverse();

        parentsArr.push(lastChild);
        items.push(...parentsArr);

        this.getRootCategory(items);
      }

      return items;
    }
  },
  methods: {
    renderParentBreadcrumbs(child, items = []) {
      let id, name, parent;

      if (!child) {
        return items;
      }

      id = child.id;
      name = child.name;
      parent = child.parent;

      items.push({
        id,
        text: name,
        disabled: false,
        href: {
          name: "dashboard.products.list",
          params: {
            id
          },
          query: {
            page: 1,
            page_size: this.$route.query.page_size
          }
        }
      });

      return this.renderParentBreadcrumbs(parent, items);
    },
  }
}
