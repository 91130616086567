<template>
  <div class="table-orders-statistics" v-if="items">
    <div name="filters" class="base-table--filters">
      <FilterOrdersStatistics @click:search-btn="handleSearch"/>
      <div class="exports" v-if="items && items.length">
        <ExportTable :items="items" :fields="exportCSV.fields" name="orders-statistic.xls"/>
      </div>
    </div>
    <BaseTable
      class="table-orders-statistics__content"
      :headers="headers"
      :items="items"
      :pageSize="10000"
      :custom-pagination="paginationsLinks"
      :footer="itemsTotal"
    ></BaseTable>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { GROUP_BY_COUNTRY } from "@/constants/groupBy";

import FilterOrdersStatistics from "@/components/Filters/FilterOrdersStatistics";
import ExportTable from "@/components/ExportTable";
import BaseTable from "@/components/BaseTable";

export default {
  components: {
    BaseTable,
    FilterOrdersStatistics,
    ExportTable
  },

  data() {
    return {
      items: [],
      itemsTotal: [],
      headers: [
        { text: "Day", value: "day" },
        { text: "Pending, qnt", value: "pending_count" },
        { text: "Paid, qnt", value: "paid_count" },
        { text: "Pending income, $", value: "pending_income" },
        { text: "Incomes, $", value: "incomes" },
        { text: "AOV, $", value: "average_check" },
        { text: "Charges, $", value: "charges" },
        { text: "Refunds, $", value: "refunds" },
        { text: "Profit, $", value: "profit" }
      ],
      exportCSV: {},
      filters: null
    };
  },

  computed: {
    ...mapGetters({
      statistics: "getOrdersStatistics"
    }),
    lang() {
      return {
        ids: `Customer’s ID`,
        phone: "Phone",
        email: "Email",
        date: "Creation Date"
      };
    }
  },

  methods: {
    ...mapActions(["fetchOrdersStatistics"]),
    fetchOrdersStatisticsWithFilters(filters = {}) {
      const salesChannel = this.$salesChannel.get();

      const newFilters = {
        salesChannel,
        ...filters
      };

      this.filters = newFilters;

      newFilters.groupBy =
        newFilters.groupBy && newFilters.groupBy.toLowerCase();

      this.fetchOrdersStatistics(newFilters);
    },
    handleSearch(filters) {
      this.fetchOrdersStatisticsWithFilters(filters);
      this.setHeaders(filters.groupBy);
      this.setFiltersToLocalStorage(filters);
    },
    setHeaders(newHeader) {
      this.headers.forEach(function(header) {
        if (header.value === "day") {
          header.text = newHeader;
        }
      });

      const exportCSVfields = {
        [newHeader[0].toUpperCase() + newHeader.slice(1)]: "day",
        "Pending count": "pending_count",
        "Paid count": "paid_count",
        "Pending income, $": "pending_income",
        "Incomes, $": "incomes",
        "Average check, $": "average_check",
        "Charges, $": "charges",
        "Refunds, $": "refunds",
        "Profit, $": "profit"
      };

      this.exportCSV.fields = exportCSVfields;
    },
    toFixed(val) {
      return Math.round(val * 100) / 100;
    },
    setFiltersToLocalStorage(filters) {
      if (filters.dateFrom && filters.dateTo) {
        this.$filters.setOrdersStatisticDateFilter(
          filters.dateFrom,
          filters.dateTo
        );
      }

      this.$filters.setOrdersStatisticCountryFilter(
        filters.countries.join(", ")
      );
    }
  },

  watch: {
    statistics: {
      handler(statistics) {
        const tempItems = Object.keys(statistics.items).map(key => {
          let item = statistics.items[key];
          return {
            day:
              this.filters.groupBy !== GROUP_BY_COUNTRY.toLowerCase()
                ? item.groupByValue
                : item.groupByName,
            pending_count: item.pendingCount,
            paid_count: item.paidCount,
            pending_income: item.pendingIncome,
            incomes: this.toFixed(item.income),
            average_check: this.toFixed(item.averageCheck),
            charges: this.toFixed(item.charge),
            refunds: this.toFixed(item.refunds),
            profit: this.toFixed(item.userProfit - item.refunds)
          };
        });
        this.items = tempItems;

        const total = statistics.total;
        this.itemsTotal = [
          {
            day: "Total",
            pending_count: total.pendingCount,
            paid_count: total.paidCount,
            pending_income: total.pendingIncome,
            incomes: this.toFixed(total.income),
            average_check: this.toFixed(total.averageCheck),
            charges: this.toFixed(total.charge),
            refunds: this.toFixed(total.refunds),
            profit: this.toFixed(total.userProfit - total.refunds)
          }
        ];
      }
    },
  },

  mounted() {
    window.$(".table-orders-statistics table").stickyTableHeaders({
      cacheHeaderHeight: true,
      fixedOffset: window.$(".table-orders-statistics table thead")
    });
  },

  beforeDestroy() {
    window.$(".table-orders-statistics table").stickyTableHeaders("destroy");
  }
};
</script>

<style lang="scss">
.table-orders-statistics__total {
  thead {
    display: none;
  }
}
</style>
