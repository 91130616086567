<template>
  <div class="button-secondary">
    <BaseButton v-bind="$attrs" :color="color" round v-on="$listeners">
      <slot/>
    </BaseButton>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    color: {
      type: String,
      default: "#a6a8a9"
    }
  }
};
</script>

<style lang="scss">
.button-secondary {
  .v-btn {
    color: #222528 !important;
    border-color: #a6a8a9 !important;
    i {
      font-size: 18px;
      margin-left: 10px;
    }

    &:hover {
      border-color: transparent !important;
    }
  }
}

.button-fixed {
  button {
    width: 160px;
  }
}
.button-fixed-small {
  button {
    width: 110px;
  }
}
</style>
