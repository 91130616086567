<template>
  <div>
    <v-dialog
      v-model="dialog"
      v-bind="$attrs"
      class="base-modal"
      :width="width"
      @keydown.esc="$emit('esc:modal')"
    >
      <v-card class="base-modal-card" color="#f0f0f1">
        <v-card-text>
          <slot name="content"></slot>
        </v-card-text>
        <v-card-actions>
          <slot name="actions"></slot>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  inheritAttrs: false,
  props: {
    show: {
      type: Boolean,
      default: false
    },
    width: {
      type: String,
      default: "800"
    }
  },
  data() {
    return {
      dialog: false
    };
  },
  watch: {
    show: {
      handler(val) {
        this.dialog = val;
      }
    },
    dialog: {
      handler(val) {
        this.$emit("change:modal", val);
      }
    }
  }
};
</script>

<style lang="scss">
.base-modal {
  touch-action: manipulation;
}
</style>
