import api from '@/api/tickets';

import {
  STATUS_OK,
} from '@/constants/httpCodes';

export default {
  state: {
    tickets: null,
    ticket: null,
    statistics: null,
    chatScripts: null
  },
  getters: {
    getTickets: state => state.tickets && state.tickets.items,
    getTicket: state => state.ticket,
    getTicketsPagination: state => state.tickets && state.tickets.links,
    getStatistics: state => state.statistics,
    getChatScripts: state => state.chatScripts
  },
  mutations: {
    SET_TICKETS_DATA(state, payload) {
      state.tickets = payload;
    },
    SET_TICKET_DATA(state, payload) {
      state.ticket = payload;
    },
    SET_TICKETS_STATISTICS_DATA(state, payload) {
      state.statistics = payload;
    },
    SET_CHAT_SCRIPTS(state, payload) {
      state.chatScripts = payload;
    }
  },
  actions: {
    fetchTickets({
      commit,
      dispatch,
    }, payload) {
      dispatch('setIsProgressData', true);
      api.get(payload)
        .then(({
          data,
          status
        }) => {
          if (status === STATUS_OK) {
            commit('SET_TICKETS_DATA', data);
          }
        })
        .finally(() => {
          dispatch('setIsProgressData', false);
        })
    },
    fetchTicketById({
      commit,
      dispatch,
    }, payload) {
      dispatch('setIsProgressData', true);
      api.getById(payload.id)
        .then(({
          data,
          status
        }) => {
          if (status === STATUS_OK) {
            commit('SET_TICKET_DATA', data);
          }
        })
        .finally(() => {
          dispatch('setIsProgressData', false);
        })
    },
    fetchStatistics({
      commit,
      dispatch,
    }, payload) {
      dispatch('setIsProgressData', true);
      api.getStatistics(payload)
        .then(({
          data,
          status
        }) => {
          if (status === STATUS_OK) {
            commit('SET_TICKETS_STATISTICS_DATA', data);
          }
        })
        .finally(() => {
          dispatch('setIsProgressData', false);
        })
    },
    getChatScripts({
      commit,
      dispatch
    }) {
      dispatch('setIsLoadingData', true);
      api.getChatScripts()
        .then(({
          data,
          status
        }) => {
          if (status === STATUS_OK) {
            commit('SET_CHAT_SCRIPTS', data);
          }
        })
        .finally(() => {
          dispatch('setIsLoadingData', false);
        })
    }
  },
};
