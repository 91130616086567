<template>
  <div class="filters-products">
    <div class="filters-products__price">
      <div class="filters-products__price__header">
        <label class="filters-products-label">{{ lang.price.label }}</label>
        <div class="filters-products__price__values">
          <div class="filters-products__price__label">{{ lang.price.from }}</div>
          <div class="filters-products__price__input v-text-field--flat v-text-field--flat--gray">
            <v-text-field type="text" v-model="inputMinPrice" mask="####"/>
          </div>
          <div class="filters-products__price__label">{{ lang.price.to }}</div>
          <div class="filters-products__price__input v-text-field--flat v-text-field--flat--gray">
            <v-text-field type="text" v-model="inputMaxPrice" mask="####"/>
          </div>
        </div>
      </div>
      <v-range-slider
        v-model="price"
        :max="maxPrice"
        :min="minPrice"
        :step="priceStep"
        color="warning"
        @change="handlePriceChange"
      ></v-range-slider>
    </div>
    <div class="filters-products__coutries">
      <InputSelect
        ref="countrySelect"
        v-if="countries"
        @change:base-select="handleSelectedCountries"
        :items="countries"
        item-text="name"
        item-value="iso2"
        backgroundColor="gray"
        :label="countrylabel"
        height="40"
        clearable
        :value="selectedCountry"
      ></InputSelect>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import InputSelect from "@/components/InputSelect";

export default {
  components: {
    InputSelect
  },
  props: {
    countrylabel: {
      type: String,
      default: "Warehouse"
    }
  },
  data() {
    return {
      country: "",
      minPrice: 0,
      maxPrice: 200,
      priceStep: 10,
      price: [0, 200],
      selectedCountry: {}
    };
  },
  computed: {
    ...mapGetters({
      countries: "getMockCountries",
      getMockCountryByISO: "getMockCountryByISO"
    }),
    lang() {
      return {
        countries: {
          label: "Ships from"
        },
        price: {
          label: "Price",
          from: "from",
          to: "to"
        }
      };
    },
    inputMinPrice: {
      get() {
        return this.price[0];
      },
      set(val) {
        this.price[0] = +val;
        this.handlePriceChange(this.price);
      }
    },
    inputMaxPrice: {
      get() {
        return this.price[1];
      },
      set(val) {
        this.price[1] = +val;
        this.handlePriceChange(this.price);
      }
    }
  },
  methods: {
    handlePriceChange(price) {
      this.$emit("change:filters-price", price);
    },
    handleSelectedCountries(country) {
      if (typeof country !== "undefined" && typeof country !== "object") {
        this.$nextTick().then(() => {
          this.country = country;
        });
      }

      if (typeof country === "undefined") {
        this.country = "";
      }
    }
  },
  watch: {
    country(val) {
      this.$emit("change:filters-country", val);
    },
    "$route.query.shipsFrom": {
      immediate: true,
      handler(val) {
        if (typeof val !== "undefined" && typeof val !== "object") {
          this.country = val;
          window.localStorage.setItem("countryISO", val);
        }
        if (typeof val === "undefined") {
          this.country = "";
          this.selectedCountry = {};
        }
      }
    },
    "$route.query.minPrice": {
      immediate: true,
      handler(val) {
        if (typeof val !== "undefined") {
          this.price[0] = +val;
        }
      }
    },
    "$route.query.maxPrice": {
      immediate: true,
      handler(val) {
        if (typeof val !== "undefined") {
          this.price[1] = +val;
        }
      }
    }
  },
  created() {
    const selectedCountryISO = window.localStorage.getItem("countryISO");
    if (
      selectedCountryISO &&
      typeof this.$route.query.shipsFrom !== "undefined"
    ) {
      this.selectedCountry = this.getMockCountryByISO(selectedCountryISO);
    }
  }
};
</script>

<style lang="scss">
.filters-products {
  * {
    font-size: 12px;
  }
  .filters-products-label {
    color: $text-color;
    margin-top: 10px;
    margin-right: 5px;
  }
  .filters-products__coutries {
    margin-top: 38px;

    .filters-products__coutries__select {
      margin-top: 7px;
    }
  }

  .filters-products__price__header {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: space-between;
  }

  .filters-products__price__input {
    display: inline-block;
    max-width: 60px;
    margin: 0 5px 0 0;
  }
  .filters-products__price__values {
    margin-right: -5px;
  }
  .filters-products__price__label {
    display: inline-block;
    margin: 0 5px 0 0;
  }
  .v-input--slider {
    margin-top: 0;
  }
  .filters-products__price__header {
    .v-input__control {
      .v-text-field__details {
        display: none;
      }
      .v-input__slot {
        margin-bottom: 0;
      }
    }
  }
}
</style>
