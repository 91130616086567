import {
  PAYOUT_WEBMONEY,
  PAYOUT_PAYPAL,
  PAYOUT_EPAYMENTS,
  PAYOUT_BANK_ACOOUNT,
  PAYOUT_CAPITALIST
} from "@/constants/payoutSystems";

export default {
  methods: {
    getPaymentMethod({
      paymentSystem,
      data
    }) {
      if (paymentSystem === PAYOUT_WEBMONEY) {
        return `Webmoney (${data.wallet || data.number})`;
      } else if (paymentSystem === PAYOUT_PAYPAL) {
        return `Paypal (${data.email || data.number})`;
      } else if (paymentSystem === PAYOUT_EPAYMENTS) {
        return `ePayments (${data.epid || data.number})`;
      } else if (paymentSystem === PAYOUT_BANK_ACOOUNT) {
        return `Bank account (${data.bankAccount})`;
      } else if (paymentSystem === PAYOUT_CAPITALIST) {
        return `Capitalist (${data.wallet})`;
      }
    }
  },
}
