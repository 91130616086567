import Vue from 'vue';
import {
  setUserToLocalStorage
} from "@/helpers/auth";

export function autoAuth(to, next) {
  if (to.matched.some(record => record.meta.autoAuth)) {
    const {
      token,
      refreshToken,
      expire,
      refreshExpire
    } = to.query;

    if (token && refreshToken && expire && refreshExpire) {
      if (Vue.auth.isAuthenticated()) {
        next({
          name: 'dashboard.store-management'
        });
        return;
      }

      const user = {
        token,
        refreshToken,
        expire,
        refreshExpire
      };

      window.axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
      setUserToLocalStorage(user, new Vue);
      next({
        name: "dashboard.dashboard"
      });
    }
  }
}

export function requireAuth(to, next) {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!Vue.auth.isAuthenticated()) {
      next({
        name: 'home',
      })
    } else {
      next();
    }
  } else {
    next();
  }
}

export function isAuth(to, next) {
  if (to.matched.some(record => record.meta.isAuth)) {
    if (Vue.auth.isAuthenticated()) {
      next({
        name: "dashboard.dashboard"
      });
    } else {
      next();
    }
  }
}

export function hasNotificationsAccess(to, next) {
  if (to.matched.some(record => record.meta.notificationsAccess)) {
    if (! Vue.auth.hasNotificationsAccess()) {
      next({
        name: "dashboard.dashboard"
      });
    } else {
      next();
    }
  }
}

export function hasPaymentSolutionsAccess(to, next) {
  if (to.matched.some(record => record.meta.paymentSolutionsAccess)) {
    if (! Vue.auth.hasPaymentSolutionsAccess()) {
      next({
        name: "dashboard.dashboard"
      });
    } else {
      next();
    }
  }
}

export function hasVirtualInventoryAccess(to, next) {
  if (to.matched.some(record => record.meta.virtualInventoryAccess)) {
    if (! Vue.auth.hasVirtualInventoryAccess()) {
      next({
        name: "dashboard.dashboard"
      });
    } else {
      next();
    }
  }
}

export function hasBalanceAccess(to, next) {
  if (to.matched.some(record => record.meta.balanceAccess)) {
    if (! Vue.auth.hasBalanceAccess()) {
      next({
        name: "dashboard.dashboard"
      });
    } else {
      next();
    }
  }
}

export function hasPayoutAccess(to, next) {
  if (to.matched.some(record => record.meta.payoutAccess)) {
    if (! Vue.auth.hasPayoutAccess()) {
      next({
        name: "dashboard.dashboard"
      });
    } else {
      next();
    }
  }
}

export function hasSubAccountsAccess(to, next) {
  if (to.matched.some(record => record.meta.subAccountsAccess)) {
    if (! Vue.auth.hasSubAccountsAccess()) {
      next({
        name: "dashboard.dashboard"
      });
    } else {
      next();
    }
  }
}

export function requiresChannelId(to, next) {
  if (to.matched.some(record => record.meta.requiresChannelId)) {
    if (! Vue.salesChannel.get()) {
      next({
        name: 'dashboard.dashboard',
      })
    } else {
      next();
    }
  } else {
    next();
  }
}
