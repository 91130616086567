<template>
  <div class="product-options">
    <v-layout row wrap justify-start class="color-picker">
      <v-flex md v-for="(item, k) in items" :key="k">
        <template v-if="item.imageSrc">
          <v-img
            v-on="$listeners"
            class="product-option-image"
            :class="{'product-option--active': selectedItem === item.id}"
            :src="item.imageSrc"
            :lazy-src="item.imageSrc"
            :width="40"
            :height="40"
            @click="handleClickOption(item)"
          ></v-img>
        </template>
        <template v-else>
          <div
            class="product-option"
            :class="{'product-option--active': selectedItem === item.id}"
            @click="handleClickOption(item)"
          >{{item.name}}</div>
        </template>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
import mixin from "@/mixins/productOptionMixin";

export default {
  mixins: [mixin],
  props: {
    items: {
      type: Array,
      required: true
    },
    optionNumber: {
      type: [Number, String],
      required: true
    }
  }
};
</script>

<style lang="scss">
</style>
