<template>
  <div class="split-products-table">
    <BaseTable
      :headers="headers"
      :items="items"
      show-checkboxes
      @fetch-selected-by-checkbox-array="handleSelected"
    >
      <div slot="selected-header" class="split-products-table__header">
        <v-layout align-center row wrap>
          <v-flex md2>
            <div class="base-table__selected_items__count">{{ selectedCount }}</div>
          </v-flex>
          <v-flex md>
            <slot name="selected-header-button">
              <ButtonPrimary
                outline
                small
                @click:base-button="handleSplitProducts"
                :loading="progress"
              >{{ lang.split }}</ButtonPrimary>
            </slot>
          </v-flex>
        </v-layout>
      </div>
    </BaseTable>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import ButtonPrimary from "@/components/ButtonPrimary";
import BaseTable from "@/components/BaseTable";

export default {
  inheritAttrs: false,
  components: {
    ButtonPrimary,
    BaseTable
  },
  data() {
    return {
      selected: [],
      items: [],
      headers: []
    };
  },
  computed: {
    ...mapGetters({
      product: "getMyProduct",
      progress: "getIsProgress"
    }),
    lang() {
      return {
        split: "Split"
      };
    },
    selectedCount() {
      if (this.selected.length === 1) {
        return `${this.selected.length} variant selected`;
      }
      return `${this.selected.length} variants selected`;
    }
  },
  methods: {
    setHeaders() {
      this.headers = [
        { text: "Id", value: "id" },
        { text: "Available", value: "available" },
        { text: "Image", value: "image" },
        { text: "Cost, $", value: "cost" },
        { text: "Price, $", value: "price" },
        { text: "Compare Price, $", value: "comparePrice" },
        { text: this.product.option1, value: "option1" },
        { text: this.product.option2, value: "option2" },
        { text: this.product.option3, value: "option3" }
      ];
    },
    handleSelected(selectedArr) {
      this.selected = selectedArr.map(item => item.id);
      this.$emit("fetch-selected-variants", this.selected);
    },
    handleSplitProducts() {
      this.$emit("click:split");
    }
  },
  watch: {
    product: {
      immediate: true,
      handler(product) {
        this.setHeaders();
        const tempItems = product.variants.map(item => {
          return {
            id: item.id,
            available: item.available,
            image: item.image ? item.image.src : "",
            cost: item.cost,
            price: item.price,
            comparePrice: item.comparePrice,
            option1: item.option1,
            option2: item.option2,
            option3: item.option3
          };
        });

        this.items = tempItems;
      }
    }
  },
  beforeDestroy() {
    this.$off();
  }
};
</script>

<style lang="scss">
.split-products-table {
  .v-table__overflow {
    height: 300px;
    overflow: auto;
  }

  &__header {
    margin-bottom: 10px;
  }
}
</style>
