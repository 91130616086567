export const STATUS_PENDING = 1;
export const STATUS_APPROVED = 2;
export const STATUS_CANCELED = 3;
export const STATUS_MODERATE = 4;
export const STATUS_MODERATED = 5;
export const STATUS_MODERATE_CANCEL = 6;
export const STATUS_CONNECTED = 7;

export const STORE_PLATFORM_SHOPIFY = 'shopify_store';
export const STORE_PLATFORM_WOO_COMMERCE = 'woo_commerce_store';
