<template>
  <div>
    <label for="card-element">{{ lang.labelCardNumber }}</label>
    <div id="card-element"></div>
    <div id="card-errors" role="alert"></div>
    <div class="card-button">
      <ButtonPrimary
        class="stripe-button"
        small
        :loading="loading"
        @click:base-button="handleSubmit"
      >{{ lang.btnText }}</ButtonPrimary>
    </div>
  </div>
</template>

<script>
import ButtonPrimary from "@/components/ButtonPrimary";
import { mapActions, mapState } from "vuex";

const STRIPE_TOKEN = process.env.VUE_APP_STRIPE_PUBLIC_KEY;

export default {
  components: {
    ButtonPrimary
  },
  data() {
    return {
      stripe: null,
      elements: null,
      card: null,
      complete: false,
      loading: false,
      options: {
        style: {
          base: {
            color: "#222528",
            fontFamily: "DIN Pro Medium, sans-serif",
            fontSmoothing: "antialiased",
            fontSize: "12px",
            "::placeholder": {
              color: "#7a7c7e"
            }
          },
          invalid: {
            color: "#f03c1f",
            iconColor: "#f03c1f"
          }
        },
        hidePostalCode: true
      }
    };
  },
  computed: {
    ...mapState({
      successMsg: state => state.errors.notifyMessages.success
    }),
    lang() {
      return {
        labelCardNumber: "Card number",
        labelExpiration: "Expiration date",
        labelCVC: "CVC",
        btnText: "Confirm"
      };
    }
  },
  methods: {
    ...mapActions(["setNotifyMsg"]),
    handleSubmit() {
      this.loading = true;
      this.stripe
        .createToken(this.card)
        .then(result => {
          if (result.error) {
            this.loading = false;
            this.setNotifyMsg({ text: result.error.message, color: "error" });
          } else {
            this.$emit("stripe:success", result.token);
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    checkErrors() {
      this.card.addEventListener("change", function(event) {
        var displayError = document.getElementById("card-errors");
        if (event.error) {
          displayError.textContent = event.error.message;
        } else {
          displayError.textContent = "";
        }
      });
    },
    validateCardPayment(token, redirect) {
      this.stripe
        .handleCardPayment(token, this.card)
        .then(result => {
          if (result.error) {
            this.setNotifyMsg({ text: result.error.message, color: "error" });
            this.$emit("repeat-transaction", true);
          } else {
            this.setNotifyMsg({ text: this.successMsg, color: "success" });
            this.$root.$emit("update:transactions");
            window.location.href = redirect;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    }
  },
  mounted() {
    this.stripe = window.Stripe(STRIPE_TOKEN, {
      betas: ["payment_intent_beta_3"]
    });
    this.elements = this.stripe.elements();

    this.card = this.elements.create("card", { ...this.options });
    this.card.mount("#card-element");

    this.checkErrors();

    this.$root.$on("client-secret", ({ token, redirect }) =>
      this.validateCardPayment(token, redirect)
    );
  }
};
</script>

<style scoped lang="scss">
label {
  font-size: 12px;
  color: $info-color;
  line-height: 28px;
}
#card-element {
  background-color: #fff;
  padding: 8px 10px;
  border-radius: 4px;
}
.card-button {
  margin-top: 50px;
  margin-left: -10px;
}

#card-errors {
  color: $warning-color;
  font-size: 12px;
}
</style>
