<template>
  <v-container class="pa-0 no-width base-table--filters" grid-list-md>
    <v-layout wrap>
      <v-flex xs12 sm6 md4>
        <InputDateTime
          @change:datetimepicker="handleDatepickerChange"
          :startedDate="startedDate"
        >{{ lang.period }}</InputDateTime>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import InputDateTime from "@/components/InputDateTime";

export default {
  components: {
    InputDateTime
  },
  data() {
    return {
      startedDate: {
        start:
          typeof this.$route.query.dateFrom !== "undefined"
            ? this.$route.query.dateFrom
            : window
                .moment()
                .startOf("month")
                .format("YYYY-MM-DD"),
        end:
          typeof this.$route.query.dateTo !== "undefined"
            ? this.$route.query.dateTo
            : window
                .moment()
                .endOf("month")
                .format("YYYY-MM-DD")
      },
      filters: {
        dateFrom: null,
        dateTo: null
      }
    };
  },
  computed: {
    lang() {
      return {
        period: "Period"
      };
    }
  },
  methods: {
    handleSearch() {
      if (!this.filters.dateFrom && !this.filters.dateTo) {
        return;
      }

      this.$router.replace({ query: { ...this.filters } });
      this.$emit("click:search-btn", this.filters);
    },
    handleDatepickerChange({ start, end }) {
      this.filters.dateFrom = start;
      this.filters.dateTo = end;
      this.handleSearch();
    }
  },
  mounted() {
    this.filters.dateFrom = this.startedDate.start;
    this.filters.dateTo = this.startedDate.end;
  },
  beforeDestroy() {
    this.$off("click:base-button");
    this.$off("change:datetimepicker");
  }
};
</script>

<style>
</style>
