<template>
  <v-container class="no-width" grid-list-md>
    <v-layout wrap>
      <v-flex xs4 sm5 md2 lg1 class="base-table--filters__item">
        <InputText :value="filters.ids" @input:base-text="handleIdsValue">{{ lang.ids }}</InputText>
      </v-flex>
      <v-flex xs8 sm7 md2 class="base-table--filters__item">
        <InputText :value="filters.phone" @input:base-text="handlePhoneValue">{{ lang.phone }}
        </InputText>
      </v-flex>
      <v-flex xs12 sm5 md3 class="filter-item">
        <InputDateTime
          @change:datetimepicker="handleDatepickerChange"
          :startedDate="startedDate"
        >{{ lang.date }}
        </InputDateTime>
      </v-flex>
      <v-flex xs12 sm4 md2 class="base-table--filters__item">
        <InputText
          :value="filters.email"
          type="email"
          @input:base-text="handleEmailValue"
        >{{ lang.email }}
        </InputText>
      </v-flex>
      <v-flex xs12 sm3 md1 align-self-center>
        <ButtonPrimary
          class="base-table--filters__button"
          small
          @click:base-button="handleSearch"
        >Search
        </ButtonPrimary>
      </v-flex>
      <v-flex xs12 sm3 md1 align-self-center>
        <ButtonPrimary
          class="base-table--filters__button"
          small
          @click:base-button="handleExportExcel">
          Export to XLS
        </ButtonPrimary>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
  import ButtonPrimary from "@/components/ButtonPrimary";
  import InputText from "@/components/InputText";
  import InputDateTime from "@/components/InputDateTime";

  export default {
    components: {
      InputDateTime,
      InputText,
      ButtonPrimary
    },
    data() {
      return {
        filters: {
          ids:
            typeof this.$route.query.ids !== "undefined"
              ? this.$route.query.ids
              : null,
          phone:
            typeof this.$route.query.phone !== "undefined"
              ? this.$route.query.phone
              : null,
          email:
            typeof this.$route.query.email !== "undefined"
              ? this.$route.query.email
              : null,
          from: null,
          to: null
        },
        startedDate: {
          start: null,
          end: null
        }
      };
    },
    computed: {
      lang() {
        return {
          ids: `Order ID`,
          phone: "Phone",
          email: "Email",
          date: "Creation Date"
        };
      }
    },
    methods: {
      handleSearch() {
        const queryFilters = {};
        const {ids, email, phone, from, to} = this.filters;

        if (ids) {
          queryFilters.ids = ids;
        }

        if (email) {
          queryFilters.email = email;
        }

        if (phone) {
          queryFilters.phone = phone;
        }

        if (from && to) {
          queryFilters.from = from;
          queryFilters.to = to;
        }

        this.$router.replace({query: {...queryFilters}});
        this.$emit("click:search-btn", this.filters);
      },
      handleExportExcel() {
        this.$emit("click:export-btn", this.filters);
      },
      handleDatepickerChange(date) {
        if (date && (date.start && date.end)) {
          if (
            date.start !== this.$route.query.from ||
            date.end !== this.$route.query.to
          ) {
            this.filters.from = date.start;
            this.filters.to = date.end;
            this.handleSearch();
          }
        }
      },
      handleIdsValue(val) {
        this.filters.ids = val;
      },
      handlePhoneValue(val) {
        this.filters.phone = val;
      },
      handleEmailValue(val) {
        this.filters.email = val;
      },
      setStartedDate() {
        const dateFrom = this.$route.query.from;
        const dateTo = this.$route.query.to;

        if (typeof dateFrom !== "undefined" && typeof dateTo !== "undefined") {
          this.startedDate = {
            start: dateFrom,
            end: dateTo
          };
          this.filters.from = dateFrom;
          this.filters.to = dateTo;
        } else {
          this.startedDate = null;
        }
      }
    },
    created() {
      this.setStartedDate();
    },
    beforeDestroy() {
      this.$off("click:base-button");
      this.$off("change:datetimepicker");
    }
  };
</script>

<style scoped>
</style>
