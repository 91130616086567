<template>
  <div class="base-datepicker">
    <label class="base-datepicker__label">
      <slot></slot>
    </label>
    <div class="base-datepicker__input">
      <date-range-picker
        opens="right"
        :startDate="computedDate ? computedDate.start : new Date()"
        :endDate="computedDate ? computedDate.end : null"
        class="base-datepicker__input__inner"
        @update="handleDateChange"
        :locale-data="locale"
        autoApply
      />
      <div class="input-form-control">
        <span v-if="computedDate">{{ computedDate.start }} - {{ computedDate.end }}</span>
      </div>
      <div class="base-datepicker__icon">
        <slot name="icon">
          <v-icon color="#a6a8a9">date_range</v-icon>
        </slot>
      </div>
    </div>
  </div>
</template>

<script>
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/lib/vue-daterange-picker.min.css";

export default {
  components: {
    DateRangePicker
  },
  props: {
    height: {
      type: String,
      default: "30"
    },
    startedDate: {
      type: Object
    }
  },
  data() {
    return {
      date: null,
      locale: {
        format: "YYYY-MM-DD",
        firstDay: 1
      }
    };
  },
  computed: {
    computedDate: {
      get() {
        if (this.startedDate && !this.date) {
          this.date = this.startedDate;
        }
        return this.date;
      },
      set(val) {
        this.date = val;
      }
    }
  },
  methods: {
    handleDateChange(date) {
      this.date = {
        start: window.moment(date.startDate).format(this.locale.format),
        end: window.moment(date.endDate).format(this.locale.format)
      };
      this.$emit("change:datetimepicker", this.date);
    }
  },
  mounted() {
    this.$emit("change:datetimepicker", this.date);
  }
};
</script>

<style lang="scss">
.base-datepicker input {
  height: 30px !important;
  min-height: 30px !important;
  max-height: 30px !important;
}

.base-datepicker {
  .base-datepicker__label {
    display: block;
    font-size: em(12);
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    color: $text-color !important;
  }

  .base-datepicker__input {
    margin-top: 4px;
    position: relative;
  }

  .base-datepicker__icon {
    bottom: 0;
    right: 5px;
    position: absolute;
    pointer-events: none;
  }

  input {
    border: none !important;
    border-radius: 4px !important;
    padding-top: 0 !important;
  }

  .is-focused {
    input {
      border: 0 !important;
    }
  }
  .caret,
  .glyphicon {
    display: none;
  }
  .form-control {
    top: 0;
    left: 0;
    opacity: 0;
    width: 100%;
    height: 30px;
    cursor: pointer;
    position: absolute;
  }
  .input-form-control {
    height: 30px;
    display: flex;
    font-size: 12px;
    background: #fff;
    line-height: 1.2;
    border-radius: 4px;
    align-items: center;
    padding: 0 32px 0 10px;
    color: rgba(0, 0, 0, 0.87);
    justify-content: flex-start;
    span,
    p {
      color: inherit;
    }
  }
  .base-datepicker__input__inner {
    display: block !important;
  }
  .daterangepicker {
    @include breakpoint($t) {
      width: 230px !important;
    }
  }
  .calendars {
    @include breakpoint($t) {
      flex-wrap: wrap;
    }
  }
  .ranges {
    @include breakpoint($t) {
      float: none !important;
      width: 100% !important;
    }
    ul {
      @include breakpoint($t) {
        display: flex;
        flex-wrap: wrap;
        width: auto !important;
        align-items: flex-start;
        justify-content: flex-start;
      }
      li {
        @include breakpoint($t) {
          width: 50%;
        }
      }
    }
  }
  .drp-calendar {
    @include breakpoint($t) {
      width: 100%;
    }
  }
  .daterangepicker td.in-range {
    background: #e2e2e2;
  }
  .daterangepicker td.active,
  .daterangepicker td.active:hover {
    background: #000;
  }
  .daterangepicker .calendar-table td,
  .daterangepicker .calendar-table th {
    @include breakpoint($t) {
      min-width: 0;
      width: 14.2857%;
    }
  }
  .drp-buttons {
    align-items: center;
    display: flex !important;
    justify-content: flex-end;
    .btn {
      display: flex;
      outline: none;
      font-size: 14px;
      margin: 6px 8px;
      line-height: 1.2;
      padding: 5px 23px;
      border-width: 2px;
      border-radius: 15px;
      align-items: center;
      border-style: solid;
      justify-content: center;
      -webkit-appearence: none;
      transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
      &.btn-success {
        color: #fff;
        font-family: "DIN Pro Bold" !important;
        background: #222528;
        border-color: #222528;
        &:hover {
          background: #d32d11;
          border-color: #d32d11;
        }
      }
      &.btn-default {
        color: #222528;
        background: #fff;
        border-color: #222528;
        &:hover {
          color: #fff;
          background: #d32d11;
          border-color: #d32d11;
        }
      }
    }
  }
}
</style>
