<template>
  <form class="landing-form" @keydown.enter.prevent="handleSubmit">
    <div class="landing-form-content">
      <div class="landing-form-content__item" :class="{'base-input--error': errors.email.length}">
        <label class="landing-form-content__item__label" for="email">{{ lang.email }}</label>
        <v-text-field
          type="email"
          v-model="form.email"
          @input="errors = {...errors, email: []}"
          :error-messages="errors.email.length ? errors.email : [] "
        />
      </div>
      <p class="landing-form-content__item__desc">{{ lang.desc }}</p>
      <div class="landing-form__button">
        <ButtonPrimary
          block
          :loading="progress"
          @click:base-button="handleSubmit"
        >{{ lang.btnText }}</ButtonPrimary>
        <router-link :to="{name: 'home'}">
          <a class="landing-form-content__item__link hover--red">{{ lang.signIn }}</a>
        </router-link>
      </div>
    </div>
  </form>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import ButtonPrimary from "@/components/ButtonPrimary";

export default {
  components: {
    ButtonPrimary
  },
  data() {
    return {
      errors: {
        email: []
      }
    };
  },
  computed: {
    ...mapGetters({
      progress: "getIsProgress"
    }),
    ...mapState({
      form: state => state.forms.forms.recovery
    }),
    lang() {
      return {
        email: "Email",
        signIn: "Sign In.",
        desc: "Link for changing will be sent to your email",
        btnText: "Reset Password"
      };
    }
  },
  methods: {
    handleSubmit() {
      if (this.validateEmail(this.form.email)) {
        this.$emit("submit:form", this.form);
      }
    },
    validateEmail(email) {
      if (!email) {
        this.errors = { ...this.errors, email: ["The email field is empty"] };
        return false;
      }

      return true;
    }
  }
};
</script>

<style scoped lang="scss">
</style>
