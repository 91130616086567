<template>
  <div class="product-list">
    <v-layout row>
      <v-flex md12>
        <div class="product-list__breadcrumbs">
          <Breadcrumbs :items="breadcrumbs"></Breadcrumbs>
        </div>
        <div class="product-list__header">
          <h2 class="product-list__header__title">{{ category && category.name }}</h2>
          <FiltersSorting
            v-if="sortByItems"
            :items="sortByItems"
            @change:sorting="handleFilterSortChange"
          />
        </div>
        <v-layout row wrap>
          <v-flex xs12 sm12 md3>
            <div class="product-list__filters">
              <FiltersProducts
                @change:filters-country="handleFilterCountryChange"
                @change:filters-price="handleFilterPriceChange"
              />
            </div>
            <div class="product-list__subcategories-filter">
              <FiltersSubcategories
                v-if="subcategories"
                :subcategories="subcategories"
                :current-subcategory-id="categoryId"
              />
            </div>
          </v-flex>
          <v-flex xs12 sm12 md9>
            <template v-if="products && products.length > 0">
              <v-container fluid grid-list-md class="pr-0 pl-0 pt-0">
                <v-layout row wrap>
                  <v-flex xs12 sm6 md4 v-for="product in products" :key="product.id">
                    <CardProduct :product="product" @click:product="handleCardProductClick"/>
                  </v-flex>
                </v-layout>
                <br>
                <div class="product-list__footer">
                  <PageSize @click:page-size-current="handlePageSizeClick"/>
                  <template v-if="pagination">
                    <Pagination :pagination="pagination" @click:pagination="handlePaginationClick"/>
                  </template>
                </div>
              </v-container>
            </template>
            <template v-else>
              <p class="empty-products">{{ lang.noItems }}</p>
            </template>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import productsBreadcrumbsMixin from "@/mixins/productsBreadcrumbsMixin";

import CardProduct from "@/components/CardProduct";
import PageSize from "@/components/PageSize";
import Pagination from "@/components/Pagination";
import Breadcrumbs from "@/components/Breadcrumbs";
import FiltersProducts from "@/components/FiltersProducts";
import FiltersSubcategories from "@/components/Filters/FilterSubcategories";
import FiltersSorting from "@/components/Filters/FilterSorting";

export default {
  components: {
    CardProduct,
    PageSize,
    Pagination,
    FiltersProducts,
    FiltersSubcategories,
    Breadcrumbs,
    FiltersSorting
  },
  mixins: [productsBreadcrumbsMixin],
  data() {
    return {
      sortByItems: [
        {
          name: "None",
          value: null
        },
        {
          name: "Price low first",
          value: "price.asc"
        },
        {
          name: "Price high first",
          value: "price.desc"
        },
        {
          name: "Orders",
          value: "orders.desc"
        },
        {
          name: "Rating",
          value: "rating.desc"
        }
      ]
    };
  },
  computed: {
    categoryId() {
      return this.$route.params.id;
    },
    ...mapGetters({
      products: "getProducts",
      category: "getCategory",
      paginationsLinks: "getProductsPagination"
    }),
    pagination() {
      if (!this.paginationsLinks) {
        return null;
      }
      return {
        first: +this.$url.getParams(this.paginationsLinks.first).page,
        last: +this.$url.getParams(this.paginationsLinks.last).page,
        self: +this.$url.getParams(this.paginationsLinks.self).page
      };
    },
    lang() {
      return {
        noItems: "No products by your criteria"
      };
    }
  },
  methods: {
    ...mapActions(["fetchProducts", "fetchCategoryById", "setNotifyMsg"]),
    handleCardProductClick(id) {
      this.$router.push({
        name: "dashboard.products.product-details",
        params: {
          id: this.categoryId,
          productId: id
        }
      });
    },
    handlePageSizeClick(page_size) {
      this.$router.push({
        query: { ...this.$route.query, page_size }
      });
    },
    handlePaginationClick(page) {
      this.$router.push({
        query: { ...this.$route.query, page }
      });
      window.scrollTo(0, 0);
    },
    handleFilterCountryChange(shipsFrom) {
      this.$router.push({
        query: { ...this.$route.query, shipsFrom, page: 1 }
      });
    },
    handleFilterNameChange(name) {
      this.$router.push({
        query: { ...this.$route.query, name, page: 1 }
      });
    },
    handleFilterPriceChange(price) {
      const [minPrice, maxPrice] = price;
      this.$router.push({
        query: { ...this.$route.query, minPrice, maxPrice, page: 1 }
      });
    },
    handleFilterSortChange(sort) {
      this.$router.push({
        query: { ...this.$route.query, sort, page: 1 }
      });
    },
    async fetchProductsWithFilters() {
      const data = {
        filters: {
          category: this.categoryId,
          ...this.$route.query
        }
      };

      const { status, text } = await this.fetchProducts(data);

      if (!status) {
        return this.setNotifyMsg({ color: "error", text });
      }
    }
  },
  watch: {
    "$route.query": {
      immediate: true,
      handler() {
        this.fetchProductsWithFilters();
        this.fetchCategoryById(this.categoryId);
      }
    },
    category: {
      handler(category) {
        this.$root.$emit(
          "set-html-title-name",
          `Ecombo - Search Products - ${category ? category.name: ''}`
        );
      }
    }
  },
  mounted() {
    this.$root.$on("input:search", name => this.handleFilterNameChange(name));
  },
  beforeDestroy() {
    this.$off("change:filters-country");
    this.$off("change:filters-price");
    this.$off("click:filters-subcategory");
    this.$off("click:page-size-current");
    this.$off("click:pagination");
    this.$root.$off("input:search");
  }
};
</script>

<style scoped lang="scss">
.product-list {
  max-width: $max-width;

  .product-list__header {
    display: flex;
    margin-bottom: 15px;
    align-items: flex-end;
    justify-content: space-between;

    .product-list__header__title {
      font-size: em(30);
    }
  }

  .product-list__filters,
  .product-list__subcategories-filter {
    background-color: #fff;
    border-radius: 4px;
    margin-right: 8px;
    padding: 18px;
    @include breakpoint($t) {
      margin-right: 0;
    }
  }

  .product-list__filters {
    padding: 18px;
  }

  .product-list__subcategories-filter {
    margin-top: 18px;
  }
  .product-list__footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .product-list__header-pagination {
    display: flex;
    flex-direction: row-reverse;
    margin-bottom: 10px;
  }
}
</style>
