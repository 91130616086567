<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="31" height="31" fill="none" viewBox="0 0 31 31">
    <g clip-path="url(#a)">
      <path
        fill="#000"
        d="M30.734.266a.908.908 0 0 0-1.285 0l-2.645 2.645a2.73 2.73 0 0 0-.513-.184L18.07.696a2.738 2.738 0 0 0-2.588.716L.797 16.074a2.728 2.728 0 0 0 0 3.854l10.275 10.275a2.728 2.728 0 0 0 3.854 0l14.662-14.686a2.739 2.739 0 0 0 .716-2.587l-2.03-8.22a2.737 2.737 0 0 0-.185-.514l2.645-2.646a.908.908 0 0 0 0-1.284zM28.542 13.37a.913.913 0 0 1-.239.863L13.641 28.919a.91.91 0 0 1-1.284 0L2.08 18.643a.91.91 0 0 1 0-1.284L16.767 2.697a.913.913 0 0 1 .863-.24l7.721 1.907-2.187 2.187a2.73 2.73 0 0 0-3.1.532 2.728 2.728 0 0 0 0 3.854 2.716 2.716 0 0 0 1.926.796c.698 0 1.396-.265 1.927-.796a2.729 2.729 0 0 0 .531-3.101l2.188-2.187 1.906 7.721zm-5.91-3.718a.91.91 0 0 1-1.284-1.284.905.905 0 0 1 1.284 0 .91.91 0 0 1 0 1.284z"
      ></path>
    </g>
    <defs>
      <clipPath id="a">
        <path fill="none" d="M0 0h31v31H0z"></path>
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  props: {
    width: {
      type: [Number, String],
      default: 34
    },
    height: {
      type: [Number, String],
      default: 34
    },
    fill: {
      type: String,
      default: "none"
    },
    stroke: {
      type: String,
      default: "#000"
    }
  }
};
</script>

<style scoped>
</style>
