<template>
  <div class="orders-wrapper">
    <router-view/>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss">
.orders-wrapper {
  max-width: $max-width;
}
</style>
