<template>
  <form class="landing-form">
    <div class="landing-form-content">
      <div
        class="landing-form-content__item"
        :class="{'base-input--error': errors.plainPassword.first.length}"
      >
        <label class="landing-form-content__item__label" for="password">{{ lang.password }}</label>
        <v-text-field
          type="password"
          v-model="plainPassword.first"
          @input="errors = {...errors, plainPassword: {...errors.plainPassword, first: []}}"
          :error-messages="errors.plainPassword.first.length ? errors.plainPassword.first : [] "
        />
      </div>
      <div
        class="landing-form-content__item"
        :class="{'base-input--error': errors.plainPassword.second.length}"
      >
        <label
          class="landing-form-content__item__label"
          for="repeat-password"
        >{{ lang.passwordRepeat }}</label>
        <v-text-field
          type="password"
          v-model="plainPassword.second"
          @input="errors = {...errors, plainPassword: {...errors.plainPassword, second: []}}"
          :error-messages="errors.plainPassword.second.length ? errors.plainPassword.second : [] "
        />
      </div>
    </div>
    <div class="landing-form__button">
      <ButtonPrimary block :loading="progress" @click:base-button="handleSubmit">{{ lang.btnText }}</ButtonPrimary>
    </div>
  </form>
</template>

<script>
import { mapGetters } from "vuex";
import ButtonPrimary from "@/components/ButtonPrimary";

export default {
  components: {
    ButtonPrimary
  },
  data() {
    return {
      plainPassword: {
        first: null,
        second: null
      },
      errors: {
        plainPassword: {
          first: [],
          second: []
        }
      }
    };
  },
  computed: {
    ...mapGetters({
      progress: "getIsProgress"
    }),
    lang() {
      return {
        password: "New password",
        passwordRepeat: "Repeat new password",
        btnText: "Change"
      };
    }
  },
  methods: {
    handleSubmit() {
      if (this.validateForm(this.plainPassword)) {
        this.$emit("submit:form", this.plainPassword);
      }
    },
    validateForm(password) {
      let isValid = true;

      if (!password.first) {
        this.errors = {
          ...this.errors,
          plainPassword: {
            ...this.errors.plainPassword,
            first: ["The password field is empty"]
          }
        };
        isValid = false;
      }

      if (password.first && password.first.length < 6) {
        this.errors = {
          ...this.errors,
          plainPassword: {
            ...this.errors.plainPassword,
            first: ["The password field length must be greater than 6"]
          }
        };
        isValid = false;
      }

      if (password.second && password.second.length < 6) {
        this.errors = {
          ...this.errors,
          plainPassword: {
            ...this.errors.plainPassword,
            second: ["The password field length must be greater than 6"]
          }
        };
        isValid = false;
      }

      if (!password.second) {
        this.errors = {
          ...this.errors,
          plainPassword: {
            ...this.errors.plainPassword,
            second: ["The repeat password field is empty"]
          }
        };
        isValid = false;
      }

      if (
        password.first &&
        password.first.length > 5 &&
        password.second &&
        password.second.length > 5 &&
        password.first !== password.second
      ) {
        this.errors = {
          ...this.errors,
          plainPassword: {
            ...this.errors.plainPassword,
            second: ["Password fields are not equal"]
          }
        };
        isValid = false;
      }

      return isValid;
    }
  }
};
</script>

<style scoped lang="scss">
</style>
