<template>
  <div class="sub-accounts__content" v-if="subAccounts && paginationsLinks">
    <div name="filter" class="base-table--filter">
      <FilterSubAccounts @click:search-btn="handleSearch"/>
    </div>
    <BaseTable
      :headers="headers"
      :items="items"
      show-pagination
      :custom-pagination="paginationsLinks"
      :hide-columns="['id', 'isDisabledWithdraw', 'enabled']"
      class="sub-accounts--base-table"
    >
      <th v-if="items && items.length" slot="th"></th>
      <template slot-scope="{item: item}" slot="conditional-button">
        <BaseButton
          :loading="loading"
          small
          outline
          :disabled="item.isDisabledWithdraw"
          @click:base-button="handleWithdrawMoney(item)"
        >{{ lang.withdrawButton }}</BaseButton>
        <BaseButton @click:base-button="onToggleCanAuth(item)" small outline>{{ getToggleAuth(item) }}</BaseButton>
      </template>
    </BaseTable>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapState } from "vuex";
import FilterSubAccounts from "@/components/Filters/FilterSubAccounts";
import {
  STATUS_OK
} from '@/constants/httpCodes';

export default {
  components: {
    FilterSubAccounts
  },
  data() {
    return {
      items: [],
      headers: [
        { text: "ID", value: "id" },
        { text: "Email", value: "email" },
        { text: "Balance, $", value: "balance" }
      ]
    };
  },
  computed: {
    lang() {
      return {
        title: "Withdraw money",
        withdrawButton: "To my balance",
        toggleAuth: null,
      };
    },
    ...mapState({
      subAccounts: state => state.users.subAccounts,
      paginationsLinks: state => state.users.subAccountsLinks
    }),
    ...mapGetters({
      currentUser: "getCurrentUser",
      loading: "getIsProgress",
    }),
  },
  methods: {
    ...mapActions([
      "setNotifyMsg",
      "fetchSubAccounts",
      "withdrawBalance",
      "toggleEnabled"
    ]),
    getToggleAuth(user) {
      if (user.enabled === true) {
        return "Disable";
      } else {
        return "Enable";
      }
    },
    async onToggleCanAuth(user) {
      user.enabled = !user.enabled;

      const { status, text } = await this.toggleEnabled({
        toggleUser: user.id
      });

      if (status === STATUS_OK) {
        this.setNotifyMsg({ color: 'success', text: text});
      }
    },
    fetchSubAccountsWithFilters(filters = {}) {
      const newFilters = {
        page: 1,
        page_size: 20,
        ...this.$route.query,
        ...filters
      };

      this.fetchSubAccounts(newFilters);
    },
    handleSearch(filters) {
      this.fetchSubAccountsWithFilters(filters);
    },
    async handleWithdrawMoney(user) {
      const { status, text } = await this.withdrawBalance({
        fromAccount: user.id
      });

      if (status === STATUS_OK) {
        this.setNotifyMsg({ color: 'success', text: text});
        this.currentUser.balance+=user.balance;
        user.balance = 0.00;
        user.isDisabledWithdraw = true;
      }
    }
  },
  watch: {
    "$route.query": {
      immediate: true,
      handler() {
        this.fetchSubAccountsWithFilters();
      }
    },
    subAccounts: {
      handler(subAccounts) {
        this.items = subAccounts.map(item => {
          return {
            id: item.id,
            email: item.email,
            balance: item.balance,
            enabled: item.enabled,
            conditionalButton: true,
            isDisabledWithdraw: item.balance === 0
          };
        });
      }
    },
  },
};
</script>

<style lang="scss">
  .theme--light.v-table {
    background-color: #f0f0f1 !important;
  }
  .sub-accounts {
    &__title {
      font-size: 18px !important;
      color: #222528;
      margin-bottom: 20px !important;
      &__sub_title {
        display: inline-block;
      }
    }
    &__content {
      white-space: nowrap;
      /deep/ th:nth-child(3) {
        width:20% !important;
      }
    }
  }
</style>
