export default {
  state: {
    forms: {
      signIn: {
        email: null,
        password: null,
      },
      register: {
        email: null,
        contact: null,
        experience: null,
        plainPassword: {
          first: null,
          second: null,
        },
        userSource: {
          utmSource: null,
          utmMedium: null,
          utmCampaign: null,
          utmContent: null,
          utmTerm: null,
          referer: null
        }
      },
      recovery: {
        email: null,
      },
    },
  },
  getters: {},
  mutations: {},
  actions: {},
};
