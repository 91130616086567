export default function (Vue) {
  Vue.salesChannel = {
    set(id) {
      localStorage.setItem('sales_channel', id);
    },

    get() {
      return localStorage.getItem('sales_channel');
    },

    destroy() {
      localStorage.removeItem('sales_channel');
    },
  }

  Object.defineProperties(Vue.prototype, {
    $salesChannel: {
      get() {
        return Vue.salesChannel;
      }
    }
  })
}
