<template>
  <div>
    <h3 class="heading-h3">
      {{ lang.title }}
      <div class="btn-panel hidden-xs-only">
        <BaseButton
          small
          round
          class="button-primary"
          color="primary"
          @click:base-button="handleSaveChanges"
          :loading="loading"
          :disabled="disabledSave"
        >Save</BaseButton>
        <BaseButton
          small
          round
          outline
          class="button-primary"
          color="primary"
          @click:base-button="handleBack"
        >Cancel</BaseButton>
      </div>
    </h3>
    <br class="hidden-xs-only">
    <Breadcrumbs :items="breadcrumbs"></Breadcrumbs>
    <div class="store-settings" v-show="Object.keys(data.salesChannel).length">
      <Tabs :tabs="tabHeaders" hidden-tabs="hidden-xs-only">
        <v-tab-item :transition="false" :reverse-transition="false" :value="0">
          <v-expansion-panel>
            <v-expansion-panel-content>
              <div slot="header" class="hidden-sm-and-up">{{ lang.pricingRules }}</div>
              <v-card flat min-height="400">
                <v-layout>
                  <v-flex xs8 sm4 md2 class="base-table--filters__item">
                    <InputSelect
                      :items="[lang.stickyPrice.absolute, lang.stickyPrice.percent, lang.stickyPrice.disabled]"
                      @change:base-select="handleStickyPrice"
                      :value="data.salesChannel.sticky_price"
                    >{{ lang.stickyPriceTitle }}</InputSelect>
                  </v-flex>
                </v-layout>

                <v-flex md12 class="base-table--filters__item" v-show="false">
                  <div class="product-cost-select-item">
                    <div class="product-cost-select-item__title">
                      {{ lang.priceModifier.title }}
                      <span class="symbol">×</span>
                    </div>
                    <v-layout class="input-panel">
                      <v-flex xs8 sm4 md8 class="base-table--filters__item">
                        <InputSelect
                          :items="[lang.priceModifierOptions.add, lang.priceModifierOptions.multiply]"
                          @change:base-select="handlePriceModifier"
                          :value="data.salesChannel.priceModifier"
                        >{{ lang.priceModifier.optionsTitle }}</InputSelect>
                      </v-flex>
                      <v-flex xs4 sm3 md4 class="base-table--filters__item">
                        <InputText
                          :value="''+data.salesChannel.price_value"
                          @input:base-text="handleChannelPriceValue"
                          :hide-details="false"
                          :rules="[rules.required, rules.isAbsNumber, rules.isNumber]"
                          @update:error="onErrorPrice($event, 1)"
                          type="number"
                        >{{ lang.priceModifier.valueTitle }}</InputText>
                      </v-flex>
                    </v-layout>
                    <div class="product-cost-select-item__title">
                      <span class="symbol">=</span>
                      {{ lang.priceModifier.text }}
                    </div>
                  </div>
                </v-flex>
                <v-flex md12 class="base-table--filters__item">
                  <div class="product-cost-select-item">
                    <div class="product-cost-select-item__title">
                      {{ lang.priceModifier.title }}
                      <span class="symbol">×</span>
                    </div>
                    <v-layout class="input-panel">
                      <v-flex xs8 sm4 md8 class="base-table--filters__item">
                        <InputSelect
                          :items="[lang.priceModifierOptions.add, lang.priceModifierOptions.multiply]"
                          @change:base-select="handlePriceModifier"
                          :value="data.salesChannel.priceModifier"
                        >{{ lang.priceModifier.optionsTitle }}</InputSelect>
                      </v-flex>
                      <v-flex xs4 sm3 md4 class="base-table--filters__item">
                        <InputText
                          :value="''+data.salesChannel.price_value"
                          @input:base-text="handleChannelPriceValue"
                          :hide-details="false"
                          :rules="[rules.requiredNumber, rules.isAbsNumber, rules.isNumber]"
                          @update:error="onErrorPrice($event, 1)"
                          type="number"
                        >{{ lang.priceModifier.valueTitle }}</InputText>
                      </v-flex>
                    </v-layout>
                    <div class="product-cost-select-item__title">
                      <span class="symbol">=</span>
                      {{ lang.priceModifier.text }}
                    </div>
                  </div>
                </v-flex>

                <v-flex md12 class="base-table--filters__item">
                  <div class="product-cost-select-item">
                    <div class="product-cost-select-item__title">
                      {{ lang.comparePriceModifier.title }}
                      <span class="symbol">×</span>
                    </div>
                    <v-layout class="input-panel">
                      <v-flex xs8 sm4 md8 class="base-table--filters__item">
                        <InputSelect
                          :items="[lang.priceModifierOptions.add, lang.priceModifierOptions.multiply]"
                          :value="data.salesChannel.compare_price"
                          @change:base-select="handleComparePriceModifier"
                        >{{ lang.comparePriceModifier.optionsTitle }}</InputSelect>
                      </v-flex>
                      <v-flex xs4 sm3 md4 class="base-table--filters__item">
                        <InputText
                          :value="''+data.salesChannel.mod_price_value"
                          @input:base-text="handleChannelComparePriceValue"
                          :hide-details="false"
                          :rules="[rules.requiredNumber, rules.isAbsNumber, rules.isNumber]"
                          @update:error="onErrorPrice($event, 2)"
                          type="number"
                        >{{ lang.comparePriceModifier.valueTitle }}</InputText>
                      </v-flex>
                    </v-layout>
                    <div class="product-cost-select-item__title">
                      <span class="symbol">=</span>
                      {{ lang.comparePriceModifier.text }}
                    </div>
                  </div>
                </v-flex>
              </v-card>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-tab-item>

        <v-tab-item :transition="false" :reverse-transition="false" :value="1">
          <v-expansion-panel>
            <v-expansion-panel-content>
              <div slot="header" class="hidden-sm-and-up">{{ lang.shippingMethods }}</div>
              <v-card flat min-height="400">
                <ChooseSuppliers
                  v-if="data.salesChannel.suppliers"
                  :items="data.salesChannel.suppliers"
                  @suppliers:change="handleSuppliers"
                ></ChooseSuppliers>
                <v-flex sm5 md4 class="base-table--filters__item">
                  <InputText
                    :subtitle="lang.overridePhoneNumberSubtitle"
                    :value="'' + data.salesChannel.phone"
                    @input:base-text="handleChannelPhone"
                  >{{ lang.overridePhoneNumber }}</InputText>
                </v-flex>
                <v-flex sm8 md6 class="base-table--filters__item">
                  <InputTextarea
                    :subtitle="lang.customNoteSubtitle"
                    :value="'' + data.salesChannel.orderNote"
                    @input:base-text="handleChannelOrderNote"
                  >{{ lang.customNote }}</InputTextarea>
                </v-flex>
              </v-card>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-tab-item>

        <v-tab-item :transition="false" :reverse-transition="false" :value="2">
          <v-expansion-panel>
            <v-expansion-panel-content>
              <div slot="header" class="hidden-sm-and-up">{{ lang.orders }}</div>
              <v-card flat min-height="400">
                <v-checkbox
                  v-model="data.salesChannel.isAutoOrderPayment"
                  :label="lang.autoOrder"
                  color="primary"
                ></v-checkbox>
              </v-card>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-tab-item>

        <v-tab-item :transition="false" :reverse-transition="false" :value="3">
          <v-expansion-panel>
            <v-expansion-panel-content>
              <div slot="header" class="hidden-sm-and-up">GDPR</div>
              <v-card flat min-height="400">
                <TableGDPRReports/>
              </v-card>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-tab-item>
      </Tabs>
      <div class="btn-panel hidden-sm-and-up">
        <BaseButton
          small
          round
          class="button-primary"
          color="primary"
          @click:base-button="handleSaveChanges"
          :loading="loading"
        >Save</BaseButton>
        <BaseButton
          small
          round
          outline
          class="button-primary"
          color="primary"
          @click:base-button="handleBack"
        >Cancel</BaseButton>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import Breadcrumbs from "@/components/Breadcrumbs";
import BaseButton from "@/components/BaseButton";
import InputSelect from "@/components/InputSelect";
import InputText from "@/components/InputText";
import InputTextarea from "@/components/InputTextarea";
import ChooseSuppliers from "@/components/ChooseSuppliers";
import TableGDPRReports from "@/components/Tables/TableGDPRReports";
import Tabs from "@/components/Tabs";
import {
  PRICE_MODIFIER_ADD,
  PRICE_MODIFIER_MULTIPLY,
  STICKY_PRICE_ABSOLUTE,
  STICKY_PRICE_PERCENT,
  STICKY_PRICE_DISABLED
} from "@/constants/storeManagement";

export default {
  components: {
    Breadcrumbs,
    BaseButton,
    InputSelect,
    InputText,
    InputTextarea,
    ChooseSuppliers,
    TableGDPRReports,
    Tabs
  },
  data() {
    return {
      data: {
        salesChannel: {}
      },
      tabs: 0,
      tabHeaders: ["Pricing rules", "Shipping methods", "Orders", "GDPR"],
      disabledSave: false,
      validPriceValues: {}
    };
  },
  computed: {
    ...mapState({
      rules: state => state.validations.rules
    }),
    ...mapGetters({
      salesChannel: "getSalesChannel",
      loading: "getIsLoading"
    }),
    salesStoreId() {
      return this.$route.params.id;
    },
    salesStoreFilters() {
      return false;
    },
    lang() {
      return {
        title: "Store settings",
        stickyPriceTitle: "Sticky price",
        stickyPrice: {
          absolute: "Absolute",
          percent: "Percent",
          disabled: "Disabled"
        },
        priceModifierOptions: {
          add: "Add",
          multiply: "Multiply"
        },
        priceModifier: {
          title: "Product Cost",
          optionsTitle: "Price modifier",
          valueTitle: "Modifier value",
          text: "Your product price"
        },
        comparePriceModifier: {
          title: "Product Cost",
          optionsTitle: "Compare price modifier",
          valueTitle: "Modifier value",
          text: "Your product compared at price"
        },
        overridePhoneNumber: "Override phone number",
        overridePhoneNumberSubtitle:
          "This will be used instead of a customer phone number",
        customNote: "Custom note",
        customNoteSubtitle:
          "A note to the supplier on the supplier checkout page",
        pricingRules: "Pricing rules",
        shippingMethods: "Shipping methods",
        orders: "Orders",
        autoOrder: "Auto order prepayment"
      };
    },
    breadcrumbs() {
      return [
        {
          text: "Store management",
          disabled: false,
          href: {
            name: "dashboard.store-management"
          }
        },
        {
          text: this.data.salesChannel.name,
          disabled: true,
          href: { name: "" }
        }
      ];
    }
  },
  methods: {
    ...mapActions(["fetchSalesChannelById", "updateSalesChannel"]),
    handleBack() {
      this.$router.push({
        name: "dashboard.store-management",
        params: {}
      });
    },
    handleSaveChanges() {
      const data = {
        id: this.data.salesChannel.id,
        priceModifier: this.getPriceVal(this.data.salesChannel.priceModifier),
        comparePriceModifier: this.getPriceVal(
          this.data.salesChannel.compare_price
        ),
        priceValue: this.data.salesChannel.price_value,
        comparePriceValue: this.data.salesChannel.mod_price_value,
        stickyPrice: this.getStickyPriceVal(
          this.data.salesChannel.sticky_price
        ),
        isAutoOrderPayment: this.data.salesChannel.isAutoOrderPayment
      };
      if (this.data.salesChannel.phone) {
        data.phone = this.data.salesChannel.phone;
      }
      if (this.data.salesChannel.orderNote) {
        data.orderNote = this.data.salesChannel.orderNote;
      }
      if (
        this.data.salesChannel.defaultShipmentMethods &&
        this.data.salesChannel.defaultShipmentMethods.length
      ) {
        data.defaultShipmentMethods = [];
        this.data.salesChannel.defaultShipmentMethods.forEach((item, i) => {
          data.defaultShipmentMethods[i] = {
            maxCost: item.maxCost,
            priority: item.priority,
            shippingCompany: item.shippingCompany.id
          };
        });
      }

      if (
        this.data.salesChannel.suppliers &&
        this.data.salesChannel.suppliers.length
      ) {
        data.suppliers = [...this.data.salesChannel.suppliers];
      }

      this.onSaving = true;
      this.updateSalesChannel(data);
    },
    handleChannelPriceValue(val) {
      this.data.salesChannel.price_value = val.replace(",", ".");
    },
    handleChannelComparePriceValue(val) {
      this.data.salesChannel.mod_price_value = val.replace(",", ".");
    },
    handleChannelOrderNote(val) {
      this.data.salesChannel.orderNote = val;
    },
    handleStickyPrice(val) {
      if (this.data.salesChannel) {
        this.data.salesChannel.sticky_price = val;
      }
    },
    handlePriceModifier(val) {
      if (this.data.salesChannel) {
        this.data.salesChannel.priceModifier = val;
      }
    },
    handleComparePriceModifier(val) {
      if (this.data.salesChannel) {
        this.data.salesChannel.compare_price = val;
      }
    },
    handleChannelPhone(val) {
      this.data.salesChannel.phone = val;
    },
    getStickyPriceVal(text) {
      if (text == this.lang.stickyPrice.absolute) {
        return STICKY_PRICE_ABSOLUTE;
      }

      if (text == this.lang.stickyPrice.percent) {
        return STICKY_PRICE_PERCENT;
      }

      if (text == this.lang.stickyPrice.disabled) {
        return STICKY_PRICE_DISABLED;
      }
    },
    getStickyPriceText(val) {
      if (val == STICKY_PRICE_ABSOLUTE) {
        return this.lang.stickyPrice.absolute;
      }

      if (val == STICKY_PRICE_PERCENT) {
        return this.lang.stickyPrice.percent;
      }

      if (val == STICKY_PRICE_DISABLED) {
        return this.lang.stickyPrice.disabled;
      }
    },
    getPriceText(val) {
      if (val == PRICE_MODIFIER_ADD) {
        return this.lang.priceModifierOptions.add;
      }

      if (val == PRICE_MODIFIER_MULTIPLY) {
        return this.lang.priceModifierOptions.multiply;
      }
    },
    getPriceVal(text) {
      if (text == this.lang.priceModifierOptions.add) {
        return PRICE_MODIFIER_ADD;
      }

      if (text == this.lang.priceModifierOptions.multiply) {
        return PRICE_MODIFIER_MULTIPLY;
      }
    },
    handleSuppliers(val) {
      this.data.salesChannel.defaultShipmentMethods = val;
    },
    onErrorPrice(e, id) {
      this.validPriceValues = {
        ...this.validPriceValues,
        [id]: !e
      };
    },
    setIsValidFields() {
      const result = Object.keys(this.validPriceValues).some(
        item => this.validPriceValues[item] === false
      );
      this.disabledSave = result;
    }
  },
  watch: {
    "$route.params": {
      immediate: true,
      handler() {
        const data = {
          id: this.salesStoreId,
          filters: { ...this.salesStoreFilters }
        };
        this.fetchSalesChannelById(data);
      }
    },
    salesChannel: {
      handler(salesChannel) {
        if (this.onSaving) {
          this.$router.push({
            name: "dashboard.store-management",
            params: {}
          });
        }

        this.data.salesChannel = {
          id: salesChannel.id,
          name: salesChannel.name,
          phone: salesChannel.phone || "",
          price_value: salesChannel.priceValue,
          mod_price_value: salesChannel.comparePriceValue,
          priceModifier: this.getPriceText(salesChannel.priceModifier),
          compare_price: this.getPriceText(salesChannel.comparePriceModifier),
          sticky_price: this.getStickyPriceText(salesChannel.stickyPrice),
          orderNote: salesChannel.orderNote || "",
          suppliers: salesChannel.defaultShipmentMethods || [],
          defaultShipmentMethods: salesChannel.defaultShipmentMethods || [],
          isAutoOrderPayment: salesChannel.isAutoOrderPayment || false
        };

        this.$root.$emit(
          "set-html-title-name",
          `Ecombo - Store Management Details ${salesChannel.name}`
        );
      }
    },
    validPriceValues: {
      immediate: true,
      handler: "setIsValidFields"
    }
  },
  mounted() {
    this.$root.$on(
      "is-active-save-store-button",
      val => (this.disabledSave = val)
    );
  }
};
</script>

<style lang="scss">
.heading-h3 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  @include breakpoint($m) {
    line-height: 1.2;
    margin-bottom: 10px;
  }
  .btn-panel {
    margin: -6px -8px;
  }
}
.store-settings {
  .v-window__container {
    padding: 0 !important;
    background-color: $body-color;
  }
  .v-card {
    padding: 22px;
    @include breakpoint($m) {
      padding: 15px 10px;
    }
  }
  .layout {
    margin-left: -5px;
    margin-right: -5px;
    > .base-table--filters__item {
      padding-left: 5px;
      padding-right: 5px;
    }
  }
  .v-input,
  .v-text-field.v-text-field--enclosed {
    margin-bottom: 0;
  }
  .base-select {
    &__label {
      display: block;
      font-size: 12px;
      line-height: 1.2;
      margin-bottom: 6px;
    }
    .theme--light.v-text-field--solo > .v-input__control > .v-input__slot {
      background: #f0f0f1 !important;
    }
  }
  .base-input,
  .base-select {
    margin: 0 0 30px;
  }
  .base-input {
    textarea {
      padding: 10px;
      font-size: 14px;
      line-height: 1.2;
      font-family: "DIN Pro Bold" !important;
      background: #f0f0f1;
      font-family: inherit;
    }

    .v-text-field__details {
      max-width: 140%;
      overflow: initial;
      @include breakpoint($t) {
        max-width: 100%;
        overflow: hidden;
      }
      .v-messages__message {
        width: 140%;
        @include breakpoint($t) {
          width: 100%;
        }
      }
    }
  }
  .product-cost-select-item {
    display: flex;
    color: #222528;
    font-size: 14px;
    flex-wrap: wrap;
    margin: 0 0 40px;
    line-height: 1.2;
    padding: 20px 0 0;
    align-items: center;
    justify-content: flex-start;
    @include breakpoint($t) {
      padding-top: 0;
      margin-bottom: 40px;
    }
    &__title {
      @include breakpoint($t) {
        width: 100%;
        margin: 0 0 10px;
      }
    }
    .input-panel {
      max-width: 300px;
      @include breakpoint($t) {
        max-width: none;
      }
    }
    .base-input,
    .base-select {
      height: 30px;
      margin-bottom: 0;
      position: relative;
      @include breakpoint($t) {
        height: auto;
      }
      input {
        height: 100%;
      }
      &__label {
        left: 0;
        right: 0;
        bottom: 100%;
        display: block;
        font-size: 12px;
        line-height: 1.2;
        margin-bottom: 4px;
        position: absolute;
        @include breakpoint($t) {
          position: static;
        }
      }
    }
    .base-table--filters__item {
      @include breakpoint($t) {
        margin: 0 0 10px;
        + .product-cost-select-item__title {
          .symbol {
            margin-left: 0;
          }
        }
      }
    }
    .symbol {
      color: #a6a8a9;
      line-height: 1;
      font-size: 18px;
      font-weight: 500;
      margin-left: 15px;
      margin-right: 15px;
      @include breakpoint($t) {
        margin-left: 10px;
        margin-right: 10px;
      }
    }
  }
  .base-input .base-input-background--white .v-input__slot input {
    background: #f0f0f1 !important;
  }
  .v-expansion-panel {
    box-shadow: none;
    &__header {
      @include rem($m) {
        display: none !important;
      }
      @include breakpoint($m) {
        padding: 10px;
        color: #020101;
        font-size: 14px;
        min-height: 55px;
        line-height: 1.2;
      }
      .material-icons {
        @include breakpoint($m) {
          font-size: 18px;
        }
      }
    }
    &__body {
      @include rem($m) {
        display: block !important;
      }
    }
  }
  .v-window-item {
    @include breakpoint($m) {
      margin: 0 0 21px;
      display: block !important;
    }
  }
}
</style>
