<template>
  <div class="notification-wrapper">
    <v-layout wrap>
      <v-flex md>
        <h3>{{ lang.title }}</h3>
      </v-flex>
      <v-flex md v-if="notifications.length">
        <div class="hidden-sm-and-down notification-headers-header">
          <ButtonPrimary
            class="border-gray"
            outline
            small
            @click:base-button="onMarkAllAsRead"
          >{{ lang.btnText }}</ButtonPrimary>
        </div>
      </v-flex>
    </v-layout>
    <div class="notification-content">
      <TableNotifications/>
    </div>
    <div class="hidden-md-and-up" v-if="notifications.length">
      <ButtonPrimary
        class="border-gray"
        outline
        small
        @click:base-button="onMarkAllAsRead"
      >{{ lang.btnText }}</ButtonPrimary>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import TableNotifications from "@/components/Tables/TableNotifications";
import ButtonPrimary from "@/components/ButtonPrimary";

export default {
  components: {
    TableNotifications,
    ButtonPrimary
  },
  computed: {
    ...mapState({
      readAllSuccessMsg: state =>
        state.errors.notifyMessages.markAsReadNotification,
      readAllErrorMsg: state => state.errors.notifyMessages.somethingWrong
    }),
    ...mapGetters({
      notifications: "getNotifications"
    }),
    lang() {
      return {
        title: "Notifications",
        btnText: "Mark all as read"
      };
    }
  },
  methods: {
    ...mapActions([
      "fetchNotifications",
      "readAllNotifications",
      "setNotifyMsg"
    ]),
    async onMarkAllAsRead() {
      const { status } = await this.readAllNotifications();
      this.setNotifyMsg({
        color: status ? "success" : "error",
        text: status ? this.readAllSuccessMsg : this.readAllErrorMsg
      });
    }
  }
};
</script>

<style scoped lang="scss">
.notification-wrapper {
  max-width: $max-width;

  .notification-content {
    margin-top: 20px;
  }
  .notification-headers-header {
    float: right;
  }
}
</style>
