<template>
  <div class="page-size">
    <div
      class="page-size__item"
      :class="{'page-size__item--active': $route.query.page_size == size || (!$route.query.page_size && size == 20)}"
      v-for="(size, k) in sizes"
      :key="k"
      @click="$emit('click:page-size-current', size)"
    >{{ size }}</div>
  </div>
</template>

<script>
export default {
  props: {
    sizes: {
      type: Array,
      default() {
        return [5, 10, 15, 20];
      }
    }
  }
};
</script>

<style scoped lang="scss">
.page-size__item {
  display: inline-block;
  padding-right: 15px;
  font-size: 14px;
  color: $text-color;
  cursor: pointer;
  @include breakpoint($m) {
    padding-right: 10px;
  }
}
.page-size__item--active {
  color: $text-sidebar-color;
}
</style>
