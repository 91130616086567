<template>
  <form class="landing-form" @keydown.enter.prevent="handleSubmit">
    <div class="landing-form-content">
      <div class="landing-form-content__item" :class="{'base-input--error': errors.email.length}">
        <label class="landing-form-content__item__label" for="email">{{ lang.email }}</label>
        <v-text-field
          type="email"
          v-model="form.email"
          :error-messages="errors.email.length ? errors.email : [] "
          @input="errors = {...errors, email: []}"
          browser-autocomplete
        />
      </div>
      <div
        class="landing-form-content__item"
        :class="{'base-input--error': errors.plainPassword.first.length}"
      >
        <label class="landing-form-content__item__label" for="password">{{ lang.password }}</label>
        <router-link :to="{name: 'recovery-pass'}">
          <a
            class="landing-form-content__item__forgot-password hover--red"
          >{{ lang.passwordForgot }}</a>
        </router-link>
        <v-text-field
          type="password"
          v-model="form.password"
          :error-messages="errors.plainPassword.first.length ? errors.plainPassword.first : [] "
          @input="errors = {...errors, plainPassword: {...errors.plainPassword, first: []}}"
          browser-autocomplete
        />
      </div>
    </div>
    <div class="landing-form__button">
      <ButtonPrimary block :loading="progress" @click:base-button="handleSubmit">{{ lang.btnText }}</ButtonPrimary>
    </div>
  </form>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import ButtonPrimary from "@/components/ButtonPrimary";

export default {
  components: {
    ButtonPrimary
  },
  data() {
    return {
      errors: {
        email: [],
        plainPassword: {
          first: []
        }
      }
    };
  },
  computed: {
    ...mapGetters({
      progress: "getIsProgress"
    }),
    ...mapState({
      form: state => state.forms.forms.signIn
    }),
    lang() {
      return {
        email: "Email",
        password: "Password",
        passwordForgot: "Forgot Password",
        btnText: "Sign In"
      };
    }
  },
  methods: {
    handleSubmit() {
      if (this.validateForm(this.form)) {
        this.$emit("submit:form", this.form);
      }
    },
    validateForm(form) {
      let isValid = true;

      if (!form.email) {
        this.errors = { ...this.errors, email: ["The email field is empty"] };
        isValid = false;
      }

      if (!form.password) {
        this.errors = {
          ...this.errors,
          plainPassword: {
            ...this.errors.plainPassword,
            first: ["The password is empty"]
          }
        };
        isValid = false;
      }

      return isValid;
    }
  }
};
</script>

<style scoped lang="scss">
</style>
