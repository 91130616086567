import { render, staticRenderFns } from "./IconReferrals.vue?vue&type=template&id=dbee3e14&scoped=true&"
import script from "./IconReferrals.vue?vue&type=script&lang=js&"
export * from "./IconReferrals.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dbee3e14",
  null
  
)

component.options.__file = "IconReferrals.vue"
export default component.exports