<template>
  <BaseIcon :width="width" :height="height" stroke="none" :fill="fill" name="test">
    <path
      d="M9.016 0C13.968 0 18 4.032 18 9.016 18 13.968 13.968 18 9.016 18 4.032 18 0 13.968 0 9.016A9.01 9.01 0 0 1 9.016 0zm0 4.413a3.4 3.4 0 0 1 3.397 3.397 3.39 3.39 0 0 1-1.715 2.952c1.842.603 3.27 2.159 3.683 4.063a7.817 7.817 0 0 0 2.571-5.81A7.94 7.94 0 0 0 9.016 1.08a7.94 7.94 0 0 0-7.937 7.937c0 2.286.984 4.38 2.54 5.81.413-1.905 1.841-3.46 3.683-4.064A3.39 3.39 0 0 1 5.587 7.81c0-1.873 1.556-3.397 3.429-3.397zm4.413 11.174v-.031c-.223-2.254-2.16-3.969-4.413-3.969-2.286 0-4.19 1.715-4.413 3.969v.031a7.732 7.732 0 0 0 4.413 1.365 7.885 7.885 0 0 0 4.413-1.365zM9.016 5.492c-1.302 0-2.35 1.048-2.35 2.318a2.344 2.344 0 0 0 2.35 2.349c1.27 0 2.317-1.048 2.317-2.35a2.33 2.33 0 0 0-2.317-2.317z"
    />
  </BaseIcon>
</template>

<script>
export default {
  props: {
    width: {
      type: [Number, String],
      default: "18"
    },
    height: {
      type: [Number, String],
      default: "18"
    },
    fill: {
      type: String,
      default: "#7A7C7E"
    },
    stroke: {
      type: String,
      default: "none"
    }
  }
};
</script>

<style scoped>
</style>
